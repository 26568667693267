import api from '@/api/'
import DocumentModel from '../models//documentModel'

class DocumentService {
  static async create (huntAreaId, folderId, file) {
    try {
      const formData = new FormData() // eslint-disable-line
      formData.append('fileContents', file)

      const response = await api.post(`/areas/${huntAreaId}/documents`, {
        name: file.name,
        folderId: folderId
      })

      const document = new DocumentModel(response.data)
      await api.post(`/areas/${huntAreaId}/documents/${document.id}/contents`, formData)

      return document
    } catch (error) {
      console.error('Failed to create document')
      throw error
    }
  }

  static async read (huntAreaId, folderId) {
    try {
      const { data } = await api.get(`/areas/${huntAreaId}/documents?folderId=${folderId}`)
      const documents = data.map(data => new DocumentModel(data))
      return documents
    } catch (error) {
      console.error(`Failed to get documents for area ${huntAreaId}.`)
      throw error
    }
  }

  static async readContents (huntAreaId, document) {
    try {
      const { data } = await api.get(`/areas/${huntAreaId}/documents/${document.id}/contents`, { responseType: 'arraybuffer' })

      const blob = new Blob([data], { // eslint-disable-line
        type: document.mimeType
      })

      return blob
    } catch (error) {
      console.error(`Failed to get content for document ${document.id}.`)
      throw error
    }
  }

  static async update (huntAreaId, document) {
    try {
      const { data } = await api.put(`/areas/${huntAreaId}/documents/${document.id}`, document.toJSON())
      return new DocumentModel(data)
    } catch (error) {
      console.error(`Failed to update document ${document.id}.`)
      throw error
    }
  }

  static async delete (huntAreaId, document) {
    try {
      await api.delete(`/areas/${huntAreaId}/documents/${document.id}`)
    } catch (error) {
      console.error(`Failed to remove document ${document.id}.`)
      throw error
    }
  }
}

export default DocumentService
