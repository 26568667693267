export default {
  huntingReport: "Rapport de chasse",
  huntingReportDefaultTitle: "Rapport de chasse {date}",

  title: "Titre",
  titlePlaceholder: "Nom de la chasse",

  startedAt: "Commencé à",
  startedAtPlaceholder: "Quand la chasse a-t-elle commencé ?",
  startedAtRequired: "Vous devez saisir une heure de début.",

  endedAt: "Terminé à",
  endedAtPlaceholder: "Quand la chasse s'est-elle terminée ?",
  endedAtRequired: "Vous devez saisir une heure de fin.",

  manHours: "Heures de travail",
  manHoursAutomaticCalculation: "Calculer automatiquement",

  comment: "Commentaire",
  commentNone: "Aucun commentaire spécifié",

  location: "Emplacement",
  locationHelpText: "Sélectionnez un poste ou un mirador en cliquant sur leur marqueur, ou cliquez sur la carte pour sélectionner une coordonnée spécifique.",
  locationError: "Vous devez choisir une coordonnée sur la carte ou sélectionner un poste/un mirador.",

  dateAndTime: "Date et heure",
  enterTime: "Saisissez l'heure",
  dateError: "Vous devez saisir une date.",
  sendToViltrapport: "Envoyer à Viltrapport",

  gameRemovedMessage: "Vous avez supprimé <strong>{gameName}</strong> qui a été signalé à Viltrapport. Pour supprimer les informations de Viltrapport, vous devez contacter votre administrateur de zone.",
  exportFailedErrorMessage: "Une erreur s'est produite et les rapports de chasse n'ont pas pu être exportés vers Excel. Veuillez réessayer.",

  // Special String used to download animals using the correct country code regardless of language chosen
  loadAnimalsCountryCode: "gb",

  participants: {
    title: 'Participants',
    none: "Il n'y a aucun participant",
    add: "Ajouter des participants",
    select: "Sélectionner un participant",
    unspecified: "Non spécifié",
    showAll: "Afficher tout",
    emailNotFound: "Il n'y a aucun utilisateur avec l'adresse e-mail spécifiée. Voulez-vous toujours ajouter le participant ?",
    helpText: "Ajoutez tous les participants à inclure dans le rapport. Vous pouvez ajouter des membres de l'équipe de chasse ou inviter des chasseurs invités en recherchant leur adresse e-mail.",
    allMembersIncludedInReport: "Tous les membres de l'équipe de chasse sont inclus dans le rapport.",
    addButtonTitle: "Ajouter | Ajouter <strong>1</strong> participants | Ajouter <strong>{userCount}</strong> participants"
  },

  events: {
    title: "Événements",
    none: "Aucun événement n'a été signalé.",
    noneOccurredYet: "Aucun événement ne s'est encore produit.",
    addKill: "Ajouter du gibier tué",
    addObservation: "Ajouter du gibier observé",
    editKill: "Modifier le gibier tué",
    editObservation: "Modifier le gibier observé",
    editAfterSearch: "Modifier après recherche",
    kill: "Gibier prélevé",
    killShort: "Prélevé",
    observation: 'Observation',
    observationShort: "Obs.",
    afterSearch: "Blessé",
    afterSearchShort: "Blessé",
    shooter: "Tireur",
    observer: "Observateur"
  },

  game: {
    title: "Gibier",
    add: "Ajouter une espèce",
    none: "Aucun gibier trouvé pour votre pays. Contactez l'assistance.",
    error: "Vous devez ajouter au moins une espèce.",
    sent: "Envoyé",
    type: "Type",
    typeSelect: "Sélectionner le type"
  },

  invitation: {
    title: 'Invitation',
    invited: "Invité",
    none: "Aucune invitation n'a été envoyée."
  },

  chat: {
    title: "Messages",
    none: "Aucun message n'a encore été envoyé."
  },

  markers: {
    affectedByHunt: "Affecté par la chasse",
    all: "Tous"
  },

  removeReport: {
    title: "Supprimer le rapport",
    message: "Êtes-vous sûr de vouloir supprimer le rapport ?",
    viltrapportMessage: "Les informations contenues dans ce rapport de chasse ont été signalées à Viltrapport. Pour supprimer les informations de Viltrapport, vous devez contacter l'administrateur de votre zone.<br/><br/> Voulez-vous supprimer le rapport de chasse ?"
  },

  removeEvent: {
    title: "Supprimer l'événement",
    message: "Êtes-vous sûr de vouloir supprimer l'événement ?",
    viltrapportMessage: "Les informations sur cet événement ont été signalées à Viltrapport. Pour supprimer les informations de Viltrapport, vous devez contacter l'administrateur de votre zone.<br/><br/>Voulez-vous supprimer l'événement ?"
  }
}
