import MemberService from '../services/memberService'
import inviteStore from './memberInviteStore'

import MemberModel from '../models/memberModel'

export default {
  namespaced: true,

  modules: {
    invites: inviteStore
  },

  state: {
    members: null,
    view: 'grid' // `list` or `grid`
  },

  getters: {
    members: state => {
      if (state.members !== null) {
        return state.members.slice().sort((a, b) => a.getFullName().localeCompare(b.getFullName()))
      }
      return null
    },

    getAdmin (state) {
      if (state.members !== null) {
        const i = state.members.findIndex(member => member.role === 'admin')
        return i !== -1 ? state.members[i] : null
      }
      return null
    },

    isAdmin (state, getters, rootState, rootGetters) {
      const userId = rootGetters['auth/getUserId']
      const admin = getters.getAdmin

      return admin !== null ? admin.id === userId : false
    },

    view: state => state.view
  },

  mutations: {
    set (state, members) {
      state.members = members
    },

    add (state, member) {
      if (!state.members) state.members = []
      state.members.push(member)
    },

    delete (state, userId) {
      if (state.members !== null) {
        const i = state.members.findIndex(member => member.id === userId)
        if (i !== -1) {
          state.members.splice(i, 1)
        }
      }
    },

    update (state, member) {
      const i = state.members.findIndex(e => e.id === member.id)
      state.members[i] = member
    },

    changeView (state, view) {
      state.view = view
      window.localStorage.setItem('teamView', view)
    }
  },

  actions: {
    async create ({ commit, rootGetters }, user) {
      const huntArea = rootGetters['huntarea/selected']

      // Add placeholder member during load
      commit('add', new MemberModel({
        id: user.id,
        firstName: user.firstName,
        lastName: user.lastName,
        status: 'loading'
      }))

      const member = await MemberService.create(huntArea.id, user.id)

      // Replace with correct member object
      commit('delete', member.id)
      commit('add', member)
    },

    async read ({ commit }, huntAreaId) {
      commit('set', null)
      const members = await MemberService.read(huntAreaId)
      commit('set', members)

      return members
    },

    delete ({ commit }, data) {
      const { huntAreaId, userId } = data

      commit('delete', userId)
      return MemberService.delete(huntAreaId, userId)
    },

    changeView ({ commit }, view) {
      commit('changeView', view)
    },

    readLocalView ({ commit }) {
      const localView = window.localStorage.getItem('teamView') || 'grid'
      commit('changeView', localView)
      return localView
    },

    clear ({ commit }) {
      commit('set', null)
    }
  }
}
