export default {
  firstName: 'First name',
  lastName: 'Last name',
  repeatPassword: 'Repeat password',
  forgotPassword: 'Forgot your password?',

  signin: {
    title: 'Sign in',
    remember: 'Remember',
    error: 'Sorry, we could not sign you in. Please try again.',
    answerInvite: 'Please sign in to reply to the invite.',
    oldSiteDividerTitle: 'Or',
    oldSiteSignIn: 'Log in to the old web'
  },

  join: {
    title: 'Sign up',
    invalidName: 'You must enter a name',
    invalidLastName: 'You must enter a last name',
    repeatPassword: 'Repeat password',
    invalidEmail: 'You must enter a valid Email',
    emailOccupied: 'This e-mail is already registered in our system.',
    passNoMatch: "Your password is either too short or doesn't match.",
    welcomeToWeHunt: 'Welcome to WeHunt <strong>{firstName}</strong>. We are glad that you like to be a part of our community!',
    downloadAppReminder: 'Don\'t forget to download the app to your phone from the Appstore or Google Play.',
    accountRequiredToAnswerInvite: 'You must register a <strong>free</strong> WeHunt account in order to respond to the invitation.',
    signInAndAnswerInvitation: 'Sign in and answer the invitation',
    unknownErrorMessage: 'An error occurred and the account could not be registered. Please try again. '
  },

  recover: {
    title: 'Recover',
    helpText: 'Enter your email address and we will send you an email with instructions on how to create a new password.',
    success: 'An e-mail message has been sent to you with instructions on how to reset your password.<br /><br />If you do not find the email, please make sure that you have typed in your email correctly and check the spam folder in your e-mail client.',
    recoverPasswordFailedErrorMessage: 'An error occurred and it was not possible to reset your password. Please try again.',
    invalidEmail: 'You have to enter a valid e-mail'
  },

  updatePassword: {
    title: 'Update password',
    helpText: 'Enter a new password for {email}',
    updateButton: 'Update password',
    invalidQueryError: 'Invalid URL',
    permissionError: 'You are not allowed to update the password for this account.',
    success: 'Password has been updated!'
  },

  signout: {
    title: 'Sign out'
  }
}
