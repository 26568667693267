export default {
  title: "Paramètres",
  name: "Nom",
  nameHelpText: "Le nom est important pour que vos membres trouvent facilement le territoire de chasse.",

  county: {
    title: "Département",
    notSpecified: "Non spécifié",
    helpText: "Le département est utilisé pour afficher le tableau de chasse correct dans l'application."
  },

  delete: {
    title: "Supprimer le territoire de chasse",
    helpText: "La suppression du territoire de chasse supprime définitivement l'équipe de chasse, la carte, toutes les chasses et toutes les informations.<br /><strong>Cette action est irréversible.</strong>",
    errorMessage: "Une erreur s'est produite et le territoire  de chasse n'a pas pu être supprimé. Veuillez réessayer."
  },

  leave: {
    title: "Quitter le territoire de chasse",
    helpText: "Lorsque vous quittez le territoire de chasse, celui-ci disparaît de la liste de l'application et vous ne pouvez plus participer à la chasse. Cependant, vous pouvez être réinvité à tout moment.",
    errorMessage: "Une erreur s'est produite et le territoire de chasse n'a pas pu être quitté. Veuillez réessayer."
  },

  viltrapport: {
    title: "Viltraport",
    helpText: "Le territoire de chasse est relié aux districts de Viltrapport.<br> Vous pouvez désormais consulter les quotas et créer des rapports dans ces districts.",
    notConnectedTitle: "Incompatible",
    notConnectedMessage: "Cette fonctionnalité n'est disponible que pour les terrrain de chasse situés en Suède."
  }
}
