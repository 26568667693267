import ContentPlaceholders from './ContentPlaceholders.vue'
import Heading from './components/Heading.vue'
import Img from './components/Img.vue'
import Text from './components/Text.vue'

export default {
  install (Vue) {
    Vue.component('ContentPlaceholders', ContentPlaceholders)
    Vue.component('ContentPlaceholdersHeading', Heading)
    Vue.component('ContentPlaceholdersImg', Img)
    Vue.component('ContentPlaceholdersText', Text)
  }
}
