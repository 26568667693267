<template>
  <Panel :isActive="isPanelActive" class="settings-panel">
    <PanelTitle :back="false" @close="close">
      <span slot="title">{{ $t('area.nav.settings') }}</span>
    </PanelTitle>

    <PanelContent scrollable>
      <FormField :label="$t('map.settings.mapType')">
        <MapBaseLayerControl />
      </FormField>

      <FormField :control="false">
        <MapPropertyLayerControl />
      </FormField>

      <FormField :control="false">
        <MapTerrainLayerControl />
      </FormField>

      <Divider />

      <slot name="switch" />

      <div v-if="scaleMarkers || scaleLabels" class="push-top">
        <h5 class="title is-6 push-bottom-xs">{{ $t('map.general.size') }}</h5>
        <ScaleMarkers v-if="scaleMarkers" />
        <ScaleLabels v-if="scaleLabels" />

        <Divider />
      </div>

      <div v-if="type === 'advanced'" class="push-top">
        <h5 class="title is-6 push-bottom-xs">{{ $t('map.general.labels') }}</h5>

        <MarkersLabelSwitch />
        <SubAreasLabelSwitch />
        <ForbiddenAreasLabelSwitch />
        <LinesLabelSwitch />
        <TrailsLabelSwitch />
        <ArrowsLabelSwitch />

        <Divider />
      </div>

      <div v-if="type === 'advanced'" class="push-top">
        <h5 class="title is-6 push-bottom-xs">{{ $t('map.general.zoom') }}</h5>
        <ScrollToZoomSwitch />
      </div>

      <slot />
    </PanelContent>
  </Panel>
</template>

<script>
import {
  ScaleMarkers,
  ScaleLabels,

  MarkersLabelSwitch,
  SubAreasLabelSwitch,
  ForbiddenAreasLabelSwitch,
  LinesLabelSwitch,
  TrailsLabelSwitch,
  ArrowsLabelSwitch,

  ScrollToZoomSwitch
} from './components/'

import MapBaseLayerControl from '@/modules/map/components/settings/MapBaseLayerControl.vue'
import MapPropertyLayerControl from '@/modules/map/components/settings/MapPropertyLayerControl.vue'
import MapTerrainLayerControl from '@/modules/map/components/settings/MapTerrainLayerControl.vue'

import { mapGetters } from 'vuex'

export default {
  components: {
    MapBaseLayerControl,
    MapPropertyLayerControl,
    MapTerrainLayerControl,

    ScaleMarkers,
    ScaleLabels,

    MarkersLabelSwitch,
    SubAreasLabelSwitch,
    ForbiddenAreasLabelSwitch,
    LinesLabelSwitch,
    TrailsLabelSwitch,
    ArrowsLabelSwitch,

    ScrollToZoomSwitch
  },

  props: {
    type: {
      type: String,
      default: 'basic' // 'basic' or 'advanced'
    },

    scaleMarkers: {
      type: Boolean,
      default: true
    },

    scaleLabels: {
      type: Boolean,
      default: true
    }
  },

  computed: {
    ...mapGetters({
      area: 'huntarea/selected',
      markers: 'map/markers/markers',
      isPanelActive: 'map/settings/isPanelActive'
    }),

    hasMarkers () {
      return this.markers && this.markers.length
    },

    isAdmin () {
      return this.$parent.isAdmin
    }
  },

  methods: {
    close () {
      this.$store.dispatch('map/settings/closePanel')
    }
  }
}
</script>

<style lang="scss">
.settings-panel label {
  font-size: 0.85rem;
}
</style>
