import LineService from '@/api/map/lines/lineService'
import LineModel from '@/api/map/lines/lineModel'

export default {
  async create ({ commit, dispatch }, data) {
    const { huntAreaId, line } = data

    dispatch('map/setDrawingMode', null, { root: true })
    const createdLine = await LineService.create(huntAreaId, new LineModel(line))
    commit('add', createdLine)
    return createdLine
  },

  async read ({ commit }, huntAreaId) {
    commit('set', null)
    const lines = await LineService.read(huntAreaId)
    commit('set', lines)

    return lines
  },

  async update ({ commit }, data) {
    const { huntAreaId, line } = data

    const updatedLine = await LineService.update(huntAreaId, line)
    commit('update', updatedLine)
    return updatedLine
  },

  async delete ({ commit }, data) {
    const { huntAreaId, line } = data

    await LineService.delete(huntAreaId, line.id)
    commit('delete', line)
  },

  select ({ commit, dispatch }, line) {
    dispatch('map/closeAllPanels', null, { root: true })
    commit('select', line)
  },

  clear ({ commit }) {
    commit('set', null)
  }
}
