import dayjs from 'dayjs'
import api from '@/api/'
import CalendarEventModel from '../models/calendarEventModel'

import dayjsPluginUTC from 'dayjs-plugin-utc'
dayjs.extend(dayjsPluginUTC, { parseToLocal: true })

class CalendarEventService {
  static async read (huntAreaId, { from, to }) {
    const startTime = dayjs(from).utc().toISOString()
    const endTime = dayjs(to).hour(23).minute(59).utc().toISOString()

    try {
      const { data } = await api.get(`/areas/${huntAreaId}/calendar/events?from=${startTime}&to=${endTime}`)
      return data.map(event => new CalendarEventModel(event))
    } catch (error) {
      console.error(`Failed to read calendar events for huntArea ${huntAreaId}.`)
      throw error
    }
  }

  static async readById (huntAreaId, eventId) {
    try {
      const response = await api.get(`/areas/${huntAreaId}/calendar/events/${eventId}`)
      return new CalendarEventModel(response.data)
    } catch (error) {
      console.error(`Failed to read calendar event ${eventId} for huntArea ${huntAreaId}.`)
      throw error
    }
  }

  static async create (event) {
    try {
      const { data } = await api.post(`/areas/${event.huntAreaId}/calendar/events`, event.toJson())
      return new CalendarEventModel(data)
    } catch (error) {
      console.error(`Failed to create calendar event for huntArea ${event.huntAreaId}.`)
      throw error
    }
  }

  static async update (event) {
    try {
      const { data } = await api.put(`/areas/${event.huntAreaId}/calendar/events/${event.id}`, event.toJson())
      return new CalendarEventModel(data)
    } catch (error) {
      console.error(`Failed to update calendar event ${event.id} for huntArea ${event.areaId}.`)
      throw error
    }
  }

  static async delete (event) {
    try {
      await api.delete(`/areas/${event.huntAreaId}/calendar/events/${event.id}`)
    } catch (error) {
      console.error(`Failed to delete calendar event ${event.id} for huntArea ${event.huntAreaId}.`)
      throw error
    }
  }
}

export default CalendarEventService
