<template>
  <Fragment>
    <header class="card-header">
      <span class="card-header-title">
        <span class="push-right-xs is-clickable" @click="back">
          <Icon name="icon-arrow-left" />
        </span>

        <span>{{ $t('userDropdown.games') }}</span>
      </span>
    </header>

    <div class="card-content is-paddingless">
      <ul class="user-dropdown-menu">
        <li>
          <a href="//my.wehuntapp.com/shootingrange" target="_blank" rel="noopener">
            {{ $t('userDropdown.elkGame') }}
          </a>
        </li>

        <li>
          <a href="//my.wehuntapp.com/aimpoint" target="_blank" rel="noopener">
            {{ $t('userDropdown.boarGame') }}
          </a>
        </li>
      </ul>
    </div>
  </Fragment>
</template>

<script>
export default {

  methods: {
    back () {
      this.$emit('back')
    },

    close () {
      this.$emit('close')
    }
  }

}
</script>
