<template>
  <aside class="sidebar-nav">
    <GlobalNavigation />
    <HuntAreaNavigation />
    <GpsNavigation />
  </aside>
</template>

<script>
import { mapGetters } from 'vuex'

import GlobalNavigation from './parts/GlobalNavigation.vue'
import HuntAreaNavigation from './parts/HuntAreaNavigation.vue'
import GpsNavigation from './parts/GpsNavigation.vue'

export default {
  components: {
    GlobalNavigation,
    HuntAreaNavigation,
    GpsNavigation
  },

  data () {
    return {
      huntAreaId: this.$route.params.huntAreaId
    }
  },

  computed: {
    ...mapGetters({
      huntArea: 'huntarea/selected',
      hasAuth: 'auth/hasAuth',
      invites: 'hunt/invites/invites'
    }),

    totalInvites () {
      return this.invites && this.invites.filter(invite => Number(invite.huntAreaId) === Number(this.huntArea.id)).length
    },

    isActive: {
      get () { return this.$store.getters['ui/isMobileNavActive'] },
      set (state) { return this.$store.dispatch('ui/mobileNav', state) }
    },

    navbarActive () {
      return this.isActive ? 'is-active' : ''
    },

    huntAreaName () {
      return this.huntArea && this.huntArea.name
    },

    huntAreasPanelActive: {
      get () { return this.$store.getters['ui/huntAreasPanelActive'] },
      set (bool) { return this.$store.commit('ui/setHuntAreasPanelActive', bool) }
    },

    gpsPanelActive: {
      get () { return this.$store.getters['ui/gpsPanelActive'] },
      set (bool) { return this.$store.commit('ui/setGpsPanelActive', bool) }
    }
  },

  methods: {
    toggleHuntAreasPanel () {
      this.huntAreasPanelActive = !this.huntAreasPanelActive
    },

    toggleGpsPanel () {
      this.gpsPanelActive = !this.gpsPanelActive
    }
  }
}

</script>

<style lang="scss">
.sidebar-nav {
  display: block;
}

.sidebar-nav-label {
  padding-right: 1.5rem;
  margin: 0 0 0.5rem 1.5rem;
  color: #eee;
  font-size: 0.75rem;
  font-family: 'Roboto', 'Open Sans', sans-serif;
  letter-spacing: 0.125rem;
  text-transform: uppercase;
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  &:not(:first-child) {
    margin-top: 1.5rem;
  }

  .menu-list {
    line-height: 1.25;
  }

  &:hover {
    color: white;
  }
}

.sidebar-nav-list {
  li {
    position: relative;
    font-size: 0.95rem;
    font-weight: 400;
    padding: 0.375rem 0 0.375rem 1.5rem;

    &::after {
      content: '';
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 8px 10px 8px 0;
      border-color: transparent #fff transparent transparent;
      position: absolute;
      right: 0;
      margin-right: -1px;
      top: 50%;
      transform: translateY(-50%);
      transition: all 0.15s ease-out;
      transition-delay: 0.25s;
      opacity: 0;
    }

    a {
      font-family: 'Open Sans', sans-serif;
      color: rgb(188, 188, 188);
      letter-spacing: 0.5px;
      display: flex;
      align-items: center;

      .icon {
        margin-right: 0.5rem;
      }

      &:hover {
        color: white;
      }

      &.is-active {
        color: #f1a269;
        border-right: 4px solid #f1a269;
      }
    }

    &.is-active {
      &::after {
        opacity: 1;
        transform: translateY(-50%);
      }
    }

    .force-right {
      margin-left: auto;
    }
  }

  &:last-of-type {
    margin-bottom: 1.5rem;
  }
}
</style>
