<template>
  <div class="accordion-item">
    <div class="accordion-item-title" @click="toggle">
      {{ name }}
    </div>
    <div class="accordion-item-content">
      <slot />
    </div>
  </div>
</template>

<script>
export default {

  props: {
    name: {
      type: String,
      default: ''
    }
  },

  methods: {
    toggle () {
      this.hideAll()
      this.$el.classList.add('is-active')
    },

    hideAll () {
      if (this.$el.parentNode.className === 'accordion') {
        const items = this.$el.parentNode.children

        Array.prototype.forEach.call(items, item => {
          item.classList.remove('is-active')
        })
      }
    }
  }

}
</script>

<style lang="scss">
.accordion-item {
  padding: 0;
  margin-bottom: 0.5rem;

  &.is-active {
    .accordion-item-content {
      opacity: 1;
      max-height: 9999px;
    }
  }

  .accordion-item-title {
    padding: 0.5rem;
    background: #eaeaea;
    margin-bottom: 0.5rem;
  }

  .accordion-item-content {
    opacity: 0;
    max-height: 0;
    transition: all 0.15s ease-in-out;
    will-change: max-height, opacity;
  }
}
</style>
