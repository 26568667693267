import dayjs from 'dayjs'

class TrailModel {
  constructor (context) {
    const data = Object.assign({}, context)

    this.id = data.id || null
    this.parent = data.parent || {}
    this.etag = data.etag || null

    this.name = data.name || ''
    this.color = data.color || ''
    this.coordinates = data.coordinates || []

    this.created = data.created ? dayjs(data.created).toDate() : null
    this.createdBy = data.createdBy || null
    this.updated = data.updated ? dayjs(data.updated).toDate() : null
    this.updatedBy = data.updatedBy || null

    this.visible = data.visible !== undefined ? data.visible : true
    this.editable = data.editable !== undefined ? data.editable : false
  }

  toJSON () {
    return {
      id: this.id,
      parent: this.parent,

      name: this.name,
      color: this.color.length > 0 ? this.color : null,
      coordinates: this.coordinates,

      visible: this.visible,
      editable: this.editable
    }
  }
}

export default TrailModel
