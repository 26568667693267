export default [{
  path: '/invite/:token/calendarevent',
  component: () => import('./components/InviteActivityPage.vue'),
  meta: {
    isPublic: false
  }
}, {
  path: '/invite/:token/hunt',
  component: () => import('./components/InviteHuntPage.vue'),
  meta: {
    isPublic: false
  }
}, {
  path: '/invite/:token/huntarea',
  component: () => import('./components/InviteHuntAreaPage.vue'),
  meta: {
    isPublic: true
  }
},
/*
 * Legacy URL's
*/
{
  path: '/invitation/:token/calendarevent',
  component: () => import('./components/InviteActivityPage.vue'),
  meta: {
    isPublic: false
  }
}, {
  path: '/invitation/:token/hunt',
  component: () => import('./components/InviteHuntPage.vue'),
  meta: {
    isPublic: false
  }
}, {
  path: '/invitation/:token/huntarea',
  component: () => import('./components/InviteHuntAreaPage.vue'),
  meta: {
    isPublic: true
  }
}, {
  path: '/invitation/:token/area',
  component: () => import('./components/InviteHuntAreaPage.vue'),
  meta: {
    isPublic: true
  }
}]
