import dayjs from 'dayjs'

export const ADVERTISEMENT_SPACE_SIGN_IN = 'signin'
export const ADVERTISEMENT_SPACE_FULL_PAGE = 'fullpage'
export const ADVERTISEMENT_SPACE_SPONSORED_POST = 'sponsoredpost'

class AdvertisementModel {
  constructor (data) {
    this.customer = data.customer || ''
    this.title = data.title || ''
    this.content = data.content || ''
    this.space = data.space || ''
    this.startDate = data.startDate ? dayjs(data.startDate).toDate() : null
    this.endDate = data.endDate ? dayjs(data.endDate).toDate() : null
    this.targetUrl = data.targetUrl || null
    this.logoUrl = data.logoUrl || ''
    this.imageUrl = data.imageUrl || ''
    this.language = data.language || null
    this.trackingPixelUrl = data.trackingPixelUrl || ''
  }
}

export default AdvertisementModel
