import api from '@/api/'
import { ImportDataModel } from './importDataModel'

class GPXService {
  static async import (huntAreaId, formData) {
    try {
      const { data } = await api.post(`/areas/${huntAreaId}/map/import`, formData)
      return new ImportDataModel(data)
    } catch (error) {
      console.error(`Failed to read import file. ${error}`, error)
      Promise.reject(error)
    }
  }

  static async export (huntAreaId, json) {
    try {
      const { data } = await api.post(`/areas/${huntAreaId}/map/export`, json)
      return data
    } catch (error) {
      console.error(`Failed to download huntarea ${huntAreaId} as GPX.`, error)
      throw error
    }
  }
}

export default GPXService
