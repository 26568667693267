<template>
  <div v-if="visible" class="notification is-white cookies-notification">
    <button class="delete" @click="close" />

    <div class="has-text-centered">
      <p class="subtitle is-6">{{ $t('core.cookies.information') }}</p>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      visible: true
    }
  },

  created () {
    this.visible = localStorage.getItem('cookies') !== 'false'
  },

  methods: {
    close () {
      this.visible = false
      localStorage.setItem('cookies', 'false')
    }
  }
}
</script>

<style lang="scss" scoped>
.notification.cookies-notification {
  background-color: white;
  border-radius: 6px;
  box-shadow: 0 0.5em 1em -0.125em rgba(10, 10, 10, 0.1),
    0 0px 0 1px rgba(10, 10, 10, 0.02);
  display: block;
  padding: 1.25rem;

  max-width: 25rem;
  margin-bottom: 0 !important;

  position: fixed !important;
  right: 1.5rem;
  bottom: 1.5rem;
  z-index: 100;

  display: flex;

  .subtitle {
    color: #4a4a4a;
  }
}
</style>
