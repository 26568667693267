export default {
  title: 'Settings',
  name: 'Name',
  nameHelpText: 'The name is important for your members to easily find the hunting ground.',

  county: {
    title: 'County',
    notSpecified: 'Unspecified',
    helpText: 'The county is used to show the correct hunting table in the app.'
  },

  delete: {
    title: 'Remove hunting ground',
    helpText: 'Removing the hunting ground removes the hunting team, the map, all hunts and all information permanently.<br /><strong>This cannot be undone.</strong>',
    errorMessage: 'An error occurred and the hunting ground could not be removed. Please try again.'
  },

  leave: {
    title: 'Leave hunting ground',
    helpText: 'When you leave the hunting ground, it disappears from the list in the app and you can no longer participate in hunting. However, you can be invited again at any time.',
    errorMessage: 'An error occurred and the hunting ground could not be left. Please try again.'
  },

  viltrapport: {
    title: 'Viltrapport',
    helpText: 'The hunting ground is connected to districts in Viltrapport.<br> You can now see quotas and create reports in these districts.',
    notConnectedTitle: 'Not supported',
    notConnectedMessage: 'This feature is only available for hunting grounds located in Sweden.'
  }
}
