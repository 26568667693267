import HuntService from '@/api/hunt/huntService'
import HuntInviteService from '@/api/hunt/invites/huntInviteService'

export default {
  async readAll ({ commit }) {
    commit('set', null)

    const now = new Date()

    let invites = await HuntInviteService.readAll()
    invites = invites.filter(invite => invite.startsAt >= now)
    invites.sort((a, b) => a.startsAt < b.startsAt ? -1 : 1)

    commit('set', invites)

    return invites
  },

  async answerInvite ({ commit }, { invite, answer }) {
    await HuntInviteService.answerById(invite.id, answer)
    commit('remove', invite.id)

    if (answer) {
      const hunt = await HuntService.read(invite.id)
      commit('hunt/add', hunt, { root: true })
    }
  }
}
