<template>
  <div class="field" :class="{ 'is-horizontal': horizontal }">
    <div v-if="horizontal" class="field-label is-normal">
      <label class="label">
        {{ label }} <span v-if="mandatory" class="has-text-danger">*</span>
      </label>
    </div>

    <label v-if="label.length > 0 && !horizontal" class="label">
      {{ label }} <span v-if="mandatory" class="has-text-danger">*</span>
    </label>

    <div v-if="horizontal" class="field-body">
      <div v-if="control" class="control">
        <slot />
      </div>

      <slot v-else />
    </div>

    <div v-else>
      <div v-if="control" class="control">
        <slot />
      </div>

      <slot v-else />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      default: ''
    },

    horizontal: {
      type: Boolean,
      default: false
    },

    mandatory: {
      type: Boolean,
      default: false
    },

    control: {
      type: Boolean,
      default: true
    }
  }
}
</script>
