export default {
  title: 'Nyheder',
  readFullArticle: 'Læs mere på {source}',
  showMore: 'Vis mere...',
  ad: 'Reklame',
  advertisedContent: 'Annonceret indhold',
  loadFailedErrorMessage: 'Der opstod en fejl, og nyhederne kunne ikke downloades. Prøv igen.',
  newsItemNotFoundTitle: 'Kunne ikke indlæse nyhederne',
  newsItemNotFoundMessage: 'Det ser ud til, at du har klikket på et forkert link. Prøv igen.'
}
