<template>
  <div :class="[barsClass]" class="icon-bars">
    <div class="first-bar bar"></div>
    <div class="second-bar bar"></div>
    <div class="third-bar bar"></div>
    <div class="fourth-bar bar"></div>
  </div>
</template>

<script>
export default {

  props: {
    bars: {
      type: Number,
      default: 0 // 0 - 4
    }
  },

  computed: {
    barsClass () {
      const bars = {
        4: 'exceptional',
        3: 'good',
        2: 'decent',
        1: 'bad'
      }

      return bars[this.bars] || ''
    }
  }

}
</script>

<style lang="scss">
.icon-bars {
  display: inline-block;
  height: 0.75rem;
  width: 1.25rem;

  .bar {
    width: 20%;
    margin-left: 5%;
    min-height: 25%;
    display: inline-block;

    &.first-bar  { height: 25%; }
    &.second-bar { height: 50%; }
    &.third-bar  { height: 75%; }
    &.fourth-bar { height: 99%; }
  }

  &.exceptional .bar {
    background-color: #16a085;
    border: thin solid darken(#16a085, 10%);
  }

  &.good .bar {
    background: #16a085;
    border: thin solid darken(#16a085, 10%);

    &.fourth-bar { @extend %default-bar; }
  }

  &.decent .bar {
    background: #f1c40f;
    border: thin solid darken(#f1c40f, 10%);

    &.third-bar { @extend %default-bar; }
    &.fourth-bar { @extend %default-bar; }
  }

  &.bad .bar {
    background: #e74c3c;
    border: thin solid darken(#e74c3c, 20%);

    &.second-bar { @extend %default-bar; }
    &.third-bar { @extend %default-bar; }
    &.fourth-bar { @extend %default-bar; }
  }
}

%default-bar {
  background: #dedede;
  border: thin solid darken(#dedede, 10%);
}
</style>
