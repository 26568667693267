/**
 * Quota status of a district in Viltrapport. The quotas contain information
 * about how many games are left that can be shot.
 */
class HuntAreaQuotaStatusModel {
  constructor (json) {
    this.restrictions = json.restrictions || null
    this.quotas = json.quotas.map(quota => new HuntAreaQuotaModel(quota))
  }
}

class HuntAreaQuotaModel {
  constructor (json) {
    this.speciesId = json.speciesId
    this.name = json.name
    this.quotas = json.quotas.map(quota => new QuotaModel(quota))
  }
}

class QuotaModel {
  constructor (json) {
    this.name = json.name
    this.quota = json.quota
    this.isFree = json.isFree
    this.animalsLeft = json.animalsLeft
  }
}

export default HuntAreaQuotaStatusModel
