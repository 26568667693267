<template>
  <Portal to="gps-panel">
    <div :class="[activeClass]" class="sidebar-panel">
      <div tabindex="-1" @focusout="close">
        <div class="default-spacing">
          <div class="level">
            <div class="level-left">
              <h5 class="title is-5">{{ $t('gps.gps') }}</h5>
            </div>

            <div class="level-right">
              <button class="delete" @click="close" />
            </div>
          </div>

          <!-- REMOVE ACTIVATE WEHUNT GPS <button class="button is-primary is-fullwidth" @click="activateGps">{{ $t('gps.activateGps') }}</button> -->
        </div>

        <div v-if="hasDevices">
          <GpsPanelBox v-for="(gps, i) in devices" :key="i" :gps="gps" />
        </div>

        <div v-else class="default-spacing is-paddingless-top">
          <!-- <p>{{ $t('gps.activateGpsHelpText') }}</p> -->
        </div>
      </div>
    </div>

    <div v-show="isActive" class="sidebar-panel-background" @click="close"></div>
  </Portal>
</template>

<script>
import { mapGetters } from 'vuex'
import GpsPanelBox from './GpsPanelBox.vue'

export default {
  components: {
    GpsPanelBox
  },

  computed: {
    ...mapGetters({
      hasPro: 'subscription/hasPro',
      devices: 'subscription/devices'
    }),

    isActive: {
      get () {
        return this.$store.getters['ui/gpsPanelActive']
      },

      set (bool) {
        return this.$store.commit('ui/setGpsPanelActive', bool)
      }
    },

    activeClass () {
      return this.isActive ? 'is-active' : ''
    },

    hasDevices () {
      return this.devices && this.devices.length > 0
    }
  },

  watch: {
    '$route' (to, from) {
      this.close()
    }
  },

  created () {
    // Allow direct link to show the GPS panel
    if (this.$route.query.gpsPanel) {
      this.$store.commit('ui/setGpsPanelActive', true)
    }
  },

  methods: {
    close () {
      this.isActive = false
    },

    activateGps () {
      this.close()
      this.$store.dispatch('ui/newGpsModal', true)
    }
  }
}
</script>
