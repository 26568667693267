import state from './state'
import actions from './actions'
import mutations from './mutations'
import getters from './getters'

import boundary from './boundary/'
import markers from './markers/'
import subAreas from './subareas/'
import lines from './lines/'
import trails from './trails/'

import print from './print/'
import settings from './settings/'
import gpx from './gpx/'
import importStore from './import/importStore.js'

const namespaced = true

export default {
  namespaced,
  state,
  actions,
  mutations,
  getters,
  modules: {
    boundary,
    markers,
    subAreas,
    lines,
    trails,
    print,
    settings,
    gpx,
    import: importStore
  }
}
