export default {
  set (state, markers) {
    state.markers = markers
  },

  add (state, marker) {
    state.markers.push(marker)
  },

  delete (state, marker) {
    const i = state.markers.indexOf(marker)

    if (i !== -1) {
      state.markers.splice(i, 1)
    }
  },

  update (state, marker) {
    const m = state.markers.find(m => m.id === marker.id)

    if (m !== undefined) {
      m.assign(marker)
    }
  },

  clear (state) {
    state.markers = null
  },

  select (state, marker) {
    state.selected = marker
  },

  showLabels (state, bool) {
    state.showLabels = bool
  },

  isDeletingOutsideBorder (state, bool) {
    state.isDeletingOutsideBorder = bool
  },

  size (state, size) {
    state.size = Number(size)
  }
}
