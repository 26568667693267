class ImageModel {
  constructor (context) {
    const data = Object.assign({}, context)

    this.imagePath = data.imagePath || ''
    this.src = data.src || ''
    this.createdBy = data.senderUserId.substring(0, data.senderUserId.indexOf('_')) || null
    this.createdAt = data.timestamp || null
    this.hasLoaded = data.hasLoaded || false
  }

  toJSON () {
    return {
      imagePath: this.imagePath,
      src: this.src,
      createdAt: this.createdAt,
      createdBy: this.createdBy,
      hasLoaded: this.hasLoaded
    }
  }
}

export default ImageModel
