<template>
  <div>
    <div :class="classes" class="chat-message">
      <div class="chat-message-bubble">
        <strong>{{ createdBy }}</strong>
        <img v-if="message.imagePath" :src="message.src" :alt="createdBy" />
        <p v-else>{{ message.text }}</p>
      </div>

      <p class="time">{{ fromNow(message.createdAt) }}</p>
    </div>

    <div class="clearfix"></div>
    <!-- IE11 doesn't respect overflowing texts within flexbox weirdly enough -->
    <!-- So I'm using float to position them left or right instead -->
  </div>
</template>

<script>
/* eslint-disable */
export default {
  props: {
    me: {
      type: Boolean,
      default: false
    },

    createdBy: {
      type: String,
      default: ''
    },

    message: {
      type: Object,
      default: () => {}
    }
  },

  data () {
    return {
      isLoading: true
    }
  },

  computed: {
    classes () {
      return this.me ? 'me' : ''
    }
  },

  mounted () {
    if (this.message.imagePath && !this.message.src) {
      this.readMedia(this.message.imagePath)
    } else {
      this.isLoading = false
    }
  },

  methods: {
    async readMedia (path) {
      try {
        const src = await this.$store.dispatch('huntarea/messages/readMedia', path)
        this.message.src = src
      } catch (error) {
        // do nothing
      }

      this.isLoading = false
    },

    fromNow (date) {
      // Show relative time if today.
      // i.e. 2 minutes ago
      if (this.$dayjs(date).isSame(this.$dayjs(), 'day')) {
        return this.$utils.capitalize(this.$dayjs(date).fromNow())
      }

      // Show time & date if this year.
      // i.e. 10.30, 12 aug
      else if (this.$dayjs(date).isSame(this.$dayjs(), 'year')) {
        return this.$dayjs(date).format('HH:mm, D MMM')
      }

      // Otherwise show year as well
      // i.e. 10.45, 13 aug 2019
      return this.$dayjs(date).format('HH:mm, D MMM YYYY')
    }
  }
}
</script>

<style lang="scss">
// Unscoped because <ChatMessagePlaceholder> shares a lot of CSS

.chat-message {
  float: left;
  min-width: 10rem;
  max-width: 30rem;
  margin-bottom: 0.75rem;
  margin-right: 1.5rem;

  // Different methods of breaking looooong words
  overflow-wrap: break-word;
  word-break: normal;
  word-wrap: break-word; /* IE 11 */

  &-bubble {
    padding: 0.75rem;
    font-size: inherit;
    background: #f1f2f6;
    width: 100%;
    border-radius: 0.375rem;

    strong {
      display: block; // break row
    }

    p {
      margin: 0 !important;
    }
  }

  .time {
    margin: 0 !important;
    padding: 0.125rem 0.25rem;
    font-size: 0.75rem;
    color: #888;
  }

  &.me {
    margin-left: 1.5rem;
    margin-right: 0;
    float: right;

    .chat-message-bubble {
      background: #d1e0e5;
    }

    strong {
      color: #3c4d54;
    }

    p {
      color: #425a65;
    }

    .time {
      text-align: right;
    }
  }

  &:last-of-type {
    margin-bottom: 0;
  }

  img {
    max-height: 40rem;
    width: auto;
  }
}
</style>
