class GpsDeviceModel {
  constructor (id, name) {
    this.id = id
    this.name = name
    this.location = { lat: 0, lng: 0 }
    this.trail = []
    this.speed = 0
    this.active = false
    this.visible = true
    this.color = 'black'
  }
}

export default GpsDeviceModel
