export default {
  title: 'GRATIS PRO',
  subtitle: 'Just nu bjuder vi på WeHunt Pro hela augusti',

  getPro: 'Skaffa Pro',
  readMore: 'Läs mer',

  infoText: 'Skaffa Pro nu och få augusti gratis, därefter dras 279kr varje år. Avsluta när du vill.',
  price: 'Augusti gratis, sedan 279kr/år'
}
