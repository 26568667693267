<template>
  <VuePellEditor
    :value="value"
    :actions="actions"
    :content="value"
    :placeholder="placeholder"
    :style-with-css="false"
    :classes="editorClasses"
    default-paragraph-separator=""
    @input="onInput">
  </VuePellEditor>
</template>

<script>
export default {
  props: {
    value: {
      type: String,
      default: ''
    },

    placeholder: {
      type: String,
      default: ''
    },

    actions: {
      type: Array,
      default: () => [
        'heading1',
        'heading2',
        'paragraph',
        'bold',
        'italic',
        'underline',
        'strikethrough',
        'olist',
        'ulist'
      ]
    }
  },

  data () {
    return {
      editorClasses: {
        actionbar: 'pell-actionbar',
        button: 'pell-button',
        content: 'pell-content',
        selected: 'pell-button-selected'
      }
    }
  },

  methods: {
    onInput (event) {
      this.$emit('input', event)
    }
  }
}
</script>

<style>
/* Must be globally declared otherwise the Pell editor will not pick the up. */
.pell-content {
  height: 200px;
  background: white;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
}

.pell-actionbar {
  background: rgb(245, 245, 245);
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
  border-color: rgb(209, 216, 224);
}

.vp-editor {
  border-radius: 4px;
  border: 1px solid rgb(209, 216, 224);
  color: #363636;
}
</style>
