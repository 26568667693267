/* global google */
import firebaseClient from '@/api/firebase/firebaseClient'
import api from '@/api/'

import HunterLocationModel from '@/api/hunt/playback/hunterLocationModel'
import GpsLocationModel from '@/api/hunt/playback/gpsLocationModel'
import GpsPrivateLocationModel from '@/api/hunt/playback/gpsPrivateLocationModel'

async function loadLocations (hunt) {
  const url = `hunts/${hunt.id}/locations`

  try {
    const response = await api.get(url)

    const gpsLocations = response.data.gpsLocations.map(
      gpsLocation => new GpsLocationModel(gpsLocation)
    )
    const hunterLocations = response.data.hunterTrailLocations.map(
      hunterLocation => new HunterLocationModel(hunterLocation)
    )

    return { gpsLocations, hunterLocations }
  } catch (error) {
    console.log(error)

    return { gpsLocations: [], hunterLocations: [] }
  }
}

async function loadHunterLocations (hunt) {
  let locations = []

  try {
    locations = await firebaseClient.getSnapshot(
      'groups/' + hunt.id + '_LLGroup/hunterTrails',
      {
        orderBy: 'timestamp',
        convertTo: data => new HunterLocationModel(data)
      },
      hunt.getHunterLocationsSlave()
    )

    sortLocations(locations)
  } catch (error) {
    console.log(error)
  }

  return locations
}

async function loadGpsLocations (hunt, userId) {
  let gpsLocations = []
  let privateGpsLocations = []

  try {
    gpsLocations = await firebaseClient.getSnapshot(
      'groups/' + hunt.id + '_LLGroup/gpsLocations',
      {
        orderBy: 'timestamp',
        convertTo: data => new GpsLocationModel(data)
      },
      hunt.getGpsLocationsSlave()
    )
  } catch (error) {
    console.log(error)
  }

  try {
    privateGpsLocations = await firebaseClient.getSnapshot(
      'users/' +
        userId +
        '_LLUser/private/gpsLocations/' +
        hunt.id +
        '_LLGroup',
      {
        orderBy: 'timestamp',
        convertTo: data => new GpsPrivateLocationModel(data)
      },
      hunt.getGpsLocationsSlave()
    )
  } catch (error) {
    console.log(error)
  }

  filterDuplicateLocations(gpsLocations, privateGpsLocations)
  sortLocations(gpsLocations)

  return gpsLocations
}

function filterDuplicateLocations (output, input) {
  input.forEach(location => {
    if (
      output.filter(
        e =>
          e.deviceId === location.deviceId && e.timestamp === location.timestamp
      ).length === 0
    ) {
      output.push(location)
    }
  })
}

function sortLocations (locations) {
  locations.sort((a, b) => (a.timestamp < b.timestamp ? -1 : 1))
}

function createDevices (locations, callback) {
  const devices = getUniqueDevices(locations, callback)

  devices.forEach(device => {
    device.trail = getDeviceLocations(device.id, locations)
  })

  return devices
}

function getUniqueDevices (locations, callback) {
  const hashedDevices = []

  locations.forEach(location => {
    if (location.visible) {
      if (hashedDevices[location.deviceId] === undefined) {
        hashedDevices[location.deviceId] = {
          id: location.deviceId,
          name: location.name
        }
      }
    }
  })

  const devices = []
  for (const key in hashedDevices) {
    const device =
      callback !== undefined
        ? callback(hashedDevices[key])
        : hashedDevices[key]
    devices.push(device)
  }

  return devices
}

function getDeviceLocations (deviceId, locations) {
  return locations.filter(location => location.deviceId === deviceId)
}

function generateUniqueGpsNames (gpsDevices) {
  const unnamedDevices = gpsDevices.filter(gpsDevice => gpsDevice.name === '')
  for (let i = 0; i < unnamedDevices.length; i++) {
    unnamedDevices[i].name = 'GPS #' + (i + 1)
  }
}

function getLastLocationTimestampInMs (hunterLocations, gpsLocations) {
  const lastHunterTimestamp =
    hunterLocations.length > 0
      ? hunterLocations[hunterLocations.length - 1].timestamp
      : 0
  const lastGpsTimestamp =
    gpsLocations.length > 0
      ? gpsLocations[gpsLocations.length - 1].timestamp
      : 0

  if (lastHunterTimestamp === 0 && lastGpsTimestamp === 0) {
    // No location what so ever (should never happen)
    return 0
  }

  return Math.max(lastHunterTimestamp, lastGpsTimestamp)
}

function getLocationsInPeriod (locations, start, end) {
  if (end > start) {
    return getForwardsEvents(locations, start, end)
  } else {
    return getRewindindgEvents(locations, start, end)
  }
}

function getForwardsEvents (locations, start, end) {
  return locations.filter(location => {
    return location.timestamp > start && location.timestamp <= end
  })
}

function getRewindindgEvents (locations, start, end) {
  return locations
    .filter(location => {
      return location.timestamp >= end && location.timestamp < start
    })
    .reverse()
}

function getMarkerById (markers, markerId) {
  if (markers !== null) {
    for (let i = 0; i < markers.length; i++) {
      if (markers[i].id === markerId) {
        return markers[i]
      }
    }
  }

  return null
}

function isMarkerCloseToTrail (marker, locations) {
  const markerLocation = new google.maps.LatLng(marker.location)

  for (const location of locations) {
    const latLng = new google.maps.LatLng(location.location)
    const distance = google.maps.geometry.spherical.computeDistanceBetween(
      markerLocation,
      latLng
    )
    if (distance <= 25) {
      return true
    }
  }
  return false
}

export {
  loadLocations,
  loadHunterLocations,
  loadGpsLocations,
  createDevices,
  getDeviceLocations,
  generateUniqueGpsNames,
  getLastLocationTimestampInMs,
  getUniqueDevices,
  getLocationsInPeriod,
  getMarkerById,
  isMarkerCloseToTrail
}
