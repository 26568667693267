<template>
  <Fragment>
    <header class="card-header">
      <span class="card-header-title">
        <span class="push-right-xs is-clickable" @click="back">
          <Icon name="icon-arrow-left" />
        </span>

        <span>{{ $t('userDropdown.feedback') }}</span>
      </span>
    </header>

    <div class="card-content">
      <a href="mailto:feedback@wehunt.se">{{ $t('userDropdown.leaveFeedback') }}</a>
    </div>
  </Fragment>
</template>

<script>
export default {

  methods: {
    back () {
      this.$emit('back')
    },

    close () {
      this.$emit('close')
    }
  }

}
</script>
