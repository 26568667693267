export default {
  duration: state => state.duration,
  elapsedTime: state => state.elapsedTime,
  status: state => state.status,
  speed: state => state.speed,

  participants: state => state.participants,
  selectedParticipant: state => state.selectedParticipant,
  hunters: state => state.hunters,
  gpsDevices: state => state.gpsDevices,
  hunterLocations: state => state.hunterLocations,
  gpsLocations: state => state.gpsLocations,
  hunterTrailLength: state => state.hunterTrailLength,
  gpsTrailLength: state => state.gpsTrailLength
}
