<template>
  <div
    :class="{ 'is-active': isActive }"
    class="cursor-loader-overlay">
  </div>
</template>

<script>
export default {

  computed: {
    isActive () { return this.$store.getters['ui/cursorLoader'] }
  }

}
</script>

<style lang="scss">
.cursor-loader-overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  display: none;

  &.is-active {
    cursor: wait !important;
    display: block;
    z-index: 999;
  }
}
</style>
