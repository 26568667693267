import sv from './sv/swedish.js'
import en from './en/english.js'
import da from './da/danish.js'
import nb from './nb/norwegian.js'
import fr from './fr/french.js'

export default {
  en,
  sv,
  da,
  nb,
  fr
}
