import dayjs from 'dayjs'

export const CALENDAR_HUNT_STATUS_INVITED = 'invited'
export const CALENDAR_HUNT_STATUS_NOT_INVITED = 'not-invited'
export const CALENDAR_HUNT_STATUS_MEMBER = 'member'

class CalendarHuntModel {
  constructor (data) {
    this.id = data.id || null
    this.name = data.name || ''

    this.status = data.status || ''
    this.memberStatus = data.memberStatus || ''

    this.huntAreaId = data.huntAreaId || null
    this.huntLeaderUserId = data.huntLeaderUserId || null
    this.afterSearchUserId = data.afterSearchUserId || null

    this.createdAt = data.createdAt !== null ? dayjs(data.createdAt).toDate() : null
    this.createdBy = data.createdBy || ''

    this.startsAt = data.startsAt ? dayjs(data.startsAt).toDate() : null
    this.endsAt = data.endsAt ? dayjs(data.endsAt).toDate() : null
  }
}

export default CalendarHuntModel
