import HuntReportParticipantModel from '../models/huntReportParticipantModel'

export default {
  namespaced: true,

  state: {
    participants: null,
    removedParticipants: []
  },

  getters: {
    getAll (state) {
      return state.participants
    }
  },

  mutations: {
    add (state, user) {
      const participant = new HuntReportParticipantModel({
        userId: user.id,
        name: user.getFullName(),
        email: user.email
      })

      state.participants.push(participant)
    },

    remove (state, participant) {
      const i = state.participants.indexOf(participant)

      if (i !== -1) {
        state.participants.splice(i, 1)
      }

      if (participant.id !== null) {
        state.removedParticipants.push(participant)
      }
    },

    set (state, participants) {
      state.participants = participants
    },

    setRemovedParticipants (state, removedParticipants) {
      state.removedParticipants = removedParticipants
    }
  }
}
