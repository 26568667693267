import dayjs from 'dayjs'
import { SUBSCRIPTION_TYPE_FREE, SUBSCRIPTION_TYPE_TRIAL } from '@/modules/subscription/store/subscriptionStore'

export default {
  namespaced: true,

  state: {
    startsAt: '2020-08-01',
    endsAt: '2020-09-01',
    subscriptionTypesAffected: [
      SUBSCRIPTION_TYPE_FREE,
      SUBSCRIPTION_TYPE_TRIAL
    ]
  },

  getters: {
    isActive (state, getters, rootState, rootGetters) {
      const now = dayjs()
      const isWithinCampaignPeriod = now.isSameOrAfter(state.startsAt, 'day') && now.isSameOrBefore(state.endsAt, 'day')

      const subscriptionType = rootGetters['subscription/type']
      const userHasAffectedSubscriptionType = state.subscriptionTypesAffected.includes(subscriptionType)

      return isWithinCampaignPeriod && userHasAffectedSubscriptionType
    }
  }
}
