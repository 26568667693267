export default {
  hunts: 'Jakter',

  reports: 'Jaktrapporter',
  noReports: 'Inga jaktrapporter har skapats än.',

  plannedHunts: 'Planerade jakter',
  noHunts: 'Inga jakter är planerade.',

  newReport: 'Ny jaktrapport',
  exportToExcel: 'Exportera till Excel',

  hunt: 'Jakt',
  date: 'Datum',
  killsAndObservations: 'Fällt/Observerat',
  planning: 'Under planering'
}
