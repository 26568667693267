import reportRoutes from './report/reportRoutes'

export default [{
  name: 'area',
  path: '/area/:huntAreaId',
  component: () => import('./HuntAreaModule.vue'),
  children: [{
    name: 'dashboard',
    path: 'dashboard',
    component: () => import('./dashboard/components/DashboardPage.vue')
  }, {
    name: 'map',
    path: 'map',
    props: { showPropertyBoundaries: true },
    component: () => import('@/views/map/Map.vue'),
    meta: { isMap: true },
    children: [{
      name: 'marker',
      path: 'marker/:markerId',
      component: () => import('@/views/map/components/panels/MarkerPanel.vue'),
      meta: { isMap: true }
    }, {
      name: 'subarea',
      path: 'subarea/:subAreaId',
      component: () => import('@/views/map/components/panels/SubAreaPanel.vue'),
      meta: { isMap: true }
    }, {
      name: 'line',
      path: 'line/:lineId',
      component: () => import('@/views/map/components/panels/LinePanel.vue'),
      meta: { isMap: true }
    }, {
      name: 'trail',
      path: 'trail/:trailId',
      component: () => import('@/views/map/components/panels/TrailPanel.vue'),
      meta: { isMap: true }
    }, {
      name: 'boundary',
      path: 'boundary',
      component: () => import('@/views/map/components/panels/BoundaryPanel.vue'),
      meta: { isMap: true }
    }]
  }, {
    name: 'TeamMembers',
    path: 'members',
    component: () => import('./members/components/MembersPage.vue'),
    meta: { isMap: false }
  }, {
    name: 'documents',
    path: 'documents',
    component: () => import('@/modules/huntArea/documents/components/DocumentsPage.vue'),
    meta: { isMap: false }
  }, {
    path: 'settings',
    component: () => import('./settings/components/SettingsPage.vue'),
    meta: { isMap: false }
  }, {
    name: 'hunts',
    path: 'hunts',
    component: () => import('@/modules/huntArea/hunts/components/HuntsPage.vue'),
    meta: { isMap: false }
  }, {
    name: 'gallery',
    path: 'gallery',
    component: () => import('./gallery/components/GalleryPage.vue'),
    meta: { isMap: false }
  },
  {
    name: 'viltrapport',
    path: 'viltrapport',
    component: () => import('./viltrapport/components/ViltrapportPage.vue')
  },
  ...reportRoutes
  ]
}]
