<template>
  <span class="tag" :class="classes">
    <slot>{{ text }}</slot>
  </span>
</template>

<script>
export default {
  props: {
    type: {
      type: String,
      default: 'light'
    },

    light: {
      type: Boolean,
      default: false
    },

    outlined: {
      type: Boolean,
      default: false
    },

    rounded: {
      type: Boolean,
      default: false
    },

    text: {
      type: String,
      default: ''
    }
  },

  computed: {
    classes () {
      const classes = {}

      classes['is-' + this.type] = true
      classes['is-light'] = this.light
      classes['is-outlined'] = this.outlined
      classes['is-rounded'] = this.rounded

      return classes
    }
  }
}
</script>
