import dayjs from 'dayjs'

class HuntInviteModel {
  constructor (context) {
    const data = Object.assign({}, context)

    this.id = data.id || null
    this.huntName = data.huntName || ''
    this.huntAreaId = data.huntAreaId || null
    this.startsAt = data.startsAt ? dayjs(data.startsAt).toDate() : null
    this.endsAt = data.endsAt ? dayjs(data.endsAt).toDate() : null
    this.message = data.message || ''
    this.response = data.response || ''
    this.respondedAt = data.respondedAt || null
    this.participants = data.participants || []

    this.answer = data.answer || ''
    this.status = data.status || ''
  }
}

export default HuntInviteModel
