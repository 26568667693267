import api from '@/api/'

import HuntDriveModel from './huntDriveModel'
import HuntAssignmentModel from './huntAssignmentModel'

/**
 * Service that manages all REST requests for hunt drives and member assignments.
 */
class HuntDriveService {
  static async getDrives (huntId) {
    try {
      const { data } = await api.get('hunts/' + huntId + '/drives')
      return data.map(data => new HuntDriveModel(data))
    } catch (error) {
      console.error('Failed to load drives for hunt ' + huntId + '.')
      throw error
    }
  }

  static async addDrive (huntId, subAreaId) {
    try {
      const response = await api.post('hunts/' + huntId + '/drives', { subAreaId })
      return new HuntDriveModel(response.data)
    } catch (error) {
      console.error('Failed to create drive for hunt ' + huntId + '.')
      throw error
    }
  }

  static async removeDrive (huntId, huntDriveId) {
    try {
      await api.delete('hunts/' + huntId + '/drives/' + huntDriveId)
    } catch (error) {
      console.error('Failed to remove drive ' + huntDriveId + ' from hunt ' + huntId + '.')
      throw error
    }
  }

  static async addAssignment (huntId, huntDriveId, assignment) {
    try {
      const response = await api.post('hunts/' + huntId + '/drives/' + huntDriveId + '/assignments', assignment.toJson())
      return new HuntAssignmentModel(response.data)
    } catch (error) {
      console.error('Failed to add assignment for hunt ' + huntId + ' and drive ' + huntDriveId + '.')
      throw error
    }
  }

  static async updateAssignment (huntId, huntDriveId, assignment) {
    try {
      const response = await api.put('hunts/' + huntId + '/drives/' + huntDriveId + '/assignments/' + assignment.id, assignment.toJson())
      return new HuntAssignmentModel(response.data)
    } catch (error) {
      console.error('Failed to update assignment ' + assignment.id + ' for hunt ' + huntId + ' and drive ' + huntDriveId + '.')
      throw error
    }
  }

  static async removeAssignment (huntId, huntDriveId, assignmentId) {
    try {
      await api.delete('hunts/' + huntId + '/drives/' + huntDriveId + '/assignments/' + assignmentId)
    } catch (error) {
      console.error('Failed to remove assignment ' + assignmentId + ' for hunt ' + huntId + ' and drive ' + huntDriveId + '.')
      throw error
    }
  }
}

export default HuntDriveService
