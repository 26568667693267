import api from '@/api'
import NewsItemModel from '../models/newsItemModel'

class NewsService {
  static async getAll (language, olderThan, maxCount) {
    try {
      const { data } = await api.get('/news?lang=' + language + '&olderThan=' + olderThan + '&count=' + maxCount)
      return data.map(newsItem => new NewsItemModel(newsItem))
    } catch (error) {
      console.error('Failed to read news.')
      console.error(error)
      throw error
    }
  }

  static async getById (id) {
    try {
      const { data } = await api.get('/news/' + id)
      return new NewsItemModel(data)
    } catch (error) {
      console.error('Failed to get news item ' + id + '.')
      console.error(error)
      throw error
    }
  }
}

export default NewsService
