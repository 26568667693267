export default {
  getCenter: state => state.center,
  type: state => state.type,
  zoom: state => state.zoom,
  mode: state => state.mode,
  markers: state => state.markers,
  boundary: state => state.boundary,
  subAreas: state => state.subAreas,
  forbiddenAreas: state => state.forbiddenAreas,
  selectedSubArea: state => state.selectedSubArea,
  selectedMarker: state => state.selectedMarker,

  getSubAreaWithId: state => subAreaId => {
    for (let i = 0; i < state.subAreas.length; i++) {
      if (state.subAreas[i].id === subAreaId) {
        return state.subAreas[i]
      }
    }

    return null
  },

  getMarkerWithId: state => markerId => {
    for (let i = 0; i < state.markers.length; i++) {
      if (state.markers[i].id === markerId) {
        return state.markers[i]
      }
    }

    return null
  },

  getMarkersInSubArea: state => subArea => {
    const polygon = new window.google.maps.Polygon({ paths: subArea.borderCoordinates })

    return state.markers.filter(marker => {
      const location = new window.google.maps.LatLng(
        marker.location.lat,
        marker.location.lng
      )

      return window.google.maps.geometry.poly.containsLocation(location, polygon)
    })
  },

  settingsPanelActive: state => state.settingsPanelActive,

  markerSize: state => state.markerSize,
  labelSize: state => state.labelSize,
  showLabels: state => state.showLabels,

  hasTerrainLayer: state => state.terrainLayer, // boolean
  hasPropertyLayer: state => state.propertyLayer // boolean
}
