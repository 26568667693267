import dayjs from 'dayjs'

export const FOLDER_STATUS_NONE = ''
export const FOLDER_STATUS_LOADING = 'loading'
export const FOLDER_STATUS_LOADED = 'loaded'
export const FOLDER_STATUS_ERROR = 'error'

class FolderModel {
  constructor (context) {
    const data = Object.assign({}, context)

    this.id = data.id || null
    this.name = data.name || ''
    this.folderId = data.folderId || null

    this.parent = null
    this.folders = null
    this.documents = null

    this.editing = data.editing !== undefined ? data.editing : false
    this.status = FOLDER_STATUS_NONE

    this.createdAt = data.createdAt ? dayjs(data.createdAt).toDate() : null
    this.createdBy = data.createdBy || null
  }

  setDocuments (documents) {
    this.documents = documents

    this.documents.forEach(document => {
      document.setParent(this)
    })
  }

  addDocument (document) {
    if (this.documents === null) {
      this.documents = []
    }

    this.documents.push(document)
    document.setParent(this)
  }

  removeDocument (document) {
    const i = this.documents.indexOf(document)
    if (i !== -1) {
      this.documents.splice(i, 1)
      document.setParent(null)
    }
  }

  setFolders (folders) {
    this.folders = folders

    this.folders.forEach(folder => {
      folder.setParent(this)
    })
  }

  addFolder (folder) {
    this.folders.push(folder)
    folder.setParent(this)
  }

  removeFolder (folder) {
    const i = this.folders.indexOf(folder)
    if (i !== -1) {
      this.folders.splice(i, 1)
      folder.setParent(null)
    }
  }

  setParent (folder) {
    this.parent = folder !== null ? folder : null
    this.folderId = folder !== null ? folder.id : null
  }

  toJSON () {
    return {
      id: this.id,
      folderId: this.folderId,
      name: this.name
    }
  }
}

export default FolderModel
