/* eslint-disable */
import Vue from 'vue'
import axios from 'axios'

import '@/components/map/index.js'

/*
 * Day.js
*/
import dayjs from 'dayjs'

import isSameOrBefore from 'dayjs/plugin/isSameOrBefore'
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter'
import relativeTime from 'dayjs/plugin/relativeTime'

dayjs.extend(isSameOrBefore)
dayjs.extend(isSameOrAfter)
dayjs.extend(relativeTime)

Vue.prototype.$dayjs = dayjs

/* 
 * Utils
*/
import * as utils from '@/assets/libs/utils/'

Vue.prototype.$utils = utils

/*
 * Axios
 * - Use axios as XHR wrapper (but we should probably look in to some alternative, axios hasn't been updated since 2017..)
*/
Vue.prototype.$http = axios

/*
 * External plugins
*/
import FlatPickr from 'vue-flatpickr-component'
import PellEditor from 'vue-pell-editor'
import Portal from 'portal-vue'
import MQ from 'vue-match-media/dist/index'

import 'flatpickr/dist/flatpickr.css'

Vue.component('FlatPickr', FlatPickr)
Vue.use(PellEditor)
Vue.use(Portal)
Vue.use(MQ)

/*
 * Local plugins
*/
import ContentPlaceholders from '@/assets/libs/contentPlaceholders/'
import Fragment from '@/assets/libs/fragment/'
import Static from '@/assets/libs/static/'

Vue.use(ContentPlaceholders)
Vue.use(Fragment)
Vue.use(Static)
