<template>
  <tbody>
    <slot />
  </tbody>
</template>

<script>
export default {
}
</script>
