import CalendarService from '../services/calendarService'
import CalendarEventService from '../services/calendarEventService'

import selectedHuntStore from './calendarSelectedHuntStore'
import CalendarHuntModel, { CALENDAR_HUNT_STATUS_MEMBER, CALENDAR_HUNT_STATUS_INVITED } from '../models/calendarHuntModel'
import { HUNT_STATUS_FINISHED } from '../../../api/hunt/huntModel'

export default {
  namespaced: true,

  modules: {
    selectedHunt: selectedHuntStore
  },

  state: {
    period: {
      from: null,
      to: null
    },

    events: null,
    hunts: null,
    reports: null,

    loading: false,
    progress: 0
  },

  getters: {
    getPeriod (state) {
      return state.period
    },

    getEvents (state) {
      return state.events
    },

    getHunts (state) {
      return state.hunts
    },

    getReports (state) {
      return state.reports
    },

    isLoading (state) {
      return state.loading
    },

    getProgress (state) {
      return state.progress
    },

    getHunt: state => id => {
      const hunts = state.hunts || []
      const i = hunts.findIndex(hunt => hunt.id === id)
      return i !== -1 ? hunts[i] : null
    }
  },

  mutations: {
    clear (state) {
      state.hunts = null
      state.events = null
      state.reports = null
    },

    setPeriod (state, { from, to }) {
      state.period.from = from
      state.period.to = to

      state.hunts = null
      state.events = null
      state.reports = null
    },

    setEvents (state, events) {
      state.events = events
    },

    setHunts (state, hunts) {
      state.hunts = hunts
    },

    setReports (state, reports) {
      state.reports = reports
    },

    addReport (state, report) {
      state.reports.push(report)
    },

    addHunt (state, hunt) {
      state.hunts.push(hunt)
    },

    removeHunt (state, huntId) {
      const i = state.hunts.findIndex(hunt => hunt.id === huntId)
      if (i !== -1) {
        state.hunts.splice(i, 1)
      }
    },

    setLoading (state, loading) {
      state.loading = loading
    },

    setProgress (state, progress) {
      state.progress = progress
    },

    addEvent (state, event) {
      state.events.push(event)
    },

    updateEvent (state, event) {
      const i = state.events.findIndex(e => e.id === event.id)
      state.events.splice(i, 1, event)
    },

    removeEvent (state, event) {
      const i = state.events.findIndex(e => e.id === event.id)
      if (i !== -1) {
        state.events.splice(i, 1)
      }
    }
  },

  actions: {
    async update ({ commit, getters, dispatch, rootGetters }) {
      commit('setLoading', true)
      commit('setProgress', 0)
      commit('setEvents', [])
      commit('setHunts', [])
      commit('setReports', [])

      const period = getters.getPeriod
      const data = await CalendarService.getPeriod(period.from, period.to)

      commit('setEvents', data.events)
      commit('setReports', data.reports)
      commit('setHunts', data.hunts.filter(hunt => hunt.status !== HUNT_STATUS_FINISHED))

      dispatch('addHuntInvitations')

      commit('setLoading', false)
    },

    async saveEvent ({ commit }, event) {
      if (event.id === null) {
        const createdEvent = await CalendarEventService.create(event)
        createdEvent.status = event.status

        commit('addEvent', createdEvent)
        return createdEvent
      } else {
        const updatedEvent = await CalendarEventService.update(event)
        updatedEvent.status = event.status

        commit('updateEvent', updatedEvent)
        return updatedEvent
      }
    },

    async removeEvent ({ dispatch, commit }, event) {
      try {
        commit('removeEvent', event)
        await CalendarEventService.delete(event)
      } catch (error) {
        commit('addEvent', event)
        throw error
      }
    },

    updateHuntFromInvite ({ getters }, invite) {
      const period = getters.getPeriod

      // Only update calendar if the hunt is within the visible dates
      if ((invite.startsAt >= period.from && invite.startsAt <= period.to) || (invite.endsAt >= period.from && invite.endsAt <= period.to)) {
        const calendarHunt = getters.getHunt(invite.id)

        if (calendarHunt !== null) {
          calendarHunt.memberStatus = CALENDAR_HUNT_STATUS_MEMBER
        }
      }
    },

    addHuntInvitations ({ commit, getters, rootGetters }) {
      const invites = rootGetters['hunt/invites/invites'] || []

      invites.forEach(invite => {
        const existingHunt = getters.getHunt(invite.id)

        if (existingHunt === null) {
          const hunt = new CalendarHuntModel(invite)
          hunt.memberStatus = CALENDAR_HUNT_STATUS_INVITED

          commit('addHunt', hunt)
        }
      })
    }
  }
}
