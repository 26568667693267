<template>
  <div class="panel-title">
    <div class="flex align-center">
      <img v-if="markerIcon !== ''" :src="markerIcon" class="is-inline-block push-right-xxs" />

      <h5 class="title is-5">
        <span v-if="name">{{ name }}</span>
        <slot v-else-if="hasTitle" name="title" />
      </h5>

      <span v-if="length" class="tag is-primary push-left-xs push-right-xs">
        {{ length }}m
      </span>

      <span
        v-if="areaInHa"
        :class="getAreaLabelColor(data.type)"
        class="tag push-left-xs push-right-xs">
        {{ areaInHa }} ha
      </span>

      <button
        v-if="!noCloseButton"
        class="delete"
        aria-label="close"
        @click="close">
      </button>
    </div>

    <div v-if="hasControllers" class="is-block push-top">
      <slot name="controllers" />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    back: {
      type: Boolean,
      default: true
    },

    data: {
      type: Object,
      default: () => {}
    },

    areaInHa: {
      type: Number,
      default: null
    },

    length: {
      type: Number,
      default: null
    },

    markerIcon: {
      type: String,
      default: ''
    },

    noCloseButton: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    name () {
      return this.data && (this.data.name || this.data.label)
    },

    hasTitle () {
      return !!this.$slots.title
    },

    hasControllers () {
      return !!this.$slots.controllers
    }
  },

  methods: {
    close () {
      this.$emit('close')
    },

    getAreaLabelColor (type) {
      const types = {
        saat: 'is-subarea',
        forbiddenArea: 'is-forbidden-area',
        default: 'is-dark'
      }

      return types[type] || types.default
    }
  }
}
</script>

<style lang="scss">
.panel-title {
  padding: 1.5rem 1.5rem 0.375rem 1.5rem;
  width: 100%;
  display: flex;
  flex-direction: column;

  .title {
    margin: 0 !important;
    line-height: 1.5;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  span:not(.tag) {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .delete {
    margin-left: auto;
  }

  .is-flipped {
    transform: rotate(180deg);
  }
}
</style>
