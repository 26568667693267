export default {
  start: "Commencer",
  news: "Actualités",
  areas: "Zones de chasse",
  hunts: "Chasses",

  dashboard: "Tableau de bord",
  calendar: "Calendrier",
  profile: "Profil",
  viltrapport: "Viltraport"
}
