<template>
  <div :class="classes" class="titlebar">
    <div class="titlebar-wrapper">
      <div class="titlebar-title">
        <div v-if="hasTitle">
          <slot name="title" />
        </div>

        <InlineLoader v-if="isLoading" size="small" class="push-left" />
      </div>

      <div class="titlebar-right">
        <slot name="local" />
        <!-- <Icon v-if="hasLocal" name="icon-more-vertical" class="titlebar-divider" /> -->
        <UserDropdown />
      </div>
    </div>

    <div v-if="hasControllers" class="titlebar-controllers">
      <slot name="controllers" />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  props: {
    isLoading: {
      type: Boolean,
      default: false
    },

    narrow: {
      type: Boolean,
      default: false
    },

    absolute: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    ...mapGetters({
      hasAuth: 'auth/hasAuth'
    }),

    hasTitle () { return !!this.$slots.title },
    hasLocal () { return !!this.$slots.local },
    hasControllers () { return !!this.$slots.controllers },

    classes () {
      return {
        'is-narrow': this.narrow,
        'position-is-absolute': this.absolute
      }
    }
  }
}
</script>

<style lang="scss">
.titlebar {
  height: auto;
  width: 100%;
  padding: 1.5rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  touch-action: none; // disable pinch-to-zoom iOS

  &.is-narrow {
    width: auto;
  }

  .map-is-active & .titlebar-wrapper {
    align-items: flex-start;
  }

  .titlebar-title {
    white-space: nowrap;
    justify-content: flex-start;
    flex-wrap: nowrap;
    display: flex;
    align-items: center;
  }

  .titlebar-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    position: relative;
    z-index: 5;
  }

  .titlebar-right {
    display: flex;
    align-items: center;

    > div {
      white-space: nowrap;
      justify-content: flex-start;
      flex-wrap: wrap;
      display: flex;
      align-items: center;
    }
  }

  .titlebar-controllers {
    margin-top: 0.75rem;

    > div {
      white-space: nowrap;
      justify-content: flex-start;
      flex-wrap: nowrap;

      @media screen and (min-width: 769px) {
        justify-content: flex-end;
      }
    }
  }

  .titlebar-divider {
    display: none;

    @media screen and (min-width: 1024px) {
      display: flex;
    }
  }

  .user-dropdown {
    display: none !important;

    @media screen and (min-width: 1024px) {
      display: block !important;
    }
  }

  .is-map & {
    position: absolute;
    z-index: 10;

    &::after {
      pointer-events: none;
      content: '';
      width: 100%;
      height: 10rem;
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      z-index: 0;
      background: linear-gradient(rgba(41, 54, 58, 0.75), rgba(0, 0, 0, 0));
    }

    .title {
      color: white;
    }

    .titlebar-divider {
      color: white;
    }
  }

  .buttons,
  .field {
    margin-bottom: 0;

    > .button {
      margin-bottom: 0;
    }
  }
}
</style>
