import GameService from '@/api/hunt/games/gameService'

export default {
  create ({ commit }, data) {
    const { huntId, game } = data

    return new Promise(async (resolve, reject) => {
      try {
        const $game = await GameService.create(huntId, game)
        commit('addGame', $game)
        resolve($game)
      } catch (error) {
        reject(error)
      }
    })
  },

  read ({ commit }, huntId) {
    commit('setGames', null)

    return new Promise(async (resolve, reject) => {
      try {
        const $games = await GameService.read(huntId)
        commit('setGames', $games)
        resolve($games)
      } catch (error) {
        reject(error)
      }
    })
  },

  readCounty (store, huntAreaId) {
    return new Promise(async (resolve, reject) => {
      try {
        const county = await GameService.readCounty(huntAreaId)
        resolve(county)
      } catch (error) {
        reject(error)
      }
    })
  },

  readAvailable ({ commit }, data) {
    commit('setAvailableGames', null)

    return new Promise(async (resolve, reject) => {
      try {
        const $games = await GameService.readAvailable(data)
        commit('setAvailableGames', $games)
        resolve($games)
      } catch (error) {
        reject(error)
      }
    })
  },

  update (store, data) {
    const { huntId, game } = data

    return new Promise(async (resolve, reject) => {
      try {
        const $game = await GameService.update(huntId, game)
        resolve($game)
      } catch (error) {
        reject(error)
      }
    })
  },

  async delete ({ commit, rootGetters }, game) {
    const hunt = rootGetters['hunt/hunt']

    commit('delete', game)

    try {
      await GameService.delete(hunt.id, game)
    } catch (error) {
      commit('addGame', game)
      throw error
    }
  }
}
