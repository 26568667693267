import dayjs from 'dayjs'

class NewsItemModel {
  constructor (context) {
    const data = Object.assign({}, context || { source: {} })

    this.id = data.id || null
    this.source = data.source || null

    this.title = data.title || ''
    this.description = data.description || ''

    this.link = data.link || ''
    this.imageUrl = data.imageUrl || null

    this.sponsored = data.sponsored !== undefined ? data.sponsored : false

    this.publishedAt = data.publishedAt ? dayjs(data.publishedAt).toDate() : null
    this.updatedAt = data.updatedAt ? dayjs(data.updatedAt).toDate() : null
  }
}

export default NewsItemModel
