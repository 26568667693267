<template>
  <label class="label">
    {{ label }} <strong v-if="mandatory" class="has-text-danger">*</strong>
  </label>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      default: ''
    },

    mandatory: {
      type: Boolean,
      default: false
    }
  }
}
</script>
