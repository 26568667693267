import ParticipantService from '@/api/hunt/participants/participantService'
import ParticipantModel from '@/api/hunt/participants/participantModel'

export default {
  read ({ commit }, huntId) {
    commit('set', null)

    return new Promise(async (resolve, reject) => {
      try {
        const response = await ParticipantService.getMembers(huntId)
        const participants = response.map(user => new ParticipantModel(user))
        commit('set', participants)
        resolve(response)
      } catch (error) {
        reject(error)
      }
    })
  },

  async add ({ commit, rootGetters, dispatch }, user) {
    const hunt = rootGetters['hunt/hunt']

    let participant = null

    if (user.id !== null) {
      participant = await ParticipantService.inviteByUserId(hunt.id, user.id)
    } else {
      participant = await ParticipantService.inviteByEmail(hunt.id, user.email)
    }

    commit('add', participant)

    const drives = rootGetters['hunt/drives/getAll']
    if (drives.length > 0) {
      const promises = []

      drives.forEach(drive => {
        const payload = {
          huntDrive: drive,
          member: participant
        }

        promises.push(dispatch('hunt/drives/addAssignmentToDrive', payload, { root: true }))
      })

      await Promise.all(promises)
    }

    return participant
  },

  async delete ({ commit, rootGetters }, member) {
    const hunt = rootGetters['hunt/hunt']

    commit('remove', member)

    const drives = rootGetters['hunt/drives/getAll']
    drives.forEach(drive => {
      commit('hunt/drives/removeMember', { huntDrive: drive, member }, { root: true })
    })

    await ParticipantService.removeMember(hunt.id, member.id)
  }
}
