export default {
  readMore: 'Läs mer',
  welcome: 'Välkommen',
  yes: 'Ja',
  no: 'Nej',
  ok: 'Ok',
  save: 'Spara',
  edit: 'Redigera',
  draw: 'Rita',
  update: 'Uppdatera',
  create: 'Skapa',
  cancel: 'Avbryt',
  close: 'Stäng',
  remove: 'Radera',
  delete: 'Ta bort',
  send: 'Skicka',
  pleaseConfirm: 'Vänligen skriv <strong>{type}</strong> för att bekräfta.',
  understandConsequences: 'Jag förstår konsekvensena, {type}',
  leave: 'Lämna',
  move: 'Flytta',
  recover: 'Återställ',
  saveAs: 'Spara som',
  add: 'Lägg till',
  search: 'Sök',
  searchForEmailOrName: 'Sök på e-post eller namn',
  admin: 'Administratör',
  email: 'E-post',
  password: 'Lösenord',
  signup: 'Bli medlem',
  signin: 'Logga in',
  signout: 'Logga ut',
  remember: 'Kom ihåg',
  notListed: 'Ej angivet',
  invite: 'Bjud in',
  invited: 'Inbjudna',
  member: 'Medlem',
  loadingContent: 'Laddar innehåll...',
  errorOccured: 'Ett fel inträffade och det gick inte att spara ändringarna. Vänligen försök igen.',
  sendInvites: 'Skicka inbjudningar',
  myAccount: 'Mitt abonnemang',
  help: 'Hjälp',
  upload: 'Ladda upp',
  sort: 'Sortera',
  name: 'Namn',
  phoneNumber: 'Telefonnummer',
  nameAZ: 'Namn A-Ö',
  nameZA: 'Namn Ö-A',
  grid: 'Kolumner',
  list: 'Lista',
  decline: 'Avböj',
  accept: 'Tacka Ja',
  settings: 'Inställningar',
  language: 'Språk',
  developer: 'Utvecklare',
  games: 'Spel',
  sex: 'Kön',
  male: 'Man',
  female: 'Kvinna',
  firstName: 'Förnamn',
  lastName: 'Efternamn',
  created: 'Skapad',
  size: 'Storlek',
  from: 'Från',
  to: 'Till',
  message: 'Meddelande',
  when: 'När',
  where: 'Var',
  type: 'Typ',
  who: 'Vem',
  comment: 'Kommentar',
  begins: 'Börjar',
  ends: 'Slutar',
  print: 'Skriv ut',
  adjust: 'Anpassa',
  orientation: "Orientering",
  status: 'Status',
  thickness: 'Tjocklek',
  color: 'Färg',
  title: 'Rubrik',
  role: 'Roll',
  needsPro: 'Kräver WeHunt Pro',
  comingSoon: 'Kommer snart',
  reallyRemove: 'Vill du verkligen radera <strong>{name}</strong>?',
  added: 'Tillagd',
  undo: 'Ångra',
  select: 'Välj',
  minutes: 'min',
  hours: 'h',

  newHuntingArea: 'Ny jaktmark',
  selectHuntingArea: 'Välj jaktmark',
  selectHuntingAreaError: 'Du måste välja en jaktmark.',

  previous: 'Förgående',
  next: 'Nästa',

  unsupportedBrowserMessage: 'Denna funktion fungerar tyvärr inte med din webbläsare. Vänligen byt till en nyare webbläsare.',
  saveFailedErrorMessage: 'Ett fel inträffade och det gick inte att spara ändringarna. Vänligen försök igen.',
  unknownErrorMessage: 'Ett okänt fel inträffade. Vänligen försök igen.',

  pageNotFound: 'Sidan du söker kunde tyvärr inte hittas.',
  goBack: 'Gå tillbaka'
}
