export default {
  setHuntAreas (state, areas) {
    state.areas = areas
  },

  add (state, area) {
    if (state.areas) state.areas.push(area)
    else state.areas = [area]
  },

  delete (state, area) {
    const i = state.areas.findIndex(e => e.id === area.id)
    if (i !== -1) {
      state.areas.splice(i, 1)
    }
  },

  update (state, area) {
    const i = state.areas.findIndex(a => a.id === area.id)
    if (i !== -1) {
      state.areas.splice(i, 1, area)
    }

    if (state.selected !== null) {
      state.selected.name = area.name
      state.selected.county = area.county
    }
  },

  select (state, area) {
    state.selected = area
  }
}
