import api from '@/api'
import UserSettingsModel from '../models/userSettingsModel'

class ProfileService {
  static async read (userId) {
    try {
      const { data } = await api.get(`/profiles/${userId}`)
      return new UserSettingsModel(data)
    } catch (error) {
      console.error(`Failed to read profile for user ${userId}.`, error)
      throw error
    }
  }

  static async readProfilePicture (userId) {
    const { data } = await api.get(`/profiles/${userId}/picture`, { responseType: 'arraybuffer' })

    const byteArray = new Uint8Array(data)
    return window.btoa(String.fromCharCode.apply(null, byteArray))
  }

  static async update (profile) {
    try {
      const { data } = await api.put(`/profiles/${profile.userId}`, profile)
      return new UserSettingsModel(data)
    } catch (error) {
      console.error(`Failed to update profile for user ${profile.userId}.`, error)
      throw error
    }
  }
}

export default ProfileService
