<script>
import { mapGetters } from 'vuex'
import LabelOverlay from './labelOverlay'

export default {
  props: {
    trail: {
      type: Object,
      required: true,
      default: () => {}
    }
  },

  data () {
    return {
      $map: null,
      $polygon: null,
      $polygonBounds: null,
      $label: null
    }
  },

  computed: {
    ...mapGetters({
      labelSize: 'map/settings/labelSize',
      selectedTrail: 'map/trails/selected',
      showTrailLabels: 'map/trails/showTrailLabels'
    }),

    coordinates () {
      return this.selectedLine ? this.selectedLine.coordinates : null
    },

    visible () {
      return this.trail.visible
    },

    editable () {
      return this.trail.editable
    },

    isSelected () {
      return this.selectedTrail === this.trail
    },

    type () {
      return this.trail.type
    },

    name () {
      return this.trail.name
    },

    color () {
      return this.trail.color
    }
  },

  watch: {
    selectedTrail (newSelection, oldSelection) {
      if (!this.$polygon) return

      if (newSelection === null) {
        // No sub area is selected, do nothing
      } else if (newSelection === this.trail) {
        // Our polygon is selected, center map on it
        // this.$store.dispatch('map/ui/settingsPanel', false)
        this.$map.fitBounds(this.$polygonBounds)
      } else {
        if (!this.$polygon) {
          console.warn(`${this.trail.name} has a missing polygon.`)
        }
      }
    },

    visible (visible) {
      if (this.$polygon) {
        this.$polygon.setVisible(visible)
        this.updateLabelVisibility()
      }
    },

    showTrailLabels () {
      this.updateLabelVisibility()
    },

    color (color) {
      this.$polygon.setOptions({ strokeColor: color })
    },

    labelSize (size) {
      this.$label.size = size
    },

    name (text) {
      this.$label.label = text
    }
  },

  async mounted () {
    this.$map = await this.$parent.getMap()

    const bounds = this.getBounds()

    const polygon = new window.google.maps.Polyline({
      clickable: false,
      path: this.trail.coordinates,
      geodesic: true,
      strokeColor: this.trail.color,
      strokeOpacity: 1.0,
      strokeWeight: 2,
      name: this.trail.name,
      objectId: this.trail.id,
      type: this.trail.type
    })

    this.$label = new LabelOverlay({
      position: polygon.getPath().getAt(0),
      text: this.trail.name,
      map: this.$map,
      type: 'trail',
      size: this.labelSize,
      class: ['trail-label-overlay']
    })

    if (polygon) {
      polygon.setMap(this.$map)

      this.$polygonBounds = bounds
      this.$polygon = polygon
    } else {
      console.error(`Failed to create polygon for trail ${this.trail.name}.`)
    }

    this.updateLabelVisibility()
  },

  destroyed () {
    if (this.$polygon) {
      this.$label.setMap(null)
      this.$polygon.setMap(null)
    }
  },

  render () {
    return ''
  },

  methods: {
    getVisibleTrails () {
      return this.$store.getters['map/trails/trails'].filter(trail => {
        return trail.visible && trail !== this.trail
      })
    },

    getBounds () {
      const bounds = new window.google.maps.LatLngBounds()

      this.trail.coordinates.forEach(({ lat, lng }) => bounds.extend(new window.google.maps.LatLng(lat, lng)))

      return bounds
    },

    updateLabelVisibility () {
      this.$label.setMap((this.showTrailLabels && this.visible ? this.$map : null))
    }
  }
}
</script>
