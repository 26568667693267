export default {
  title: "Profil",

  account: {
    title: "Compte",

    information: {
      title: 'Information',
      helpText: "<p>Pour que vos amis puissent facilement vous trouver lorsque vous êtes invité à une chasse, il est important que votre nom soit correctement orthographié.</p><p>Votre numéro de téléphone portable n'est jamais affiché comme information consultable dans WeHunt. Votre numéro de téléphone portable est nécessaire pour la fonction « Appeler » lors des chasses en cours avec WeHunt.</p>",
      cellphoneNumber: "Numéro de téléphone portable"
    },

    interests: {
      title: "Intérêts",
      helpText: "Quelle est votre passion? Remplissez les informations pour qu'on ne parle pas de choses qui ne vous intéressent pas !",

      dog: "Chiens",
      fishing: "Pêcher",
      huntingTrips: "Voyages de chasse",
      outdoors: "Vie en plein air",
      food: "Cuisson",
      shooting: "Tirer",
      secondHand: "D'occasion",
      gadgets: "Accessoires de chasse",
      politics: "Politique",
      conservation: "Conservation de la faune",
      education: "Éducation"
    },

    whoAreYou: {
      title: "Qui es-tu?",
      helpText: "<p>Afin de vous donner les bonnes informations sur nos offres, nous souhaitons savoir qui vous êtes. Il s'agit d'un outil important pour ne pas vous déranger inutilement. Cela nous fournit également des informations importantes sur nos utilisateurs, afin que nous puissions continuer à développer WeHunt dans la bonne direction.</p><p>Nous ne partageons jamais ces informations avec qui que ce soit.</p>",
      born: "Année de naissance",
      roles: "Rôles",

      leader: "Responsable de chasse",
      stand: "Chasseur posté",
      stalker: "Chasseur à l'approche",
      carrion: "Chasseur de charognes",
      handler: "Conducteur de chien",
      agent: "Intermédiaires de chasse",
      trackingHunter: "Chasseur à l'approche ",
      butcher: "Boucher",
      gunTech: "Armurier"
    },

    manage: {
      title: "Gérer l'abonnement",
      helpText: '',
      endSubscription: "Mettre fin à l'abonnement",
      endSubscriptionSurveyText: "Je voudrais répondre à quelques questions sur les raisons pour lesquelles j'ai choisi de mettre fin à mon abonnement",
      endSubscriptionHelpText: "Etes-vous sûr de vouloir mettre fin à votre abonnement ?"
    }
  },

  settings: {
    title: "Paramètres",

    email: {
      title: 'E-mail',
      helpText: "Ici, vous pouvez modifier votre adresse e-mail si vous le souhaitez. Si vous rencontrez des problèmes, veuillez contacter <a href=\"mailto:support@wehunt.se\">support@wehunt.se</a> et nous serons heureux de vous aider !",
      currentEmail: "E-mail adresse actuelle",
      newEmail: "Nouvelle adresse e-mail",
      emailAddress: 'E-mail',
      changeEmail: "Changer de courrier électronique",
      busyEmail: "Cet email est déjà enregistré dans notre système.",
      emailChangedMessage: "Votre adresse e-mail a été modifiée en <strong>{email}</strong>"
    },

    password: {
      title: "Mot de passe",
      noAccessToPassword: "WeHunt Nordic AB n'a pas accès à votre mot de passe. Les informations sont cryptées et verrouillées sur votre compte personnel.",
      passwordIsUnique: "Le mot de passe dans WeHunt est unique à WeHunt et ne peut pas être utilisé avec, par exemple, « Mes pages » du Jägareförbundet suédois.",
      passwordRequirements: "Votre mot de passe doit contenir au moins six lettres ou chiffres et uniquement des caractères de a à z et de 0 à 9.",
      newPassword: "Nouveau mot de passe",
      repeatPassword: "Répéter le mot de passe",
      changePassword: "Changer le mot de passe",
      passwordChangedMessage: "Votre mot de passe a été changé."
    },

    payment: {
      title: "Paiement",
      helpText: "Tout le traitement des paiements s'effectue via Stripe. Pour plus d'informations sur Stripe, consulter l'historique de facturation ou modifier le mode de paiement, veuillez vous rendre sur Stripe.",
      openStripe: "Vers Stripe",
      getPortalSessionFailedErrorMessage: "Une erreur s'est produite et il n'a pas été possible d'ouvrir le portail de paiement. Veuillez réessayer."
    },

    notifications: {
      title: 'Notifications',
      helpText: "Recevez des notifications sur votre smartphone lorsque WeHunt ou l'un de nos partenaires publie des actualités.",

      weHunt: "WeHunt",
      jaktJournalen: 'Jaktjournalen',
      sjf: 'Svenska Jägareförbundet',
      svenskJakt: 'Svensk Jakt',
      njff: 'NJFF',
      dj: "Danmarks Jaegarforbund",
      theGuardian: "The Guardian",
      theField: "The Field",
      shootingUK: "Shooting UK"
    },

    newsletter: {
      title: "Newsletter",
      helpText: "Nous envoyons régulièrement notre populaire newsletter contenant des nouvelles, des rapports et des conseils sur la façon d'utiliser WeHunt, avant, pendant et après la chasse, ainsi que nos offres et celles de nos partenaires.",
      helpText2: "Nous ne partageons jamais votre adresse e-mail ou toute autre information personnelle et vous pouvez vous désinscrire de la newsletter à tout moment.",
      subtitle: "Souhaitez-vous vous abonner à la newsletter WeHunt ?"
    }
  }
}
