import api from '@/api/'
import TimeZone from '@/assets/libs/timeZone'
import HuntModel from './huntModel'

class HuntService {
  static async read (huntId) {
    try {
      const response = await api.get(`hunts/${huntId}`)
      return new HuntModel(response.data)
    } catch (error) {
      console.error(`Failed to read hunt ${huntId}`)
      throw error
    }
  }

  static async getAll (huntAreaId) {
    const url = huntAreaId !== undefined ? `hunts?huntAreaId=${huntAreaId}` : 'hunts'

    try {
      const response = await api.get(url)
      return response.data.map(hunt => new HuntModel(hunt))
    } catch (error) {
      let message = 'Failed to get hunts'
      if (huntAreaId !== undefined) {
        message += ` for huntarea ${huntAreaId}`
      }
      message += '.'

      console.error(message)

      throw error
    }
  }

  static async create (data) {
    try {
      const response = await api.post('hunts', data)
      return new HuntModel(response.data)
    } catch (error) {
      console.error('Failed to create hunt.')
      throw error
    }
  }

  static async update (hunt) {
    try {
      const response = await api.put(`hunts/${hunt.id}`, hunt.toJSON())
      return new HuntModel(response.data)
    } catch (error) {
      console.error(`Failed to update hunt ${hunt.id}`)
      throw error
    }
  }

  static async delete (huntId) {
    try {
      await api.delete(`hunts/${huntId}`)
    } catch (error) {
      console.error(`Failed to delete hunt ${huntId}`)
      throw error
    }
  }

  static async plan (hunt, language) {
    try {
      const request = {
        timeZone: TimeZone.getCurrent(),
        language: language
      }

      const response = await api.post(`hunts/${hunt.id}/plan`, request)
      return new HuntModel(response.data)
    } catch (error) {
      console.error(`Failed to plan hunt for hunt ${hunt.id}`)
      throw error
    }
  }
}

export default HuntService
