<template>
  <ButtonGroup :size="size" :segmented="true">
    <Button
      v-for="(segment, i) in segments"
      :key="i"
      :type="buttonTypeForSegment(segment)"
      :style="segmentStyle"
      :icon="segment.icon || ''"
      :title="segment.title"
      @click="onSegmentClicked(segment)">
    </Button>
  </ButtonGroup>
</template>

<script>
export default {
  props: {
    value: {
      type: String,
      default: null
    },

    size: {
      type: String,
      default: ''
    },

    fitToParent: {
      type: Boolean,
      default: true
    },

    segments: {
      type: Array,
      default: () => []
    },

    type: {
      type: String,
      default: 'secondary'
    }
  },

  computed: {
    segmentStyle () {
      if (this.fitToParent) {
        return {
          width: 100 / this.segments.length + '%'
        }
      }
      return {}
    }
  },

  methods: {
    buttonTypeForSegment (segment) {
      return segment.value === this.value ? this.type : ''
    },

    onSegmentClicked (segment) {
      this.$emit('input', segment.value)
    }
  }
}
</script>
