/* eslint-disable no-undef */
import api from '@/api/'
import MarkerModel from './markerModel'

class MarkerService {
  static async create (huntAreaId, marker) {
    try {
      const { data } = await api.post(`/areas/${huntAreaId}/map/markers`, marker.toJSON())
      return new MarkerModel(data)
    } catch (error) {
      console.error(`Failed to create marker for huntarea ${huntAreaId}.`)
      throw error
    }
  }

  static async read (huntAreaId) {
    try {
      const { data } = await api.get(`/areas/${huntAreaId}/map/markers`)
      return data.map(data => new MarkerModel(data))
    } catch (error) {
      console.error(`Failed to read markers for huntarea ${huntAreaId}.`)
      throw error
    }
  }

  static async update (huntAreaId, marker) {
    try {
      const { data } = await api.put(`/areas/${huntAreaId}/map/markers/${marker.id}`, marker.toJSON())
      return new MarkerModel(data)
    } catch (error) {
      console.error(`Failed to update marker ${marker.id}.`)
      throw error
    }
  }

  static async delete (huntAreaId, marker) {
    if (!marker.id) return

    try {
      await api.delete(`/areas/${huntAreaId}/map/markers/${marker.id}`)
      return true
    } catch (error) {
      console.error(`Failed to delete marker ${marker.id}.`)
      throw error
    }
  }
}

export default MarkerService
