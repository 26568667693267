<template>
  <div
    :class="{ 'is-active': isActive }"
    class="accordion">
    <div
      slot="title"
      :class="[textClass]"
      :style="{ 'background': background }"
      class="accordion-title"
      @click="toggle">
      <slot v-if="title === null" name="title" />
      <p v-else>
        {{ title }}
      </p>
      <Icon
        name="icon-chevron-down"
        class="is-pulled-right is-clickable" />
    </div>

    <div class="accordion-content">
      <slot />
    </div>
  </div>
</template>

<script>
export default {

  props: {
    title: {
      type: String,
      default: null
    },

    background: {
      type: String,
      default: '#666'
    },

    text: {
      type: String,
      default: 'light'
    },

    active: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
      isActive: false
    }
  },

  computed: {
    textClass () {
      return this.text ? `is-${this.text}` : 'is-light'
    }
  },

  created () {
    if (this.active) this.isActive = this.active
  },

  methods: {
    toggle () {
      this.isActive = !this.isActive
    }
  }

}
</script>

<style lang="scss">
.accordion {
  display: block;
  margin-bottom: 0.75rem;

  &.is-active {
    .accordion-title {
      border-top-left-radius: 0.25rem;
      border-top-right-radius: 0.25rem;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }
  }

  .accordion-title {
    background-color: #dde4e2;
    padding: 0.25rem;
    user-select: none;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;

    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;

    &.is-dark {
      color: #333;
    }
    &.is-light {
      color: #fff;
    }

    > div {
      display: flex;
      align-items: center;
    }

    > p {
      margin: 0;
    }
  }

  &.is-active .accordion-content {
    display: block;
  }

  .accordion-content {
    padding: 0.5rem;
    background: white;
    display: none;
    border: 0.0625rem solid #dde4e2;
    border-bottom-left-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;

    > .accordion {
      margin-bottom: 0;

      > .accordion-title {
        border-bottom: 1px solid #999;
        border-radius: 0;

        &:last-of-type {
          border-bottom: 0;
        }
      }

      > .accordion-content {
        background: white;
      }
    }
  }
}
</style>
