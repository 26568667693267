import ParticipantModel from '@/api/hunt/participants/participantModel'
import mapState from './../map/state'
// import subAreasState from './../../map/subareas/state'
// import markersState from './../../map/markers/state'
import participantsState from './../participants/state'

export default {
  set (state, assignments) {
    if (assignments) {
      state.assignments = assignments

      assignments.forEach(assignment => {
        // Map assignment to correct subarea
        if (assignment.subArea) {
          for (let i = 0; i < mapState.subAreas.length; i++) {
            if (mapState.subAreas[i].id === assignment.subArea) {
              assignment.subArea = mapState.subAreas[i]
              break
            }
          }
        }

        // Add all participants
        participantsState.participants.forEach(participant => {
          if (participant.isAssignable()) {
            assignment.participants.push(new ParticipantModel(participant))
          }
        })
      })
    } else {
      state.assignments = null
    }
  },

  add (state, assignment) {
    if (state.assignments === null) {
      state.assignments = []
    }

    // Add all participants
    if (participantsState.participants) {
      participantsState.participants.forEach(participant => {
        if (participant.isAssignable()) {
          assignment.participants.push(new ParticipantModel(participant))
        }
      })
    }

    state.assignments.push(assignment)
  },

  delete (state, assignment) {
    if (this.selected === assignment) {
      this.selected = null
    }

    // Detach markers from participants
    assignment.participants.forEach(participant => {
      if (participant.pass) {
        participant.pass.user = null
      }
    })

    const i = state.assignments.indexOf(assignment)

    if (i !== -1) {
      state.assignments.splice(i, 1)
    }
  },

  setCurrentAssignmentArea (state, assignment) {
    mapState.markers.forEach(marker => {
      marker.user = null
    })

    if (assignment) {
      assignment.participants.forEach(participant => {
        if (participant.pass) {
          participant.pass.user = participant
        }
      })
    }

    state.currentAssignmentArea = assignment
    state.userToAssign = null
  },

  userToAssign (state, participant) {
    state.userToAssign = participant
  },

  // setAssignmentStand (state, data) {
  //   const { participant, marker } = data

  //   participant.pass = marker
  //   marker.user = participant
  // },

  // removeAssignmentStand (state, participant) {
  //   participant.pass.user = null
  //   participant.pass = null
  // },

  // setAssignmentUser (state, user) {
  //   state.assignmentUser = user
  // },

  addParticipant (state, participant) {
    if (participant.isAssignable()) {
      state.assignments.forEach(assignment => {
        assignment.addParticipant(new ParticipantModel(participant))
      })
    }
  },

  removeParticipant (state, userId) {
    if (state.assignments) {
      state.assignments.forEach(assignment => {
        assignment.removeParticipant(userId)
      })
    }
  }
}
