/* eslint-disable standard/no-callback-literal */
import api from '@/api/'
import { clear } from './userContext'
import { eventManager } from '@/main.js'
import * as storage from '@/assets/libs/storage/'

import store from '@/store/'
import UserModel from '@/modules/user/models/userModel'

class AuthService {
  static signIn (data) {
    const { email, password, remember } = data

    return new Promise(async (resolve, reject) => {
      try {
        const { status, data } = await api.post('authentication', { email, password })
        const { token, user } = data

        if (status === 200 || status === 204) {
          api.defaults.headers.common.Authorization = `Bearer ${token}`

          try {
            const $user = await store.dispatch('user/read', user.id)
            const $profile = await store.dispatch('profile/read', user.id)

            await storage.local.add('remember', remember)
            await eventManager.$emit('setUser', $user)
            await eventManager.$emit('setProfile', $profile)

            resolve(data)
          } catch (error) {
            reject(error)
          }
        } else resolve(false)
      } catch (error) {
        reject(error)
      }
    })
  }

  static async join (userData) {
    const request = {
      firstName: userData.firstName,
      lastName: userData.lastName,
      email: userData.email,
      password: userData.password,
      phoneNumber: userData.phoneNumber
    }

    try {
      const response = await api.post('users?utm_source=web', request)
      return new UserModel(response.data)
    } catch (error) {
      console.log('Failed to register user with email ' + request.email + '.')
      if (error.response) {
        throw error.response
      } else {
        throw error
      }
    }
  }

  static async signOut () {
    try {
      await api.delete('/authentication')
    } catch (error) {
      console.warn('Failed to signout from server. Ignoring...', error)
    }

    api.defaults.headers.common.Authorization = null
    clear()
  }

  static recoverPassword (email) {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await api.post('authentication/resetpassword', { email })

        if (response.status === 200 || response.status === 204) {
          resolve(true)
        }
      } catch (error) {
        reject(error)
      }
    })
  }

  static confirm (data) {
    return new Promise((resolve, reject) => resolve())
  }

  static async updatePassword (email, token, password) {
    const request = {
      email,
      token,
      password
    }

    console.log(request)

    try {
      await api.post('authentication/updatepassword', request)
      return true
    } catch (error) {
      console.log('Failed to update password for email ' + request.email + '.')
      if (error.response) {
        throw error.response
      } else {
        throw error
      }
    }
  }
}

export default AuthService
