export default {
  createNew: 'Create new Hunting ground',
  newArea: 'New Hunting ground',
  members: 'Members',
  area: 'Hunting ground',
  areas: 'Hunting grounds',
  hunts: 'Hunts',
  noAreasFound: 'You haven\'t created any hunting ground yet.',
  areaName: 'Hunting ground/team name',
  name: 'Name',
  invalidName: 'You must enter a name.'
}
