import axios from "axios"
import { hasAuth, getToken } from "@/modules/auth/services/userContext"
import router from "@/router/"
import store from "@/store/"

class Api {
  constructor () {
    this.api = this.intializeAxios()
    this.setHeaderDefaults()

    return this.api
  }

  get hasAuth () {
    return hasAuth()
  }

  intializeAxios () {
    const api = axios.create({
      baseURL: process.env.VUE_APP_APIURL + "/v1/",
      validateStatus (status) {
        return status >= 200 && status < 300
      }
    })

    let isRefreshing = false
    let failedQueue = []

    const processQueue = (error, token = null) => {
      failedQueue.forEach((prom) => {
        if (error) {
          prom.reject(error)
        } else {
          prom.resolve(token)
        }
      })

      failedQueue = []
    }

    api.interceptors.response.use(
      async (response) => {
        return response
      },
      async (error) => {
        const originalRequest = error.config

        if (error.response.status === 401 && !originalRequest._retry) {
          console.log("Auth token refresh")
          originalRequest._retry = true

          if (isRefreshing) {
            return new Promise(function (resolve, reject) {
              failedQueue.push({ resolve, reject })
            })
              .then((token) => {
                originalRequest.headers.Authorization = "Bearer " + token
                return api(originalRequest)
              })
              .catch((err) => {
                return Promise.reject(err)
              })
          }

          isRefreshing = true

          return new Promise(function (resolve, reject) {
            store
              .dispatch("auth/refreshToken")
              .then(() => {
                const token = getToken()
                originalRequest.headers.Authorization = "Bearer " + token
                processQueue(null, token)
                resolve(api(originalRequest))
              })
              .catch((err) => {
                processQueue(err, null)
                reject(err)
              })
              .then(() => {
                isRefreshing = false
              })
          })
        }

        // If the response status is still 401 after retrying with a new token, redirect to signout
        if (error.response.status === 401 && originalRequest._retry) {
          router.push("/signout")
        }

        return Promise.reject(error)
      }
    )

    return api
  }

  setHeaderDefaults () {
    if (this.hasAuth) {
      axios.defaults.headers.common.Authorization = "Bearer " + getToken()
    }

    // More info: https://github.com/axios/axios/issues/362
    axios.defaults.headers.post["Content-Type"] =
      "application/x-www-form-urlencoded"
  }
}

const api = new Api()

export default api
