export default {
  dashboard: "Tableau de bord",
  map: "Carte",
  team: "Équipe",
  documents: 'Documents',
  hunts: "Chasses",
  calendar: "Calendrier",
  gallery: "Galerie",
  messages: "Messages",
  settings: "Paramètres"
}
