import dayjs from 'dayjs'

export default class HuntAreaInviteModel {
  constructor (json) {
    this.id = json.id || null
    this.huntArea = json.huntArea || ''
    this.email = json.email || ''
    this.createdBy = json.createdBy || ''
    this.acceptedAt = json.acceptedAt ? dayjs(json.acceptedAt).toDate() : null
  }
}
