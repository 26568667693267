class MemberModel {
  constructor (context) {
    const data = Object.assign({}, context)

    this.id = data.id || null
    this.firstName = data.firstName || ''
    this.lastName = data.lastName || ''
    this.email = data.email || ''
    this.phoneNumber = data.phoneNumber || ''
    this.role = data.role || ''
    this.status = data.status || ''
    this.selected = data.selected || false
  }

  getFullName () {
    return this.firstName.length > 0
      ? this.firstName + ' ' + this.lastName
      : this.email
  }

  toJSON () {
    return {
      id: this.id,
      firstName: this.firstName,
      lastName: this.lastName,
      email: this.email,
      phoneNumber: this.phoneNumber,
      role: this.role,
      status: this.status
    }
  }
}

export default MemberModel
