<template>
  <Fragment>
    <header class="card-header">
      <span class="card-header-title">
        <span class="push-right-xs is-clickable" @click="back">
          <Icon name="icon-arrow-left" />
        </span>

        <span>{{ $t('userDropdown.selectLanguage') }}</span>
      </span>
    </header>

    <div class="card-content is-paddingless">
      <ul class="user-dropdown-menu">
        <li
          v-for="(language, i) in languages"
          :key="i"
          :class="{ 'is-active': currentLanguage === language.locale }"
          @click="selectLanguage(language)">
          <a>
            <img class="flag-icon" :src="language.icon" />
            {{ language.name }}
          </a>
        </li>
      </ul>
    </div>
  </Fragment>
</template>

<script>
import { supportedLanguages } from '@/i18n.js'
import { mapGetters } from 'vuex'

export default {
  data () {
    return {
      languages: supportedLanguages
    }
  },

  computed: {
    ...mapGetters({
      currentLanguage: 'ui/language'
    })
  },

  methods: {
    selectLanguage (language) {
      this.$store.dispatch('ui/setLanguage', language.locale)
      this.close()
    },

    back () {
      this.$emit('back')
    },

    close () {
      this.$emit('close')
    }
  }
}
</script>

<style scoped>
.flag-icon {
  width: 20px;
  height: 20px;
  margin-right: 0.5rem;
}

li.is-active {
  background-color: #eee;
}
</style>
