import HuntService from '@/api/hunt/huntService'

export default {
  async setHuntLeader ({ rootGetters }, member) {
    const hunt = rootGetters['hunt/hunt']

    try {
      hunt.huntLeaderUserId = member !== null ? member.userId : null
      await HuntService.update(hunt)
    } catch (error) {
      console.log('Failed to set hunt leader ' + member.userId + ' for hunt ' + hunt.id + '.')
      console.error(error)
      throw error
    }
  },

  async setTrackingHunter ({ rootGetters }, member) {
    const hunt = rootGetters['hunt/hunt']

    try {
      hunt.afterSearchUserId = member !== null ? member.userId : null
      await HuntService.update(hunt)
    } catch (error) {
      console.log('Failed to set tracking hunter ' + member.userId + ' for hunt ' + hunt.id + '.')
      console.error(error)
      throw error
    }
  }
}
