<template>
  <div
    :class="{ 'is-active': isActive }"
    :data-key="dialog.key"
    class="modal">
    <div class="modal-background"></div>

    <div class="modal-content">
      <article class="message is-danger">
        <div class="message-header">
          <p v-if="dialog.title" class="is-marginless">
            {{ dialog.title }}
          </p>
          <button class="delete" aria-label="delete" @click="close(false)"></button>
        </div>

        <div class="message-body">
          <p v-if="dialog.message">
            {{ dialog.message }}
          </p>
          <p v-html="pleaseConfirm"></p>

          <form @submit.prevent="action">
            <div class="field">
              <div class="control">
                <input
                  v-model="input"
                  class="input"
                  type="text" />
              </div>
            </div>

            <div class="field">
              <div class="control">
                <button
                  :disabled="!isValid"
                  class="button is-fullwidth"
                  @click="action">
                  <strong class="has-text-danger">{{ text }}</strong>
                </button>
              </div>
            </div>
          </form>
        </div>
      </article>
    </div>

    <button class="modal-close is-large" aria-label="close" @click="close(false)"></button>
  </div>
</template>

<script>
import { i18n } from '@/i18n.js'

export default {

  props: {
    dialog: {
      type: Object,
      required: true
    },

    isActive: {
      type: Boolean,
      default: false
    },

    type: {
      type: String,
      default: 'remove'
    }
  },

  data () {
    return {
      input: ''
    }
  },

  computed: {
    text () {
      return i18n.t(`general.${this.type}`)
    },

    pleaseConfirm () {
      return i18n.t('general.pleaseConfirm', { type: this.text.toUpperCase() })
    },

    isValid () {
      return this.input === this.text.toUpperCase()
    }
  },

  methods: {
    close (response) {
      this.$emit('close', response, this.dialog)
    },

    action () {
      if (this.isValid) this.close(true)
    }
  }

}
</script>
