import api from '@/api/'
import AssignmentStandModel from './assignmentStandModel'

class AssignmentStandService {
  static async create (huntId, assignmentId, stand) {
    let response

    try {
      const { data } = await api.post(`hunts/${huntId}/assignments/${assignmentId}/stands`, stand)
      response = new AssignmentStandModel(data)
    } catch (error) {
      console.error(`Failed to create stand for user ${stand.userId} on assignment ${assignmentId}.`)
      Promise.reject(error)
    }

    return Promise.resolve(response)
  }

  static async read (huntId, assignmentId) {
    let response

    try {
      const { data } = await api.get(`hunts/${huntId}/assignments/${assignmentId}/stands`)
      const stands = data.map(data => new AssignmentStandModel(data))
      response = stands
    } catch (error) {
      console.error(`Failed to get stands for assignment ${assignmentId}.`)
      Promise.reject(error)
    }

    return Promise.resolve(response)
  }

  static async delete (huntId, assignmentId, userId) {
    let response

    try {
      await api.delete(`hunts/${huntId}/assignments/${assignmentId}/stands/${userId}`)
      response = true
    } catch (error) {
      console.error(`Failed to remove stand for user ${userId} on assignment ${assignmentId}.`)
      Promise.reject(error)
    }

    return Promise.resolve(response)
  }
}

export default AssignmentStandService
