<template>
  <a
    :class="{ 'is-active': active }"
    class="list-item">
    <slot />
  </a>
</template>

<script>
export default {

  props: {
    active: {
      type: Boolean,
      default: false
    }
  },

  watch: {
    active (foo) {
      console.log('mama', foo)
    }
  }

}
</script>
