export default {
  setMap ({ commit }, map) {
    commit('setMap', map)
  },

  setCenter ({ commit }, latLng) {
    commit('setCenter', latLng)
  },

  setDrawingMode ({ commit }, drawingMode) {
    commit('setDrawingMode', drawingMode)
  },

  select ({ dispatch, commit }, object) {
    const { type, data } = object

    dispatch('deselectAll')
    dispatch(`map/${type}/select`, data, { root: true })
  },

  deselectAll ({ commit }) {
    const types = ['boundary', 'markers', 'subAreas', 'trails', 'lines']

    types.forEach(type => {
      commit(`map/${type}/select`, null, { root: true })
    })

    commit('map/print/isAdjusting', false, { root: true })
  },

  isFilterActive ({ commit, dispatch }, bool) {
    dispatch('closeAllPanels')
    commit('isFilterActive', bool)
  },

  isPrintActive ({ commit, dispatch }, bool) {
    dispatch('closeAllPanels')
    commit('isPrintActive', bool)
  },

  isImportActive ({ commit, dispatch }, bool) {
    dispatch('closeAllPanels')
    commit('isImportActive', bool)
  },

  isExportActive ({ commit, dispatch }, bool) {
    dispatch('closeAllPanels')
    commit('isExportActive', bool)
  },

  clear ({ dispatch, commit }) {
    dispatch('deselectAll')

    commit('map/markers/clear', null, { root: true })
    commit('map/subAreas/clear', null, { root: true })
    commit('map/lines/clear', null, { root: true })
    commit('map/trails/clear', null, { root: true })
    commit('map/boundary/delete', null, { root: true })
  },

  closeAllPanels ({ commit }) {
    commit('isFilterActive', false)
    commit('isPrintActive', false)
    commit('isImportActive', false)
    commit('isExportActive', false)
    commit('isExportActive', false)
    commit('map/settings/closePanel', null, { root: true })
  },

  startLoadingPropertyLayerTiles ({ commit }) {
    commit('setPropertyLayerTileLoadingProgress', {
      status: 'loading-protected-resources',
      tileCount: 0,
      loadedCount: 0
    })
  },

  propertyLayerTilesLoaded ({ commit }) {
    commit('setPropertyLayerTileLoadingProgress', {
      status: '',
      tileCount: 0,
      loadedCount: 0
    })
  }
}
