import HunterModel, {
  HUNTER_STATUS_INACTIVE
} from '@/api/hunt/playback/hunterModel'
import GpsDeviceModel from '@/api/hunt/playback/gpsDeviceModel'
import DeviceMap from '../utils/deviceMap'

import {
  loadLocations,
  loadHunterLocations,
  loadGpsLocations,
  createDevices,
  generateUniqueGpsNames,
  getLocationsInPeriod,
  getMarkerById,
  isMarkerCloseToTrail
} from '../utils/playbackUtils'

export const REPORT_PLAYBACK_STATUS_STARTED = 'started'
export const REPORT_PLAYBACK_STATUS_PAUSED = 'paused'
export const REPORT_PLAYBACK_STATUS_STOPPED = 'stopped'

export const REPORT_VISIBLE_MARKERS_AFFECTED = 'affected'
export const REPORT_VISIBLE_MARKERS_ALL = 'all'

export default {
  namespaced: true,

  state: {
    status: REPORT_PLAYBACK_STATUS_STOPPED, //  stopped, started, paused
    speed: 10,

    startTime: 0,
    duration: 0,
    elapsedTime: 0,

    hunters: null,
    hunterLocations: null,
    hunterTrailLength: 600, // In seconds, default 10 min

    gpsDevices: null,
    gpsLocations: [],
    gpsTrailLength: 600, // In seconds, default 10 min

    selectedDevice: null, // The selected hunter or GPS to automatically center on
    visibleMarkers: REPORT_VISIBLE_MARKERS_AFFECTED,

    lastHunterLocationTimestamp: 0,
    lastGpsDeviceLocationTimestamp: 0,
    hunterDeviceMap: null,
    gpsDeviceMap: null
  },

  getters: {
    getStatus: state => state.status,
    getSpeed: state => state.speed,

    getStartTime: state => state.startTime,
    getDuration: state => state.duration,
    getElapsedTime: state => state.elapsedTime,
    getElapsedTimeInMs: state =>
      state.startTime * 1000 + state.elapsedTime * 1000,

    getHunters: state => state.hunters,
    getHunterLocations: state => state.hunterLocations,
    getHunterTrailLength: state => state.hunterTrailLength,
    getLastHunterLocationTimestamp: state => state.lastHunterLocationTimestamp,

    getGpsDevices: state => state.gpsDevices,
    getGpsLocations: state => state.gpsLocations,
    getGpsTrailLength: state => state.gpsTrailLength,
    getLastGpsDeviceLocationTimestamp: state =>
      state.lastGpsDeviceLocationTimestamp,

    getSelectedDevice: state => state.selectedDevice,
    getVisibleMarkers: state => state.visibleMarkers,

    getMarkers (state, getters, rootState, rootGetters) {
      const markers = rootGetters['map/markers/markers'] || []
      const hunterLocations = state.hunterLocations || []

      if (state.visibleMarkers === 'affected') {
        const checkInMarkers = hunterLocations.filter(
          hunterLocation => hunterLocation.checkIn !== null
        )
        const checkInMarkerIds = checkInMarkers.map(
          hunterLocation => hunterLocation.checkIn.id
        )

        return markers.filter(
          marker =>
            checkInMarkerIds.includes(marker.id) ||
            isMarkerCloseToTrail(marker, hunterLocations)
        )
      }

      return markers
    },

    isLoading (state) {
      const values = [
        state.hunters,
        state.hunterLocations,
        state.gpsDevices,
        state.gpsLocations
      ]

      return values.indexOf(null) !== -1
    }
  },

  mutations: {
    setSpeed (state, speed) {
      state.speed = speed
    },

    play (state) {
      state.status = REPORT_PLAYBACK_STATUS_STARTED
    },

    pause (state) {
      state.status = REPORT_PLAYBACK_STATUS_STOPPED
    },

    stop (state) {
      state.status = REPORT_PLAYBACK_STATUS_STOPPED
      state.elapsedTime = 0
      state.selectedDevice = null

      state.hunters.forEach(hunter => {
        hunter.active = false
        hunter.status = HUNTER_STATUS_INACTIVE
      })

      state.gpsDevices.forEach(gpsDevice => {
        gpsDevice.active = false
      })
    },

    setElapsedTime (state, elapsed) {
      state.elapsedTime = elapsed
    },

    setHunters (state, hunters) {
      state.hunters = hunters
      state.hunterDeviceMap = hunters != null ? new DeviceMap(hunters) : null
    },

    setHunterLocations (state, hunterLocations) {
      state.hunterLocations = hunterLocations
    },

    setHunterTrailLength (state, trailLength) {
      state.hunterTrailLength = trailLength
    },

    setLastHunterLocationTimestamp (state, timestamp) {
      state.lastHunterLocationTimestamp = timestamp
    },

    setGpsDevices (state, gpsDevices) {
      state.gpsDevices = gpsDevices
      state.gpsDeviceMap =
        gpsDevices !== null ? new DeviceMap(state.gpsDevices) : null
    },

    setGpsLocations (state, gpsLocations) {
      state.gpsLocations = gpsLocations
    },

    setGpsTrailLength (state, trailLength) {
      state.gpsTrailLength = trailLength
    },

    setSelectedDevice (state, device) {
      state.selectedDevice = device
    },

    setVisibleMarkers (state, value) {
      state.visibleMarkers = value
    },

    initializePlayback (state, report) {
      var startTimeInSeconds = report.startsAt.getTime() / 1000
      var endTimeInSeconds = report.endsAt.getTime() / 1000

      state.startTime = startTimeInSeconds
      state.duration = Math.round(endTimeInSeconds - startTimeInSeconds)
      state.elapsedTime = 0

      state.status = REPORT_PLAYBACK_STATUS_STOPPED

      state.lastHunterLocationTimestamp = 0
      state.lastGpsDeviceLocationTimestamp = 0
    },

    clear (state) {
      state.hunters = null
      state.hunterLocations = null
      state.gpsDevices = null
      state.gpsDeviceLocations = null
      state.selectedDevice = null
      state.elapsedTime = 0
    }
  },

  actions: {
    async read ({ dispatch, commit }, { hunt, report }) {
      commit('initializePlayback', report)

      await dispatch('loadLocations', hunt)

      // Old GPS locations loading from Firebase
      // await dispatch('loadHunterLocations', hunt)
      // await dispatch('loadGpsLocations', hunt)
    },

    async loadLocations ({ commit }, hunt) {
      commit('setHunterLocations', null)
      commit('setHunters', null)
      commit('setGpsLocations', null)
      commit('setGpsDevices', null)

      const locations = await loadLocations(hunt)

      const hunters = createDevices(
        locations.hunterLocations,
        device => new HunterModel(device.id, device.name)
      )

      commit('setHunterLocations', locations.hunterLocations)
      commit('setHunters', hunters)

      commit('setGpsLocations', locations.gpsLocations)

      const gpsDevices = createDevices(
        locations.gpsLocations,
        device => new GpsDeviceModel(device.id, device.name)
      )

      generateUniqueGpsNames(gpsDevices)

      commit('setGpsDevices', gpsDevices)
    },

    async loadHunterLocations ({ commit }, hunt) {
      commit('setHunterLocations', null)
      commit('setHunters', null)

      const hunterLocations = await loadHunterLocations(hunt)
      const hunters = createDevices(
        hunterLocations,
        device => new HunterModel(device.id, device.name)
      )

      commit('setHunterLocations', hunterLocations)
      commit('setHunters', hunters)
    },

    async loadGpsLocations ({ commit, rootGetters }, hunt) {
      commit('setGpsLocations', null)
      commit('setGpsDevices', null)

      const userId = rootGetters['auth/getUserId']
      const gpsLocations = await loadGpsLocations(hunt, userId)

      commit('setGpsLocations', gpsLocations)

      const gpsDevices = createDevices(
        gpsLocations,
        device => new GpsDeviceModel(device.id, device.name)
      )

      generateUniqueGpsNames(gpsDevices)

      commit('setGpsDevices', gpsDevices)
    },

    setElapsedTime ({ commit, dispatch }, elapsedTime) {
      commit('setElapsedTime', elapsedTime)

      dispatch('animateHunters')
      dispatch('animateGpsDevices')

      if (elapsedTime === 0) {
        commit('stop')
      }
    },

    animateHunters ({ dispatch, state, getters }) {
      const elapsedTimeInMs = getters.getElapsedTimeInMs

      const hunterLocationsInPeriod = getLocationsInPeriod(
        state.hunterLocations,
        state.lastHunterLocationTimestamp,
        elapsedTimeInMs
      )

      hunterLocationsInPeriod.forEach(hunterLocation => {
        dispatch('animateHunter', hunterLocation)
      })

      state.lastHunterLocationTimestamp = elapsedTimeInMs
    },

    animateHunter ({ state, rootGetters }, location) {
      const hunter = state.hunterDeviceMap.get(location.deviceId)

      hunter.active = true
      hunter.visible = location.visible
      hunter.location = location.location
      hunter.accuracy = location.accuracy

      if (location.checkIn !== null) {
        const markers = rootGetters['map/markers/markers']

        const marker = getMarkerById(markers, location.checkIn.id)
        if (marker !== null) {
          hunter.checkIn(marker)
        }
      } else {
        if (hunter.checkInMarker !== null) {
          hunter.checkOut()
        }

        hunter.status = location.isPublishing ? 'active' : 'inactive'
      }
    },

    animateGpsDevices ({ dispatch, state, getters }) {
      const elapsedTimeInMs = getters.getElapsedTimeInMs

      const gpsLocations = getLocationsInPeriod(
        state.gpsLocations,
        state.lastGpsDeviceLocationTimestamp,
        elapsedTimeInMs
      )

      gpsLocations.forEach(gpsLocation => {
        dispatch('animateGpsDevice', gpsLocation)
      })

      state.lastGpsDeviceLocationTimestamp = elapsedTimeInMs
    },

    animateGpsDevice ({ state }, location) {
      const gpsDevice = state.gpsDeviceMap.get(location.deviceId)
      if (gpsDevice !== undefined) {
        if (!gpsDevice.active) {
          gpsDevice.active = true
        }

        gpsDevice.visible = location.visible
        gpsDevice.location = location.location
        gpsDevice.speed = location.speed
        gpsDevice.color = location.color
      }
    }
  }
}
