<template>
  <div class="notifications">
    <Notification
      v-for="item in data"
      :key="item.key"
      :isActive="item.isActive"
      :data="item"
      @close="close">
    </Notification>
  </div>
</template>

<script>
import Notification from './Notification.vue'

const key = () => `${Date.now()}-${Math.random()}`

export default {
  components: {
    Notification
  },

  data () {
    return {
      data: []
    }
  },

  methods: {
    open (text, type, timeout) {
      this.$mount()
      document.body.appendChild(this.$el)

      const types = {
        success: 'is-success',
        warning: 'is-warning',
        danger: 'is-danger'
      }

      const item = {
        key: key(),
        isActive: true,
        text: text,
        type: types[type]
      }

      this.data.push(item)

      timeout = timeout || 5000

      setTimeout(() => {
        this.remove(item)
      }, timeout)
    },

    success (text, timeout) {
      return this.open(text, 'success', timeout)
    },

    warning (text, timeout) {
      return this.open(text, 'warning', timeout)
    },

    danger (text, timeout) {
      if (process.env.NODE_ENV !== 'production' && text) {
        console.error(text)
      }

      return this.open(text, 'danger', timeout)
    },

    remove (item) {
      let i = this.data.indexOf(item)
      if (i >= 0) this.data.splice(i, 1)
    },

    close (item) {
      item.isActive = false
      this.remove(item)
    }
  }
}
</script>

<style lang="scss" scoped>
.notifications {
  position: fixed;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  z-index: 9999;
  max-width: 40rem;

  .notification:last-of-type {
    border-bottom-right-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
  }
}
</style>
