export default {
  title: 'Documents',
  download: "Télécharger",
  rename: "Renommer",
  remove: "Supprimer",
  move: "Déplacer",
  uploadFiles: "Télécharger des fichiers",

  createFolder: "Créer un nouveau dossier",
  createFolderPlaceholer: "Nom de dossier",
  createFolderFailedErrorMesssage: "Une erreur s'est produite et le dossier n'a pas pu être créé. Veuillez réessayer.",

  removeFolder: "Supprimer le dossier",
  confirmRemoveFolder: "Voulez-vous vraiment supprimer le dossier <strong>{folderName}</strong> ?",
  removeFolderFailedErrorMessage: "Une erreur s'est produite et le dossier n'a pas pu être supprimé. Veuillez réessayer.",

  uploadDocumentFailedErrorMessage: "Une erreur s'est produite et le document n'a pas pu être téléchargé. Veuillez réessayer.",
  readDocumentsFailedErrorMessage: "Une erreur s'est produite et tous les dossiers n'ont pas pu être lus. Veuillez réessayer.",
  moveDocumentFailedErrorMessage: "Une erreur s'est produite et le document n'a pas pu être déplacé. Veuillez réessayer.",
  removeDocumentFailedErrorMessage: "Une erreur s'est produite et le document n'a pas pu être supprimé. Veuillez réessayer.",

  nameOnFolder: "Nom sur le dossier",

  moveDocumentTitle: "Déplacer le document vers...",
  moveDocumentHelpText: "Choisissez le dossier vers lequel déplacer le document <strong>{name}</strong>.",

  moveFolderTitle: "Déplacer le dossier vers...",
  moveFolderHelpText: "Vers quel dossier souhaitez-vous déplacer le dossier <strong>{name}</strong> ?",

  renameDocumentTitle: "Renommer le document",
  renameDocumentPlaceholder: "Nom du document",
  renameFolderTitle: "Renommer le dossier",
  renameFolderPlaceholder: "Nom du dossier",
  missingName: "Vous devez saisir un nom.",

  noMoreFolders: "Il n'y a plus de dossiers.",
  noDocumentsTitle: "Aucun document",
  noDocumentsUploaded: "Vous n'avez téléchargé aucun document.",
  noDocumentsMatchedSearchTitle: "Aucune concordance",
  noDocumentsMatchedSearch: "Nous n'avons trouvé aucun document ou dossier correspondant à votre recherche \"{search}\".",

  missingFolderName: "Vous devez saisir un nom pour le dossier."
}
