import dayjs from 'dayjs'

class MemberInviteModel {
  constructor (context) {
    const data = Object.assign({}, context)

    this.id = data.id || null
    this.email = data.email || ''
    this.huntArea = data.huntArea || ''
    this.createdBy = data.createdBy || ''
    this.acceptedAt = data.acceptedAt != null ? dayjs(data.acceptedAt).toDate() : null
    this.status = data.status || ''
  }

  toJSON () {
    return {
      id: this.id,
      email: this.email,
      huntArea: this.huntArea,
      createdBy: this.createdBy,
      acceptedAt: this.acceptedAt,
      status: this.status
    }
  }
}

export default MemberInviteModel
