<template>
  <div>
    <Checkbox
      v-if="hasPro"
      v-model="terrain"
      :label="title"
      type="switch">
    </Checkbox>

    <div v-else class="level">
      <div class="level-left">
        {{ $t('map.settings.terrain') }}
      </div>

      <div class="level-right">
        <Icon name="icon-lock" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { FEATURE_PROPERTY_BOUNDARIES_LAYER } from '@/modules/subscription/store/subscriptionStore'

export default {
  computed: {
    ...mapGetters({
      hasFeature: 'subscription/hasFeature'
    }),

    hasPropertyBoundariesLayerFeature () {
      return this.hasFeature(FEATURE_PROPERTY_BOUNDARIES_LAYER)
    },

    title () {
      return this.hasPropertyBoundariesLayerFeature ? this.$i18n.t('map.settings.nordicPropertyBoundariesLayer') : this.$i18n.t('map.settings.terrain')
    },

    terrain: {
      get () {
        return this.$store.getters['map/settings/isTerrainLayerVisible']
      },

      set (visibility) {
        return this.$store.dispatch('map/settings/toggleTerrainLayerVisibility', visibility)
      }
    },

    hasPro () {
      return this.$store.getters['subscription/hasPro']
    }
  }
}
</script>
