import dashboard from '@/modules/huntArea/dashboard/translations/en'

export default {
  huntingGround: 'Hunting ground',
  date: 'Date',
  time: 'Time',
  newHuntingGround: 'New Hunting ground',
  createHuntingGround: 'Create new Hunting ground',
  huntingGroundNamePlaceholder: 'Hunting ground name or name of the hunting party',
  noHuntingGroundNameErrorMessage: 'You must give the hunting ground a name',
  createHuntingGroundFailedErrorMessage: 'Something wen\'t wrong. Please try again.',
  noHuntAreasEmptyStateMessage: 'You\'re not a member of any hunting ground.<br /><br />Click on the button above to create your first hunting ground.',

  dashboard
}
