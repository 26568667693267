export default {
  title: 'Documents',
  download: 'Download',
  rename: 'Rename',
  remove: 'Delete',
  move: 'Move',
  uploadFiles: 'Upload files',

  createFolder: 'Create new folder',
  createFolderPlaceholer: 'Folder name',
  createFolderFailedErrorMesssage: 'An error occurred and the folder could not be created. Please try again.',

  removeFolder: 'Remove folder',
  confirmRemoveFolder: 'Do you really want to remove the folder <strong>{folderName}</strong>?',
  removeFolderFailedErrorMessage: 'An error occurred and the folder could not be removed. Please try again.',

  uploadDocumentFailedErrorMessage: 'An error occurred and the document could not be uploaded. Please try again.',
  readDocumentsFailedErrorMessage: 'An error occurred and all folders could not be read. Please try again.',
  moveDocumentFailedErrorMessage: 'An error occurred and the document could not be moved. Please try again.',
  removeDocumentFailedErrorMessage: 'An error occurred and the document could not be removed. Please try again.',

  nameOnFolder: 'Name on folder',

  moveDocumentTitle: 'Move document to...',
  moveDocumentHelpText: 'Choose which folder to move the document <strong>{name}</strong> to.',

  moveFolderTitle: 'Move folder to...',
  moveFolderHelpText: 'Which folder do you want to move the folder <strong>{name}</strong> to?',

  renameDocumentTitle: 'Rename document',
  renameDocumentPlaceholder: 'Name of the document',
  renameFolderTitle: 'Rename folder',
  renameFolderPlaceholder: 'Name of the folder',
  missingName: 'You must enter a name.',

  noMoreFolders: 'There are no more folders.',
  noDocumentsTitle: 'No documents',
  noDocumentsUploaded: 'You haven\'t uploaded any documents.',
  noDocumentsMatchedSearchTitle: 'No match',
  noDocumentsMatchedSearch: 'We could not find any documents or folders matching your search "{search}".',

  missingFolderName: 'You must enter a name for the folder.'
}
