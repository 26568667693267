<template>
  <div class="dialogs">
    <Dialog
      v-for="dialog in dialogs"
      :key="dialog.key"
      :isActive="dialog.isActive"
      :type="type"
      :dialog="dialog"
      @close="close">
    </Dialog>
  </div>
</template>

<script>
import Dialog from './Dialog.vue'
import router from '@/router/index.js'
import store from '@/store/index.js'
import { i18n } from '@/i18n.js'

export default {
  router,
  store,
  i18n,

  components: {
    Dialog
  },

  data () {
    return {
      dialogs: [],
      type: ''
    }
  },

  methods: {
    create (options) {
      this.$mount()
      document.body.appendChild(this.$el)

      return new Promise(resolve => {
        this.type = options['type'] || 'remove'

        if (!options.title) options['title'] = i18n.t(`general.${this.type}`)
        if (!options.remove) options['remove'] = i18n.t('general.understandConsequences', { type: i18n.t(`general.${this.type}`) })
        if (!options.message) options['message'] = ''

        const $dialog = Object.assign(options, {
          key: `${Math.random(0x7FFFFFFF).toString(18).slice(2, 9)}`,
          isActive: true,
          resolve
        })

        this.dialogs.push($dialog)
      })
    },

    delete (dialog) {
      let i = this.dialogs.indexOf(dialog)
      if (i >= 0) this.dialogs.splice(i, 1)
    },

    confirm (options = {}) {
      return this.create(options)
    },

    close (ok, dialog) {
      dialog.isActive = false
      dialog.resolve({ ok, dialog })
      this.delete(dialog)
    }
  }
}
</script>
