export default {
  namespaced: true,

  state: {
    duration: 0,
    elapsed: 0,
    currentLocation: null
  },

  getters: {
    duration: state => state.duration,
    elapsed: state => state.elapsed,
    currentLocation: state => state.currentLocation
  },

  mutations: {
    duration (state, duration) {
      state.duration = duration
    },

    elapsed (state, elapsed) {
      state.elapsed = elapsed
    },

    setCurrentLocation (state, location) {
      state.currentLocation = location
    }
  }
}
