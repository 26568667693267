import BoundaryService from '@/api/map/boundary/boundaryService'
import BoundaryModel from '@/api/map/boundary/boundaryModel'

export default {
  namespaced: true,

  state: {
    boundary: null,
    selected: false
  },

  getters: {
    boundary: state => state.boundary,
    selected: state => state.selected
  },

  actions: {
    async read ({ commit }, huntAreaId) {
      commit('set', null)
      const bounds = await BoundaryService.read(huntAreaId)
      commit('set', bounds)

      return bounds
    },

    async update ({ state, dispatch }, huntAreaId) {
      dispatch('ui/cursorLoader', true, { root: true })

      try {
        await BoundaryService.update(huntAreaId, state.boundary.coordinates)
      } finally {
        dispatch('ui/cursorLoader', false, { root: true })
      }
    },

    select ({ commit, dispatch }, state) {
      dispatch('map/closeAllPanels', null, { root: true })
      commit('select', state)
    }
  },

  mutations: {
    set (state, coordinates) {
      if (coordinates) {
        state.boundary = new BoundaryModel({
          coordinates: coordinates,
          visible: true,
          selected: false,
          editable: false
        })
      } else {
        state.boundary = null
      }
    },

    delete (state) {
      state.boundary = null
    },

    select (state, bool) {
      state.selected = bool
    }
  }
}
