/**
 * Helper class that stores hunters or gps devices in a map for quick access
 * when animating hunter or gps device locations.
 */
export default class DeviceMap {
  constructor (devices) {
    this.devices = []

    devices.forEach(device => {
      this.devices[device.id] = device
    })
  }

  get (deviceId) {
    return this.devices[deviceId]
  }
}
