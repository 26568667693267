/**
 * Information about a report sent to Viltrapport.
 */
class ViltrapportReportModel {
  constructor (data) {
    this.id = data.id || null

    this.speciesId = data.speciesId || null
    this.name = data.name || null

    this.type = data.type ? {
      speciesTypeId: data.type.speciesTypeId,
      name: data.type.name,
      gameId: data.type.gameId
    } : null

    this.comment = data.comment || ''

    this.externalId = data.externalId || null
    this.districtId = data.districtId || null
    this.selected = (data.selected || this.id !== null) || false
    this.invalid = false
  }

  toJSON () {
    return {
      speciesId: this.speciesId,
      speciesTypeId: this.type.speciesTypeId,
      gameId: this.type.gameId,
      externalId: this.externalId,
      comment: this.comment.length > 0 ? this.comment : null
    }
  }
}

export default ViltrapportReportModel
