class JsonP {
  constructor (options) {
    if (!options.url) return

    this.url = options.url
    this.data = options.data || {}
    this.callback = 'cb_' + Math.random(0x7FFFFFFF).toString(18).slice(2, 9)
    this.script = document.createElement('script')

    return new Promise((resolve, reject) => {
      window[this.callback] = data => {
        delete window[this.callback]
        document.body.removeChild(this.script)
        resolve(data)
      }

      this.script.src = this.dataString()
      document.body.appendChild(this.script)
    })
  }

  dataString () {
    const data = []
    let url = this.url + '?callback=' + this.callback

    for (const key in this.data) {
      data.push(key + '=' + this.data[key])
    }

    if (data.length === 1) {
      url += '&' + encodeURI(data.join(''))
    } else if (data.length >= 1) {
      url += '&' + encodeURI(data.join('&'))
    }

    url += '&_=' + new Date().valueOf()

    return url
  }
}

const jsonp = options => new JsonP(options)

export default jsonp
