import Vue from 'vue'

import Navbar from './navbar/Navbar.vue'
import Sidebar from './sidebar/Sidebar.vue'

import Titlebar from './Titlebar.vue'
import Wrapper from './Wrapper.vue'
import Page from './Page.vue'
import SplitView from './SplitView.vue'

import GridView from './GridView.vue'
import GridViewItem from './GridViewItem.vue'

Vue.component('Navbar', Navbar)
Vue.component('Sidebar', Sidebar)

Vue.component('Titlebar', Titlebar)
Vue.component('Wrapper', Wrapper)
Vue.component('Page', Page)
Vue.component('SplitView', SplitView)

Vue.component('GridView', GridView)
Vue.component('GridViewItem', GridViewItem)
