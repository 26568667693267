<template>
  <div :class="{ 'is-active': isActive }" :data-key="dialog.key" class="modal">
    <div class="modal-background" @click="close(false)"></div>

    <div v-if="hasLoaded" class="modal-card">
      <header class="modal-card-head">
        <p v-if="dialog.title" class="modal-card-title">{{ dialog.title }}</p>

        <button
          v-if="dialog.type !== 'alert'"
          class="delete"
          aria-label="close"
          @click="close(false)">
        </button>
      </header>

      <section class="modal-card-body">
        <p class="is-marginless" v-html="dialog.message"></p>

        <form @submit.prevent="ok">
          <Component
            :is="dialog.component"
            v-if="dialog.component"
            ref="component"
            :options="dialog.data"
            @close="close(false)">
          </Component>
        </form>
      </section>

      <footer class="modal-card-foot">
        <div class="flex justify-end" style="width: 100%;">
          <div class="buttons">
            <button v-if="dialog.type !== 'alert'" class="button" @click="close(false)">
              {{ dialog.cancel }}
            </button>

            <button :disabled="!isValid" class="button is-primary" @click="ok">
              {{ dialog.ok }}
            </button>
          </div>
        </div>
      </footer>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    dialog: {
      type: Object,
      required: true
    },

    isActive: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
      hasLoaded: false,
      isValid: false
    }
  },

  mounted () {
    this.hasLoaded = true

    this.$nextTick(() => {
      this.isValid = this.valid()

      this.$watch(() => {
        if (this.$refs.component) {
          return this.$refs.component.isValid
        }
      }, state => {
        this.isValid = this.valid()
      })
    })
  },

  methods: {
    valid () {
      if (this.$refs.component) {
        return this.$refs.component.isValid
      }

      return true
    },

    close (response) {
      if (this.dialog.component) {
        this.dialog.component = this.$refs.component
      }

      this.$emit('close', response, this.dialog)
    },

    ok () {
      if (this.isValid) {
        this.close(true)
      }
    }
  }
}
</script>
