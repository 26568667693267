<template>
  <Advertisement
    v-if="advertisement !== null"
    class="advertisement is-hidden-tablet"
    :advertisement="advertisement">
  </Advertisement>
</template>

<script>
import Advertisement from '@/modules/advertisement/components/Advertisement.vue'

export default {
  components: {
    Advertisement
  },

  computed: {
    advertisement () {
      const advertisement = this.$store.getters['advertisement/getSignInAd']
      if (advertisement !== null) {
        // Only show advertisement if not we haven't set a fallback image
        return advertisement.startDate !== null ? advertisement : null
      }
      return null
    }
  }
}
</script>

<style scoped>
.advertisement {
  margin-top: 2rem;
}
</style>
