import HuntEventService from '@/api/hunt/events/huntEventService'
import HuntEventModel from '@/api/hunt/events/huntEventModel'

export default {
  create ({ commit, dispatch }, data) {
    const { huntId, event } = data
    commit('add', new HuntEventModel(event))

    return new Promise(async (resolve, reject) => {
      dispatch('ui/cursorLoader', true, { root: true })

      try {
        const $event = await HuntEventService.create(huntId, new HuntEventModel(event))
        commit('update', $event)
        resolve($event)
      } catch (error) {
        commit('delete', event)
        reject(error)
      }

      dispatch('ui/cursorLoader', false, { root: true })
    })
  },

  read ({ commit }, huntId) {
    commit('set', null)

    return new Promise(async (resolve, reject) => {
      try {
        const $events = await HuntEventService.read(huntId)
        commit('set', $events || [])
        resolve($events || [])
      } catch (error) {
        reject(error)
      }
    })
  },

  update ({ commit }, data) {
    const { huntId, event } = data

    return new Promise(async (resolve, reject) => {
      try {
        const $event = await HuntEventService.update(huntId, event)
        commit('update', $event)
        resolve($event)
      } catch (error) {
        reject(error)
      }
    })
  },

  delete ({ commit, dispatch }, data) {
    const { huntId, event } = data
    event.status = 'deleting'

    return new Promise(async (resolve, reject) => {
      dispatch('ui/cursorLoader', true, { root: true })

      try {
        await HuntEventService.delete(huntId, event.id)
        commit('delete', event)
        resolve()
      } catch (error) {
        event.status = ''
        reject(error)
      }

      dispatch('ui/cursorLoader', false, { root: true })
    })
  },

  setEvents ({ commit }, type) {
    commit('set', type)
  }
}
