export default {
  start: 'Start',
  news: 'Nyheter',
  areas: 'Jaktmarker',
  hunts: 'Jakter',

  dashboard: 'Översikt',
  calendar: 'Kalender',
  profile: 'Profil',
  viltrapport: 'Viltrapport'
}
