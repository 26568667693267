<template>
  <FormField>
    <Checkbox
      v-model="label"
      :label="$t('map.general.subAreas')"
      type="switch">
    </Checkbox>
  </FormField>
</template>

<script>
export default {
  computed: {
    label: {
      get () {
        return this.$store.getters['map/subAreas/showSubAreaLabels']
      },

      set (state) {
        return this.$store.commit('map/subAreas/showSubAreaLabels', state)
      }
    }
  }
}
</script>
