import AuthService from "../services/authService"
import firebaseClient from "@/api/firebase/firebaseClient"
import { propertyLayerTileStore } from "@/components/map/layers/propertyLayer"
import Storage from "@/assets/libs/storage/storage"

const TOKEN_KEY = "token"
const CREDENTIALS_KEY = "base64"
const USER_ID_KEY = "userId"

export default {
  namespaced: true,

  state: {
    persistent: false,
    token: null,
    userId: null,
    credentials: null
  },

  getters: {
    hasAuth: (state) => state.token !== null,
    getToken: (state) => state.token,
    getUserId: (state) => state.userId,
    getCredentials: (state) => state.credentials,

    getBase64 (state) {
      // IMPORTANT: In the rest of this module we use the builtin btoa() and atob() which is needed to correctly store and read back
      // swedish characters like å, ä and ö. But when sending these to the server through the basic auth header we must encode these differently.
      return b64EncodeUnicode(
        state.credentials.email + ":" + state.credentials.password
      )
    }
  },

  mutations: {
    setPersistent (state, persistent) {
      state.persistent = persistent
    },

    setToken (state, token) {
      state.token = token

      if (token !== null) {
        const storage = new Storage(state.persistent)
        storage.setItem(TOKEN_KEY, token)
      }
    },

    setUserId (state, userId) {
      state.userId = userId

      if (userId !== null) {
        const storage = new Storage(state.persistent)
        storage.setItem(USER_ID_KEY, userId)
      }
    },

    setCredentials (state, credentials) {
      if (credentials !== null) {
        state.credentials = {
          email: credentials.email || state.credentials.email,
          password: credentials.password || state.credentials.password
        }

        state.persistent = credentials.persistent || state.persistent

        const base64 = window.btoa(
          state.credentials.email + ":" + state.credentials.password
        )
        const storage = new Storage(state.persistent)
        storage.setItem(CREDENTIALS_KEY, base64)
      } else {
        credentials = null
      }
    },

    clear (state) {
      state.token = null
      state.userId = null
      state.credentials = {
        email: null,
        password: null
      }

      const storage = new Storage(state.persistent)
      storage.removeItem(TOKEN_KEY)
      storage.removeItem(CREDENTIALS_KEY)
      storage.removeItem(USER_ID_KEY)
    }
  },

  actions: {
    setEmail ({ commit }, email) {
      commit("setCredentials", { email })
    },

    setPassword ({ commit }, password) {
      commit("setCredentials", { password })
    },

    join (store, userData) {
      return AuthService.join(userData)
    },

    async signIn ({ commit, dispatch }, credentials) {
      const authResult = await AuthService.signIn(credentials)

      commit("setCredentials", {
        email: credentials.email,
        password: credentials.password,
        persistent: credentials.remember
      })

      commit("setToken", authResult.token)
      commit("setUserId", authResult.user.id)
    },

    autoSignIn ({ commit, dispatch }) {
      const persistent = isPersistent()

      commit("setPersistent", persistent)
      commit("setToken", readToken(persistent))
      commit("setCredentials", readCredentials(persistent))
      commit("setUserId", readUserId(persistent))
    },

    async refreshToken ({ commit, dispatch }) {
      const persistent = isPersistent()
      const credentials = readCredentials(persistent)

      if (credentials) {
        await dispatch("signIn", credentials)
      }
    },

    async signOut ({ commit, dispatch }) {
      try {
        await AuthService.signOut()
        await firebaseClient.signOut()
      } catch (error) {
        // Fail silently...
      } finally {
        propertyLayerTileStore.clear() // Clear the tile cache on disk. TODO: Move to App.vue

        commit("clear")
        dispatch("ui/clearAllStates", null, { root: true })
      }
    },

    recoverPassword (store, email) {
      return AuthService.recoverPassword(email)
    },

    updatePassword (store, { email, token, password }) {
      return AuthService.updatePassword(email, token, password)
    }
  }
}

/********************/
/* HELPER FUNCTIONS */
/********************/

function isPersistent () {
  return Storage.isPersistent(TOKEN_KEY)
}

function readToken (persistent) {
  const storage = new Storage(persistent)
  return storage.getItem(TOKEN_KEY)
}

function readUserId (persistent) {
  const storage = new Storage(persistent)
  return storage.getItem(USER_ID_KEY)
}

function readCredentials (persistent) {
  const storage = new Storage(persistent)

  const base64 = storage.getItem(CREDENTIALS_KEY)
  if (base64) {
    const decoded = window.atob(base64).split(":")
    return {
      email: decoded[0],
      password: decoded[1]
    }
  }

  return null
}

/**
 * Found at https://stackoverflow.com/questions/29166479/encoding-special-characters-to-base64-in-javascript-and-decoding-using-base64-de
 */
function b64EncodeUnicode (str) {
  return window.btoa(
    encodeURIComponent(str).replace(/%([0-9A-F]{2})/g, (match, p1) => {
      return String.fromCharCode("0x" + p1)
    })
  )
}
