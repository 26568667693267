import { HUNT_STATUS_FINISHED } from '@/api/hunt/huntModel'

export default {
  hunt: state => state.hunt,
  hunts: state => state.hunts,

  getFinishedHunts (state) {
    if (state.hunts !== null) {
      return state.hunts.filter(hunt => hunt.status === HUNT_STATUS_FINISHED)
    }
    return null
  },

  state: state => state.state,
  isLoading: state => state.state === 'loading',

  canEdit (state, getters, rootState, rootGetters) {
    if (state.hunt !== null) {
      const userId = rootGetters['auth/getUserId']
      return state.hunt.status !== HUNT_STATUS_FINISHED && state.hunt.createdBy === userId
    }
    return false
  },

  isAdmin (state, getters, rootState, rootGetters) {
    const userId = rootGetters['auth/getUserId']
    return state.hunt !== null ? state.hunt.createdBy === userId : false
  },

  getHuntWithId: state => huntId => {
    const hunts = state.hunts || []
    const i = hunts.findIndex(hunt => hunt.id === huntId)
    return i !== -1 ? state.hunts[i] : null
  },

  getParticipantWithId: state => userId => {
    for (let i = 0; i < state.participants.length; i++) {
      if (state.participants[i].id === userId) {
        return state.participants[i]
      }
    }

    return null
  },

  // Assignments
  assignments: state => state.assignments,
  assignmentUser: state => state.assignmentUser,
  selectedUser: state => state.selectedUser,
  selectedAssignment: state => state.selectedAssignment
}
