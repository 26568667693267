<template>
  <section :class="{ 'content': content }" class="section">
    <div v-if="(centered && boxed) || (centered && !boxed)" class="container">
      <slot />
    </div>

    <div v-if="!centered && boxed" class="box">
      <slot />
    </div>

    <div v-if="!centered && !boxed" class="container is-fluid is-paddingless flex direction-vertical">
      <slot />
    </div>
  </section>
</template>

<script>
export default {
  props: {
    centered: {
      type: Boolean,
      default: false
    },

    boxed: {
      type: Boolean,
      default: false
    },

    content: {
      type: Boolean,
      default: false
    }
  }
}
</script>
