<template>
  <Fragment>
    <div class="tabs">
      <ul>
        <li
          v-for="(tab, i) in tabs"
          :key="i"
          :class="{ 'is-active': tab.isActive }">
          <a @click.prevent="select(tab)">
            <span>{{ tab.name }}</span>

            <span v-if="tab.icon">
              <Icon :name="[tab.icon]" />
            </span>

            <span
              v-if="tab.badge"
              :class="[tab.badgeType]"
              class="tag is-rounded push-left-xs">
              {{ tab.badge }}
            </span>
          </a>
        </li>

        <slot name="extra" />
      </ul>
    </div>

    <div class="tabs-details">
      <slot />
    </div>
  </Fragment>
</template>

<script>
export default {
  props: {
    icon: {
      type: String,
      default: ''
    }
  },

  data () {
    return {
      tabs: []
    }
  },

  created () {
    this.tabs = this.$children
  },

  methods: {
    select (selected) {
      this.tabs.forEach(tab => {
        tab.isActive = (tab.name === selected.name)
      })
    }
  }
}
</script>

<style lang="scss">
.tabs {
  ul {
    position: relative;
  }

  > .tag {
    height: 1.25rem;
    padding: 0.125rem 0.375rem 0;
  }
}
</style>
