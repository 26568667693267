<template>
  <div :class="classes">
    <slot />
  </div>
</template>

<script>
export default {
  props: {
    alignment: {
      type: String,
      default: 'left'
    }
  },

  computed: {
    classes () {
      return this.alignment !== 'center' ? 'level-' + this.alignment : ''
    }
  }
}
</script>
