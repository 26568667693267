import api from '@/api/'
import HuntModel from '@/api/hunt/huntModel'

class HuntInviteService {
  static async readAll () {
    try {
      const { data } = await api.get('invitations/hunts')
      return data.map(hunt => new HuntModel(hunt))
    } catch (error) {
      console.error('Failed to read hunt invites.')
      throw error
    }
  }

  static async answerById (huntInvitationId, accept) {
    try {
      await api.post(`invitations/hunts/${huntInvitationId}`, { accept })
    } catch (error) {
      console.error(`Failed to answer hunt invite ${huntInvitationId} with answer ${accept ? 'yes' : 'no'}.`)
      throw error
    }
  }
}

export default HuntInviteService
