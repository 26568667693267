import GameService from '@/api/hunt/games/gameService'
import { i18n } from '@/i18n'

export default {
  namespaced: true,

  state: {
    games: null,
    countryCode: null // The last country code used.
  },

  getters: {
    getAll (state) {
      return state.games
    }
  },

  mutations: {
    setGames (state, games) {
      state.games = games
    },

    setCountryCode (state, countryCode) {
      state.countryCode = countryCode
    }
  },

  actions: {
    async read ({ commit, state }) {
      const countryCode = i18n.t('report.loadAnimalsCountryCode')

      if (state.games === null || state.countryCode !== countryCode) {
        commit('setGames', null)

        const games = await GameService.readGamesForCountry(countryCode)

        commit('setGames', games)
        commit('setCountryCode', countryCode)
      }
    }
  }
}
