import AssignmentStandService from '@/api/hunt/assignments/assignmentStandService'
import state from './../state' // from `assignment`

export default {

  create ({ commit }, data) {
    const { huntId, assignmentId, participant, marker } = data

    return new Promise(async (resolve, reject) => {
      try {
        await AssignmentStandService.create(huntId, assignmentId, {
          userId: participant.id,
          marker: { id: marker.id, name: marker.name }
        })

        commit('setAssignmentStand', data)
        commit('setAssignmentUser', null)

        resolve()
      } catch (error) {
        reject(error)
      }
    })
  },

  read ({ commit, rootGetters }, huntId) {
    return new Promise((resolve, reject) => {
      state.assignments.forEach(async assignment => {
        try {
          const stands = await AssignmentStandService.read(huntId, assignment.id)

          stands.forEach(stand => {
            const participant = assignment.getParticipantWithId(stand.userId)
            const marker = rootGetters['hunt/map/getMarkerWithId'](stand.markerId)

            if (participant && marker) {
              participant.pass = marker

              commit('setAssignmentStand', { participant, marker })
            }
          })
        } catch (error) {
          reject(error)
        }
      })

      resolve()
    })
  },

  delete ({ commit }, params) {
    const { huntId, assignment, participant } = params

    return new Promise(async (resolve, reject) => {
      try {
        await AssignmentStandService.delete(huntId, assignment.id, participant.id)
        commit('removeAssignmentStand', participant)

        resolve()
      } catch (error) {
        reject(error)
      }
    })
  }

}
