export default {
  years: {
    all: 'All years',
    today: 'Today'
  },

  events: {
    kills: 'Downed',
    observations: 'Observed',
    all: 'All'
  },

  game: {
    killed: 'Downed game',
    observed: 'Observed game',
    killedAndObserved: 'Downed and observed game',
    all: 'All game',
    select: 'Select game',
    none: 'There are no game'
  },

  time: {
    time: 'Time (h)',
    total: 'Total time',
    perGame: 'Time per game'
  },

  units: {
    pcs: "pc",
    hours: 'h',
    minutes: 'min'
  },

  pro: {
    heatMap: {
      title: 'Heatmap',
      subtitle: 'Follow the game\'s movements on the hunting ground over time.'
    },

    killed: 'Statistics on downed game.',
    observed: 'Statistics on observed game.',
    killedAndObserved: 'Overview of all hunting game reported, hunted and observed.',
    manHours: 'Follow the hunting ground\'s game population through man-hours.'
  },

  manHoursPerGame: 'Manhours per game'
}
