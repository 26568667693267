<template>
  <div class="field">
    <label class="label text-is-normal is-marginless" for="scale">{{ $t('map.general.labels') }}</label>

    <div class="control">
      <div class="select is-small">
        <select v-model="labelSize">
          <option
            v-for="(size, i) in sizes"
            :key="i"
            :value="size"
            :selected="labelSize === size">
            {{ size * 100 }}%
          </option>
        </select>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      sizes: [0.5, 0.75, 1, 1.25, 1.5, 1.75, 2]
    }
  },

  computed: {
    labelSize: {
      get () { return this.$store.getters['map/settings/labelSize'] },
      set (size) { return this.$store.commit('map/settings/setLabelSize', size) }
    }
  }
}
</script>

<style scoped>
.field {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.field .label {
  margin: 0;
}
</style>
