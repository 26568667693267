export default {
  readMore: 'Read more',
  welcome: 'Welcome',
  yes: 'Yes',
  no: 'No',
  ok: 'Ok',
  save: 'Save',
  edit: 'Edit',
  draw: 'Draw',
  update: 'Update',
  create: 'Create',
  cancel: 'Cancel',
  close: 'Close',
  remove: 'Remove',
  delete: 'Delete',
  send: 'Send',
  pleaseConfirm: 'Please type: <strong>{type}</strong> to confirm.',
  understandConsequences: 'I understand the consequences, {type}',
  leave: 'Leave',
  move: 'Move',
  recover: 'Recover',
  saveAs: 'Save',
  add: 'Add',
  search: 'Search',
  searchForEmailOrName: 'Search for e-mail or name',
  admin: 'Admin',
  email: 'E-mail',
  password: 'Password',
  signup: 'Sign up',
  signin: 'Sign in',
  signout: 'Sign out',
  remember: 'Remember',
  notListed: 'None',
  invite: 'Invite',
  invited: 'Invited',
  member: 'Member',
  loadingContent: 'Loading content...',
  errorOccured: 'An error occurred and it was not possible to save  the changes. Please try again.',
  sendInvites: 'Send invites',
  myAccount: 'My subscription',
  help: 'Help',
  upload: 'Upload',
  sort: 'Sort',
  name: 'Name',
  phoneNumber: 'Phone number',
  nameAZ: 'Name (A-Z)',
  nameZA: 'Name (Z-A)',
  grid: 'Grid',
  list: 'List',
  decline: 'Decline',
  accept: 'Accept',
  settings: 'Settings',
  language: 'Language',
  developer: 'Developer',
  games: 'Games',
  sex: 'Sex',
  male: 'Male',
  female: 'Female',
  firstName: 'First name',
  lastName: 'Last name',
  created: 'Created',
  size: 'Size',
  from: 'From',
  to: 'To',
  message: 'Message',
  when: 'When',
  where: 'Where',
  type: 'Type',
  who: "WHO",
  comment: 'Comment',
  begins: 'Begins',
  ends: 'Ends',
  print: 'Print',
  adjust: 'Adjust',
  orientation: 'Orientation',
  status: 'Status',
  thickness: 'Thickness',
  color: 'Color',
  title: 'Title',
  role: 'Role',
  needsPro: 'Needs WeHunt Pro',
  comingSoon: 'Coming soon',
  reallyRemove: 'Do you really want to remove <strong>{name}</strong>?',
  added: 'Added',
  undo: 'Undo',
  select: 'Select',
  minutes: 'min',
  hours: 'h',

  newHuntingArea: 'New hunting ground',
  selectHuntingArea: 'Select hunting ground',
  selectHuntingAreaError: 'You need to choose a hunting ground.',

  previous: 'Previous',
  next: 'Next',

  unsupportedBrowserMessage: 'This function doesn\'t work with your browser. Please switch to a more modern browser.',
  saveFailedErrorMessage: 'An error occurred and the changes could not be saved. Please try again',
  unknownErrorMessage: 'An unknown error occurred. Please try again.',

  pageNotFound: 'The page you\'re looking for couldn\'t be found.',
  goBack: 'Go back'
}
