class HuntAssignmentModel {
  constructor (data) {
    this.id = data.id || null
    this.huntDriveId = data.huntDriveId || null
    this.memberId = data.memberId || null
    this.userId = data.userId || null
    this.firstName = data.firstName || ''
    this.lastName = data.lastName || ''
    this.email = data.email || ''
    this.role = data.role || null
    this.markerId = data.markerId || null
  }

  getFullName () {
    return this.firstName.length > 0 ? this.firstName + ' ' + this.lastName : this.email
  }

  toJson () {
    return {
      memberId: this.memberId,
      role: this.role,
      markerId: this.markerId
    }
  }
}

export default HuntAssignmentModel
