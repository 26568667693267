<template>
  <Fragment>
    <header class="card-header">
      <span class="card-header-title">
        <span class="push-right-xs is-clickable" @click="back">
          <Icon name="icon-arrow-left" />
        </span>

        <span>Utvecklare</span>
      </span>
    </header>

    <div v-if="isDeveloper" class="card-content">
      <DeveloperSubscription />
      <hr />
      <DeveloperHunt />
      <DeveloperClearState />
      <hr />

      <ul>
        <li><RouterLink to="/icons">Ikoner</RouterLink></li>
        <li><a href="https://bulma.io/" target="_blank">Bulma Docs</a></li>
        <li><a href="https://vuejs.org/v2/guide/" target="_blank">Vue Docs</a></li>
      </ul>
    </div>

    <div v-if="!isDeveloper" class="card-content">
      <pre v-html="errorText" />
    </div>
  </Fragment>
</template>

<script>
import DeveloperSubscription from './DeveloperSubscription.vue'
import DeveloperHunt from './DeveloperHunt.vue'
import DeveloperClearState from './DeveloperClearState.vue'

export default {

  components: {
    DeveloperSubscription,
    DeveloperHunt,
    DeveloperClearState
  },

  data () {
    return {
      isDeveloper: false,
      errorText: ''
    }
  },

  mounted () {
    this.check()
    this.errorText = `
while (notADeveloper)
  showErrorMessage()
    `.trim()
  },

  methods: {
    async check () {
      this.isDeveloper = await this.$store.dispatch('ui/isDeveloper')
    },

    back () {
      this.$emit('back')
    },

    close () {
      this.$emit('close')
    }
  }

}
</script>
