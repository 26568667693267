class ImportMarkerModel {
  constructor (context) {
    const data = Object.assign({}, context)

    this.name = data.name || ''
    this.type = data.type || ''
    this.note = data.note || ''
    this.location = data.location || { lat: 0, lng: 0 }
    this.customType = data.customType || null
    this.customColor = data.customColor || null

    this.selected = true
  }
}

class ImportAreaModel {
  constructor (context) {
    const data = Object.assign({}, context)

    this.name = data.name || ''
    this.type = data.type || ''
    this.borderCoordinates = data.borderCoordinates || []

    this.selected = true
  }
}

class ImportDataModel {
  constructor (context) {
    const data = Object.assign({}, context)

    this.creator = data.creator || null
    this.author = data.author || null
    this.createdAt = data.createdAt || null

    this.markers = data.markers.map(data => new ImportMarkerModel(data))
    this.areas = data.areas.map(data => new ImportAreaModel(data))
  }
}

export {
  ImportMarkerModel,
  ImportAreaModel,
  ImportDataModel
}
