class AssignmentStandModel {
  constructor (context) {
    const data = Object.assign({}, context)

    this.userId = data.userId || null
    this.markerId = data.markerId || null
  }
}

export default AssignmentStandModel
