import dayjs from 'dayjs'

class HuntEventModel {
  constructor (context) {
    const data = Object.assign({}, context)

    this.id = data.id || null
    this.name = data.name || null
    this.note = data.note || ''
    this.type = data.type || ''
    this.category = data.category || ''
    this.animalId = data.animalId || null
    this.location = data.location || null
    this.createdAt = data.createdAt !== null ? dayjs(data.createdAt).toDate() : null
    this.createdBy = data.createdBy || null
    this.status = ''
    this.editable = data.editable || false
    this.visible = data.visible !== undefined ? data.visible : true
  }

  toJSON () {
    return {
      id: this.id,
      name: this.name,
      note: this.note,
      type: this.type,
      category: this.category,
      animalId: this.animalId,
      location: this.location
    }
  }
}

export default HuntEventModel
