import * as storage from '@/assets/libs/storage/'

const set = (data, persist) => {
  if (persist) {
    storage.local.add('token', data.token)
    storage.local.add('userId', data.userId)
  } else {
    storage.session.add('token', data.token)
    storage.session.add('userId', data.userId)
  }
}

const getToken = () => storage.session.read('token') || storage.local.read('token')
const getUserId = () => JSON.parse(storage.session.read('userId') || storage.local.read('userId'))
const getUser = () => JSON.parse(storage.session.read('user') || storage.local.read('user'))
const getProfile = () => JSON.parse(storage.session.read('profile') || storage.local.read('profile'))
const hasAuth = () => getToken() !== null

const clear = () => {
  if (storage.has('token')) (storage.session.del('token') || storage.local.del('token'))
  if (storage.has('user')) (storage.session.del('user') || storage.local.del('user'))
  if (storage.has('profile')) (storage.session.del('profile') || storage.local.del('profile'))
  if (storage.has('userId')) (storage.session.del('userId') || storage.local.del('userId'))
}

export {
  set,
  getUser,
  getProfile,
  getToken,
  getUserId,
  hasAuth,
  clear
}
