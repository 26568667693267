class GsmCoverageModel {
  constructor (context) {
    const data = Object.assign({}, context)

    this.location = data.location ? {
      lat: data.location.lat,
      lng: data.location.lng
    } : {
      lat: 0,
      lng: 0
    }

    this.weight = data.strength || null
  }
}

export default GsmCoverageModel
