import TrailService from '@/api/map/trails/trailService'

export default {
  async read ({ commit }, huntAreaId) {
    commit('set', null)
    const trails = await TrailService.read(huntAreaId)
    commit('set', trails)

    return trails
  },

  update (store, data) {
    const { huntAreaId, trail } = data

    return new Promise(async (resolve, reject) => {
      try {
        await TrailService.update(huntAreaId, trail)
        resolve()
      } catch (error) {
        reject(error)
      }
    })
  },

  async delete ({ commit }, data) {
    const { huntAreaId, trail } = data

    await TrailService.delete(huntAreaId, trail.id)
    commit('delete', trail)
  },

  select ({ commit, dispatch }, trail) {
    dispatch('map/closeAllPanels', null, { root: true })
    commit('select', trail)
  },

  clear ({ commit }) {
    commit('set', null)
  }
}
