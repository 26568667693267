<script>
import { mapGetters } from 'vuex'
import LabelOverlay from './labelOverlay'

export default {
  props: {
    borderColor: {
      type: String,
      default: 'black'
    },

    borderWidth: {
      type: Number,
      default: 2
    },

    fillColor: {
      type: String,
      default: ''
    },

    fillOpacity: {
      type: Number,
      default: 0
    },

    clickable: {
      type: Boolean,
      default: false
    },

    data: {
      type: Object,
      required: true,
      default: () => {}
    }
  },

  data () {
    return {
      $map: null,
      $polygon: null,
      $polygonBounds: null,
      $label: null
    }
  },

  computed: {
    ...mapGetters({
      showSubAreaLabels: 'map/subAreas/showSubAreaLabels',
      showForbiddenLabels: 'map/subAreas/showForbiddenLabels'
    }),

    subArea: {
      get () { return this.$store.getters['map/subAreas/selected'] },
      set (line) { return this.$store.dispatch('map/subAreas/select', line) }
    },

    markersVisible () { return this.data.markersVisible },
    borderVisible () { return this.data.borderVisible },
    visible () { return this.data.visible },
    editable () { return this.data.editable },
    borderCoordinates () { return this.subArea ? this.subArea.borderCoordinates : null },
    isSelected () { return this.subArea === this.data },
    type () { return this.data.type },
    name () { return this.data.name }
  },

  watch: {
    '$route' () {
      this.checkRoute()
    },

    subArea (subArea) {
      if (!this.$polygon) return
      this.$polygonBounds = this.getBounds()
      if (subArea === this.data) this.$map.fitBounds(this.$polygonBounds)
    },

    editable (state) {
      this.$polygon.setEditable(state)

      if (state) {
        this.$map.fitBounds(this.$polygonBounds)
        const path = this.$polygon.getPath()
        const methods = ['insert_at', 'remove_at', 'set_at']
        methods.forEach(m => path.addListener(m, () => this.updateBorderCoodinates()))
      } else {
        this.$polygon.setPath(this.data.borderCoordinates)
        this.$polygonBounds = this.getBounds()

        this.$label.position = this.$polygonBounds.getCenter()
        this.$map.fitBounds(this.$polygonBounds)
      }

      this.updateLabelVisibility()
    },

    visible (visible) {
      if (this.$polygon) {
        this.$polygon.setVisible(visible)

        this.showMarkersWithinBounds(visible)
        this.updateLabelVisibility()
      }
    },

    markersVisible (visible) {
      this.showMarkersWithinBounds(visible)
      this.updateLabelVisibility()
    },

    borderVisible (visible) {
      this.$polygon.setVisible(visible)
    },

    showSubAreaLabels () {
      this.updateLabelVisibility()
    },

    showForbiddenLabels () {
      this.updateLabelVisibility()
    },

    name (text) {
      this.$label.label = text
    }
  },

  beforeMount () {
    this.checkRoute()
  },

  async mounted () {
    this.$map = await this.$parent.getMap()

    const bounds = this.getBounds()

    this.$label = new LabelOverlay({
      position: bounds.getCenter(),
      text: this.data.name,
      map: this.$map,
      class: 'subarea-label-overlay'
    })

    const polygon = new window.google.maps.Polygon({
      map: this.$map,
      clickable: this.clickable,
      editable: this.data.editable,
      paths: this.data.borderCoordinates,
      strokeColor: this.borderColor,
      strokeWeight: this.strokeWeight,
      strokeOpacity: 1,
      fillColor: this.fillColor,
      fillOpacity: this.fillOpacity
    })

    if (polygon) {
      this.$polygonBounds = this.getBounds()
      this.$polygon = polygon

      polygon.addListener('click', () => {
        this.$store.dispatch('map/subAreas/select', this.data)

        this.$router.push({
          name: 'subarea',
          params: {
            subAreaId: this.data.id
          }
        })
      })

      polygon.addListener('mouseover', () => {
        if (!this.isSelected) polygon.setOptions({ fillOpacity: this.fillOpacity + 0.2 })
      })

      polygon.addListener('mouseout', () => {
        if (!this.isSelected) polygon.setOptions({ fillOpacity: this.fillOpacity })
      })
    } else {
      console.error(`Failed to create polygon for subarea ${this.data.name}.`)
    }

    this.updateLabelVisibility()
  },

  destroyed () {
    if (this.$polygon) {
      this.$label.setMap(null)
      this.$polygon.setMap(null)
    }
  },

  render () {
    return ''
  },

  methods: {
    checkRoute () {
      const path = this.$route.path
      const isActive = path.startsWith('/area/') && path.includes(this.data.id)

      if (isActive) {
        this.$store.dispatch('map/subAreas/select', this.data)
      }
    },

    updateBorderCoodinates () {
      const path = this.$polygon.getPath().getArray()
      this.subArea.borderCoordinates = path.map(latLng => latLng.toJSON())

      const bounds = new window.google.maps.LatLngBounds()

      this.subArea.borderCoordinates.forEach(({ lat, lng }) => {
        bounds.extend(new window.google.maps.LatLng(lat, lng))
      })

      this.$label.position = bounds.getCenter()
      this.updateLabelVisibility()
    },

    showMarkersWithinBounds (visible) {
      let markers = this.$store.getters['map/subAreas/getMarkersInSubArea'](this.data)

      if (!visible) {
        const subAreas = this.getVisibleSubAreas()
        markers = markers.filter(marker => !this.isMarkerInSubAreas(marker, subAreas))
      }

      markers.forEach(marker => {
        marker.visible = visible
      })
    },

    isMarkerInSubAreas (marker, subAreas) {
      const location = new window.google.maps.LatLng(marker.location.lat, marker.location.lng)

      for (var i = 0; i < subAreas.length; i++) {
        const polygon = new window.google.maps.Polygon({ paths: subAreas[i].borderCoordinates })

        if (window.google.maps.geometry.poly.containsLocation(location, polygon)) {
          return true
        }
      }

      return false
    },

    getVisibleSubAreas () {
      return this.$store.getters['map/subAreas/getSubAreas'].filter(subArea => {
        return subArea.visible && subArea !== this.data
      })
    },

    getBounds () {
      const bounds = new window.google.maps.LatLngBounds()
      const coords = this.data.borderCoordinates

      coords.forEach(coord => bounds.extend(new window.google.maps.LatLng(coord.lat, coord.lng)))

      return bounds
    },

    updateLabelVisibility () {
      const label = this.type === 'saat' ? this.showSubAreaLabels : this.showForbiddenLabels
      this.$label.setMap((label && this.visible ? this.$map : null))
    }
  }

}
</script>
