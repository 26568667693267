import dashboard from '@/modules/huntArea/dashboard/translations/fr'

export default {
  huntingGround: 'Territoire de chasse',
  date: 'Date',
  time: 'Temps',
  newHuntingGround: 'Nouveau territoire de chasse',
  createHuntingGround: 'Créer un nouveau territoire de chasse',
  huntingGroundNamePlaceholder: 'Nom du territoire de chasse ou nom du groupe de chasse',
  noHuntingGroundNameErrorMessage: 'Vous devez donner un nom au territoire de chasse',
  createHuntingGroundFailedErrorMessage: 'Nous avons rencontré un problème. Veuillez réessayer.',
  noHuntAreasEmptyStateMessage: "Vous n'êtes membre d'aucun territoire de chasse.<br /><br />Cliquez sur le bouton ci-dessus pour créer votre premier territoire de chasse.",

  dashboard
}
