export default {
  unsupportedBrowser: {
    updateRequired: "Vous devez mettre à jour votre navigateur.",
    readMore: "Lire la suite ici",
    browserNotSupported: "Votre navigateur est incompatible.",
    browserListHelpText: "<p>Malheureusement, WeHunt n'est plus compatible avec votre navigateur, ce qui signifie que certaines parties de WeHunt ne fonctionneront pas comme prévu.</p><p>Voici quelques navigateurs qui fonctionneront mieux :</p>"
  },

  cookies: {
    information: "Nous utilisons des cookies pour garantir la meilleure expérience possible sur notre site."
  }
}
