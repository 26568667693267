import dayjs from 'dayjs'
import GpsService from '../services/gpsService'
import playback from './gpsPlaybackStore'

export default {
  namespaced: true,

  state: {
    selected: null,
    historyDates: null,
    selectedDate: null,
    positions: null,
    heatmapLoading: false,
    heatmapVisible: false
  },

  getters: {
    selected: state => state.selected,
    selectedDate: state => state.selectedDate,
    positions: state => state.positions,
    heatmapLoading: state => state.heatmapLoading,
    heatmapVisible: state => state.heatmapVisible,
    historyDates: state => state.historyDates
  },

  mutations: {
    setPositions (state, positions) {
      state.positions = positions
    },

    setHeatmapLoading (state, loading) {
      state.heatmapLoading = loading
    },

    setHeatmapVisibility (state, visible) {
      state.heatmapVisible = visible
    },

    select (state, imei) {
      state.selected = imei
    },

    setHistoryDates (state, dates) {
      state.historyDates = dates
    },

    setSelectedDate (state, date) {
      state.selectedDate = date
    },

    clear (state) {
      state.selected = null
      state.positions = null
    }
  },

  actions: {
    async readHistoryDates ({ getters, commit }) {
      commit('setHistoryDates', null)
      commit('setSelectedDate', null)
      commit('setPositions', null)

      const imei = getters.selected
      const dates = await GpsService.readAvailableDates(imei)

      commit('setHistoryDates', dates)

      if (dates.length > 0) {
        commit('setSelectedDate', dayjs(dates[dates.length - 1]).toDate())
      }

      return dates
    },

    async readGpsPositions ({ commit, getters }) {
      const imei = getters.selected
      const date = getters.selectedDate

      commit('setPositions', null)

      const positions = await GpsService.readGpsPositions(imei, date)

      commit('setPositions', positions)
      commit('gps/playback/setCurrentLocation', positions[0], { root: true })

      return positions
    }
  },

  modules: {
    playback: playback
  }
}
