export default {
  hunts: "Chasses",

  reports: "Rapports de chasse",
  noReports: "Aucun rapport de chasse n'a encore été créé.",

  plannedHunts: "Chasses planifiées",
  noHunts: "Aucune chasse n'est prévue.",

  newReport: "Nouveau rapport de chasse",
  exportToExcel: "Exporter vers Excel",

  hunt: "Chasse",
  date: 'Date',
  killsAndObservations: "Gibier prélevé/Observations",
  planning: "Planification en cours"
}
