<template>
  <Fragment>
    <label class="label is-marginless">Abonnemang</label>

    <div class="buttons are-small has-addons push-top-xs push-bottom-xs">
      <button
        class="button"
        :class="{ 'is-secondary': type === 'free' }"
        @click.prevent="setSubscriptionType('free')">
        Free
      </button>
      <button
        class="button"
        :class="{ 'is-secondary': type === 'trial' }"
        @click.prevent="setSubscriptionType('trial')">
        Trial
      </button>
      <button
        class="button"
        :class="{ 'is-secondary': type === 'pro' }"
        @click.prevent="setSubscriptionType('pro')">
        Pro
      </button>
      <button
        class="button"
        :class="{ 'is-secondary': type === 'standard' }"
        @click.prevent="setSubscriptionType('standard')">
        Standard
      </button>
      <button
        class="button"
        :class="{ 'is-secondary': type === 'premium' }"
        @click.prevent="setSubscriptionType('premium')">
        Premium
      </button>
      <button
        class="button"
        :class="{ 'is-secondary': type === 'gps' }"
        @click.prevent="setSubscriptionType('gps')">
        GPS
      </button>
      <button
        class="button"
        :class="{ 'is-secondary': type === 'gps_1' }"
        @click.prevent="setSubscriptionType('gps_1')">
        GPS 1
      </button>
      <button
        class="button"
        :class="{ 'is-secondary': type === 'gps_2' }"
        @click.prevent="setSubscriptionType('gps_2')">
        GPS 2
      </button>
      <button
        class="button"
        :class="{ 'is-secondary': type === 'gps_3' }"
        @click.prevent="setSubscriptionType('gps_3')">
        GPS 3
      </button>
      <button
        class="button"
        :class="{ 'is-secondary': type === 'gps_6' }"
        @click.prevent="setSubscriptionType('gps_6')">
        GPS 6
      </button>
    </div>

    <label class="label is-marginless">Plattform</label>
    <div class="buttons are-small has-addons push-top-xs push-bottom-xs">
      <button
        class="button"
        :class="{ 'is-secondary': platform === 'stripe' }"
        @click.prevent="setPlatform('stripe')">
        Stripe
      </button>
      <button
        class="button"
        :class="{ 'is-secondary': platform === 'ios' }"
        @click.prevent="setPlatform('ios')">
        iOS
      </button>
      <button
        class="button"
        :class="{ 'is-secondary': platform === 'android' }"
        @click.prevent="setPlatform('android')">
        Android
      </button>
    </div>

    <div class="flex field align-center justify-between">
      <label class="label is-marginless">Auto förlängning</label>
      <Checkbox v-model="autoRenew" type="switch" />
    </div>
  </Fragment>
</template>

<script>
import * as subscriptionTypes from '@/modules/subscription/store/subscriptionStore'

export default {
  computed: {
    type () {
      return this.$store.getters['subscription/type']
    },

    platform () {
      return this.$store.getters['subscription/platform']
    },

    autoRenew: {
      get () { return this.$store.getters['subscription/autoRenew'] },
      set (state) { return this.$store.commit('subscription/toggleAutoRenew', state) }
    }
  },

  methods: {
    setSubscriptionType (type) {
      this.$store.commit('subscription/setType', type)
      const subscriptionWeightConstant = `SUBSCRIPTION_TYPE_${type.toUpperCase()}_WEIGHT`
      this.$store.commit('subscription/setWeight', subscriptionTypes[subscriptionWeightConstant])
    },

    setPlatform (platform) {
      this.$store.commit('subscription/setPlatform', platform)
    }
  }
}
</script>
