import InviteService from '../services/inviteService'

import {
  STATUS_DEFAULT,
  STATUS_LOADING,
  STATUS_SUBMITTING,
  STATUS_ALREADY_ANSWERED,
  STATUS_UNKNOWN_ERROR,
  STATUS_NOT_FOUND,
  STATUS_EMAIL_EXISTS
} from './inviteStatus'

export default {
  namespaced: true,

  state: {
    token: null,
    invite: null,
    status: STATUS_DEFAULT
  },

  mutations: {
    setToken (state, token) {
      state.token = token
    },

    setStatus (state, status) {
      state.status = status
    },

    setInvite (state, invite) {
      state.invite = invite
    }
  },

  getters: {
    getToken (state) {
      return state.token
    },

    getStatus (state) {
      return state.status
    },

    getInvite (state) {
      return state.invite
    }
  },

  actions: {
    async readByToken ({ commit }, token) {
      commit('setToken', token)
      commit('setStatus', STATUS_LOADING)

      try {
        const invite = await InviteService.getHuntAreaInviteByToken(token)

        commit('setInvite', invite)
        commit('setStatus', invite.acceptedAt !== null ? STATUS_ALREADY_ANSWERED : STATUS_DEFAULT)

        return invite
      } catch (error) {
        commit('setStatus', STATUS_NOT_FOUND)
        console.error(error)
      }
    },

    async acceptInvite ({ getters, commit, dispatch }, user) {
      const token = getters.getToken

      commit('setStatus', STATUS_SUBMITTING)

      try {
        const createdUser = await dispatch('auth/join', user, { root: true })
        await InviteService.acceptHuntAreaInvite(token, createdUser.id)
      } catch (error) {
        if (error.status === 400) {
          commit('setStatus', STATUS_EMAIL_EXISTS)
        } else {
          commit('setStatus', STATUS_UNKNOWN_ERROR)
        }

        throw error
      }
    }
  }
}
