import api from '@/api/'
import ParticipantModel from './participantModel'

class ParticipantService {
  static async getMembers (huntId) {
    try {
      const response = await api.get(`hunts/${huntId}/members`)
      return response.data.map(data => new ParticipantModel(data))
    } catch (error) {
      console.error(`Failed to read participants for hunt ${huntId}`)
      throw error
    }
  }

  static async inviteByUserId (huntId, userId) {
    try {
      const response = await api.post(`hunts/${huntId}/members`, { userId })
      return new ParticipantModel(response.data)
    } catch (error) {
      console.error(`Failed to add user ${userId} to hunt ${huntId}`)
      throw error
    }
  }

  static async inviteByEmail (huntId, email) {
    try {
      const response = await api.post(`hunts/${huntId}/members`, { email })
      return new ParticipantModel(response.data)
    } catch (error) {
      console.error(`Failed to add ${email} to hunt ${huntId}`)
      throw error
    }
  }

  static async removeMember (huntId, memberId) {
    try {
      await api.delete(`hunts/${huntId}/members/${memberId}`)
    } catch (error) {
      console.error(`Failed to remove member ${memberId} from hunt ${huntId}`)
      throw error
    }
  }
}

export default ParticipantService
