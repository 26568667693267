export default {
  title: "Équipe",

  noMembersMatchedSearched: "Aucun membre ne correspond à votre recherche.",

  inviteTitle: "Ajouter des membres",
  inviteDescription: "Tous les membres ont accès à la carte des territoires de chasse et peuvent participer aux chasses y étant organisées",
  inviteButtonTitle: "Ajouter | Ajouter <strong>1</strong> membres | Ajouter <strong>{userCount}</strong> membres",
  inviteAccountNotFoundMessage: "Nous ne trouvons pas de compte dans WeHunt avec cette adresse e-mail. Voulez-vous toujours envoyer une invitation ?",
  inviteFailedErrorMessage: "Une erreur s'est produite et le membre n'a pas pu être ajouté. Veuillez réessayer.",
  inviteAwaitingAnswer: "En attente de réponse",

  removeMemberTitle: "Supprimer un membre",
  removeMemberConfirmMessage: "Voulez-vous vraiment retirer <strong>{name}</strong> du territoire de chasse ?",
  removeMemberFailedErrorMessage: "Une erreur s'est produite et le membre n'a pas pu être expulsé du territoire de chasse. Veuillez réessayer.",

  removeInviteTitle: "Supprimer l'invitation",
  removeInviteConfirmMessage: "Voulez-vous vraiment supprimer l'invitation à <strong>{email}</strong> ?",
  removeInviteFailedErrorMessage: "Une erreur s'est produite et l'invitation n'a pas pu être supprimée. Veuillez réessayer."
}
