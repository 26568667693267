<template>
  <button
    :type="submit ? 'submit' : 'button'"
    class="button"
    :disabled="disabled"
    :class="classList"
    :title="tooltip"
    @click="click($event)">
    <slot>
      <Icon v-if="icon.length > 0" :name="icon" />
      <span v-if="title.length">{{ title }}</span>
    </slot>
  </button>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: ''
    },

    disabled: {
      type: Boolean,
      default: false
    },

    loading: {
      type: Boolean,
      default: false
    },

    type: {
      type: String,
      default: ''
    },

    submit: {
      type: Boolean,
      default: false
    },

    size: {
      type: String,
      default: ''
    },

    icon: {
      type: String,
      default: ''
    },

    outlined: {
      type: Boolean,
      default: false
    },

    hasShadow: {
      type: Boolean,
      default: false
    },

    fullwidth: {
      type: Boolean,
      default: false
    },

    tooltip: {
      type: String,
      default: ''
    }
  },

  computed: {
    classList () {
      let classes = { 'is-loading': this.loading }

      if (this.type.length > 0) {
        classes['is-' + this.type] = true
      }

      if (this.size.length > 0) {
        classes['is-' + this.size] = true
      }

      if (this.outlined) {
        classes['is-outlined'] = true
      }

      if (this.hasShadow) {
        classes['has-shadow'] = true
      }

      classes['is-fullwidth'] = this.fullwidth

      return classes
    }
  },

  methods: {
    click (event) {
      this.$emit('click', event)
    }
  }
}
</script>

<style lang="scss" scoped>
.button:focus,
.button.is-focused {
  border-color: #b5b5b5;
}
</style>
