import dayjs from 'dayjs'

class ChatMessageModel {
  constructor (context) {
    const data = Object.assign({}, context)

    this.id = data.id || null
    this.createdBy = data.createdBy || null
    this.createdAt = data.createdAt ? dayjs(data.createdAt).toDate() : null
    this.text = data.text || ''
    this.visible = data.visible || false
  }
}

export default ChatMessageModel
