export default class HuntReportParticipantModel {
  constructor (params) {
    const data = Object.assign({}, params)

    this.id = data.id || null
    this.userId = data.userId || null
    this.name = data.name || ''
    this.email = data.email || '' // Not saved to back-end. Only needed by UI.
  }
}
