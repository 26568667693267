export default {

  setAvailableGames (state, games) {
    state.availableGames = games
  },

  setGames (state, games) {
    state.games = games
  },

  addGame (state, game) {
    state.games.push(game)
  },

  update (state, game) {
    const g = state.games.find(g => g.id === game.id)
    if (g !== undefined) g.assign(game)
  },

  delete (state, game) {
    const i = state.games.indexOf(game)
    if (i !== -1) state.games.splice(i, 1)
  }

}
