import MemberInviteService from '../services/memberInviteService'

export default {
  namespaced: true,

  state: {
    invites: null
  },

  getters: {
    invites: state => state.invites
  },

  mutations: {
    set (state, invites) {
      state.invites = invites
    },

    add (state, invite) {
      if (!state.invites) state.invites = []
      state.invites.push(invite)
    },

    delete (state, inviteId) {
      const i = state.invites.findIndex(e => e.id === inviteId)
      state.invites.splice(i, 1)
    }
  },

  actions: {
    async create ({ commit, rootGetters }, email) {
      const huntArea = rootGetters['huntarea/selected']
      const language = rootGetters['ui/language']

      const invite = await MemberInviteService.create(huntArea.id, email, language)
      commit('add', invite)
      return invite
    },

    async read ({ commit }, data) {
      const { huntAreaId, inviteId } = data

      commit('set', null)
      const invites = await MemberInviteService.read(huntAreaId, inviteId)
      commit('set', invites)

      return invites
    },

    delete ({ commit }, data) {
      const { huntAreaId, inviteId } = data

      commit('delete', inviteId)
      return MemberInviteService.delete(huntAreaId, inviteId)
    }
  }
}
