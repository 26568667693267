export default {
  isAdjusting: false,
  isPrinting: false,
  isModalActive: false,

  paperSize: 'a4',
  paperOrientation: 'portrait',

  skipTileCache: false,
  pendingTiles: [],
  busyLayers: []
}
