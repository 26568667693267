<script>
import { mapGetters } from 'vuex'
import LabelOverlay from './labelOverlay'

export default {
  props: {
    data: {
      type: Object,
      required: true,
      default: () => {}
    }
  },

  data () {
    return {
      lineId: this.$route.params.lineId,
      $map: null,
      $polyline: null,
      $label: null
    }
  },

  computed: {
    ...mapGetters({
      lines: 'map/lines/lines',
      labelSize: 'map/settings/labelSize',
      showLineLabels: 'map/lines/showLineLabels',
      showArrowLabels: 'map/lines/showArrowLabels'
    }),

    line: {
      get () { return this.$store.getters['map/lines/selected'] },
      set (line) { return this.$store.dispatch('map/lines/select', line) }
    },

    visible () { return this.data.visible },
    editable () { return this.data.editable },
    width () { return this.data.width },
    color () { return this.data.color },
    label () { return this.data.label }
  },

  watch: {
    '$route' () {
      this.checkRoute()
    },

    line (line) {
      if (!this.$polyline) return

      this.$polylineBounds = this.getBounds()

      if (line === this.data) {
        this.$map.fitBounds(this.$polylineBounds)
      }
    },

    editable (state) {
      this.$polyline.setEditable(state)

      if (state) {
        this.$map.fitBounds(this.$polylineBounds)
        const path = this.$polyline.getPath()
        const methods = ['insert_at', 'remove_at', 'set_at']
        methods.forEach(m => path.addListener(m, () => this.updatePath()))
      } else {
        // Reset border in case user aborted editing.
        // Since a new path is created, this effectively stop events insert_at, remove_at and set_at until a new edit is started
        if (this.data.path.constructor === Array) {
          this.$polyline.setPath(this.data.path)
        }

        this.$polylineBounds = this.getBounds()

        this.$label.position = this.$polyline.getPath().getAt(0)
        this.$map.fitBounds(this.$polylineBounds)
      }

      this.updateLabelVisibility()
    },

    visible (state) {
      if (this.$polyline) {
        this.$polyline.setVisible(state)
        this.updateLabelVisibility()
      }
    },

    showLineLabels () {
      this.updateLabelVisibility()
    },

    showArrowLabels () {
      this.updateLabelVisibility()
    },

    width (width) {
      this.$polyline.setOptions({ strokeWeight: Number(width) })
    },

    color (color) {
      this.$polyline.setOptions({ strokeColor: color })
    },

    label (text) {
      this.$label.label = text
    }
  },

  async mounted () {
    const { name, path, color, width, lineType } = this.data
    this.$map = await this.$parent.getMap()

    const polyline = new window.google.maps.Polyline({
      clickable: true,
      path: path,
      strokeColor: color,
      strokeOpacity: 1.0,
      strokeWeight: width,
      type: lineType,
      ...(lineType === 'arrow' ? {
        icons: [{
          icon: { path: window.google.maps.SymbolPath.FORWARD_CLOSED_ARROW },
          offset: '100%'
        }]
      } : {})
    })

    this.$label = new LabelOverlay({
      position: polyline.getPath().getAt(0),
      text: this.label,
      map: this.$map,
      type: 'line',
      size: this.labelSize,
      class: ['line-label-overlay']
    })

    if (polyline) {
      this.$polyline = polyline
      this.$polylineBounds = this.getBounds()

      polyline.setMap(this.$map)

      polyline.addListener('click', () => {
        this.$store.dispatch('map/lines/select', this.data)

        this.$router.push({
          name: 'line',
          params: {
            lineId: this.data.id
          }
        })
      })
    } else {
      console.error(`Failed to create polyline for line ${name}.`)
    }

    this.checkRoute()
  },

  destroyed () {
    if (this.$polyline) {
      this.$label.setMap(null)
      this.$polyline.setMap(null)
    }
  },

  render () {
    return ''
  },

  methods: {
    checkRoute () {
      const path = this.$route.path
      const isActive = path.startsWith('/area/') && path.includes(this.data.id)

      if (isActive) {
        this.$store.dispatch('map/lines/select', this.data)
      }
    },

    updatePath () {
      const path = this.$polyline.getPath().getArray()
      this.line.path = path.map(latLng => latLng.toJSON())

      const bounds = new window.google.maps.LatLngBounds()

      this.line.path.forEach(({ lat, lng }) => {
        bounds.extend(new window.google.maps.LatLng(lat, lng))
      })

      this.$label.position = bounds.getCenter()
      this.updateLabelPosition()
    },

    updateLabelPosition () {
      this.$label.position = this.$polyline.getPath().getAt(0)
      this.updateLabelVisibility()
    },

    updateLabelVisibility () {
      const label = this.data.lineType === 'line' ? this.showLineLabels : this.showArrowLabels
      this.$label.setMap((label && this.visible ? this.$map : null))
    },

    getBounds () {
      const bounds = new window.google.maps.LatLngBounds()

      if (this.data.path && this.data.path.constructor === Array) {
        this.data.path.forEach(({ lat, lng }) => bounds.extend(new window.google.maps.LatLng(lat, lng)))
      }

      return bounds
    },

    getVisibleLines () {
      return this.$store.getters['map/lines/lines'].filter(line => {
        return line.visible && line !== this.data
      })
    }
  }
}
</script>
