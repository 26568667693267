<template>
  <div :class="{ 'is-active': isActive }" class="dropdown">
    <div class="dropdown-trigger">
      <button
        class="button"
        aria-haspopup="true"
        @click.prevent="toggleState">
        <span v-if="selected && selected.icon !== undefined" class="icon is-small">
          <img :src="selected.icon" />
        </span>

        <span>{{ selected.name }}</span>
        <span class="arrow icon icon-chevron-down" />
      </button>
    </div>

    <div class="dropdown-menu" role="menu">
      <div class="dropdown-content">
        <a
          v-for="(item, i) in items"
          :key="i"
          href="#"
          class="dropdown-item"
          @click="select(item)">
          <img v-if="item && item.icon !== undefined" :src="item.icon" />
          <span>{{ item.name }}</span>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    selected: {
      type: Object,
      default: () => {}
    },

    items: {
      type: Array,
      default: () => []
    }
  },

  data () {
    return {
      isActive: false
    }
  },

  methods: {
    toggleState () {
      this.isActive = !this.isActive
    },

    select (item) {
      this.toggleState()
      this.$emit('dropdown-selected', item)
    },

    close () {
      this.isActive = false
    }
  }
}
</script>

<style scoped>
.dropdown.is-small .button {
  font-size: 0.75rem;
}

.dropdown.is-small .dropdown-item {
  font-size: 0.75rem;
  padding: 0.25rem 0.75rem;
  padding-right: 0.75rem;
}

.dropdown-item {
  display: flex;
  align-items: center;
}

.dropdown-item > img {
  margin-right: 0.5rem;
  max-width: 1.5rem;
  max-height: 1.5rem;

  position: relative;
  top: 2px;
}
</style>
