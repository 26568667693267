export default {
  gps: 'GPS',
  gsm: 'Coverage',
  battery: 'Battery',
  latency: 'Delay',
  info: 'Info',
  coverage: 'Coverage',
  coverageMap: 'Coverage map',
  pickDate: 'Select date',
  noPositions: 'No positions have been received from the GPS in the last three months.',

  activateGps: 'Activate new GPS',
  activateGpsHelpText: 'Click on the button above to activate your first GPS.',

  loadGpsDeviceFailedErrorMessage: 'An error occurred and the GPS information could not be read. Please try again.',
  loadGpsDataFailedErrorMessage: 'An error occurred and the GPS location data could not be loaded. Please try again.',
  loadGpsCoverageFailedErrorMessage: 'An error occurred and the GPS coverage map could not be loaded. Please try again.',

  gpsContentGood: 'When GPS coverage is 100%, it is considered good. GPS coverage depends on the number of satellites it has made contact with.',
  gpsContentDecent: 'When GPS coverage is between 80% and 100%, it is considered as average. GPS coverage depends on the number of satellites it has made contact with. If the GPS moves around, it can get better coverage from making contact with more satellites.',
  gpsContentBad1: 'When GPS coverage is lower than 80% the GPS can be incorrectly positioned. The position can get stuck in one place and then, when the coverage has become better, moves to the correct position. This can be perceived as jumpy.',
  gpsContentBad2: 'GPS coverage depends on the number of satellites it has made contact with. If the GPS moves around, it can get better coverage from making contact with more satellites.',

  gsmContentGood: 'When GSM coverage is 6 or more, it is considered good. GSM coverage is based upon the ability to connect to the 2G networks.',
  gsmContentDecent: 'When GSM coverage is between 3 and 6, it is considered as average. GSM coverage is based on the ability to connect to 2G networks.',
  gsmContentBad: 'When GSM coverage is lower than 3 the GPS can be positioned incorrectly. There may be long delays, the GPS may get stuck in the wrong position and then move quickly to the correct position.',

  latencyContentGood: 'A delay of less than 5 seconds does not significantly affect the accuracy of the GPS. Positioning may be delayed when GPS and GSM is not working properly.',
  latencyContentDecent: 'When the delay is between 2 and 20 seconds, it starts to affect the positioning of the GPS negatively. Positioning can be delayed when GPS and GSM coverage is not working properly.',
  latencyContentBad: 'When the delay is more than 20 seconds it greatly affects the positioning of the GPS. Positioning may be delayed when GPS and GSM coverage is not working properly.'
}
