<template>
  <input
    type="email"
    class="input input-email"
    :class="classObject"
    :placeholder="placeholder"
    :disabled="disabled"
    :value="value"
    @input="$emit('input', $event.target.value)" />
</template>

<script>
export default {
  props: {
    value: {
      type: String,
      default: ''
    },

    placeholder: {
      type: String,
      default: ''
    },

    disabled: {
      type: Boolean,
      default: false
    },

    valid: {
      type: Boolean,
      default: true
    },

    size: {
      type: String,
      default: ''
    }
  },

  computed: {
    classObject () {
      const classes = {}

      if (this.size.length > 0) {
        classes['is-' + this.size] = true
      }

      classes['is-danger'] = !this.valid

      return classes
    }
  }
}
</script>
