<template>
  <p>
    <slot>
      <span v-html="text" />
    </slot>
  </p>
</template>

<script>
export default {
  props: {
    text: {
      type: String,
      default: ''
    }
  }
}
</script>
