export default {
  title: 'Dokument',
  download: 'Ladda ner',
  rename: 'Byt namn',
  remove: 'Radera',
  move: 'Flytta',
  uploadFiles: 'Ladda upp filer',

  createFolder: 'Skapa ny mapp',
  createFolderPlaceholer: 'Namn på mapp',
  createFolderFailedErrorMesssage: 'Ett fel inträffade och det gick inte att skapa mappen. Vänligen försök igen.',

  removeFolder: 'Radera mapp',
  confirmRemoveFolder: 'Vill du verkligen radera mappen <strong>{folderName}</strong>?',
  removeFolderFailedErrorMessage: 'Ett fel inträffade och det gick inte att ta bort mappen. Vänligen försök igen.',

  uploadDocumentFailedErrorMessage: 'Ett fel inträffade och det gick inte att ladda upp dokumentet. Vänligen försök igen.',
  readDocumentsFailedErrorMessage: 'Ett fel inträffade och det gick inte att läsa in alla mappar. Vänligen försök igen.',
  moveDocumentFailedErrorMessage: 'Ett fel inträffade och det gick inte att flytta dokumentet. Vänligen försök igen.',
  removeDocumentFailedErrorMessage: 'Ett fel inträffade och det gick inte att ta bort dokumentet. Vänligen försök igen.',

  nameOnFolder: 'Namn på mappen',

  moveDocumentTitle: 'Flytta dokument till...',
  moveDocumentHelpText: 'Vilken mapp vill du flytta dokumentet <strong>{name}</strong> till?',

  moveFolderTitle: 'Flytta mapp till...',
  moveFolderHelpText: 'Vilken mapp vill du flytta mappen <strong>{name}</strong> till?',

  renameDocumentTitle: 'Döp om dokument',
  renameDocumentPlaceholder: 'Dokumentets namn',
  renameFolderTitle: 'Döp om mapp',
  renameFolderPlaceholder: 'Folderns namn',
  missingName: 'Du måste ange ett namn',

  noMoreFolders: 'Det finns inga fler foldrar.',
  noDocumentsTitle: 'Inga dokument',
  noDocumentsUploaded: 'Du har inte laddat upp några dokument',
  noDocumentsMatchedSearchTitle: 'Ingen match',
  noDocumentsMatchedSearch: 'Inga dokument eller foldrar matchar din sökning "{search}".',

  missingFolderName: 'Du måste ange ett namn på foldern.'
}
