import getCurrentPosition from '@/assets/libs/currentPosition/'
import UserService from '../services/userService'
import UserModel from '../models/userModel'

export default {
  namespaced: true,

  state: {
    data: null,
    currentPosition: null
  },

  getters: {
    data: state => state.data,
    getCurrentPosition: state => state.currentPosition
  },

  mutations: {
    setUser (state, user) {
      state.data = new UserModel(user)
    },

    setCurrentPosition (state, latLng) {
      state.currentPosition = latLng
    },

    setEmail (state, email) {
      state.data.email = email
    },

    clear (state) {
      state.data = null
      state.currentPosition = null
    }
  },

  actions: {
    read (store, userId) {
      return UserService.read(userId)
    },

    async getCurrentPosition ({ commit }) {
      const latLng = await getCurrentPosition()
      commit('setCurrentPosition', latLng)
      return latLng
    },

    async update ({ commit }, user) {
      commit('setUser', await UserService.update(new UserModel(user)))
    },

    getFullNameById (store, userId) {
      return UserService.getFullName(userId)
    },

    getByName (store, name) {
      return UserService.getByName(name)
    },

    getByEmail (store, email) {
      return UserService.getByEmail(email)
    },

    async changeEmail ({ commit }, data) {
      const { userId, email } = data
      await UserService.changeEmail(userId, email)
      commit('setEmail', email)
    },

    changePassword (store, data) {
      const { userId, password } = data
      return UserService.changePassword(userId, password)
    }
  }
}
