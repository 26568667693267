<template>
  <VDatePicker
    :value="value"
    :mode="mode"
    :is24hr="true"
    :locale="locale"
    @input="onInput">
    <template v-slot="{ inputValue, updateValue, togglePopover, hidePopover }">
      <div class="field has-addons">
        <div class="control is-expanded">
          <input
            :value="inputValue"
            :disabled="disabled"
            :placeholder="placeholder"
            class="input"
            type="text"
            @focus="hidePopover"
            @blur="updateValue(new Date($event.target.value))" />
        </div>

        <div class="control">
          <Button
            :disabled="disabled"
            icon="icon-calendar"
            @click="togglePopover">
          </Button>
        </div>
      </div>
    </template>
  </VDatePicker>
</template>

<script>
import { mapGetters } from 'vuex'
import VDatePicker from 'v-calendar/lib/components/date-picker.umd'

export default {
  components: {
    VDatePicker
  },

  props: {
    value: {
      type: Date,
      default: null
    },

    mode: {
      type: String,
      default: 'dateTime'
    },

    placeholder: {
      type: String,
      default: ''
    },

    disabled: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    ...mapGetters({
      locale: 'ui/getLocale'
    })
  },

  methods: {
    onInput (date) {
      this.$emit('input', date)
    }
  }
}
</script>
