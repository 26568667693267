import dayjs from 'dayjs'
import DeviceModel from './deviceModel'

class SubscriptionModel {
  constructor (context) {
    const data = Object.assign({}, context)

    this.userId = data.hcid ? Number(data.hcid.substring(0, data.hcid.indexOf('_'))) : null
    this.type = data.type || ''
    this.expires = data.expires ? dayjs.unix(data.expires).toDate() : null
    this.platform = data.platform || ''
    this.price = data.price ? { amount: data.price.amount, currency: data.price.currency } : null
    this.subscriptionId = data.subscription_id
    this.weight = data.subscription_weight
    this.autoRenew = data.auto_renew === 1

    this.devices = data.gps ? data.gps.map(gps => new DeviceModel(gps)) : null
  }

  toJSON () {
    return {
      userId: this.userId,
      type: this.type,
      expires: this.expires,
      platform: this.platform,
      price: this.price,
      subscriptionId: this.subscriptionId,
      weight: this.weight,
      autoRenew: this.autoRenew,
      devices: this.devices
    }
  }
}

export default SubscriptionModel
