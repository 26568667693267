import NewsService from '../services/newsService'

export default {
  namespaced: true,

  state: {
    newsItems: null
  },

  getters: {
    newsItems (state) {
      return state.newsItems
    }
  },

  mutations: {
    clear (state) {
      state.newsItems = null
    },

    set (state, newsItems) {
      state.newsItems = newsItems
    }
  },

  actions: {
    async getAll ({ commit, state, rootGetters }, maxCount) {
      let date = null

      if (state.newsItems && state.newsItems.length > 0) {
        date = state.newsItems[state.newsItems.length - 1].publishedAt
      } else {
        date = new Date()
      }

      const olderThan = Math.round(date.getTime() / 1000)
      const language = rootGetters['ui/language']

      const newsItems = await NewsService.getAll(language, olderThan, maxCount)

      if (state.newsItems === null) {
        commit('set', newsItems)
      } else {
        commit('set', [...state.newsItems, ...newsItems])
      }

      return newsItems
    },

    getById ({ commit }, newsItemId) {
      return NewsService.getById(newsItemId)
    }
  }
}
