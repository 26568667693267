const isInternetExplorer = () => /Trident\/|MSIE/.test(window.navigator.userAgent)

const capitalize = string => string.charAt(0).toUpperCase() + string.slice(1)

const random = (length = 9) => Math.random(0x7FFFFFFF).toString(18).slice(2, length)

const isEmail = email => email.indexOf('@') !== -1
const validateEmail = email => {
  // eslint-disable-next-line
  var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return re.test(email)
}

const slug = string => {
  string = string.replace(/^\s+|\s+$/g, '')
  string = string.toLowerCase()

  const from = 'åäöÅÄÖ·/_,:;'
  const to = 'aaoaao------'

  for (var i = 0, l = from.length; i < l; i++) {
    string = string.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i))
  }

  string = string.replace(/[^a-z0-9 -]/g, '').replace(/\s+/g, '-').replace(/-+/g, '-')

  return string
}

export {
  isInternetExplorer,
  capitalize,
  random,
  isEmail,
  validateEmail,
  slug
}
