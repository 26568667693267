import dayjs from 'dayjs'
import { getYears } from '@/modules/huntArea/report/utils/reportUtils.js'

export const DASHBOARD_EVENT_TYPE_KILLS = 'kill'
export const DASHBOARD_EVENT_TYPE_OBSERVATIONS = 'observation'
export const DASHBOARD_EVENT_TYPE_ALL = 'all'

export default {
  namespaced: true,

  state: {
    startDateOffsetInDays: 0,
    startDate: null,
    endDate: null,
    eventType: DASHBOARD_EVENT_TYPE_ALL,
    year: null
  },

  getters: {
    getStartDate (state) {
      return state.startDate
    },

    getEndDate (state) {
      return state.endDate
    },

    getCurrentDate (state) {
      return dayjs(state.startDate).add(state.startDateOffsetInDays, 'day').toDate()
    },

    getEventType (state) {
      return state.eventType
    },

    getStartDateOffsetInDays (state) {
      return state.startDateOffsetInDays
    },

    getTotalDaysInPeriod (state) {
      return dayjs(state.endDate).diff(state.startDate, 'day')
    },

    getYear (state) {
      return state.year
    },

    getYears (state, getters, rootState, rootGetters) {
      const reports = rootGetters['huntarea/reports/getAll'] || []
      return reports !== null ? getYears(reports) : []
    }
  },

  mutations: {
    setStartDateOffsetInDays (state, days) {
      state.startDateOffsetInDays = Number(days) // Will come as a string from the <input>
    },

    setEventType (state, eventType) {
      state.eventType = eventType
    },

    setYear (state, year) {
      state.year = year
    },

    setPeriod (state, { startDate, endDate }) {
      const today = new Date()

      state.startDate = dayjs(startDate).toDate()
      state.endDate = dayjs(endDate).toDate()

      if (state.endDate.getFullYear() === today.getFullYear()) {
        state.endDate = today
      }

      state.startDateOffsetInDays = dayjs(state.endDate).diff(state.startDate, 'day')
    }
  },

  actions: {
    clear ({ commit }) {
      const currentYear = new Date().getFullYear()

      commit('setPeriod', {
        startDate: `${currentYear}-01-01`,
        endDate: `${currentYear}-12-31`
      })

      commit('setEventType', DASHBOARD_EVENT_TYPE_ALL)
    },

    setYear ({ state, commit, getters, rootGetters }, year) {
      commit('setYear', year)

      if (year !== null) {
        commit('setPeriod', {
          startDate: `${year}-01-01`,
          endDate: `${year}-12-31`
        })
      } else {
        const years = getters.getYears
        const oldestYear = years[0]
        const newestYear = years[years.length - 1]

        commit('setPeriod', {
          startDate: `${oldestYear}-01-01`,
          endDate: `${newestYear}-12-31`
        })
      }
    }
  }
}
