import SubscriptionService from '../services/subscriptionService'
import { capitalize } from '@/assets/libs/utils'
import { i18n } from '@/i18n'

export const SUBSCRIPTION_TYPE_FREE = 'free'
export const SUBSCRIPTION_TYPE_TRIAL = 'trial'
export const SUBSCRIPTION_TYPE_PRO = 'pro'
export const SUBSCRIPTION_TYPE_STANDARD = 'standard'
export const SUBSCRIPTION_TYPE_PREMIUM = 'premium'
export const SUBSCRIPTION_TYPE_GPS = 'gps'
export const SUBSCRIPTION_TYPE_GPS_MAX_PREFIX = 'gps_'
export const SUBSCRIPTION_TYPE_GPS_MAX_DEVICES = 20

export const SUBSCRIPTION_TYPE_FREE_WEIGHT = 0
export const SUBSCRIPTION_TYPE_TRIAL_WEIGHT = 1
export const SUBSCRIPTION_TYPE_PRO_WEIGHT = 11
export const SUBSCRIPTION_TYPE_STANDARD_WEIGHT = 10
export const SUBSCRIPTION_TYPE_PREMIUM_WEIGHT = 20

export const SUBSCRIPTION_STATUS_LOADING = 'loading'
export const SUBSCRIPTION_STATUS_SUCCESS = 'success'
export const SUBSCRIPTION_STATUS_FAILED = 'failed'

export const FEATURE_TERRAIN_LAYER = 'feature-terrain-layer'
export const FEATURE_PROPERTY_BOUNDARIES_LAYER =
  'feature-property-boundaries-layer'

const SUBSCRIPTIONS = [
  SUBSCRIPTION_TYPE_PRO,
  SUBSCRIPTION_TYPE_STANDARD,
  SUBSCRIPTION_TYPE_PREMIUM,
  SUBSCRIPTION_TYPE_GPS
]

const SUBSCRIPTIONS_WITH_FEATURE_ACCESS = [
  SUBSCRIPTION_TYPE_TRIAL,
  SUBSCRIPTION_TYPE_PRO,
  SUBSCRIPTION_TYPE_STANDARD,
  SUBSCRIPTION_TYPE_PREMIUM,
  SUBSCRIPTION_TYPE_GPS
]

const FEATURES = {
  free: [],

  trial: [FEATURE_TERRAIN_LAYER],
  pro: [FEATURE_TERRAIN_LAYER],
  standard: [FEATURE_TERRAIN_LAYER],
  premium: [FEATURE_TERRAIN_LAYER, FEATURE_PROPERTY_BOUNDARIES_LAYER],
  gps: [FEATURE_TERRAIN_LAYER, FEATURE_PROPERTY_BOUNDARIES_LAYER],
  gps_: [FEATURE_TERRAIN_LAYER, FEATURE_PROPERTY_BOUNDARIES_LAYER]
}

export default {
  namespaced: true,

  state: {
    type: '', // String free/trial/pro/gps
    weight: 0, // Subscription weight
    expires: null, // Date
    platform: null, // ios/android/stripe(empty)
    price: null,
    autoRenew: false,
    devices: [],
    status: SUBSCRIPTION_STATUS_LOADING,

    prices: {
      pro: null,
      gps: null
    },

    sessions: {
      pro: null,
      gps: null
    }
  },

  getters: {
    type: (state) => state.type,
    weight: (state) => state.weight,
    expires: (state) => state.expires,
    platform: (state) => state.platform,
    autoRenew: (state) => state.autoRenew,
    getPrice: (state) => state.price,
    devices: (state) => state.devices,
    getStatus: (state) => state.status,
    isLoading: (state) => state.status === SUBSCRIPTION_STATUS_LOADING,
    hasFailed: (state) => state.status === SUBSCRIPTION_STATUS_FAILED,

    // User is allowed to make a Stripe purchase of a new subscription if no current subscription exists (autoRenew is false by default), or is not auto renewing
    canPurchase (state) {
      return !state.autoRenew
    },

    canUpgradeTo: (state) => (weight) => {
      return state.platform === 'stripe' && state.weight < weight
    },

    isStripe (state) {
      return state.platform === 'stripe'
    },

    isAppStore (state) {
      return state.platform === 'ios'
    },

    willBeRefunded (state) {
      return state.platform === 'stripe' && state.autoRenew
    },

    isGooglePlay (state) {
      return state.platform === 'android'
    },

    // Returns whether or not the user has access to pro FEATURES
    hasPro (state) {
      return SUBSCRIPTIONS_WITH_FEATURE_ACCESS.includes(state.type) || state.type.startsWith(SUBSCRIPTION_TYPE_GPS_MAX_PREFIX)
    },

    // Returns whether or not the user has an ongoing subscription and therfore should be presented with different content related to subscription and sales
    hasSubscription (state) {
      return SUBSCRIPTIONS.includes(state.type) || state.type.startsWith(SUBSCRIPTION_TYPE_GPS_MAX_PREFIX)
    },

    getSubscriptionName (state) {
      return capitalize(state.type)
    },

    hasFeature: (state) => (feature) => {
      const subscriptionType = state.type.startsWith(SUBSCRIPTION_TYPE_GPS_MAX_PREFIX) ? SUBSCRIPTION_TYPE_GPS_MAX_PREFIX : state.type

      return FEATURES[subscriptionType] !== undefined
        ? FEATURES[subscriptionType].includes(feature)
        : false
    }
  },

  mutations: {
    setSubscription (state, subscription) {
      if (subscription) {
        const { type, weight, expires, platform, price, autoRenew, devices } =
          subscription

        state.type = type
        state.weight = weight
        state.expires = expires
        state.platform = platform
        state.price = price
        state.autoRenew = autoRenew
        state.devices = devices || []
      }
    },

    clear (state) {
      state.type = ''
      state.weight = 0
      state.expires = null
      state.platform = null
      state.price = null
      state.autoRenew = false
      state.devices = []
    },

    // Development tools
    setType (state, type) {
      state.type = type
      console.log(type)
    },

    setWeight (state, weight) {
      state.weight = weight
    },

    setPlatform (state, platform) {
      state.platform = platform
    },

    toggleAutoRenew (state) {
      state.autoRenew = !state.autoRenew
    },

    setStatus (state, status) {
      state.status = status
    }
  },

  actions: {
    async read ({ commit, rootGetters }) {
      commit('clear')
      commit('setStatus', SUBSCRIPTION_STATUS_LOADING)

      try {
        const credentials = rootGetters['auth/getBase64']
        const subscription = await SubscriptionService.read(credentials)

        commit('setSubscription', subscription)
        commit('setStatus', SUBSCRIPTION_STATUS_SUCCESS)

        return subscription
      } catch (error) {
        commit('setStatus', SUBSCRIPTION_STATUS_FAILED)
      }
    },

    purchase ({ rootGetters }, payload) {
      const { type, returnPath } = payload

      const credentials = rootGetters['auth/getBase64']
      const willBeRefunded = rootGetters['subscription/willBeRefunded']
      return SubscriptionService.purchase(
        type,
        credentials,
        returnPath,
        willBeRefunded ? i18n.t('subscription.willBeRefunded') : ''
      )
    },

    /*
    async purchaseGps ({ rootGetters }, params) {
      const { imei, returnPath } = params
      const credentials = rootGetters['auth/getBase64']

      return SubscriptionService.purchaseGps(imei, returnPath, credentials)
    },
    */

    checkout (store, session) {
      return SubscriptionService.checkout(session)
    },

    async cancel ({ dispatch, rootGetters }) {
      const credentials = rootGetters['auth/getBase64']

      await SubscriptionService.cancel(credentials)
      dispatch('read')
    },

    async cancelGps ({ dispatch, rootGetters }, imei) {
      const credentials = rootGetters['auth/getBase64']

      await SubscriptionService.cancelGps(imei, credentials)
      dispatch('read')
    },

    getBillingPortalSession ({ rootGetters }, returnPath) {
      const credentials = rootGetters['auth/getBase64']
      return SubscriptionService.getBillingPortalSession(
        credentials,
        returnPath
      )
    }
  }
}
