<template>
  <section :class="pageClass">
    <slot />
  </section>
</template>

<script>
export default {
  props: {
    name: {
      type: String,
      default: ''
    }
  },

  computed: {
    pageClass () {
      return `page-${this.name}`
    }
  }
}
</script>
