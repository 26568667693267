<script>
import { mapGetters } from 'vuex'
import ClickMenuOverlay from './clickMenuOverlay'
import colors from '@/components/map/utils/colors'

export default {
  data () {
    return {
      $map: null,
      $polygon: null,
      $polygonBounds: null,
      $drawingManager: null
    }
  },

  computed: {
    ...mapGetters({
      boundary: 'map/boundary/boundary'
    }),

    selected: {
      get () { return this.$store.getters['map/boundary/selected'] },
      set (state) { this.$store.dispatch('map/boundary/select', state) }
    },

    visible () { return this.boundary && this.boundary.visible },
    editable () { return this.boundary && this.boundary.editable },
    borderCoordinates () { return this.boundary ? this.boundary.coordinates : null },
    isEmpty () { return this.boundary && this.boundary.coordinates.length === 0 }
  },

  watch: {
    visible (visible) {
      if (this.$polygon) {
        this.$polygon.setVisible(visible)
      }
    },

    selected (isSelected) {
      if (this.$polygon) { // Not available if the border has not been drawn yet
        if (isSelected) {
          this.$map.fitBounds(this.$polygonBounds)
        }
      }
    },

    editable (editable) {
      if (this.$polygon) {
        this.$polygon.setEditable(editable)

        if (editable) {
          const path = this.$polygon.getPath()

          path.addListener('insert_at', () => this.updateBorderCoodinates())
          path.addListener('remove_at', () => this.updateBorderCoodinates())
          path.addListener('set_at', () => this.updateBorderCoodinates())
        } else {
          // Reset border in case user aborted editing.
          // Since a new path is created, this effectively stop events insert_at, remove_at and set_at until a new edit is started
          if (!this.isEmpty) {
            this.$polygon.setPath(this.boundary.coordinates)
            this.$polygonBounds = this.getBounds()
          } else {
            this.removePolygon()
          }
        }
      } else { // No border yet
        if (this.$drawingManager == null) {
          this.createDrawingManager()
        }

        this.$drawingManager.setDrawingMode(editable ? window.google.maps.drawing.OverlayType.POLYGON : null)
      }
    }
  },

  async mounted () {
    if (!this.$map) {
      this.$map = await this.$parent.getMap()
    }

    if (!this.isEmpty) {
      this.createPolygon()
    }
  },

  destroyed () {
    if (this.$polygon) {
      this.removePolygon()
    }
  },

  // created () {
  //   eventManager.$on('boundary:fitBounds', () => {
  //     if (this.$polygon) this.$map.fitBounds(this.$polygonBounds)
  //   })
  // },

  render () {
    return ''
  },

  methods: {
    createDrawingManager () {
      this.$drawingManager = new window.google.maps.drawing.DrawingManager({
        map: this.$map,
        drawingControl: false,
        polygonOptions: {
          clickable: false,
          editable: true,
          fillOpacity: 0,
          strokeOpacity: 1,
          strokeColor: colors.boundary,
          strokeWeight: this.strokeWeight || 4
        }
      })

      window.google.maps.event.addListener(this.$drawingManager, 'polygoncomplete', polygon => {
        if (this.editable) {
          const path = polygon.getPath()

          path.addListener('insert_at', () => this.updateBorderCoodinates())
          path.addListener('remove_at', () => this.updateBorderCoodinates())
          path.addListener('set_at', () => this.updateBorderCoodinates())

          this.$polygon = polygon
          this.updateBorderCoodinates()

          this.$polygonBounds = this.getBounds()
        } else {
          polygon.setMap(null)
        }

        this.$drawingManager.setDrawingMode(null)
      })
    },

    createPolygon () {
      if (!this.boundary) return

      const polygon = new window.google.maps.Polygon({
        map: this.$map,
        clickable: false,
        editable: false,
        paths: this.boundary.coordinates,
        strokeColor: colors.boundary,
        strokeWeight: this.strokeWeight || 4,
        strokeOpacity: 1,
        fillOpacity: 0
      })

      if (polygon) {
        this.$polygonBounds = this.getBounds()
        this.$polygon = polygon

        this.$map.fitBounds(this.$polygonBounds)

        const clickMenuOverlay = new ClickMenuOverlay()

        window.google.maps.event.addListener(polygon, 'rightclick', e => {
          if (e.vertex === undefined) return
          clickMenuOverlay.open(this.$map, polygon.getPath(), e.vertex)
        })
      } else {
        console.error('Failed to create polygon for boundary.')
      }
    },

    removePolygon () {
      this.$polygon.setMap(null)
      this.$polygon = null
      this.$polygonBounds = null
    },

    updateBorderCoodinates () {
      const path = this.$polygon.getPath().getArray()
      this.boundary.coordinates = path.map(latLng => latLng.toJSON())
    },

    getBounds () {
      const bounds = new window.google.maps.LatLngBounds()
      const coords = this.boundary.coordinates

      coords.forEach(coord => bounds.extend(new window.google.maps.LatLng(coord.lat, coord.lng)))

      return bounds
    }
  }
}
</script>
