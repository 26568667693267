export default {
  title: 'Team',

  noMembersMatchedSearched: 'No members matched your search.',

  inviteTitle: 'Add members',
  inviteDescription: 'Add members who are part of the hunting team. All members have access to the hunting ground map and can participate in the hunting ground hunts.',
  inviteButtonTitle: 'Add | Add <strong>1</strong> members | Add <strong>{userCount}</strong> members',
  inviteAccountNotFoundMessage: 'We can not find an account in WeHunt with this e-mail address. Do you still want to send an invitation?',
  inviteFailedErrorMessage: 'An error occurred and the member could not be added. Please try again.',
  inviteAwaitingAnswer: 'Awaiting answer',

  removeMemberTitle: 'Remove member',
  removeMemberConfirmMessage: 'Do you really want to remove <strong>{name}</strong> from the hunting ground?',
  removeMemberFailedErrorMessage: 'An error occurred and the member could not be removed from the hunting ground. Please try again.',

  removeInviteTitle: 'Remove invite',
  removeInviteConfirmMessage: 'Do you really want to remove the invitation to <strong>{email}</strong>?',
  removeInviteFailedErrorMessage: 'An error occurred and the invitation could not be remove. Please try again.'
}
