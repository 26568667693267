<template>
  <th>
    <slot>{{ heading }}</slot>
  </th>
</template>

<script>
export default {
  props: {
    heading: {
      type: String,
      default: ''
    }
  }
}
</script>
