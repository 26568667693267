import ParticipantService from '@/api/hunt/participants/participantService'
import GameService from '@/api/hunt/games/gameService'
import HuntDriveService from '@/api/hunt/drives/huntDriveService'

export default {
  namespaced: true,

  state: {
    note: null,
    members: null,
    games: null,
    drives: null
  },

  getters: {
    getInviteText (state) {
      return state.note
    },

    getMembers (state) {
      return state.members
    },

    getGames (state) {
      return state.games
    },

    getDrives (state) {
      return state.drives
    }
  },

  mutations: {
    setNote (state, note) {
      state.note = note
    },

    setMembers (state, members) {
      state.members = members
    },

    setGames (state, games) {
      state.games = games
    },

    setDrives (state, drives) {
      state.drives = drives
    },

    clear (state) {
      state.note = null
      state.members = null
      state.games = null
      state.drives = null
    }
  },

  actions: {
    read ({ commit }, hunt) {
      commit('setNote', hunt.note)
      commit('setMembers', null)
      commit('setGames', null)
      commit('setDrives', null)

      ParticipantService.getMembers(hunt.id).then(members => {
        commit('setMembers', members)
      })

      GameService.read(hunt.id).then(games => {
        commit('setGames', games)
      })

      HuntDriveService.getDrives(hunt.id).then(drives => {
        commit('setDrives', drives)
      })
    }
  }
}
