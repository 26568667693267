import ViltrapportReportModel from '@/modules/huntArea/viltrapport/models/viltrapportReportModel'

export default class HuntReportEventGameModel {
  constructor (params) {
    const data = Object.assign({}, params)

    this.id = data.id || null
    this.animalId = data.animalId || null
    this.animalName = data.animalName || null
    this.count = data.count || 0
    this.viltrapportExternalId = data.viltrapportExternalId || null
    this.invalid = null

    this.viltrapportReports = data.viltrapportReports ? data.viltrapportReports.map(report => new ViltrapportReportModel(report)) : []
  }
}
