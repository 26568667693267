<template>
  <Portal to="loader">
    <div class="page-loader">
      <div :class="[size]" class="loader"></div>
      <slot />
    </div>
  </Portal>
</template>

<script>
export default {
  props: {
    size: {
      type: String,
      default: 'normal' // small, normal, large
    },

    content: {
      type: String,
      default: null
    }
  }
}
</script>

<style lang="scss">
.page-loader {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 100;
  background: rgba(0, 0, 0, 0.5);

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .loader {
    &.small {
      height: 1.5rem;
      width: 1.5rem;
    }

    &.normal {
      height: 2.5rem;
      width: 2.5rem;
    }

    &.large {
      height: 4rem;
      width: 4rem;
    }
  }

  p {
    margin-top: 1.5rem;
    color: white;
    font-size: 112.5%;
    font-weight: 600;
    text-align: center;
  }
}
</style>
