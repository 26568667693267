export default {
  title: "Invité à {nom}",
  huntAreaInviteMessage: "Vous avez été invité par <strong>{name}</strong> à rejoindre <strong>{area}</strong>.<br/><br/>Un compte <strong>WeHunt</strong> gratuit est nécessaire pour répondre à l'invitation.",
  alreadyAccepted: "Vous avez déjà accepté l'invitation.",
  alreadyAnswered: "Vous avez déjà répondu à l'invitation.",
  userAcceptedHunt: "Vous avez accepté l'invitation à la chasse",
  userDeclinedHunt: "Vous avez refusé l'invitation à la chasse",
  userAcceptedCalendarEvent: "Vous avez accepté l'invitation à l'événement",
  userDeclinedCalendarEvent: "Vous avez refusé l'invitation à l'événement",
  accountCreated: "Votre compte a été créé et l'invitation a été acceptée.",
  noInviteFound: "L'invitation n'est plus disponible.",
  joinArea: "Rejoignez le territoire de chasse",
  passwordsNotMatching: "Le mot de passe ne correspond pas",
  notAnswered: "Non répondu",
  accept: "Accepter",
  decline: "Refuser",
  accepted: "Accepté",
  declined: "Refusée",
  unknownErrorMessage: "Une erreur s'est produite et il n'a pas été possible de répondre à l'invitation. Veuillez réessayer."
}
