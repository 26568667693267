export default {
  set (state, messages) {
    state.messages = messages
  },

  add (state, message) {
    if (!state.messages) state.messages = []
    state.messages.push(message)
  }
}
