<template>
  <div>
    <div class="notification unsupported-browser-banner">
      <Paragraph>
        {{ $t('core.unsupportedBrowser.updateRequired') }} <a @click="showModal = true">{{ $t('core.unsupportedBrowser.readMore') }}</a>.
      </Paragraph>
    </div>

    <UnsupportedBrowserDialog
      v-if="showModal"
      @close="showModal = false">
    </UnsupportedBrowserDialog>
  </div>
</template>

<script>
import UnsupportedBrowserDialog from './UnsupportedBrowserDialog.vue'

export default {
  components: {
    UnsupportedBrowserDialog
  },

  data () {
    return {
      showModal: false
    }
  }
}
</script>

<style lang="scss" scoped>
.unsupported-browser-banner {
  display: none;
  position: fixed;
  z-index: 10;
  top: 0;
  left: 50%;
  // margin-left: 6.75rem; // sidebar width divided by two
  transform: translateX(-50%);
  white-space: nowrap;
  background: #f2a269;
  color: white;
  padding: 0.75rem;
  margin-bottom: 0 !important;
  border-top-left-radius: 0;
  border-top-right-radius: 0;

  @media screen and (min-width: 1024px) {
    display: block;
  }

  p {
    margin: 0 !important;
  }
}
</style>
