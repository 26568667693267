import dayjs from 'dayjs'

class SubAreaModel {
  constructor (context) {
    const data = Object.assign({}, context)

    this.id = data.id || null
    this.name = data.name || ''
    this.type = data.type || 'saat'

    this.createdAt = data.createdAt ? dayjs(data.createdAt).toDate() : null
    this.createdBy = data.createdBy || null

    this.fillColor = data.fillColor || ''
    this.borderColor = data.borderColor || ''
    this.borderCoordinates = data.borderCoordinates || []

    this.visible = data.visible !== undefined ? data.visible : true
    this.borderVisible = data.borderVisible !== undefined ? data.borderVisible : true
    this.markersVisible = data.markersVisible !== undefined ? data.markersVisible : true
    this.editable = data.editable !== undefined ? data.editable : false

    this.markers = data.markers || null
  }

  computeArea () {
    const latLng = this.borderCoordinates.map(coord => new window.google.maps.LatLng(coord.lat, coord.lng))
    return window.google.maps.geometry.spherical.computeArea(latLng)
  }

  toJSON () {
    return {
      name: this.name,
      type: this.type,
      fillColor: this.fillColor,
      borderColor: this.borderColor.length > 0 ? this.borderColor : null,
      borderCoordinates: this.borderCoordinates,
      markers: this.markers
    }
  }
}

export default SubAreaModel
