<template>
  <input
    type="color"
    class="input is-fullwidth"
    :class="classes"
    :placeholder="placeholder"
    :disabled="disabled"
    :value="value"
    @input="$emit('input', $event.target.value)" />
</template>

<script>
export default {
  props: {
    value: {
      type: String,
      default: ''
    },

    placeholder: {
      type: String,
      default: ''
    },

    disabled: {
      type: Boolean,
      default: false
    },

    size: {
      type: String,
      default: ''
    }
  },

  computed: {
    classes () {
      return this.size !== '' ? ['is-' + this.size] : []
    }
  }
}
</script>

<style lang="scss" scoped>
input {
  width: 100%;
}
</style>
