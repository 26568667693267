import HuntDriveService from '@/api/hunt/drives/huntDriveService'
import HuntAssignmentModel from '@/api/hunt/drives/huntAssignmentModel'

export default {
  namespaced: true,

  state: {
    drives: null,
    selectedDrive: null
  },

  getters: {
    getAll (state) {
      return state.drives
    },

    selectedDrive (state) {
      return state.selectedDrive
    }
  },

  mutations: {
    set (state, huntDrives) {
      state.drives = huntDrives
    },

    add (state, huntDrive) {
      if (state.drives === null) {
        state.drives = []
      }

      state.drives.push(huntDrive)
    },

    remove (state, huntDrive) {
      const i = state.drives.indexOf(huntDrive)
      state.drives.splice(i, 1)
    },

    setRole (state, payload) {
      const { member, role } = payload
      member.role = role
    },

    addAssignmentToDrive (state, payload) {
      const { huntDrive, assignment } = payload
      huntDrive.assignments.push(assignment)
    },

    removeMember (state, payload) {
      const { huntDrive, member } = payload
      const i = huntDrive.assignments.findIndex(assignment => assignment.memberId === member.id)
      if (i !== -1) {
        huntDrive.assignments.splice(i, 1)
      }
    },

    selectDrive (state, drive) {
      if (state.selectedDrive !== null) {
        state.selectedDrive.selected = false
      }

      state.selectedDrive = drive

      if (state.selectedDrive !== null) {
        state.selectedDrive.selected = true
      }
    }
  },

  actions: {
    async read ({ commit, rootGetters }) {
      const hunt = rootGetters['hunt/hunt']

      const drives = await HuntDriveService.getDrives(hunt.id)
      if (drives.length > 0) {
        drives[0].expanded = true
      }

      commit('set', drives)
    },

    async createDriveForSubArea ({ commit, rootGetters }, subAreaId) {
      const hunt = rootGetters['hunt/hunt']
      const members = rootGetters['hunt/participants/participants']

      const promises = []

      const huntDrive = await HuntDriveService.addDrive(hunt.id, subAreaId)
      huntDrive.expanded = true

      members.forEach(member => {
        const promise = HuntDriveService.addAssignment(hunt.id, huntDrive.id, new HuntAssignmentModel({
          memberId: member.id,
          role: 'unassigned'
        }))

        promises.push(promise)

        promise.then((assignment) => {
          huntDrive.assignments.push(new HuntAssignmentModel({
            id: assignment.id,
            memberId: member.id,
            huntDriveId: assignment.huntDriveId,
            userId: member.userId,
            role: member.role,
            firstName: member.firstName,
            lastName: member.lastName,
            email: member.email
          }))
        })
      })

      await Promise.all(promises)

      commit('add', huntDrive)
    },

    async removeDrive ({ commit, rootGetters }, huntDrive) {
      const hunt = rootGetters['hunt/hunt']

      commit('remove', huntDrive)

      try {
        await HuntDriveService.removeDrive(hunt.id, huntDrive.id)
      } catch (error) {
        commit('add', huntDrive)
        throw error
      }
    },

    async addAssignmentToDrive ({ commit, rootGetters }, payload) {
      const hunt = rootGetters['hunt/hunt']
      const { huntDrive, member } = payload

      const assignment = await HuntDriveService.addAssignment(hunt.id, huntDrive.id, new HuntAssignmentModel({
        memberId: member.id,
        role: 'unassigned'
      }))

      commit('addAssignmentToDrive', {
        huntDrive: huntDrive,
        assignment: new HuntAssignmentModel({
          id: assignment.id,
          memberId: member.id,
          huntDriveId: assignment.huntDriveId,
          userId: member.userId,
          role: member.role,
          firstName: member.firstName,
          lastName: member.lastName,
          email: member.email
        })
      })
    },

    updateAssignment ({ commit, rootGetters }, assignment) {
      const hunt = rootGetters['hunt/hunt']
      return HuntDriveService.updateAssignment(hunt.id, assignment.huntDriveId, assignment)
    }
  }
}
