<template>
  <div class="media">
    <div class="media-left">
      <figure class="image is-48x48">
        <ProfilePicture
          :userId="Number(user.id)"
          :name="fullName"
          size="small"
          round>
        </ProfilePicture>
      </figure>
    </div>

    <div class="media-content is-marginless">
      <p :title="fullName" class="title is-5 is-marginless has-text-dark">
        {{ fullName }}
      </p>

      <a
        :title="user.email"
        :href="`mailto: ${user.email}`"
        class="subtitle is-6 is-marginless is-size-7 has-text-dark">
        {{ user.email }}
      </a>

      <div>
        <Badge
          v-if="hasSubscription"
          class="push-top-xxs has-text-weight-semibold"
          :text="subscriptionName">
        </Badge>

        <RouterLink v-else to="/profile/subscription" class="is-size-7">
          {{ $t('subscription.upgrade') }}
        </RouterLink>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

import { SUBSCRIPTION_TYPE_GPS_MAX_PREFIX } from '@/modules/subscription/store/subscriptionStore'

export default {
  computed: {
    ...mapGetters({
      hasSubscription: 'subscription/hasSubscription',
      subscriptionType: 'subscription/type',
      user: 'user/data',
      profile: 'profile/data'
    }),

    subscriptionName () {
      if (this.subscriptionType.startsWith(SUBSCRIPTION_TYPE_GPS_MAX_PREFIX)) {
        const namePrefix = this.$t('subscription.gps_max.shortName')

        return namePrefix + ' ' + this.subscriptionType.substring(SUBSCRIPTION_TYPE_GPS_MAX_PREFIX.length)
      }

      return this.$t(`subscription.${this.subscriptionType}.shortName`)
    },

    fullName () {
      if (this.user.firstName.length > 12) return `${this.user.firstName}`
      return `${this.user.firstName} ${this.user.lastName}`
    },

    roles () {
      const roles = []

      Object.keys(this.profile.roles).forEach(role => {
        if (this.profile.roles[role]) {
          roles.push(this.$t(`settings.roles.${role}`))
        }
      })

      return roles
    }
  }

}
</script>

<style lang="scss" scoped>
.media {
  margin: 0 !important;
  padding: 1rem;

  position: relative;
  white-space: nowrap;
  overflow: hidden;

  .media-content {
    position: relative;
    overflow: hidden;

    .title {
      color: #333;
    }

    &::after {
      content: "";
      position: absolute;
      right: 0;
      margin-left: -40px;
      width: 40px;
      height: 100%;
      top: 0;
      background: linear-gradient(
        to right,
        rgba(255, 255, 255, 0),
        rgba(255, 255, 255, 1)
      );

      .is-dark & {
        background: linear-gradient(
          to right,
          rgba(#293b3f, 0),
          rgba(#293b3f, 1)
        );
      }
    }
  }
}

.media-left {
  margin-right: 0.5rem;

  .image {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>
