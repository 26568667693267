export default {
  title: "Viltraport",
  quotas: 'Quotas',
  report: "Rapport",
  showingQuotasForDistrict: "Afficher les quotas pour <strong>{name}</strong>.",
  district: 'District',

  restrictions: 'Restrictions',
  noRestrictionsSpecified: "Aucune restriction générale spécifiée.",

  type: "Type",
  allocated: "Alloué",
  animalsLeft: "Animaux restants",
  quotaFull: "Tous les gibiers tués",
  noLimitQuota: "Pas de restrictions",
  quotaExceeded: " | Quota dépassé par 1 animal | Quota dépassé par {count} animaux",
  noQuota: "Aucun gibier autorisé pour la chasse."
}
