class AreaSVG {
  constructor (bounds) {
    this.props = []
    return this.init(bounds)
  }

  init (bounds) {
    if (!bounds.length) return

    const area = new window.google.maps.Polygon({ paths: bounds })
    const paths = [window.google.maps.geometry.encoding.encodePath(area.getPath())]

    if (paths[0].length === 0) return

    for (var i = 0; i < paths.length; ++i) {
      paths[i] = window.google.maps.geometry.encoding.decodePath(paths[i])
    }

    this.props = this.bounds2svg(paths, latLng => {
      return {
        lat: latLng.lat(),
        lng: latLng.lng()
      }
    })

    return this.createSVG(this.props)
  }

  bounds2svg (bounds, fx) {
    let point
    let bound
    let props
    let minX = 256
    let minY = 256
    let maxX = 0
    let maxY = 0

    for (let pp = 0; pp < bounds.length; ++pp) {
      bound = bounds[pp]
      props = []

      for (let p = 0; p < bound.length; ++p) {
        point = this.latLng2point(fx(bound[p]))
        minX = Math.min(minX, point.x).toFixed(4)
        minY = Math.min(minY, point.y).toFixed(4)
        maxX = Math.max(maxX, point.x).toFixed(4)
        maxY = Math.max(maxY, point.y).toFixed(4)

        props.push([point.x.toFixed(4), point.y.toFixed(4)].join(','))
      }

      this.props.push(props.join(' '))
    }

    return {
      path: 'M' + this.props.join(' M'),
      x: minX,
      y: minY,
      width: maxX - minX,
      height: maxY - minY
    }
  }

  latLng2point (latLng) {
    return {
      x: (latLng.lng + 180) * (256 / 360),
      y: (256 / 2) - (256 * Math.log(Math.tan((Math.PI / 4) + ((latLng.lat * Math.PI / 180) / 2))) / (2 * Math.PI))
    }
  }

  createSVG (props) {
    const svg = document.createElementNS('http://www.w3.org/2000/svg', 'svg')
    const g = document.createElementNS('http://www.w3.org/2000/svg', 'g')
    const path = document.createElementNS('http://www.w3.org/2000/svg', 'path')

    // We have to manually style the SVG here since Vue's scoped styles won't affect this code since it's not within the same scope.
    // An earlier attempt had the styling as a global style but this affected all SVG elements including charts which made it unusable.
    svg.style.fill = '#dde4e2'
    path.style.stroke = '#dde4e2'
    path.style.strokeWidth = 0.0001

    path.setAttribute('d', props.path + ' z')

    g.appendChild(path)
    svg.appendChild(g)
    svg.setAttribute('viewBox', [props.x, props.y, props.width.toFixed(4), props.height.toFixed(4)].join(' '))

    return svg
  }
}

const areaSVG = bounds => new AreaSVG(bounds)

export default areaSVG
