export default {
  areas: state => state.areas,
  selected: state => state.selected,
  currentAreaName: state => state.selected.name,

  hasActiveHunt (state, getters, rootState, rootGetters) {
    const hunts = rootGetters['hunt/hunts']
    const huntArea = state.selected

    if (hunts !== null && huntArea !== null) {
      const activeHunts = hunts.filter(hunt => {
        return hunt.huntAreaId === huntArea.id && (hunt.status === 'started' || hunt.status === 'paused')
      })

      return activeHunts.length > 0
    }

    return false
  },

  isAdmin (state, getters, rootState, rootGetters) {
    const userId = rootGetters['auth/getUserId']
    return state.selected !== null ? state.selected.createdBy === userId : false
  },

  getAreaWithId: state => huntAreaId => {
    if (state.areas !== null) {
      const i = state.areas.findIndex(huntArea => huntArea.id === huntAreaId)
      return i !== -1 ? state.areas[i] : null
    }
    return null
  },

  isMember: state => huntAreaId => {
    const areas = state.areas || []
    const i = areas.findIndex(huntArea => huntArea.id === huntAreaId)
    return i !== -1
  }
}
