// Original snippet: https://developers.google.com/maps/documentation/javascript/examples/delete-vertex-menu

class ClickMenu extends window.google.maps.OverlayView {
  constructor (data) {
    super()
    Object.assign(this, data)
    this.div = document.createElement('div')
    this.div.className = 'click-menu icon icon-trash'

    window.google.maps.event.addDomListener(this.div, 'click', () => this.removeVertex())
  }

  onAdd () {
    const clickMenu = this
    const map = this.getMap()
    this.getPanes().floatPane.appendChild(this.div)

    this.divListener = window.google.maps.event.addDomListener(map.getDiv(), 'mousedown', e => {
      if (e.target !== clickMenu.div) clickMenu.close()
    }, true)
  }

  onRemove () {
    window.google.maps.event.removeListener(this.divListener)
    this.div.parentNode.removeChild(this.div)

    this.set('position')
    this.set('path')
    this.set('vertex')
  }

  close () {
    this.setMap(null)
  }

  draw () {
    const position = this.get('position')
    const projection = this.getProjection()

    if (!position || !projection) return

    const point = projection.fromLatLngToDivPixel(position)

    this.div.style.top = point.y + 'px'
    this.div.style.left = point.x + 'px'
  }

  open (map, path, vertex) {
    this.set('position', path.getAt(vertex))
    this.set('path', path)
    this.set('vertex', vertex)
    this.setMap(map)
    this.draw()
  }

  removeVertex () {
    const path = this.get('path')
    const vertex = this.get('vertex')

    if (!path || vertex === undefined) {
      this.close()
      return
    }

    path.removeAt(vertex)
    this.close()
  }
}

export default ClickMenu
