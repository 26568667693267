<template>
  <thead>
    <tr>
      <slot />
    </tr>
  </thead>
</template>

<script>
export default {
}
</script>
