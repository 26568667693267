import SubAreaService from '@/api/map/subareas/subAreaService'
import SubAreaModel from '@/api/map/subareas/subAreaModel'

export default {
  async create ({ commit, dispatch }, data) {
    const { huntAreaId, subArea } = data

    dispatch('ui/cursorLoader', true, { root: true })
    dispatch('map/setDrawingMode', null, { root: true })

    try {
      const result = await SubAreaService.create(huntAreaId, new SubAreaModel(subArea))

      if (subArea.type === 'saat') {
        commit('addSubArea', result)
      } else if (subArea.type === 'forbiddenArea') {
        commit('addForbiddenArea', result)
      }

      return result
    } finally {
      dispatch('ui/cursorLoader', false, { root: true })
    }
  },

  async readSubAreas ({ commit }, huntAreaId) {
    commit('setSubAreas', null)

    let subAreas = null

    try {
      subAreas = await SubAreaService.read(huntAreaId, 'saat')
    } catch (error) {
      subAreas = []
    }

    commit('setSubAreas', subAreas)
    return subAreas
  },

  async readForbiddenAreas ({ commit }, huntAreaId) {
    commit('setForbiddenAreas', null)

    let forbiddenAreas = null

    try {
      forbiddenAreas = await SubAreaService.read(huntAreaId, 'forbiddenArea')
    } catch (error) {
      forbiddenAreas = []
    }

    commit('setForbiddenAreas', forbiddenAreas)
    return forbiddenAreas
  },

  async update ({ dispatch }, data) {
    const { huntAreaId, subArea } = data

    dispatch('ui/cursorLoader', true, { root: true })

    try {
      await SubAreaService.update(huntAreaId, subArea)
    } finally {
      dispatch('ui/cursorLoader', false, { root: true })
    }
  },

  async delete ({ commit, dispatch }, data) {
    const { huntAreaId, subArea } = data

    dispatch('ui/cursorLoader', true, { root: true })

    try {
      await SubAreaService.delete(huntAreaId, subArea.id)

      if (subArea.type === 'saat') {
        commit('deleteSubArea', subArea)
      } else if (subArea.type === 'forbiddenArea') {
        commit('deleteForbiddenArea', subArea)
      }
    } finally {
      dispatch('ui/cursorLoader', false, { root: true })
    }
  },

  select ({ commit, dispatch }, subArea) {
    dispatch('map/closeAllPanels', null, { root: true })
    commit('select', subArea)
  },

  setSize ({ commit }, size) {
    commit('size', size)
  },

  clear ({ commit }) {
    commit('setSubAreas', null)
    commit('setForbiddenAreas', null)
  }
}
