export default {
  title: "Actualités",
  readMore: "En savoir plus",
  readFullArticle: "Lire l'article sur {source}",
  showMore: "Montrer plus...",
  ad: "Annonce",
  advertisedContent: "Contenu annoncé",
  loadFailedErrorMessage: "Une erreur s'est produite et les actualités n'ont pas pu être téléchargées. Veuillez réessayer.",
  newsItemNotFoundTitle: "Impossible de charger l'article de presse",
  newsItemNotFoundMessage: "Il semble que vous ayez cliqué sur un lien incorrect. Veuillez réessayer."
}
