export default {
  upgrade: 'Upgrade',
  upcoming: 'You have an upcoming subscription',
  show: 'Show subscription',
  purchaseErrorMessage:
  'An error occurred and the purchase could not be completed. Please try again.',

  purchase: {
    success: {
      title: 'Thank you for your purchase',
      text: 'Your payment is being processed and your subscription will be automatically activated in a short moment.'
    }
  },

  price: {
    yearly: '{price} {currency}/year',
    ongoing: 'Thereafter {price} {currency}/year'
  },

  willBeRefunded:
  'The remaining period of your current subscription will be refunded separately.',

  requiresVersion63:
  '<strong></strong>NOTE! Does NOT yet work in Android (update to be released before the hunting season). Only works in the latest version in iPhone.</strong>',

  firstPayment: 'First payment is on {date}',
  nextPayment: 'Automatically renews on {date}',

  managedInAppStore: 'Managed in Apple App Store',
  managedInGooglePlay: 'Managed in Google Play',
  buyInAppStore: 'Buy in Apple App Store',
  buyInGooglePlay: 'Buy in Google Play',

  autoRenew: 'You have a <strong>{subscription}</strong> subscription',
  expires: '<strong>{subscription}</strong> expires on <br /> {date}',

  mySubscription: 'Your WeHunt',

  pro: {
    name: 'WeHunt Pro',
    shortName: 'Pro',
    autoRenew: 'The solution that increases the excitement and safety',
    upgrade: 'Upgrade to <strong>WeHunt Standard</strong> now',
    extend: 'Buy Standard'
  },

  standard: {
    name: 'WeHunt Standard',
    shortName: 'Standard',
    description: 'See each other on the map for a better hunting experience',
    autoRenew: 'The solution that increases the excitement and safety',
    upgrade:
    'Avoid hassle, switch to ongoing subscriptions and secure your WeHunt Standard for each season',
    buy: 'Buy Standard',
    extend: 'Extend Standard',
    features: [
      'See location of hunters in real-time',
      'Hunting seasons',
      'Start hunts',
      'Scent cone']

  },

  premium: {
    name: 'WeHunt Premium',
    shortName: 'Premium',
    description: 'See Garmin, Tracker, UltraCom or b-Bark',
    autoRenew:
    'The subscription that offers all features we have in the app & on the web',
    upgrade:
    'Avoid hassle, switch to ongoing subscriptions and secure your WeHunt Premium for each season',
    buy: 'Buy Premium',
    extend: 'Extend Premium',

    benefits: {
      title: 'Premium benefits',
      description:
      'As a Premium user you get a 20% discount when you shop at: <br />- widforss.se <br />- widforss.no <br />- alaska1795.com (not in Norway)<br /> - chevalier.se'
    },

    features: {
      addThirdPartyGPS: 'See & share Garmin GPS',
      seeThirdPartyGPS: 'See Tracker, Ultracom, b-Bark GPS',
      terrainMap: 'Terrain map with property<br/> boundaries',
      benefits: 'Premium benefits'
    }
  },

  gps: {
    name: 'WeHunt GPS',
    shortName: 'GPS',
    description:
    "See & share your dog's position with your phone. Everything in WeHunt is included.",
    autoRenew:
    'Everything WeHunt has to offer is included in your subscription',
    upgrade: '',
    buy: 'Activate new GPS',
    extend: 'Extend subscription',
    renew: 'Renew subscription',
    nextPayment: 'Automatically renews on {date}.',
    purchaseErrorMessage: 'Invalid IMEI-number entered. Please try again.',
    activateHelpText: 'Activate your WeHunt GPS Plus or WeHunt GPS',
    activate: 'Activate',
    paymentFailed: 'Payment failed! Extend subscription.',
    cancelled: 'Subscription cancelled. Expires on {date}.',
    upgradeGpsSubscription: 'Upgrade your subscription to use more GPS devices',

    features: [
      'Your team can see your GPS',
      'Unlimited service in EU/EES',
      'Calling feature',
      'Create a GEO-fence'],

    price: {
      regularPrice: 99.5,
      percentOff: 0,
      finalPrice: 99.5,
      currency: 'eur'
    }
  },

  gps_max: {
    name: 'GPS Max',
    shortName: 'GPS Max',
    description:
    "See & share your dogs' positions with your phone. Two GPS subscriptions and everything in WeHunt Premium are included.",
    buy: 'Buy GPS Max',
    autoRenew: '',
    upgrade: '',
    extend: 'Extend subscription',

    features: {
      maxGPS: 'Up to 1 GPS device (sold separately) | Up to {count} GPS devices (sold separately)',
      worksWith:
      'Works with Tracker Artemis, Tracker Bark, Tracker G10i, Tracker Supra, Tracker G1000, Tracker G500FI'
    },

    amountOfDevices: 'Amount of devices'
  },

  gps_1: {
    name: 'GPS Max 1',
    shortName: 'GPS Max 1',
    description:
    "See & share your dog's positions with your phone. One GPS subscription and everything in WeHunt Premium are included.",
    buy: 'Buy GPS Max 1',
    autoRenew: '',
    upgrade: '',
    extend: 'Extend subscription',

    features: {
      maxGPS: 'Up to 1 GPS device (sold separately)',
      worksWith:
      'Works with Tracker Artemis, Tracker Bark, Tracker G10i, Tracker Supra, Tracker G1000, Tracker G500FI'
    }
  },

  gps_2: {
    name: 'GPS Max 2',
    shortName: 'GPS Max 2',
    description:
    "See & share your dogs' positions with your phone. Two GPS subscriptions and everything in WeHunt Premium are included.",
    buy: 'Buy GPS Max 2',
    autoRenew: '',
    upgrade: '',
    extend: 'Extend subscription',

    features: {
      maxGPS: 'Up to 2 GPS devices (sold separately)',
      worksWith:
      'Works with Tracker Artemis, Tracker Bark, Tracker G10i, Tracker Supra, Tracker G1000, Tracker G500FI'
    }
  },

  gps_3: {
    name: 'GPS Max 3',
    shortName: 'GPS Max 3',
    description:
    "See & share your dogs' positions with your phone. Three GPS subscriptions and everything in WeHunt Premium are included.",
    buy: 'Buy GPS Max 3',
    autoRenew: '',
    upgrade: '',
    extend: 'Extend subscription',

    features: {
      maxGPS: 'Up to 3 GPS devices (sold separately)',
      worksWith:
      'Works with Tracker Artemis, Tracker Bark, Tracker G10i, Tracker Supra, Tracker G1000, Tracker G500FI'
    }
  },

  gps_6: {
    name: 'GPS Max 6',
    shortName: 'GPS Max 6',
    description:
    "See & share your dogs' positions with your phone. Six GPS subscriptions and everything in WeHunt Premium are included.",
    buy: 'Buy GPS Max 6',
    autoRenew: '',
    upgrade: '',
    extend: 'Extend subscription',

    features: {
      maxGPS: 'Up to 6 GPS devices (sold separately)',
      worksWith:
      'Works with Tracker Artemis, Tracker Bark, Tracker G10i, Tracker Supra, Tracker G1000, Tracker G500FI'
    }
  }
}
