export default {
  createNew: "Créer un nouveau territoire de chasse",
  newArea: "Nouveau territoire de chasse",
  members: "Membres",
  area: "Territoire de chasse",
  areas: "Territoires de chasse",
  hunts: "Chasses",
  noAreasFound: "Vous n'avez pas encore créé de territoire  de chasse.",
  areaName: "Territoire de chasse/nom de l'équipe",
  name: "Nom",
  invalidName: "Vous devez saisir un nom."
}
