export default {

  add (key, value, callback) {
    if (value && value.constructor === Object) {
      window.localStorage.setItem(key, JSON.stringify(value))
    } else {
      window.localStorage.setItem(key, value)
    }

    if (callback) {
      callback()
    }
  },

  read (key) {
    return window.localStorage.getItem(key)
  },

  del (key, callback) {
    if (window.localStorage.getItem(key)) {
      window.localStorage.removeItem(key)
    }

    if (callback) {
      callback()
    }
  }

}
