const Colors = {
  boundary: '#6666FF',
  subArea: '#FF00FF',
  forbiddenArea: '#FF0000',
  trails: '#EB914E',
  lines: '#22a6b3',
  arrows: '#30336b',
  default: '#dce4e2'
}

export default Colors
