export default {

  isModalActive (state, bool) {
    state.isModalActive = bool
  },

  isPrinting (state, bool) {
    state.isPrinting = bool
  },

  isAdjusting (state, bool) {
    state.isAdjusting = bool
  },

  setPaperSize (state, size) {
    state.paperSize = size
  },

  setPaperOrientation (state, orientation) {
    state.paperOrientation = orientation
  }

}
