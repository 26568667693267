function getUniqueGamesFromReports (reports, sortingLanguage) {
  const games = []

  reports.forEach(report => {
    report.events.forEach(event => {
      event.games.forEach(game => {
        if (isValidGame(game)) {
          if (games[game.animalId] === undefined) {
            games[game.animalId] = {
              id: game.animalId,
              name: game.animalName,
              selected: true
            }
          }
        }
      })
    })
  })

  const array = flatten(games)
  return array.sort((a, b) => a.name.localeCompare(b.name, sortingLanguage, { sensitivity: 'base' }))
}

function isValidGame (game) {
  return game.animalId !== null && game.animalName !== null
}

function getYears (reports) {
  const years = []

  reports.forEach(report => {
    const year = report.startsAt.getFullYear()
    if (years[year] === undefined) {
      years[year] = year
    }
  })

  const array = flatten(years)
  return array.sort((a, b) => a - b)
}

function flatten (object) {
  const array = []
  for (const key in object) {
    array.push(object[key])
  }

  return array
}

export {
  getUniqueGamesFromReports,
  getYears,
  isValidGame
}
