<template>
  <form class="chat-form" @submit.prevent="sendMessage">
    <div class="field has-addons">
      <div class="control is-expanded">
        <TextInputField
          v-model="message"
          :placeholder="placeholder">
        </TextInputField>
      </div>

      <div class="control">
        <Button
          type="secondary"
          :loading="loading"
          @click="sendMessage">
          {{ $t('general.send') }}
        </Button>
      </div>
    </div>
  </form>
</template>

<script>
export default {
  props: {
    loading: {
      type: Boolean,
      default: false
    },

    placeholder: {
      type: String,
      default: ''
    }
  },

  data () {
    return {
      message: ''
    }
  },

  methods: {
    sendMessage () {
      if (this.message.length > 0) {
        this.$emit('send', this.message)
        this.message = ''
      }
    }
  }
}
</script>

<style lang="scss">
.chat-form {
  margin-top: auto; // Force to bottom if no messages
  border-top: 1px solid rgb(209, 216, 224);
  padding: 0.75rem;
  width: 100%;

  @media screen and (min-width: 769px) {
    padding: 1.5rem;
  }
}
</style>
