export default {
  invites: 'Inbjudningar',
  noInvites: 'Det finns inga inbjudningar.',
  showMoreInvites: ' | Visa ytterligare 1 inbjudning | Visa ytterligare {count} inbjudningar',
  ongoingHunts: 'Pågående',
  ongoingHunt: 'Jakt pågår',
  planningHunts: 'Under planering',
  noPlanningHunts: 'Det finns inga jakter under planering.',
  plannedHunts: 'Planerade',
  plannedHunt: 'Planerad jakt',
  noPlannedHunts: 'Det finns inga planerade jakter.',
  finishedHunts: 'Avslutade',
  noFinishedHunts: 'Det finns inga avslutade jakter.',
  saving: 'Sparar...',

  title: 'Jakt',
  hunt: 'Jakt',
  hunts: 'Jakter',
  noDateAvailable: 'Inget datum angivet',
  overview: 'Översikt',
  overviewHelpText: 'Här kan du dela med dig av information och praktiska detaljer kring jakten till deltagarna.',
  date: 'Datum',
  startDate: 'Datum',
  startTime: 'Klockslag',
  startsAt: 'Börjar',
  endsAt: 'Slutar',
  toHunt: 'Till jakt',

  invitation: 'Inbjudan',
  invitationToHunt: 'Inbjudan till jakt',
  invitationHelpText: 'Författa en inbjudan till deltagarna. Information som bör framgå är var och när ni ska samlas och övriga praktiska detaljer kring jakten.',

  huntableGames: {
    title: 'Jaktbart vilt',
    helpText: 'Ange vilket vilt som är lovligt under jakten.',
    noHuntableGamesSelected: 'Inget jaktbart vilt angivet.',
    add: 'Lägg till jaktbart vilt',
    species: 'Art',
    comment: 'Kommentar',
    mammals: 'Däggdjur',
    noMammalsAvailable: 'Inga däggdjur lovliga',
    birds: 'Fåglar',
    noBirdsAvailable: 'Inga fåglar lovliga',
    addFailedErrorMessage: 'Ett fel inträffade och det gick inte att lägga till viltet. Vänligen försök igen.',
    removeFailedErrorMessage: 'Ett fel inträffade och det gick inte att ta bort viltet. Vänligen försök igen.'
  },

  noDateSelected: 'Välj datum innan du kan välja lovligt vilt.',
  participant: 'Deltagare',
  participants: 'Deltagare',
  accepted: 'Accepterat',
  declined: 'Avböjt',
  waitingForReply: 'Inväntar svar',
  unknownParticipant: 'Okänd',
  addHuntLeader: 'Lägg till jaktledare',
  addTrackingHunter: 'Lägg till jägare som hanterar eftersök',
  removeParticipantFailedErrorMessage: 'Ett fel inträffade och det gick inte att ta bort medlemmen. Vänligen försök igen.',
  assign: 'Tilldela',

  assignments: {
    title: 'Pass- och rolltilldelning',
    helpText: 'Här bestämmer du vilka såtar som ska ingå i jakten och kan dela ut roller och pass till alla jägare.',
    addArea: 'Lägg till område',
    entireHuntArea: 'Hela jaktmarken',
    createDriveFailedErrorMessage: 'Ett fel inträffade och det gick inte att lägga till såten. Vänligen försök igen.',
    removeDrive: 'Är du säker på att du vill ta bort pass- och rolltilldelningen för såten <strong>{name}</strong>?',
    removeDriveErrorMessage: 'Ett fel inträffade och det gick inte att ta bort såten. Vänligen försök igen.',
    removeMarkerFailedErrorMessage: 'Ett fel inträffade och det gick inte att ta bort passtilldelningen. Vänligen försök igen.',
    stand: 'Pass',
    noAssignments: 'Ingen pass- eller rolltilldelning gjord.',
    showAllParticipants: 'Visa alla deltagare',
    hideDeclinedParticipants: 'Dölj deltagare som avböjt'
  },

  // Participants
  huntLeader: {
    title: 'Jaktledare',
    helpText: 'Välj den jaktdeltagare som är jaktledare under hela jakten.',
    errorMessage: 'Ett fel inträffade och det gick inte att välja jaktledare. Vänligen försök igen.'
  },

  trackingHunter: {
    title: 'Eftersöksjägare',
    helpText: 'Välj den jaktdeltagare som hanterar eftersök under jakten.',
    noneSelected: 'Ingen vald',
    errorMessage: 'Ett fel inträffade och det gick inte att välja eftersöksjägare. Vänligen försök igen.'
  },

  // Add participants modal dialog
  addParticipants: {
    title: 'Lägg till deltagare',
    helpText: 'Lägg till alla deltagare som ska ingå i jakten. Du kan lägga till jaktlagets medlemmar eller bjuda in gästjägare genom att söka på deras e-post.',
    searchPlaceholder: 'Namn eller e-post',
    allMembersIncludedInHunt: 'Alla medlemmar i jaktlaget ingår i jakten.',
    searchMatchCounterMessage: '{userCount} användare matchade din sökning.',
    noUserWithEmail: 'Vi hittar inget konto i WeHunt med denna e-postadress. Vill du ändå skicka en inbjudan?',
    addWithCount: 'Lägg till | Lägg till <strong>1</strong> deltagare | Lägg till <strong>{userCount}</strong> deltagare'
  },

  // Different roles during hunt on a drive
  roles: {
    dogHandler: 'Hundförare',
    standShooter: 'Passkytt',
    driveHunter: 'Drevkedja',
    unassigned: 'Ingen roll'
  },

  // Summary/confirm modal
  summary: {
    title: 'Skicka inbjudningar?',
    summaryHelpText: 'Alla deltagare kommer få en inbjudan via e-post och alla deltagare som redan har appen får även en notis.',
    inviteTitle: 'Inbjudan som skickas till deltagarna'
  },

  selectStand: 'Välj pass',
  assignNotification: 'Klicka på ett <strong>Pass</strong> eller <strong>Torn</strong> för att tilldela detta till <strong>{name}</strong>',
  reports: 'Rapporter',
  wannaInviteEmail: '<a href="mailto: {email}">{email}</a>&nbsp;har inget WeHunt konto.',
  hasNoAccount: 'Har inget konto i <strong>WeHunt</strong>',
  noMembersFound: 'Vi hittar inga fler medlemmar i jaktlaget.',
  noUsersFound: 'Hittade ingen användare.',
  isAlreadyAMember: 'Denna användare är redan en deltagare.',
  huntCreatedMessage: 'Jakten har skapats och inbjudningar har skickats till alla deltagare via e-post och appen.',

  chat: 'Meddelande',
  play: 'Spela upp',

  mandatoryFieldsDialogTitle: 'Ej fullständig',
  mandatoryFieldsDialogText: 'Din jaktplanering är inte fullständig. Vänligen fyll i alla fält markerade med *.',

  planHunt: 'Planera jakt',
  planHuntFailedErrorMessage: 'Ett fel inträffade och det gick inte att påbörja planering av en ny jakt. Vänligen försök igen.',
  details: "Detaljer",
  huntReport: 'Jaktrapport',

  settings: "Inställningar",
  terrainMap: 'Terrängkarta',
  propertyMap: 'Fastighetskarta',

  tower: 'Torn',

  events: {
    title: 'Händelser',
    helpText: 'Här kan du rapportera fällt vilt & observationer.',
    noEvents: 'Det finns inga händelser rapporterade.',
    locked: 'Du kan inte redigera händelsen då den är skapad av en annan jägare.',
    kill: 'Fällt vilt',
    killShort: 'Fällt',
    observation: 'Observation',
    observationShort: 'Obs',
    selectSpecies: 'Välj art',
    eventPanelHelpText: 'Klicka på kartan för att placera en händelse',
    createFailedErrorMessage: 'Ett fel inträffade och det gick inte att skapa händelsen. Vänligen försök igen.',
    updateFailedErrorMessage: 'Ett fel inträffade och det gick inte att uppdatera händelsen. Vänligen försök igen.',
    removeFailedErrorMessage: 'Ett fel inträffade och det gick inte att ta bort händelse. Vänligen försök igen.',
    removeConfirmMessage: 'Är du säker på att du vill ta bort <strong>{type}</strong> som gjordes <strong>{date}</strong>.'
  },

  // Chat
  sendMessageToParticipantsPlaceholder: 'Skicka meddelande till jaktdeltagare',

  // Playback
  hunters: 'Jägare',
  dogs: 'Hundar',
  noDogsInHunt: 'Inga hundar med en kompatibel GPS deltog i jakten.',
  trailLength: 'Spårlängd',
  noEventsYet: 'Inga händelser har skett än.',
  noMessagesYet: 'Inga meddelanden har skickats än.',

  showPanelText: 'Visa deltagare, meddelanden och händelser',
  hidePanelText: 'Dölj alla',

  gameTitle: 'Viltslag',

  invite: {
    acceptInvite: 'Acceptera inbjudan',
    accept: 'Acceptera',
    declineInvite: 'Avböj inbjudan',
    decline: 'Avböj',
    acceptConfirmMessage: 'Är du säker på att du vill acceptera inbjudan till jakten på {huntArea} den {date}?',
    declineConfirmMessage: 'Är du säker på att du vill avböja inbjudan till jakten på {huntArea} den {date}?',
    respondToInviteFailedErrorMessage: 'Ett fel inträffade och det gick inte att besvara inbjudan. Vänligen försök igen.'
  },

  removeHuntFailedErrorMessage: 'Ett fel inträffade och det gick inte att ta bort jakten. Vänligen försök igen.',
  leave: 'Lämna jakt',
  confirmLeaveTitle: 'Lämna jakt?',
  confirmLeaveMessage: 'Är du säker på att du vill lämna jakten?'
}
