import ParticipantModel from '@/api/hunt/participants/participantModel'

export default {
  set (state, participants) {
    state.participants = participants
  },

  add (state, participant) {
    state.participants.push(participant)
  },

  delete (state, userId) {
    const i = state.participants.findIndex(obj => obj.id === userId)
    state.participants.splice(i, 1)
  },

  setAssignments (state, assignments) {
    if (assignments) {
      state.assignments = assignments

      assignments.forEach(assignment => {
        // Map assignment to correct subarea
        if (assignment.subArea) {
          for (let i = 0; i < state.subAreas.length; i++) {
            if (state.subAreas[i].id === assignment.subArea) {
              assignment.subArea = state.subAreas[i]
              break
            }
          }
        }

        // Add all participants
        state.participants.forEach(participant => {
          assignment.participants.push(new ParticipantModel(participant))
        })
      })
    } else {
      state.assignments = null
    }
  },

  addAssignment (state, assignment) {
    if (state.assignments === null) {
      state.assignments = []
    }

    if (state.participants) {
      state.participants.forEach(participant => {
        assignment.participants.push(new ParticipantModel(participant))
      })
    }

    state.assignments.push(assignment)
  },

  removeAssignment (state, assignment) {
    if (this.selectedAssignment === assignment) {
      this.selectedAssignment = null
    }

    // Detach markers from participants
    assignment.participants.forEach(participant => {
      if (participant.pass) {
        participant.pass.user = null
      }
    })

    const i = state.assignments.indexOf(assignment)
    if (i !== -1) {
      state.assignments.splice(i, 1)
    }
  },

  setSelectedAssignment (state, assignment) {
    state.markers.forEach(marker => {
      marker.user = null
    })

    if (assignment) {
      assignment.participants.forEach(participant => {
        if (participant.pass) {
          participant.pass.user = participant
        }
      })
    }

    state.selectedAssignment = assignment
    state.selectedUser = null
  },

  setAssignmentStand (state, data) {
    const { participant, marker } = data

    participant.pass = marker
    marker.user = participant
  },

  removeAssignmentStand (state, participant) {
    participant.pass.user = null
    participant.pass = null
  },

  setAssignmentUser (state, user) {
    state.assignmentUser = user
  },

  addParticipantToAssignments (state, participant) {
    state.assignments.forEach(assignment => {
      assignment.participants.push(new ParticipantModel(participant))
    })
  },

  removeParticipantFromAssignments (state, userId) {
    if (state.assignments) {
      state.assignments.forEach(assignment => {
        for (let i = 0; i < assignment.participants.length; i++) {
          const participant = assignment.participants[i]

          if (participant.id === userId) {
            assignment.participants.splice(i, 1)
            break
          }
        }
      })
    }
  }
}
