import Vue from "vue"
import VueI18n from "vue-i18n"
import flatpickr from "flatpickr"
import dayjs from "dayjs"
import DOMPurify from "dompurify"

// All strings/messages in the UI
import translations from "@/translations/translations.js"

// Localization for FlatPickr
import { english } from 'flatpickr/dist/l10n/default.js'
import { Swedish } from 'flatpickr/dist/l10n/sv.js'
import { Danish } from 'flatpickr/dist/l10n/da.js'
import { Norwegian } from 'flatpickr/dist/l10n/no.js'
import { French } from 'flatpickr/dist/l10n/fr.js'

// Localization for dayjs
import 'dayjs/locale/sv'
import 'dayjs/locale/en'
import 'dayjs/locale/da'
import 'dayjs/locale/nb'
import 'dayjs/locale/fr'

// All languages currently supported
const supportedLanguages = [
  { locale: "sv", name: "Svenska", icon: "/img/flags/sweden.svg" },
  // { locale: 'nb', name: 'Norskt Bokmål', icon: '/img/flags/norway.svg' },
  // { locale: 'da', name: 'Dansk', icon: '/img/flags/denmark.svg' },
  { locale: 'en', name: 'English', icon: '/img/flags/uk.svg' },
  { locale: 'fr', name: 'Français', icon: '/img/flags/france.svg' }
]

// FlatPickr specific locales
const flatPickrLocales = {
  en: english,
  sv: Swedish,
  da: Danish,
  nb: Norwegian,
  fr: French
}

function isLanguageSupported (languageCode) {
  const i = supportedLanguages.findIndex(
    (language) => language.locale === languageCode
  )
  return i !== -1
}

function setCurrentLanguage (languageCode) {
  // All date conversions in output
  dayjs.locale(languageCode)

  // Language in date picker popup
  flatpickr.localize(flatPickrLocales[languageCode])

  // Language for all strings displayed
  i18n.locale = languageCode

  // Set <html lang="en" />
  document.documentElement.setAttribute("lang", languageCode)
}

function getDefaultLanguage () {
  const defaultLocale = navigator.language.split("-")[0]

  if (isLanguageSupported(defaultLocale)) {
    return defaultLocale
  }

  return "en"
}

const defaultLocale = getDefaultLanguage()

Vue.use(VueI18n)

const i18n = new VueI18n({
  locale: defaultLocale,
  fallbackLocale: "en",
  messages: translations
})

// Define the sanitized translate function
function saniT (key, values = {}) {
  const sanitizedValues = {}
  for (const [k, v] of Object.entries(values)) {
    sanitizedValues[k] = DOMPurify.sanitize(v)
  }

  // Use the original $t method with sanitized values
  // Ensure the correct context (`this`) is used for the original `t` method
  return this.origT(key, sanitizedValues)
}

// Store the original `t` method
const originalT = i18n.t.bind(i18n)
i18n.origT = originalT

// Override the `t` method on the i18n instance
i18n.t = saniT.bind(i18n)

// Override the $t method to use the sanitized version
Vue.prototype.$t = function (key, values = {}) {
  return saniT.call(this.$i18n, key, values)
}

// Alternatively, comment out the above overrides and use the sanitized version where needed:
i18n.sani_t = saniT.bind(i18n)

Vue.prototype.$sani_t = function (key, values = {}) {
  return saniT.call(this.$i18n, key, values)
}

// Setup the default language based on the browser. The user can later change this in one of the date pickers.
setCurrentLanguage(defaultLocale)

function getCountryCode (languageCode) {
  const countryCodes = {
    sv: 'se',
    en: 'gb',
    da: 'dk',
    nb: 'no',
    fr: 'fr'
  }

  return countryCodes[languageCode]
}

export {
  i18n,
  supportedLanguages,
  isLanguageSupported,
  getDefaultLanguage,
  setCurrentLanguage,
  getCountryCode
}
