<template>
  <aside class="sidebar is-hidden-touch is-flex-desktop">
    <div>
      <RouterLink to="/" class="logotype">
        <Logo color="white" />
      </RouterLink>

      <Navigation />
    </div>

    <Copyright />

    <HuntAreasPanel />
    <GpsPanel />
  </aside>
</template>

<script>
import Navigation from './components/navigation/Navigation.vue'
import Copyright from './components/Copyright.vue'

import HuntAreasPanel from './components/panels/huntAreasPanel/HuntAreasPanel.vue'
import GpsPanel from './components/panels/gpsPanel/GpsPanel.vue'

export default {
  components: {
    Navigation,
    Copyright,

    HuntAreasPanel,
    GpsPanel
  }
}
</script>

<style lang="scss">
.sidebar {
  position: relative;
  z-index: 5;

  height: 100vh;
  width: 13.5rem;
  min-width: 13.5rem;
  padding: 0;
  overflow-y: auto;
  overflow-x: hidden;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: unset;

  box-shadow: 0 0.5rem 1rem -0.125rem rgba(40, 40, 40, 0.05),
    0 0 0 1px rgba(60, 60, 60, 0.15);
  /* background: linear-gradient(25deg, #28393d, #42555b); */
  /* background: -ms-linear-gradient(25deg, #28393d, #42555b); */ /* IE fallback */
  background-color: #2e4448;

  .map-is-fullscreen & {
    display: none;
  }

  .logotype {
    user-select: none;
    width: 140px;
    margin: 1.5rem;
    display: block;

    img {
      vertical-align: middle;
    }
  }
}

.sidebar-panel {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 13.5rem;
  z-index: 30;

  background: white;
  width: 22.5rem;
  display: block;
  overflow: auto;
  visibility: hidden;
  opacity: 0;
  transform: translateX(-3.5rem);
  transition: all 0.15s ease-out;

  div:first-of-type:focus {
    outline: 0;
  }

  &.is-active {
    visibility: visible;
    opacity: 1;
    transform: translateX(0);
  }

  > .level {
    padding: 1.5rem 1.5rem 0 1.5rem;
  }
}

.sidebar-panel-background {
  position: absolute;
  z-index: 5;
  top: 0;
  right: 0;
  bottom: 0;
  left: 13.5rem;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
}
</style>
