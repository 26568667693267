<template>
  <h1 :class="classes">
    <slot>{{ text }}</slot>
  </h1>
</template>

<script>
export default {
  props: {
    type: {
      type: String,
      default: 'title'
    },

    size: {
      type: String,
      default: '1'
    },

    text: {
      type: String,
      default: ''
    }
  },

  computed: {
    classes () {
      return [
        this.type,
        'is-' + this.size
      ]
    }
  }
}
</script>

<style scoped>
h1 {
  margin-bottom: 0.75rem !important;
}
</style>
