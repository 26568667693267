export default {
  namespaced: true,

  state: {
    events: null,
    removedEvents: [],
    selectedEvent: null
  },

  getters: {
    getAll (state) {
      return state.events
    },

    getSelectedEvent (state) {
      return state.selectedEvent
    }
  },

  mutations: {
    add (state, event) {
      state.events.push(event)
    },

    update (state, event) {
      const i = state.events.findIndex(evt => evt.id === event.id)
      if (i !== -1) {
        state.events.splice(i, 1, event)
      }
    },

    remove (state, event) {
      const i = state.events.indexOf(event)

      if (i !== -1) {
        state.events.splice(i, 1)
      }

      if (event.id !== null) {
        state.removedEvents.push(event)
      }
    },

    set (state, events) {
      state.events = events
      state.removedEvents = []
    },

    setRemovedEvents (state, removedEvents) {
      state.removedEvents = removedEvents
    },

    selectEvent (state, event) {
      state.selectedEvent = event
    },

    removeParticipant (state, participant) {
      state.events.forEach(event => {
        if (event.reportedBy !== null) {
          const idMatch = participant.id !== null ? event.reportedBy.id === participant.id : false
          const emailMatch = participant.email !== null ? event.reportedBy.email === participant.email : false

          if (idMatch || emailMatch) {
            event.reportedBy = null
          }
        }
      })
    }
  }
}
