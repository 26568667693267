export default {
  firstName: 'Förnamn',
  lastName: 'Efternamn',
  repeatPassword: 'Repetera lösenord',
  forgotPassword: 'Glömt lösenord?',

  signin: {
    title: 'Logga in',
    remember: 'Kom ihåg',
    error: 'Det gick tyvärr inte att logga in. Vänligen försök igen.',
    answerInvite: 'Vänligen logga in för att besvara inbjudan.',
    oldSiteDividerTitle: 'Eller',
    oldSiteSignIn: 'Logga in på gamla webben'
  },

  join: {
    title: 'Registrera',
    invalidName: 'Du måste ange ett namn',
    invalidLastName: 'Du måste ange ett efternamn',
    repeatPassword: 'Upprepa lösenord',
    invalidEmail: 'Du måste ange en giltig E-postadress',
    emailOccupied: 'Denna e-post finns redan registrerad i vårt system.',
    passNoMatch: 'Dina lösenord är antingen för korta eller matchar ej.',
    welcomeToWeHunt: 'Välkommen till WeHunt <strong>{firstName}</strong>. Kul att du vill vara med på resan!',
    downloadAppReminder: 'Glöm inte att ladda ner appen till din telefon från Appstore eller Google Play.',
    accountRequiredToAnswerInvite: 'Du måste registrera ett <strong>kostnadsfritt</strong> WeHunt-konto för att kunna besvara inbjudan.',
    signInAndAnswerInvitation: 'Logga in och besvara inbjudan',
    unknownErrorMessage: 'Ett fel inträffade och det gick inte att registrera kontot. Vänligen försök igen.'
  },

  recover: {
    title: 'Återställ konto',
    helpText: 'Ange din e-postadress så skickar vi dig ett mail med instruktioner för hur du skapar ett nytt lösenord.',
    success: 'Ett e-post meddelande har skickats till dig med instruktioner för hur du återställer ditt lösenord.<br /><br /> Hittar du inte e-postmeddelandet, kontrollera att du angett rätt e-postadress och titta i foldern för skräppost.',
    recoverPasswordFailedErrorMessage: 'Ett fel inträffade och det gick inte att återställa ditt lösenord. Vänligen försök igen.',
    invalidEmail: 'Du måste ange en giltig e-postadress'
  },

  updatePassword: {
    title: 'Uppdatera lösenord',
    helpText: 'Skriv in ett nytt lösenord för {email}',
    updateButton: 'Uppdatera lösenord',
    invalidQueryError: 'Ogiltig länk',
    permissionError: 'Du har inte tillåtelse att uppdatera lösenordet för det här kontot.',
    success: 'Lösenordet har uppdaterats!'
  },

  signout: {
    title: 'Logga ut'
  }
}
