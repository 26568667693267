export default {
  start: 'Start',
  news: 'News',
  areas: 'Hunting Areas',
  hunts: 'Hunts',

  dashboard: 'Dashboard',
  calendar: 'Calendar',
  profile: 'Profile',
  viltrapport: 'Viltrapport'
}
