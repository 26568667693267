/* eslint-disable no-undef */
import areaSVG from '@/api/map/areaSvg'
import HuntAreaService from '@/api/huntarea/huntAreaService'

export default {
  async create ({ commit }, huntArea) {
    const createdHuntArea = await HuntAreaService.create(huntArea)

    commit('add', createdHuntArea)
    commit('select', createdHuntArea)

    return createdHuntArea
  },

  async readAll ({ commit, dispatch }) {
    commit('setHuntAreas', null)

    const huntAreas = await HuntAreaService.readAll()

    huntAreas.forEach(async (huntArea, i) => {
      huntArea.svg = areaSVG(huntArea.borderCoordinates)
    })

    commit('setHuntAreas', huntAreas)

    return huntAreas
  },

  async read ({ commit, dispatch, getters }, huntAreaId) {
    commit('members/set', null)
    commit('images/setImages', null)
    commit('documents/clear', null)
    commit('messages/setMessages', null)
    commit('gps/select', null, { root: true })

    dispatch('map/clear', null, { root: true })
    dispatch('dashboard/clear')

    let huntArea = getters.getAreaWithId(huntAreaId)
    if (huntArea === null) {
      huntArea = await HuntAreaService.read(huntAreaId) // Will happen on refresh when huntareas have not been loaded yet
    }

    commit('select', huntArea)

    dispatch('reports/read')
    dispatch('viltrapport/getStatus', huntAreaId)
  },

  async update ({ commit }, huntArea) {
    await HuntAreaService.update(huntArea)
    commit('update', huntArea)
  },

  async delete ({ commit }, huntArea) {
    await HuntAreaService.delete(huntArea.id)

    commit('delete', huntArea)
    commit('select', null)
  },

  select ({ commit, dispatch }, area) {
    commit('select', area)
  },

  clear ({ commit }) {
    commit('select', null)
    commit('setHuntAreas', null)
  },

  async leave ({ commit, dispatch, rootGetters }, huntArea) {
    const userId = rootGetters['auth/getUserId']

    await dispatch('members/delete', {
      huntAreaId: huntArea.id,
      userId: userId
    })

    commit('select', null)
    commit('delete', huntArea)

    commit('hunt/removeAllForHuntArea', huntArea.id, { root: true })
  },

  async getById ({ commit, getters }, huntAreaId) {
    // This method can be called by a guest hunter so we must NOT
    // add the huntArea to the cache because the user might not be allowed to
    // navigate to it.
    let huntArea = getters.getAreaWithId(huntAreaId)

    if (huntArea === null) {
      huntArea = await HuntAreaService.read(huntAreaId)
    }

    return huntArea
  }
}
