import dayjs from 'dayjs'
import HuntReportEventModel from './huntReportEventModel'
import HuntReportParticipantModel from './huntReportParticipantModel'

export const HUNT_REPORT_STATE_FINISHED = 'finished'

export default class HuntReportModel {
  constructor (params) {
    const data = Object.assign({}, params)

    this.id = data.id || null
    this.title = data.title || ''
    this.huntAreaId = data.huntAreaId || null
    this.huntId = data.huntId || null
    this.startsAt = data.startsAt !== undefined ? dayjs(data.startsAt).toDate() : null
    this.endsAt = data.endsAt !== undefined ? dayjs(data.endsAt).toDate() : null
    this.state = data.state || ''
    this.comment = data.comment || ''
    this.hours = data.hours !== undefined ? data.hours : 0

    this.createdAt = data.createdAt ? dayjs(data.createdAt).toDate() : null
    this.createdBy = data.createdBy || null
    this.updatedAt = data.updatedAt ? dayjs(data.updatedAt).toDate() : null

    this.deleted = data.deleted || false

    this.members = data.members ? data.members.map(member => new HuntReportParticipantModel(member)) : []
    this.events = data.events ? data.events.map(event => new HuntReportEventModel(event)) : []

    this.removedMembers = []
    this.removedEvents = []
  }

  isMultipleDays () {
    return this.startsAt.getDate() !== this.endsAt.getDate()
  }
}
