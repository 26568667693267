import api from '@/api/'
import LineModel from './lineModel'

class LineService {
  static async create (huntAreaId, line) {
    try {
      const { data } = await api.post(`/areas/${huntAreaId}/map/lines`, line.toJSON())
      return new LineModel(data)
    } catch (error) {
      console.error(`Failed to create line for area ${huntAreaId}.`)
      throw error
    }
  }

  static async read (huntAreaId) {
    try {
      const { data } = await api.get(`/areas/${huntAreaId}/map/lines`)
      return data.map(line => new LineModel(line))
    } catch (error) {
      console.error(`Failed to read lines for area ${huntAreaId}.`)
      throw error
    }
  }

  static async update (huntAreaId, line) {
    try {
      const { data } = await api.put(`/areas/${huntAreaId}/map/lines/${line.id}`, line.toJSON())
      return new LineModel(data)
    } catch (error) {
      console.error(`Failed to update line ${line.id}.`)
      throw error
    }
  }

  static async delete (huntAreaId, lineId) {
    try {
      await api.delete(`/areas/${huntAreaId}/map/lines/${lineId}`)
    } catch (error) {
      console.error(`Failed to delete line ${lineId}.`)
      throw error
    }
  }
}

export default LineService
