<template>
  <div :class="className">
    <div
      v-for="i in lines"
      :key="i"
      :class="`${className}-line`" />
  </div>
</template>

<script>
export default {

  props: {
    lines: {
      type: Number,
      default: 4
    }
  },

  data () {
    return {
      className: 'content-placeholders-text'
    }
  }

}
</script>
