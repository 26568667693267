export default {
  title: 'Viltrapport',
  quotas: 'Quotas',
  report: 'Report',
  showingQuotasForDistrict: 'Showing quotas for <strong>{name}</strong>.',
  district: 'District',

  restrictions: 'Restrictions',
  noRestrictionsSpecified: 'No general restrictions specified.',

  type: 'Type',
  allocated: 'Allocated',
  animalsLeft: 'Animals left',
  quotaFull: "All games downed",
  noLimitQuota: 'No restrictions',
  quotaExceeded: ' | Quota exceeded by 1 game | Quota exceeded by {count} games',
  noQuota: 'No game allowed for hunt.'
}
