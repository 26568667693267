<template>
  <div class="auth">
    <AuthAdvertisementBackground />
    <AuthAdvertisementLogo />

    <div class="wrapper">
      <LanguageDropdown />

      <section class="auth-form">
        <RouterView />
        <AuthAdvertisement />
      </section>
    </div>
  </div>
</template>

<script>
import LanguageDropdown from './LanguageDropdown.vue'

import AuthAdvertisement from './advertisement/AuthAdvertisement.vue'
import AuthAdvertisementLogo from './advertisement/AuthAdvertisementLogo.vue'
import AuthAdvertisementBackground from './advertisement/AuthAdvertisementBackground.vue'

export default {
  components: {
    LanguageDropdown,
    AuthAdvertisement,
    AuthAdvertisementLogo,
    AuthAdvertisementBackground
  }
}
</script>

<style lang="scss">
.auth {
  position: relative;
  width: 100vw;
  height: 100vh;
  overflow: hidden;

  @media screen and (max-width: 768px) {
    display: flex;
    flex-direction: column;
  }

  .wrapper {
    background: white;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    max-width: 640px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow-y: auto;
    box-shadow: -0.5rem 0 3rem 1.5rem rgba(40, 40, 40, 0.15),
      0 0 0 1px rgba(40, 40, 40, 0.02);

    // &.is-fullwidth {
    //   max-width: 100% !important;
    // }

    @media screen and (max-height: 500px) {
      padding-top: 0.75rem;
      justify-content: flex-start;
    }

    @media screen and (max-width: 1600px) {
      max-width: 480px;
    }

    @media screen and (max-width: 1400px) {
      max-width: 480px;
    }

    @media screen and (max-width: 768px) {
      position: relative;
      height: 100vh;
      max-width: 100%;
      padding: 2.5rem 0;
    }

    .auth-form {
      width: 80%;
      max-width: 400px;
    }

    header {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      width: 100%;
      margin: 0.875rem auto 1.675rem;

      img {
        max-width: 10rem;
        width: auto;
        height: auto;
      }

      h2 {
        font-size: 1.25rem;
        margin-top: 0.5rem;
        display: inline-block;
        color: #333;
      }
    }
  }
}

@keyframes authAnimation {
  0% {
    transform: translateX(100%);
  }

  to {
    transform: translateX(0);
  }
}
</style>
