export default {
  getPro: 'Skaffa Pro',

  selectLanguage: 'Välj språk',

  feedback: 'Feedback',
  leaveFeedback: 'Lämna feedback',

  games: 'Spel',
  elkGame: 'Älgbanan',
  boarGame: 'Vildsvinspasset',

  theme: "",
  themeContent: "",
  dark: ""
}
