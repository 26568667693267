<template>
  <div class="dialogs">
    <Dialog
      v-for="dialog in dialogs"
      :key="dialog.key"
      :isActive="dialog.isActive"
      :dialog="dialog"
      @close="close">
    </Dialog>
  </div>
</template>

<script>
import Dialog from './Dialog.vue'
import router from '@/router/index.js'
import store from '@/store/index.js'
import { i18n } from '@/i18n.js'

export default {
  router,
  store,
  i18n,

  components: {
    Dialog
  },

  data () {
    return {
      dialogs: []
    }
  },

  methods: {
    create (options) {
      this.$mount()
      document.body.appendChild(this.$el)

      return new Promise(resolve => {
        if (!options.title) options['title'] = ''
        if (!options.cancel && options.type !== 'alert') options['cancel'] = i18n.t('general.cancel')
        if (!options.ok) options['ok'] = i18n.t('general.ok')
        if (!options.message) options['message'] = ''

        const dialog = Object.assign(options, {
          key: `${Math.random(0x7FFFFFFF).toString(18).slice(2, 9)}`,
          isActive: true,
          resolve
        })

        this.dialogs.push(dialog)
      })
    },

    delete (dialog) {
      let i = this.dialogs.indexOf(dialog)
      if (i >= 0) this.dialogs.splice(i, 1)
    },

    alert (options) {
      options['type'] = 'alert'
      return this.create(options)
    },

    confirm (options) {
      options['type'] = 'confirm'
      return this.create(options)
    },

    prompt (options) {
      options['type'] = 'prompt'
      return this.create(options)
    },

    close (ok, dialog) {
      dialog.isActive = false
      dialog.resolve({ ok, dialog })
      this.delete(dialog)
    }
  }
}
</script>
