import api from '@/api/'
import TimeZone from '@/assets/libs/timeZone'
import firebaseClient from '@/api/firebase/firebaseClient'

import HuntReportModel from '../models/huntReportModel'
import HuntReportParticipantModel from '../models/huntReportParticipantModel'
import HuntReportEventModel from '../models/huntReportEventModel'
import HuntReportEventGameModel from '../models/huntReportEventGameModel'

import ViltrapportService from '@/modules/huntArea/viltrapport/services/viltrapportService.js'
import HuntAreaService from '@/api/huntarea/huntAreaService'

export default class ReportService {
  constructor (credentials) {
    this.credentials = credentials
  }

  async getReports (huntArea) {
    try {
      const slave = await this.getSlave(huntArea)
      const database = await firebaseClient.getDatabase(slave)

      const reports = await database.getAll(`/huntReports/${huntArea.id}_LLGroup`, { convertTo: buildHuntReport })
      return reports.filter(report => report.deleted === false)
    } catch (error) {
      console.error(`Failed to read reports for huntarea ${huntArea.id}.`)
      throw error
    }
  }

  async getReport (huntArea, reportId) {
    try {
      const slave = await this.getSlave(huntArea)
      const database = await firebaseClient.getDatabase(slave)

      const report = await database.get(`/huntReports/${huntArea.id}_LLGroup/${reportId}`, { convertTo: buildHuntReport })
      return report
    } catch (error) {
      console.error('Failed to read report with id' + reportId + '.')
      throw error
    }
  }

  async getReportFromHunt (hunt) {
    try {
      const { data } = await api.get(`reports?huntAreaId=${hunt.huntAreaId}&huntId=${hunt.id}`)
      const huntReport = new HuntReportModel(data)

      // Map each event's reportedByMemberId to the correct member object
      for (let i = 0; i < data.events.length; i++) {
        const event = data.events[i]

        if (event.reportedByMemberId !== null) {
          const j = huntReport.members.findIndex(member => member.id === event.reportedByMemberId)
          if (j !== -1) {
            huntReport.events[i].reportedBy = huntReport.members[j]
          }
        }
      }

      return huntReport
    } catch (error) {
      console.error(`Failed to read report from hunt ${hunt.id}.`)
      throw error
    }
  }

  async saveReport (huntArea, report) {
    try {
      const slave = await this.getSlave(huntArea)
      const database = await firebaseClient.getDatabase(slave)

      const path = `/huntReports/${report.huntAreaId}_LLGroup`
      const huntReportRef = report.id !== null ? database.ref(`${path}/${report.id}`) : database.ref(path).push()

      const firebaseUpdate = {}
      this.buildFirebasReport(report, firebaseUpdate, huntReportRef, database)
      console.log(firebaseUpdate)

      await huntReportRef.update(firebaseUpdate)

      const viltrapport = new ViltrapportService(this.credentials)

      const promises = []

      report.events.forEach(event => {
        event.games.forEach(game => {
          const viltrapportReports = game.viltrapportReports.filter(report => report.selected && report.id === null)
          viltrapportReports.forEach(viltrapportReport => {
            viltrapportReport.externalId = game.viltrapportExternalId
            promises.push(viltrapport.createReport(report.huntAreaId, viltrapportReport))
          })
        })
      })

      await Promise.all(promises)

      if (report.id === null) {
        report.id = huntReportRef.key
        report.createdAt = new Date()
      }

      report.updatedAt = new Date()

      return report
    } catch (error) {
      console.error('Failed to create report.')
      throw error
    }
  }

  async deleteReport (huntArea, report) {
    try {
      const slave = await this.getSlave(huntArea)
      const database = await firebaseClient.getDatabase(slave)

      const huntReportRef = database.ref(`/huntReports/${report.huntAreaId}_LLGroup/${report.id}`)

      await huntReportRef.set({
        updated: database.getTimestamp(),
        deleted: true
      })
    } catch (error) {
      console.error('Failed to delete report ' + report.id + '.')
      console.error(error)

      throw error
    }
  }

  async downloadAsExcel (huntAreaId, language) {
    try {
      const request = {
        huntAreaId: huntAreaId,
        timeZone: TimeZone.getCurrent(),
        language: language
      }

      const config = {
        responseType: 'arraybuffer'
      }

      const { data } = await api.post('/reports/export', request, config)

      const blob = new Blob([data], { // eslint-disable-line
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      })

      return blob
    } catch (error) {
      console.error('Failed to download reports as Excel file.')
      console.error(error)
      throw error
    }
  }

  async getSlave (huntArea) {
    if (huntArea.firebaseConfig.huntReports === null) {
      await HuntAreaService.updateFirebaseConfig(huntArea)
    }

    return huntArea.firebaseConfig.huntReports
  }

  buildFirebasReport (report, firebaseUpdate, huntReportRef, database) {
    if (report.id === null) {
      firebaseUpdate.huntAreaId = report.huntAreaId + '_LLGroup'
      firebaseUpdate.huntId = report.huntId ? report.huntId + '_LLGroup' : null
      firebaseUpdate.createdBy = report.createdBy + '_LLUser'
      firebaseUpdate.created = database.getTimestamp()
    }

    firebaseUpdate.customTitle = report.title && report.title.length > 0 ? report.title : null
    firebaseUpdate.state = report.state
    firebaseUpdate.startsAt = this.dateToTimestamp(report.startsAt)
    firebaseUpdate.endsAt = this.dateToTimestamp(report.endsAt)
    firebaseUpdate.comment = report.comment.length > 0 ? report.comment : null
    firebaseUpdate.hours = report.hours
    firebaseUpdate.updated = database.getTimestamp()

    this.buildFirebaseMembers(firebaseUpdate, report.members, report.removedMembers, huntReportRef)
    this.buildFirebaseEvents(firebaseUpdate, report.events, report.removedEvents, huntReportRef)
  }

  buildFirebaseMembers (firebaseUpdate, members, removedMembers, huntReportRef) {
    members.forEach(member => {
      if (member.id === null) {
        member.id = huntReportRef.child('members').push().key
      }

      firebaseUpdate[`members/${member.id}/userId`] = member.userId ? member.userId + '_LLUser' : null
      firebaseUpdate[`members/${member.id}/name`] = member.name
    })

    removedMembers.forEach(member => {
      firebaseUpdate[`members/${member.id}`] = null
    })
  }

  buildFirebaseEvents (firebaseUpdate, events, removedEvents, huntReportRef) {
    events.forEach(event => {
      if (event.id === null) {
        event.id = huntReportRef.child('events').push().key
        firebaseUpdate[`events/${event.id}/createdBy`] = event.createdBy + '_LLUser' // Only set for new events or validation fails
      }

      firebaseUpdate[`events/${event.id}/eventType`] = event.type
      firebaseUpdate[`events/${event.id}/reportedByMemberId`] = event.reportedBy !== null ? event.reportedBy.id : null
      firebaseUpdate[`events/${event.id}/timestamp`] = this.dateToTimestamp(event.timestamp)
      firebaseUpdate[`events/${event.id}/useTimeInTimestamp`] = event.useTimeInTimestamp
      firebaseUpdate[`events/${event.id}/comment`] = event.comment.length > 0 ? event.comment : null
      firebaseUpdate[`events/${event.id}/poiId`] = event.poiId ? event.poiId + '_LLPOI' : null
      firebaseUpdate[`events/${event.id}/poiName`] = event.poiName
      firebaseUpdate[`events/${event.id}/poiType`] = event.poiType
      firebaseUpdate[`events/${event.id}/latitude`] = event.coordinate ? event.coordinate.lat : null
      firebaseUpdate[`events/${event.id}/longitude`] = event.coordinate ? event.coordinate.lng : null

      this.buildFirebaseGames(firebaseUpdate, event, huntReportRef)
    })

    removedEvents.forEach(event => {
      firebaseUpdate[`events/${event.id}`] = null
    })
  }

  buildFirebaseGames (firebaseUpdate, event, huntReportRef) {
    event.games.forEach(game => {
      if (game.id === null) {
        game.id = huntReportRef.child(`events/${event.id}/games`).push().key
        game.viltrapportExternalId = game.id
      }

      firebaseUpdate[`events/${event.id}/games/${game.id}/animalId`] = game.animalId
      firebaseUpdate[`events/${event.id}/games/${game.id}/animalName`] = game.animalName
      firebaseUpdate[`events/${event.id}/games/${game.id}/count`] = game.count
      firebaseUpdate[`events/${event.id}/games/${game.id}/viltrapportExternalId`] = game.id
    })

    event.removedGames.forEach(game => {
      firebaseUpdate[`events/${event.id}/games/${game.id}`] = null
    })
  }

  dateToTimestamp (date) {
    return date ? date.getTime() : null
  }
}

function buildHuntReport (key, object) {
  const report = new HuntReportModel({
    id: key,
    title: object.customTitle,
    huntAreaId: parseHcId(object.huntAreaId),
    huntId: parseHcId(object.huntId),
    startsAt: timestampToDate(object.startsAt),
    endsAt: timestampToDate(object.endsAt),
    state: object.state,
    comment: object.comment || '',
    hours: object.hours,
    createdBy: parseHcId(object.createdBy),
    createdAt: timestampToDate(object.created),
    updatedAt: timestampToDate(object.updated),
    deleted: object.deleted
  })

  const members = object.members || {} // Will be undefined if there are no members
  Object.keys(members).forEach(key => {
    report.members.push(buildHuntReportMember(key, members[key]))
  })

  const events = object.events || {} // Will be undefined if there are no events
  Object.keys(events).forEach(key => {
    report.events.push(buildHuntReportEvent(key, events[key], report.members))
  })

  return report
}

function buildHuntReportMember (key, object) {
  return new HuntReportParticipantModel({
    id: key,
    userId: parseHcId(object.userId),
    name: object.name
  })
}

function buildHuntReportEvent (key, object, members) {
  const event = new HuntReportEventModel({
    id: key,
    type: object.eventType,
    timestamp: timestampToDate(object.timestamp),
    useTimeInTimestamp: object.useTimeInTimestamp || false,
    comment: object.comment || '',
    poiId: parseHcId(object.poiId),
    poiName: object.poiName || null,
    poiType: object.poiType || null,
    createdBy: parseHcId(object.createdBy),
    coordinate: object.latitude && object.longitude ? { lat: object.latitude, lng: object.longitude } : null
  })

  if (object.reportedByMemberId !== null) {
    const i = members.findIndex(member => member.id === object.reportedByMemberId)
    if (i !== -1) {
      event.reportedBy = members[i]
    }
  }

  const games = object.games || {} // Will be undefined if there are no games
  Object.keys(games).forEach(key => {
    event.games.push(buildHuntReportEventGame(key, games[key]))
  })

  return event
}

function buildHuntReportEventGame (key, object) {
  const game = new HuntReportEventGameModel({
    id: key,
    animalId: object.animalId,
    animalName: object.animalName || null,
    count: object.count || 0,
    viltrapportExternalId: object.viltrapportExternalId || null
  })

  return game
}

function parseHcId (hcId) {
  return hcId ? hcId.substring(0, hcId.indexOf('_')) : null
}

function timestampToDate (timestamp) {
  return timestamp ? new Date(timestamp) : null
}
