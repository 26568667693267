export default {
  years: {
    all: 'Alla år',
    today: 'Idag'
  },

  events: {
    kills: 'Fällt',
    observations: 'Observerat',
    all: 'Alla'
  },

  game: {
    killed: 'Fällda vilt',
    observed: 'Observerade vilt',
    killedAndObserved: 'Fällda och observerade vilt',
    all: 'Alla vilt',
    select: 'Välj vilt',
    none: 'Det finns inget vilt'
  },

  time: {
    time: 'Tid (h)',
    total: 'Total tid',
    perGame: 'Tid per vilt'
  },

  units: {
    pcs: 'st',
    hours: 'h',
    minutes: 'min'
  },

  pro: {
    heatMap: {
      title: 'Heatmap',
      subtitle: 'Följ viltets rörelser på jaktmarken över tid.'
    },

    killed: 'Statistik på jaktmarkens fällda vilt.',
    observed: 'Statistik på jaktmarkens observerade vilt.',
    killedAndObserved: 'Överblicken på jaktmarkens alla rapporterade vilt, fällda och observerade.',
    manHours: 'Följ markens viltpopulation genom mantimmar.'
  },

  manHoursPerGame: 'Mantimmar per vilt'
}
