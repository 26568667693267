import api from '@/api'

import ActivityInviteModel from '../models/activityInviteModel'
import HuntAreaInviteModel from '../models/huntAreaInviteModel'
import HuntInviteModel from '../models/huntInviteModel'

class InviteService {
  static async getActivityInviteByToken (token) {
    try {
      const { data } = await api.get(`/invitations/calendar/${token}`)
      return new ActivityInviteModel(data)
    } catch (error) {
      console.error(`Failed to read activity invite for token ${token}.`, error)
      throw error
    }
  }

  static async getHuntInviteByToken (token) {
    try {
      const { data } = await api.get(`invitations/hunts/token/${token}`)
      return new HuntInviteModel(data)
    } catch (error) {
      console.error(`Failed to read hunt invite for token ${token}.`)
      throw error
    }
  }

  static async getHuntAreaInviteByToken (token) {
    try {
      const { data } = await api.get(`/invitations/huntarea/${token}`)
      return new HuntAreaInviteModel(data)
    } catch (error) {
      console.error(`Failed to read huntarea invite for token ${token}.`, error)
      throw error
    }
  }

  static async answerHuntInvite (accept, token) {
    try {
      await api.post(`/invitations/hunts/token/${token}`, { accept: accept })
    } catch (error) {
      console.error('Failed to answer hunt invite.', error)
      throw error
    }
  }

  static async answerActivityInvite (accept, token) {
    try {
      await api.post(`/invitations/calendar/${token}`, { accept: accept })
    } catch (error) {
      console.error('Failed to answer activity invite.', error)
      throw error
    }
  }

  static async acceptHuntAreaInvite (token, userId) {
    try {
      await api.post(`/invitations/huntarea/${token}`, { userId })
    } catch (error) {
      console.error(`Failed to accept huntarea invite for user ${userId}.`, error)
      throw error
    }
  }
}

export default InviteService
