import MarkerService from '@/api/map/markers/markerService'
import MarkerModel from '@/api/map/markers/markerModel'

export default {
  async create ({ commit, dispatch }, data) {
    const { huntAreaId, marker } = data

    dispatch('ui/cursorLoader', true, { root: true })
    dispatch('map/setDrawingMode', null, { root: true })

    try {
      const createdMarker = await MarkerService.create(huntAreaId, new MarkerModel(marker))
      commit('add', createdMarker)
      return createdMarker
    } finally {
      dispatch('ui/cursorLoader', false, { root: true })
    }
  },

  async read ({ commit }, huntAreaId) {
    commit('set', null)

    let markers = await MarkerService.read(huntAreaId)
    markers = markers.filter(marker => marker.type !== 'mammal') // Remove mammals for now
    commit('set', markers)

    return markers
  },

  async update ({ commit, dispatch }, data) {
    const { huntAreaId, marker } = data

    dispatch('ui/cursorLoader', true, { root: true })

    try {
      const updatedMarker = await MarkerService.update(huntAreaId, marker)
      commit('update', updatedMarker)
      return updatedMarker
    } finally {
      dispatch('ui/cursorLoader', false, { root: true })
    }
  },

  async delete ({ commit, dispatch }, data) {
    const { huntAreaId, marker } = data

    dispatch('ui/cursorLoader', true, { root: true })

    try {
      await MarkerService.delete(huntAreaId, marker)
      commit('delete', marker)
      return marker
    } finally {
      dispatch('ui/cursorLoader', false, { root: true })
    }
  },

  select ({ dispatch, commit }, marker) {
    dispatch('map/closeAllPanels', null, { root: true })
    commit('select', marker)
  },

  setSize ({ commit }, size) {
    commit('size', size)
  },

  clear ({ commit }) {
    commit('set', null)
  }
}
