<template>
  <Fragment>
    <RouterLink v-if="gps" :to="`/gps/${gps}/coverage`" class="sidebar-nav-label">
      {{ gps }}
    </RouterLink>

    <ul v-if="gps" class="sidebar-nav-list">
      <!--
      <li>
        <RouterLink :to="`/gps/${gps}/dashboard`">
          <Icon name="icon-layout" class="push-right-xxs" />
          <span>Översikt</span>
        </RouterLink>
      </li>
      -->

      <li>
        <RouterLink :to="`/gps/${gps}/coverage`">
          <Icon name="icon-bar-chart" class="push-right-xxs" />
          <span>{{ $t('gps.coverage') }}</span>
        </RouterLink>
      </li>
    </ul>
  </Fragment>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  data () {
    return {
      imei: this.$route.params.imei
    }
  },

  computed: {
    ...mapGetters({
      gps: 'gps/selected'
    })
  }
}
</script>
