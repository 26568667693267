import api from '@/api/'
import UserModel from '../models/userModel'

class UserService {
  static read (userId) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await api.get(`/users/${userId}`)
        resolve(new UserModel(data))
      } catch (error) {
        console.error(`Failed to get user with id ${userId}.`, error)
        reject(error)
      }
    })
  }

  static update (user) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await api.put(`/users/${user.id}`, user.toJSON())
        resolve(new UserModel(data))
      } catch (error) {
        console.error(`Failed to update user ${user.id}.`, error)
        reject(error)
      }
    })
  }

  static changeEmail (userId, email) {
    return new Promise(async (resolve, reject) => {
      try {
        await api.post(`/users/${userId}/email`, { email })
        resolve()
      } catch (error) {
        console.error(`Failed to update email for user ${userId}.`, error)
        reject(error)
      }
    })
  }

  static changePassword (userId, password) {
    return new Promise(async (resolve, reject) => {
      try {
        await api.post(`/users/${userId}/password`, { password })
        resolve()
      } catch (error) {
        console.error(`Failed to update password for user ${userId}.`, error)
        reject(error)
      }
    })
  }

  static getFullName (userId) {
    return new Promise(async (resolve, reject) => {
      try {
        const user = await UserService.read(userId)
        resolve(user.getFullName())
      } catch (error) {
        reject(error)
      }
    })
  }

  static getByName (name) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await api.get(`/users?name=${name}`)
        const users = []
        data.forEach(data => users.push(new UserModel(data)))
        resolve(users)
      } catch (error) {
        console.error(`Failed to get users matching name '${name}'.`, error)
        reject(error)
      }
    })
  }

  static getByEmail (email) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await api.get(`/users?email=${email}`)
        const users = []
        data.forEach(data => users.push(new UserModel(data)))
        resolve(users)
      } catch (error) {
        console.error(`Failed to get users matching email '${email}'.`, error)
        reject(error)
      }
    })
  }

  static async getFirebaseToken () {
    try {
      const { data } = await api.get('/users/firebase/token')
      return data.token
    } catch (error) {
      console.error('Failed to get firebase token', error)
      throw error
    }
  }
}

export default UserService
