import firebaseClient from '@/api/firebase/firebaseClient'
import ImageModel from '@/api/huntarea/images/imageModel'

export default {

  async read ({ commit }, huntAreaId) {
    commit('setImages', null)

    try {
      const images = []

      const messages = await firebaseClient.getSnapshot(`groups/${huntAreaId}_LLGroup/chatMessages`, { orderBy: 'timestamp' })
      messages.forEach(message => {
        if (message.imagePath) {
          images.push(new ImageModel(message))
        }
      })

      commit('setImages', images)
      return images
    } catch (error) {
      console.log('Failed to get images for huntarea ' + huntAreaId + '.')
      throw error
    }
  },

  async readMedia (store, path) {
    let response

    try {
      response = await firebaseClient.getMedia(path)
    } catch (error) {
      Promise.reject(error)
    }

    return Promise.resolve(response)
  }

}
