export default {
  zoomLevelNotSupportedNotification: 'Ett av de valda kartlagren stödjer inte den nuvarande zoom-nivån.<br/><strong>Zooma ut</strong> för att se alla valda kartlager.',
  colorPickerNotSupportedMessage: 'För att kunna ändra färg måste du uppgradera till en nyare webbläsare.',
  nameMissing: 'Du måste ange ett namn',
  invalidCoordinate: 'Felaktig koordinat',
  filter: {
    title: 'Filter',
    searchPlaceholder: 'Sök'
  },

  tools: {
    export: 'Ladda ner',
    import: 'Ladda upp',
    filter: 'Filter',
    print: 'Skriv ut'
  },

  general: {
    huntingGround: 'Hela jaktmarken',
    boundary: 'Gräns',
    boundaries: 'Gränser',
    marker: 'Kartnål',
    markers: 'Kartnålar',
    subArea: 'Såt',
    subAreas: 'Såtar',
    forbiddenArea: 'Förbjudet område',
    forbiddenAreas: 'Förbjudna områden',
    line: 'Linje',
    lines: 'Linjer',
    arrow: 'Pil',
    arrows: 'Pilar',
    trails: 'Spår',
    print: 'Skriv ut',
    hectare: 'Hektar',
    size: 'Storlek',
    labels: 'Etiketter',
    zoom: 'Zooma',
    note: 'Notering',
    type: 'Typ',
    export: 'Exportera',

    noNote: 'Ingen notering',

    printLoadingMessage: 'Vi förbereder nu din utskrift, detta kan ta några minuter. Vänligen vänta.',
    reallyRemoveOutsideBoundary: 'Är du säker på att du vill radera <strong>{total}</strong> kartnålar utanför gränsen?',

    hasActiveHunt: 'Jakt pågår',

    tools: {
      addMarker: 'Lägg till Kartnål',
      addSubArea: 'Lägg till Såt',
      addForbiddenArea: 'Lägg till Förbjudet område',
      editBoundary: 'Redigera gräns',
      drawLine: 'Rita Linje',
      drawArrow: 'Rita Pil'
    },

    createdAt: 'Skapades den {date} av {name}',
    fullscreen: 'Fullskärm',
    normal: 'Normal',
    zoomIn: 'Zooma in',
    zoomOut: 'Zooma ut',

    notification: {
      marker: '<b>Klicka på kartan</b> för att ange positionen för kartnålen. Ange sedan <b>namn</b> och <b>typ av kartnål</b> i panelen innan du sparar ändringarna.',
      subArea: 'Rita ut såtens yttre gräns på kartan genom att <b>peka och klicka med musen</b>. Börja gärna med att rita upp en ungefärlig gräns, du kan alltid finjustera den senare.',
      forbiddenArea: '<b>Rita ut områdets yttre gräns</b> på kartan genom att <b>peka och klicka med musen</b>. Börja gärna med att rita upp en ungefärlig gräns, du kan alltid finjustera den senare.',
      line: '<b>Klicka på kartan</b> för att starta en linje, klicka sedan igen för att välja en till punkt. <b>Dubbelklicka på den sista punkten.</b>',
      arrow: '<b>Klicka på kartan</b> för att starta en pil, klicka sedan igen för att välja en till punkt. <b>Dubbelklicka på den sista punkten.</b>'
    }
  },

  markers: {
    pass: 'Pass',
    tower: 'Torn',
    food_place: 'Foderplats',
    bait: 'Åtel',
    gathering_place: 'Samlingsplats',
    bridge: 'Bro',
    ford: 'Vadställe',
    salt_lick: 'Saltsten',
    bar: 'Vägbom',
    cam: 'Kamera',
    burrow: 'Gryt',
    parking: 'Parkering',
    other: 'Övrigt',
    custom: 'Anpassningsbar',

    after_search: 'Eftersök',
    observation: 'Observation',
    kill: 'Fällt vilt',
    poo: 'Spillning',
    tracks: 'Spår',

    hunter: '',
    hunter_safe: '',
    hunter_off: '',

    dog: '',
    dog_off: ''
  },

  marker: {
    customType: 'Symbol',
    customTypeMissing: 'Du måste ange en symbol',
    customColor: 'Färg'
  },

  print: {
    title: 'Skriv ut',
    size: 'Storlek',
    orientation: 'Orientation',
    portrait: 'Stående',
    landscape: 'Liggande',
    createButtonTitle: 'Skapa PDF'
  },

  settings: {
    title: 'Inställningar',
    mapType: 'Karttyp',
    standard: 'Standard',
    satellite: 'Satellit',
    hybrid: 'Hybrid',
    terrain: 'Terrängkartan',
    propertyMap: 'Fastighetskartan 2018',
    nordicPropertyBoundariesLayer: 'Nordisk fastighetskarta',
    labels: 'Etiketter',
    size: 'Storlek',
    zoom: 'Zoom',
    scrollToZoom: 'Zooma med scrollhjul',
    removeMarkersOutsideBorder: 'Radera kartnålar utanför gräns',
    removeAllMarkers: 'Radera alla kartnålar',
    removingMarkers: 'Raderar kartnålar',
    removeMarkersFailed: 'En ett flera kartnålar kunde inte tas bort.'
  },

  import: {
    title: 'Ladda upp',
    content: 'Ladda upp kartdata från extern <strong>GPS</strong>, eller nedladdad data från <strong>WeHunt</strong>.',
    readFailedErrorMesssage: 'Ett fel inträffade och det gick inte att läsa in filen. Vänligen försök igen.',
    importFailedErrorMessage: 'Ett eller flera objekt kunde inte laddas upp till jaktmarken.',
    importSucceededMessage: 'Alla objekten laddades upp till jaktmarken utan problem.',
    objectsWithEmptyNamesWarningMessage1: ' | Ett valt objekt har <em>inget angivet namn</em> och kan därför <em>inte sparas</em>. | {count} valda objekt har <em>inget angivet namn</em> och kan därför <em>inte sparas</em>.',
    objectsWithEmptyNamesWarningMessage2: ' | Gå tillbaka till listan och avmarkera objektet eller ge det ett namn. | Gå tillbaka till listan och avmarkera objekten eller ge objekten ett namn.',
    noSupportedDataMessage: 'Filen innehåller ingen data som kan läsas in av WeHunt.',
    selectFile: 'Välj fil',

    application: '<strong>Applikation</strong> {name}',
    createdBy: '<strong>Skapad av</strong> {name}',
    createdAt: '<strong>Skapad</strong> {date}',

    boundary: 'Jaktmarkens gräns',
    boundaries: 'Gränser',
    markers: 'Kartnålar',
    subAreas: 'Såtar',
    forbiddenAreas: 'Förbjudna Områden',

    confirmTitle: 'Ladda upp?',
    confirmMessage: 'Är du säker på att du vill ladda upp följande objekt till jaktmarken?',
    doImport: 'Ladda upp',
    importingData: 'Laddar upp data',

    boundaryPlural: ' | gräns | gränser',
    subAreaPlural: ' | såt | såtar',
    forbiddenAreaPlural: ' | förbjudet område | förbjudna områden',
    markerPlural: ' | kartnål | kartnålar'
  },

  export: {
    title: 'Ladda ner',
    error: 'Ett fel inträffade och det gick inte att spara filen. Vänligen försök igen.',
    content1: 'Ladda ner utvald data från kartan till en <b>GPX (GPS Exchange Format)</b> fil.',
    content2: 'Filen kan användas för att kopiera datan till en <b>extern GPS</b>, eller för att kopiera data till ett <b>nytt jaktområde i WeHunt</b>.</p>',
    subtitle: 'Vad vill du ladda ner?',
    noExport: 'Det finns ingen data i kartan att ladda ner.',
    download: 'Ladda ner'
  }
}
