export default {
  title: 'Profil',

  account: {
    title: 'Konto',

    information: {
      title: 'Information',
      helpText: '<p>För att du enkelt ska hittas vid inbjudan till jakt är det viktigt att ditt namn är rättstavat så att dina kamrater kan hitta dig.</p><p>Ditt telefonnummer visas aldrig som sökbar information i WeHunt. Ditt mobiltelefonnummer behövs för funktionen "Ring till" under pågående jakt med WeHunt.</p>',
      cellphoneNumber: 'Mobiltelefonnummer'
    },

    interests: {
      title: 'Intressen',
      helpText: 'Vad brinner du för? Fyll i informationen för att vi inte ska prata om sådant som inte intresserar dig!',

      dog: 'Hundar',
      fishing: 'Fiske',
      huntingTrips: 'Jaktresor',
      outdoors: 'Friluftsliv',
      food: 'Matlagning',
      shooting: 'Skytte',
      secondHand: 'Andra hand',
      gadgets: 'Jakttillbehör',
      politics: 'Politik',
      conservation: 'Viltvård',
      education: 'Utbildning'
    },

    whoAreYou: {
      title: 'Om',
      helpText: '<p>För att kunna ge dig rätt information om våra erbjudanden vill vi veta vem du är. Detta är ett viktigt verktyg för att inte störa dig i onödan. Det ger oss också viktig information om våra användare, så att vi kan fortsätta utveckla WeHunt i rätt riktning.</p><p>Vi delar aldrig med oss av informationen till någon annan.</p>',
      born: 'När är du född?',
      roles: 'Roller',

      leader: 'Jaktledare',
      stand: 'Passkytt',
      stalker: 'Pyrschjägare',
      carrion: 'Åteljägare',
      handler: 'Hundförare',
      agent: 'Jaktförmedlare',
      trackingHunter: 'Eftersöksjägare',
      butcher: 'Slaktare',
      gunTech: 'Vapentekniker'
    },

    manage: {
      title: 'Hantera abonnemang',
      helpText: '',
      endSubscription: 'Avsluta abonnemang',
      endSubscriptionSurveyText: 'Jag svarar gärna på en enkät om varför jag väljer att avsluta min prenumeration',
      endSubscriptionHelpText: 'Är du säker på att du vill avsluta ditt abonnemang?'
    }
  },

  settings: {
    title: 'Inställningar',

    email: {
      title: 'E-post',
      helpText: 'Här kan du ändra din mailadress om du önskar. Om du inte lyckas ändra till önskad mailadress ber vi dig kontakta <a href="mailto:support@wehunt.se">support@wehunt.se</a> så hjälper vi dig gärna!',
      currentEmail: 'Nuvarande E-post',
      newEmail: 'Ny E-post',
      emailAddress: 'E-postadress',
      changeEmail: 'Byt E-post',
      busyEmail: 'Denna e-post finns redan registrerad i vårt system.',
      emailChangedMessage: 'Din e-postadress har ändrats till <strong>{email}</strong>.'
    },

    password: {
      title: 'Lösenord',
      noAccessToPassword: 'WeHunt Nordic AB har inte tillgång till ditt lösenord. Informationen är krypterad och låst till ditt personliga konto.',
      passwordIsUnique: 'Lösenordet i WeHunt är unikt för WeHunt och går inte att använda tillsammans med exempelvis “Mina sidor” hos Svenska Jägareförbundet.',
      passwordRequirements: 'Ditt lösenord måste bestå av minst sex bokstäver eller siffror och bör endast bestå av tecknen a-z och 0-9.',
      newPassword: 'Nytt lösenord',
      repeatPassword: 'Upprepa lösenord',
      changePassword: 'Byt lösenord',
      passwordChangedMessage: 'Ditt lösenord har ändrats.'
    },

    payment: {
      title: 'Betalning',
      helpText: 'All betalningshantering sker via Stripe. För mer information om Stripe, se faktureringshistorik eller ändra betalningsmetod, vänligen gå vidare till Stripe.',
      openStripe: 'Gå till stripe',
      getPortalSessionFailedErrorMessage: 'Ett fel inträffade och det gick inte att gå till betalningsportalen. Vänligen försök igen.'
    },

    notifications: {
      title: 'Notifikationer',
      helpText: 'Få en notis till din smartphone när vi eller någon av våra samarbetspartners publicerar en nyhet.',

      weHunt: 'WeHunt',
      jaktJournalen: 'Jaktjournalen',
      sjf: 'Svenska Jägareförbundet',
      svenskJakt: 'Svensk Jakt',
      njff: 'NJFF',
      dj: 'Danmarks Jaegarforbund',
      theGuardian: 'The Guardian',
      theField: 'The Field',
      shootingUK: 'Shooting UK'
    },

    newsletter: {
      title: 'Nyhetsbrev',
      helpText: 'Vi skickar regelbundet ut vårt populära nyhetsbrev med nyheter, reportage och tips om hur du kan använda WeHunt, före, under och efter jakten samt erbjudanden från oss och våra samarbetspartners.',
      helpText2: 'Vi lämnar aldrig ut din e-postadress eller några andra personuppgifter och du kan när som helst avanmäla dig från nyhetsbrevet.',
      subtitle: 'Vill du prenumerera på WeHunts nyhetsbrev?'
    }
  }
}
