export default [{
  path: '/news',
  name: 'News',
  component: () => import('./components/NewsPage.vue'),
  meta: { isPublic: true }
}, {
  path: '/news/:id',
  name: 'NewsItem',
  component: () => import('./components/NewsItemPage.vue'),
  meta: { isPublic: true }
}]
