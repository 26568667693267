import dayjs from 'dayjs'

export default class HuntAreaModel {
  constructor (context) {
    const data = Object.assign({}, context)

    this.id = data.id || null
    this.name = data.name || ''
    this.borderCoordinates = data.borderCoordinates || []
    this.createdAt = data.createdAt !== null ? dayjs(data.createdAt).toDate() : null
    this.createdBy = data.createdBy || null
    this.county = data.county || ''
    this.firebaseConfig = new HuntAreaFirebaseConfig(data.firebaseConfig)

    this.selected = data.selected || true
  }

  toJSON () {
    return {
      id: this.id,
      name: this.name,
      borderCoordinates: this.borderCoordinates,
      county: this.county.length > 0 ? this.county : null,
      createdBy: this.createdBy
    }
  }
}

export class HuntAreaFirebaseConfig {
  constructor (json) {
    const data = Object.assign({}, json)

    this.hunterLocations = data.hunterLocations || null
    this.gpsLocations = data.gpsLocations || null
    this.huntReports = data.huntReports || null
  }
}
