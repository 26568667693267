<template>
  <Portal to="modal">
    <div class="modal is-active">
      <div class="modal-background"></div>

      <div class="modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title">{{ title }}</p>

          <button
            v-if="closable"
            class="delete"
            aria-label="close"
            :disabled="!closeEnabled"
            @click="close">
          </button>
        </header>

        <section class="modal-card-body">
          <slot />
        </section>

        <footer class="modal-card-foot">
          <div class="flex justify-end" style="width: 100%;">
            <div class="buttons">
              <Button
                v-for="(button, i) in buttons"
                :key="i"
                :type="button.type"
                :disabled="button.disabled"
                :loading="button.loading"
                @click="button.click">
                <span v-html="button.title" />
              </Button>
            </div>
          </div>
        </footer>
      </div>
    </div>
  </Portal>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: ''
    },

    closable: {
      type: Boolean,
      default: true
    },

    closeEnabled: {
      type: Boolean,
      default: true
    },

    buttons: {
      type: Array,
      default: () => []
    }
  },

  methods: {
    close () {
      this.$emit('close')
    }
  }
}
</script>
