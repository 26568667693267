<template>
  <div id="map" class="map map-wrapper">
    <PrintOverlay v-if="print" />

    <div :id="id" :ref="id" class="map"></div>

    <slot />

    <MapTerrainLayer v-if="hasTerrainLayerFeature" />

    <MapPropertyBoundariesLayer
      v-if="showPropertyBoundaries && hasPropertyBoundariesLayerFeature" />

    <MapCastradalLayer
      v-if="showPropertyBoundaries && hasPropertyBoundariesLayerFeature" />

    <MapFIPropertyBoundariesLayer
      v-if="showPropertyBoundaries && hasPropertyBoundariesLayerFeature" />
  </div>
</template>

<script>
/* global google */
import { mapGetters } from "vuex"
import { MAP_MAX_ZOOM, MAP_MIN_ZOOM, MAP_DEFAULT_ZOOM } from "./map"
import {
  FEATURE_TERRAIN_LAYER,
  FEATURE_PROPERTY_BOUNDARIES_LAYER
} from "@/modules/subscription/store/subscriptionStore"
import mapStyles from "./mapStyles"

import MapTerrainLayer from "@/modules/map/components/layers/MapTerrainLayer.vue"
import MapPropertyBoundariesLayer from "@/modules/map/components/layers/MapPropertyBoundariesLayer.vue"
import MapCastradalLayer from "@/modules/map/components/layers/MapCastradalLayer.vue"
import MapFIPropertyBoundariesLayer from "@/modules/map/components/layers/MapFIPropertyBoundariesLayer.vue"
import { PropertyLayer } from "@/components/map/layers/propertyLayer"

import PrintOverlay from "@/components/map/print/PrintOverlay.vue"

export default {
  components: {
    PrintOverlay,
    MapTerrainLayer,
    MapPropertyBoundariesLayer,
    MapCastradalLayer,
    MapFIPropertyBoundariesLayer
  },

  props: {
    id: {
      type: String,
      default: ""
    },

    print: {
      type: Boolean,
      default: false
    },

    showPropertyBoundaries: {
      type: Boolean,
      default: false
    }
  },

  provide () {
    return {
      getMap: this.getMap
    }
  },

  data () {
    return {
      $map: null,
      promises: [],
      ignoreCenterUpdates: true,
      dragging: false,
      propertyLayer: null
    }
  },

  computed: {
    ...mapGetters({
      center: "map/getCenter",
      bounds: "map/getBounds",

      boundary: "map/boundary/boundary",

      zoom: "map/settings/zoom",
      mapType: "map/settings/mapType",
      propertyLayerVisible: "map/settings/isPropertyLayerVisible",
      scrollZoom: "map/settings/hasScrollZoom",
      hasFeature: "subscription/hasFeature"
    }),

    hasTerrainLayerFeature () {
      return this.hasFeature(FEATURE_TERRAIN_LAYER)
    },

    hasPropertyBoundariesLayerFeature () {
      return this.hasFeature(FEATURE_PROPERTY_BOUNDARIES_LAYER)
    }
  },

  watch: {
    bounds (bounds) {
      if (bounds !== null) {
        this.$map.fitBounds(bounds)
      }
    },

    zoom (level) {
      this.$map.setZoom(level)
    },

    mapType (type) {
      this.setMapType(type)
    },

    center (latLng) {
      if (latLng !== null) {
        this.$map.setCenter(latLng)
      }
    },

    propertyLayerVisible (visible) {
      this.togglePropertyLayer(visible)
    },

    scrollZoom (state) {
      this.$map.setOptions({ scrollwheel: state })
    }
  },

  mounted () {
    if (typeof google === "object" && typeof google.maps === "object") {
      this.initialize()
    } else {
      this.loadGoogleMaps()
    }
    this.createLayers()
    this.resolvePromises()
    this.setMapType(this.mapType)

    document.documentElement.classList.add("map-is-active")

    this.$emit("on-initialized", this.$map)
  },

  destroyed () {
    document.documentElement.classList.remove("map-is-active")
  },

  methods: {
    loadGoogleMaps () {
      // Add the Google Maps script dynamically
      const script = document.createElement("script")
      script.src = `https://maps.googleapis.com/maps/api/js?key=key=AIzaSyDzpjGAVIGzujydLuPxrkJsdLEevlKimhQ&libraries=drawing,geometry,visualization&callback=initGoogleMaps`
      script.async = true
      script.defer = true
      window.initGoogleMaps = this.initialize // Set the global callback function
      document.head.appendChild(script)
    },
    initialize () {
      const options = {
        zoom: MAP_DEFAULT_ZOOM,
        maxZoom: MAP_MAX_ZOOM,
        minZoom: MAP_MIN_ZOOM,
        disableDefaultUI: true,
        fullscreenControl: false,
        scaleControl: true,
        mapTypeControl: false,
        center: {
          lat: 59.7,
          lng: 14.5
        },
        scrollwheel: this.scrollZoom,
        styles: mapStyles
      }

      this.$map = new google.maps.Map(this.$refs[this.id], options)

      const bounds = this.$store.getters["map/getBounds"]
      if (bounds !== null) {
        this.$map.fitBounds(bounds)
      }

      this.$map.addListener("zoom_changed", () => {
        this.$store.commit("map/settings/setZoom", this.$map.getZoom())
        this.$store.commit("map/setCenter", this.$map.getCenter())
      })

      this.$map.addListener("dragend", () => {
        this.$store.commit("map/setCenter", this.$map.getCenter())
      })
    },

    resolvePromises () {
      this.promises.forEach((resolve) => resolve(this.$map))
    },

    getMap () {
      return new Promise((resolve) => {
        if (this.$map) {
          resolve(this.$map)
        } else {
          this.promises.push(resolve)
        }
      })
    },

    setMapType (mapType) {
      this.$map.setMapTypeId(mapType)
    },

    createLayers () {
      this.propertyLayer = new PropertyLayer(this.$map)

      this.togglePropertyLayer(this.propertyLayerVisible)
    },

    togglePropertyLayer (visible) {
      this.propertyLayer.setVisible(visible)

      if (visible) {
        document.documentElement.classList.add("map-has-property-layer")
      } else { document.documentElement.classList.remove("map-has-property-layer") }
    }
  }
}
</script>

<style lang="scss">
.map {
  height: 100vh;
  width: 100%;
}
</style>
