import dayjs from 'dayjs'

class UserSettingsModel {
  constructor (context) {
    const data = context || {
      roles: {},
      interests: {},
      settings: {
        notifications: {}
      }
    }

    this.userId = data.userId || null
    this.name = data.name || ''
    this.email = data.email || ''
    this.createdAt = data.createdAt ? dayjs(data.createdAt).toDate() : null
    this.discountedSubscription = data.discountedSubscription || false
    this.yearOfBirth = data.yearOfBirth || null
    this.sex = data.sex || null

    this.languageCode = data.languageCode || null
    this.countryCode = data.countryCode || null

    this.ambassador = data.ambassador || false

    this.membership = {
      dj: data.membership.dj,
      sjf: data.membership.sjf,
      njff: data.membership.njff
    }

    this.roles = {
      leader: data.roles.leader || false,
      stand: data.roles.stand || false,
      stalker: data.roles.stalker || false,
      carrion: data.roles.carrion || false,
      handler: data.roles.handler || false,
      agent: data.roles.agent || false,
      butcher: data.roles.butcher || false,
      gunTech: data.roles.gunTech || false,
      trackingHunter: data.roles.trackingHunter || false
    }

    this.interests = {
      dog: data.interests.dog || false,
      fishing: data.interests.fishing || false,
      huntingTrips: data.interests.huntingTrips || false,
      outdoors: data.interests.outdoors || false,
      food: data.interests.food || false,
      shooting: data.interests.shooting || false,
      secondHand: data.interests.secondHand || false,
      gadgets: data.interests.gadgets || false,
      politics: data.interests.politics || false,
      conservation: data.interests.conservation || false,
      education: data.interests.education || false
    }

    this.settings = {
      notifications: {
        jaktJournalen: data.settings.notifications.jaktJournalen || false,
        svenskJakt: data.settings.notifications.svenskJakt || false,
        weHunt: data.settings.notifications.weHunt || false,
        njff: data.settings.notifications.njff || false,
        sjf: data.settings.notifications.sjf || false,
        dj: data.settings.notifications.dj || false,
        theGuardian: data.settings.notifications.theGuardian || false,
        theField: data.settings.notifications.theField || false,
        shootingUK: data.settings.notifications.shootingUK || false
      },
      newsletter: data.settings.newsletter || false
    }
  }
}

export default UserSettingsModel
