<template>
  <td :colspan="colspan" @click="clicked">
    <slot />
  </td>
</template>

<script>
export default {
  props: {
    colspan: {
      type: String,
      default: ''
    }
  },

  methods: {
    clicked (event) {
      this.$emit('click', event)
    }
  }
}
</script>
