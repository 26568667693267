export default {
  upgrade: '? version ou verbe',
  upcoming: 'Vous avez un abonnement à venir',
  show: "Afficher l'abonnement",
  purchaseErrorMessage:
  "Une erreur s'est produite et l'achat n'a pas pu être finalisé. Veuillez réessayer.",

  purchase: {
    success: {
      title: 'Merci pour votre achat',
      text: 'Votre paiement est en cours de traitement et votre abonnement sera automatiquement activé dans un court instant.'
    }
  },

  price: {
    yearly: '{price} {currency}/an',
    ongoing: 'Par la suite, {price} {currency}/an'
  },

  willBeRefunded:
  'La période restante de votre abonnement en cours sera remboursée séparément.',

  requiresVersion63:
  "<strong></strong>REMARQUE ! Ne fonctionne PAS encore sous Android (mise à jour à paraître avant la saison de chasse). Fonctionne uniquement dans la dernière version de l'iPhone.</strong>",

  firstPayment: 'Le premier paiement est le {date}',
  nextPayment: 'Se renouvelle automatiquement le {date}',

  managedInAppStore: "Géré dans l'App Store d'Apple",
  managedInGooglePlay: 'Géré dans Google Play',
  buyInAppStore: "Acheter dans l'App Store d'Apple",
  buyInGooglePlay: 'Acheter sur Google Play',

  autoRenew: "Vous disposez d'un abonnement <strong>{subscription}</strong>",
  expires: '<strong>{abonnement}</strong> expire le <br /> {date}',

  mySubscription: 'Votre WeHunt',

  pro: {
    name: 'WeHunt Pro',
    shortName: 'Pro',
    autoRenew: "La solution qui augmente l'enthousiasme et la sécurité",
    upgrade: 'Passez à <strong>WeHunt Standard</strong> maintenant',
    extend: 'Acheter Standard'
  },

  standard: {
    name: 'WeHunt Standard',
    shortName: 'Standard',
    description: 'Voyez-vous mutuellement sur la carte pour une meilleure expérience de chasse',
    autoRenew: "La solution qui augmente l'enthousiasme et la sécurité",
    upgrade:
    "Pas d'inquiétude, passez aux abonnements continus et sécurisez votre WeHunt Standard pour chaque saison",
    buy: 'Acheter Standard',
    extend: 'Prolonger Standard',
    features: [
      'See location of hunters in real-time',
      'Hunting seasons',
      'Start hunts',
      'Scent cone']

  },

  premium: {
    name: 'WeHunt Premium',
    shortName: 'Premium',
    description: 'Voir Garmin, Tracker, UltraCom ou b-Bark',
    autoRenew:
    "L'abonnement qui offre toutes les fonctionnalités dont nous disposons dans l'application et sur le Web",
    upgrade:
    "Pas d'inquiétude, passez aux abonnements continus et sécurisez votre WeHunt Premium pour chaque saison.",
    buy: 'Acheter Premium',
    extend: 'Prolonger Premium',

    benefits: {
      title: 'Avantages Premium',
      description:
      "En tant qu'utilisateur Premium, vous bénéficiez d'une réduction de 20 % lorsque vous acheter sur : <br />- widforss.se <br />- widforss.no <br />- alaska1795.com (pas en Norvège)<br /> - chevalier .se"
    },

    features: {
      addThirdPartyGPS: 'Voir et partager le GPS Garmin',
      seeThirdPartyGPS: 'Voir Tracker, Ultracom, b-Bark GPS',
      terrainMap: 'Carte du relief avec les limites de propriété<br/>',
      benefits: 'Avantages Premium'
    }
  },

  gps: {
    name: 'WeHunt GPS',
    shortName: 'GPS',
    description:
    'Visualisez et partagez la position de votre chien avec votre téléphone. WeHunt dans son intégralité est inclus.',
    autoRenew:
    'Tout ce que WeHunt a à offrir est inclus dans votre abonnement',
    upgrade: '',
    buy: 'Activer un nouveau GPS',
    extend: "Prolonger l'abonnement",
    renew: "Renouveler l'abonnement",
    nextPayment: 'Se renouvelle automatiquement le {date}.',
    purchaseErrorMessage: 'Numéro IMEI saisi non valide. Veuillez réessayer.',
    activateHelpText: 'Activez votre WeHunt GPS Plus ou WeHunt GPS',
    activate: 'Activer',
    paymentFailed: "Paiement échoué! Prolongez l'abonnement.",
    cancelled: 'Abonnement annulé. Expire le {date}.',
    upgradeGpsSubscription: "Mettez à niveau votre abonnement pour utiliser plus d'appareils GPS",

    features: [
      'Your team can see your GPS',
      'Unlimited service in EU/EES',
      'Calling feature',
      'Create a GEO-fence'],

    price: {
      regularPrice: 99.5,
      percentOff: 0,
      finalPrice: 99.5,
      currency: 'EUR'
    }
  },

  gps_max: {
    name: 'GPS Max',
    shortName: 'GPS Max',
    description:
    'Visualisez et partagez les positions de vos chiens avec votre téléphone. WeHunt Premium dans son intégralité et deux abonnements GPS sont inclus.',
    buy: 'Acheter GPS Max',
    autoRenew: '',
    upgrade: '',
    extend: "Prolonger l'abonnement",

    features: {
      maxGPS: "Jusqu'à 1 appareil GPS (vendu séparément) | Jusqu'à {count} appareils GPS (vendus séparément)",
      worksWith:
      'Fonctionne avec Tracker Artemis, Tracker Bark, Tracker G10i, Tracker Supra, Tracker G1000, Tracker G500FI'
    },

    amountOfDevices: 'Amount of devices'
  },

  gps_1: {
    name: 'GPS Max 1',
    shortName: 'GPS Max 1',
    description:
    'Visualisez et partagez les positions de votre chien avec votre téléphone. WeHunt Premium dans son intégralité et un abonnement GPS sont inclus. ',
    buy: 'Acheter GPS Max 1',
    autoRenew: '',
    upgrade: '',
    extend: "Prolonger l'abonnement",

    features: {
      maxGPS: "Jusqu'à 1 appareil GPS (vendu séparément)",
      worksWith:
      'Fonctionne avec Tracker Artemis, Tracker Bark, Tracker G10i, Tracker Supra, Tracker G1000, Tracker G500FI'
    }
  },

  gps_2: {
    name: 'GPS Max 2',
    shortName: 'GPS Max 2',
    description:
    'Visualisez et partagez les positions de vos chiens avec votre téléphone. WeHunt Premium dans son intégralité et deux abonnements GPS sont inclus. ',
    buy: 'Acheter GPS Max 2',
    autoRenew: '',
    upgrade: '',
    extend: "Prolonger l'abonnement",

    features: {
      maxGPS: "Jusqu'à 2 appareils GPS (vendus séparément)",
      worksWith:
      'Fonctionne avec Tracker Artemis, Tracker Bark, Tracker G10i, Tracker Supra, Tracker G1000, Tracker G500FI'
    }
  },

  gps_3: {
    name: 'GPS Max 3',
    shortName: 'GPS Max 3',
    description:
    'Visualisez et partagez les positions de vos chiens avec votre téléphone. WeHunt Premium dans son intégralité et trois abonnements GPS sont inclus. ',
    buy: 'Acheter GPS Max 3',
    autoRenew: '',
    upgrade: '',
    extend: "Prolonger l'abonnement",

    features: {
      maxGPS: "Jusqu'à 3 appareils GPS (vendus séparément)",
      worksWith:
      'Fonctionne avec Tracker Artemis, Tracker Bark, Tracker G10i, Tracker Supra, Tracker G1000, Tracker G500FI'
    }
  },

  gps_6: {
    name: 'GPS Max 6',
    shortName: 'GPS Max 6',
    description:
    'Visualisez et partagez les positions de vos chiens avec votre téléphone. WeHunt Premium dans son intégralité et six abonnements GPS sont inclus. ',
    buy: 'Acheter GPS Max 6',
    autoRenew: '',
    upgrade: '',
    extend: "Prolonger l'abonnement",

    features: {
      maxGPS: "Jusqu'à 6 appareils GPS (vendus séparément)",
      worksWith:
      'Fonctionne avec Tracker Artemis, Tracker Bark, Tracker G10i, Tracker Supra, Tracker G1000, Tracker G500FI'
    }
  }
}
