<template>
  <Dialog
    :title="$t('huntArea.newHuntingGround')"
    :buttons="buttons"
    :close-enabled="!isSubmitting"
    @close="close">
    <form @submit.prevent="submit">
      <FormField>
        <TextInputField
          v-model="huntArea.name"
          :placeholder="$t('huntArea.huntingGroundNamePlaceholder')"
          :disabled="isSubmitting"
          @input="onNameChanged">
        </TextInputField>

        <HelpText v-if="hasError" type="danger" :text="errorMessage" />
      </FormField>
    </form>
  </Dialog>
</template>

<script>
import Dialog from '@/components/ui/dialog2/Dialog2.vue'
import HuntAreaModel from '@/api/huntarea/huntAreaModel.js'

export default {
  components: {
    Dialog
  },

  data () {
    return {
      huntArea: new HuntAreaModel(),
      status: '',
      noNameError: false
    }
  },

  computed: {
    buttons () {
      return [
        {
          title: this.$t('general.cancel'),
          disabled: this.isSubmitting,
          click: this.close
        },
        {
          title: this.$t('huntArea.createHuntingGround'),
          type: 'primary',
          loading: this.isSubmitting,
          click: this.submit
        }
      ]
    },

    errorMessage () {
      if (this.status === 'error') {
        return this.$t('huntArea.createHuntingGroundFailedErrorMessage')
      } else if (this.noNameError) {
        return this.$t('huntArea.noHuntingGroundNameErrorMessage')
      }

      return ''
    },

    isSubmitting () {
      return this.status === 'submitting'
    },

    hasError () {
      return this.status === 'error' || this.noNameError
    }
  },

  methods: {
    onNameChanged () {
      this.noNameError = false
    },

    async submit () {
      this.status = ''

      if (this.validate()) {
        this.status = 'submitting'

        try {
          const huntArea = await this.$store.dispatch('huntarea/create', this.huntArea)

          this.$router.push(`/area/${huntArea.id}/map`, () => {
            this.close()
          })
        } catch (error) {
          this.status = 'error'
        }
      }
    },

    validate () {
      this.noNameError = this.huntArea.name.length === 0
      return !this.noNameError
    },

    close () {
      this.$emit('close')
    }
  }
}
</script>
