export default {
  unsupportedBrowser: {
    updateRequired: 'Du behöver uppdatera din webbläsare.',
    readMore: 'Läs mer här',
    browserNotSupported: 'Din webbläsare stöds inte.',
    browserListHelpText: '<p>Tyvärr stödjs inte WeHunt av din webbläsare längre, det betyder att delar av WeHunt inte kommer att fungera/bete sig som vi tänkt.</p><p>Här är några webbläsare som bättre visar vad vi går för:</p>'
  },

  cookies: {
    information: 'Vi använder cookies för att försäkra dig om bästa möjliga upplevelse av vår webbplats.'
  }
}
