<template>
  <div class="buttons" :class="classes">
    <slot />
  </div>
</template>

<script>
export default {
  props: {
    segmented: {
      type: Boolean,
      default: false
    },

    alignment: {
      type: String,
      default: 'left'
    },

    size: {
      type: String,
      default: 'normal' // small, normal, large
    }
  },

  computed: {
    classes () {
      let classes = {}

      if (this.segmented) {
        classes['has-addons'] = true
      }

      if (this.alignment === 'center') {
        classes['is-centered'] = true
      } else if (this.alignment === 'right') {
        classes['is-right'] = true
      }

      // Size
      classes['are-' + this.size] = true

      return classes
    }
  }
}
</script>
