<template>
  <div class="chat-messages chat-messages-placeholder">
    <slot />
  </div>
</template>

<script>
export default {
  mounted () {
    this.$nextTick(this.scrollToBottom)
  },

  methods: {
    scrollToBottom () {
      const chat = this.$el

      if (chat !== null) {
        chat.scrollTop = chat.scrollHeight
      }
    }
  }
}
</script>
