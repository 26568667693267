import HuntAssignmentModel from './huntAssignmentModel'

class HuntDriveModel {
  constructor (data) {
    this.id = data.id || null
    this.subAreaId = data.subAreaId || null
    this.assignments = data.assignments !== undefined ? data.assignments.map(assignment => new HuntAssignmentModel(assignment)) : []
    this.expanded = false
    this.selected = false
    this.showDeclinedParticipants = false
  }
}

export default HuntDriveModel
