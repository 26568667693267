import FolderService from '@/modules/huntArea/documents/services/folderService'
import FolderModel, { FOLDER_STATUS_NONE, FOLDER_STATUS_LOADED, FOLDER_STATUS_ERROR } from '../models/folderModel'

import DocumentService from '@/modules/huntArea/documents/services/documentService'
import DocumentModel, { DOCUMENT_STATUS_UPLOADING } from '@/modules/huntArea/documents/models/documentModel'
import { DOCUMENT_STATUS_NONE } from '../models/documentModel'

export const DOCUMENT_VIEW_GRID = 'grid'
export const DOCUMENT_VIEW_LIST = 'list'

const DOCUMENT_SORT_ASCENDING = 'nameAZ'

export default {
  namespaced: true,

  state: {
    root: null,
    currentFolder: null,

    renameObject: null,

    search: '',
    view: window.localStorage.getItem('documentView') || DOCUMENT_VIEW_LIST,
    sortOrder: DOCUMENT_SORT_ASCENDING
  },

  getters: {
    getRootFolder: state => state.root,
    getCurrentFolder: state => state.currentFolder,

    getView: state => state.view,
    getSearch: state => state.search,
    getSortOrder: state => state.sortOrder,

    isRenaming: state => state.renameObject !== null,

    getFolders (state) {
      if (state.currentFolder.folders !== null) {
        const sort = state.sortOrder === DOCUMENT_SORT_ASCENDING ? sortAscending : sortDescending
        const folders = filterBySearch(state.currentFolder.folders, state.search)
        return folders.sort(sort)
      }

      return null
    },

    getDocuments (state) {
      if (state.currentFolder.documents !== null) {
        const sort = state.sortOrder === DOCUMENT_SORT_ASCENDING ? sortAscending : sortDescending
        const documents = filterBySearch(state.currentFolder.documents, state.search)
        return documents.sort(sort)
      }

      return null
    }
  },

  mutations: {
    clear (state) {
      state.root = new FolderModel({ id: 'root' })
      state.currentFolder = state.root
      state.search = ''
    },

    setCurrentFolder (state, folder) {
      state.currentFolder = folder
    },

    setView (state, view) {
      state.view = view
      window.localStorage.setItem('documentView', view)
    },

    setSearch (state, string) {
      state.search = string
    },

    setSortOrder (state, sortOrder) {
      state.sortOrder = sortOrder
    },

    startRename (state, object) {
      state.renameObject = object
      object.editing = true
    },

    cancelRename (state) {
      if (state.renameObject !== null) {
        state.renameObject.editing = false
        state.renameObject = null
      }
    }
  },

  actions: {
    setCurrentFolder ({ commit, dispatch }, folder) {
      commit('cancelRename')
      commit('setCurrentFolder', folder)

      dispatch('readFolder', folder)
    },

    async readFolder ({ dispatch }, folder) {
      if (folder.status === FOLDER_STATUS_NONE) {
        try {
          await dispatch('readFolders', folder)
          await dispatch('readDocuments', folder)

          folder.status = FOLDER_STATUS_LOADED
        } catch (error) {
          folder.status = FOLDER_STATUS_ERROR

          console.error('Failed to load documents or folders for folder ' + folder.id + '.')
          console.error(error)
        }
      }
    },

    async readFolders ({ rootGetters }, folder) {
      const huntArea = rootGetters['huntarea/selected']
      folder.setFolders(await FolderService.read(huntArea.id, folder.id))
    },

    async readDocuments ({ rootGetters }, folder) {
      const huntArea = rootGetters['huntarea/selected']
      folder.setDocuments(await DocumentService.read(huntArea.id, folder.id))
    },

    async createFolder ({ commit, getters, rootGetters }, name) {
      const huntArea = rootGetters['huntarea/selected']
      const currentFolder = getters.getCurrentFolder

      let folder = new FolderModel({
        name: name,
        folderId: currentFolder.id
      })

      folder = await FolderService.create(huntArea.id, folder)
      currentFolder.addFolder(folder)
    },

    async updateFolder ({ commit, rootGetters }, folder) {
      const huntArea = rootGetters['huntarea/selected']
      await FolderService.save(huntArea.id, folder)
    },

    async moveFolder ({ dispatch }, payload) {
      const { folder, destinationFolder } = payload

      const copy = new FolderModel(folder)
      copy.folderId = destinationFolder.id

      await dispatch('updateFolder', copy)

      folder.parent.removeFolder(folder)
      destinationFolder.addFolder(folder)
    },

    async deleteFolder ({ getters, rootGetters }, folder) {
      const huntArea = rootGetters['huntarea/selected']

      const currentFolder = getters.getCurrentFolder

      try {
        currentFolder.removeFolder(folder)
        await FolderService.delete(huntArea.id, folder)
      } catch (error) {
        currentFolder.addFolder(folder)
        throw error
      }
    },

    async createDocument ({ getters, rootGetters }, payload) {
      const { huntAreaId, file } = payload

      const userId = rootGetters['auth/getUserId']
      const currentFolder = getters.getCurrentFolder

      const document = new DocumentModel({
        name: file.name,
        folderId: currentFolder.id,
        fileSize: file.size,
        mimeType: file.type,
        createdAt: new Date(),
        createdBy: userId,
        status: DOCUMENT_STATUS_UPLOADING
      })

      currentFolder.addDocument(document)

      const createdDocument = await DocumentService.create(huntAreaId, currentFolder.id, file)

      document.id = createdDocument.id
      document.status = DOCUMENT_STATUS_NONE

      return document
    },

    updateDocument ({ rootGetters }, document) {
      const huntArea = rootGetters['huntarea/selected']
      return DocumentService.update(huntArea.id, document)
    },

    async moveDocument ({ dispatch }, payload) {
      const { document, destinationFolder } = payload

      const copy = new DocumentModel(document)
      copy.folderId = destinationFolder.id

      await dispatch('updateDocument', copy)

      document.parent.removeDocument(document)
      destinationFolder.addDocument(document)
    },

    async deleteDocument ({ getters, rootGetters }, document) {
      const huntArea = rootGetters['huntarea/selected']

      const currentFolder = getters.getCurrentFolder

      try {
        currentFolder.removeDocument(document)
        await DocumentService.delete(huntArea.id, document)
      } catch (error) {
        currentFolder.addDocument(document)
        throw error
      }
    },

    async downloadDocument ({ dispatch, rootGetters }, document) {
      const huntArea = rootGetters['huntarea/selected']

      dispatch('ui/cursorLoader', true, { root: true })

      try {
        const blob = await DocumentService.readContents(huntArea.id, document)

        if (window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveBlob(blob, document.name)
        } else {
          const a = window.document.createElement('a')

          a.setAttribute('href', window.URL.createObjectURL(blob))
          a.setAttribute('download', document.name)

          a.click()
        }
      } finally {
        dispatch('ui/cursorLoader', false, { root: true })
      }
    },

    async previewDocument ({ dispatch, rootGetters }, document) {
      if (!isPreviewSupported(document)) {
        return dispatch('downloadDocument', document)
      }

      const huntArea = rootGetters['huntarea/selected']

      dispatch('ui/cursorLoader', true, { root: true })

      try {
        const blob = await DocumentService.readContents(huntArea.id, document)

        if (window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveBlob(blob, document.name)
        } else {
          window.open(window.URL.createObjectURL(blob))
        }
      } finally {
        dispatch('ui/cursorLoader', false, { root: true })
      }
    },

    startRename ({ commit, getters }, object) {
      if (getters.isRenaming) {
        commit('cancelRename')
      }

      commit('startRename', object)
    }
  }
}

/********************/
/* Helper functions */
/********************/

function sortAscending (itemA, itemB) {
  return itemA.name.localeCompare(itemB.name)
}

function sortDescending (itemA, itemB) {
  return itemB.name.localeCompare(itemA.name)
}

function filterBySearch (array, search) {
  if (search.length > 0) {
    return array.filter(item => {
      return item.name.toLowerCase().indexOf(search.toLowerCase()) !== -1
    })
  }

  return array
}

function isPreviewSupported (document) {
  const fileTypes = [
    'jpg',
    'jpeg',
    'png',
    'gif',
    'pdf',
    'txt'
  ]

  return fileTypes.includes(document.type.toLowerCase())
}
