export default {
  duration: 0,
  elapsedTime: 0,
  speed: 10,

  status: 'stopped', //  stopped, started, paused
  participants: [],
  selectedParticipant: null,

  hunters: [],
  hunterLocations: [],
  hunterTrailLength: 600, // In seconds, default 10 min

  gpsDevices: [],
  gpsLocations: [],
  gpsTrailLength: 600 // In seconds, default 10 min
}
