<template>
  <div :class="{ 'is-active': active }" class="modal">
    <div class="modal-background"></div>

    <form class="modal-card" @submit.prevent="confirm">
      <div class="modal-card-head">
        <h2 class="modal-card-title">
          {{ title }}
        </h2>
        <button class="delete" aria-label="close" @click.prevent="abandon" />
      </div>

      <section class="modal-card-body">
        <slot />
      </section>

      <footer class="modal-card-foot buttons is-right">
        <button class="button" @click.prevent="abandon">
          {{ no }}
        </button>
        <button :disabled="disabled" class="button is-primary" @click.prevent="confirm">
          {{ yes }}
        </button>
      </footer>
    </form>
  </div>
</template>

<script>
export default {
  props: {
    active: {
      type: Boolean,
      default: false
    },

    title: {
      type: String,
      default: ''
    },

    yes: {
      type: String,
      default: 'Ja'
    },

    no: {
      type: String,
      default: 'Nej'
    },

    disabled: {
      type: Boolean,
      default: false
    }
  },

  methods: {
    confirm () {
      if (!this.disabled) {
        this.$emit('confirm', true)
      }
    },

    abandon () {
      this.$emit('confirm', false)
    }
  }
}
</script>

<style lang="scss" scoped>
.modal-content {
  width: 90%;
  max-width: 20rem;
  margin-top: -12rem;

  h1, h2, h3, h4, h5, h6 {
    margin-bottom: 0.5rem;
  }

  &.content {
    padding: 1rem;
  }

  .buttons {
    margin-top: 0.5rem;
  }
}
</style>
