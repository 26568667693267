import api from '@/api/'
import GameModel from './gameModel'
import HuntGameModel from './huntGameModel'
import HuntAreaService from '@/api/huntarea/huntAreaService'

class GameService {
  static async create (huntId, game) {
    try {
      const response = await api.post(`hunts/${huntId}/games`, game.toJSON())
      return new HuntGameModel(response.data)
    } catch (error) {
      console.error(`Failed to add game for hunt ${huntId}`)
      throw error
    }
  }

  static async read (huntId) {
    try {
      const response = await api.get(`hunts/${huntId}/games`)
      const games = response.data.map(game => new HuntGameModel(game))
      return games
    } catch (error) {
      console.error(`Failed to fetch games for hunt ${huntId}`)
      throw error
    }
  }

  static async update (huntId, game) {
    try {
      const response = await api.put(`hunts/${huntId}/games/${game.id}`, game.toJSON())
      return new HuntGameModel(response.data)
    } catch (error) {
      console.error(`Failed to update game for hunt ${huntId}`)
      throw error
    }
  }

  static async delete (huntId, game) {
    try {
      await api.delete(`hunts/${huntId}/games/${game.id}`)
    } catch (error) {
      console.error('Failed to delete game ' + game.id + ' from hunt ' + huntId + '.')
      throw error
    }
  }

  static async readAvailable (options) {
    let { countryCode, county, date } = options
    if (!countryCode) countryCode = 'se'

    let url = 'games?countryCode=' + countryCode + '&date=' + date
    if (county) url += ('&county=' + encodeURIComponent(county))

    try {
      const { data } = await api.get(url)
      const games = data.map(game => new GameModel(game))
      return games
    } catch (error) {
      console.error(`Failed to fetch available games for county ${county} at ${date}`, error)
      throw error
    }
  }

  static async readGamesForCountry (countryCode) {
    try {
      const { data } = await api.get('games?countryCode=' + countryCode)
      const games = data.map(game => new GameModel(game))
      return games
    } catch (error) {
      console.error(`Failed to fetch available games for country ${countryCode}.`)
      throw error
    }
  }

  static async readCounty (huntAreaId) {
    try {
      const { county } = await HuntAreaService.read(huntAreaId)
      return county
    } catch (error) {
      console.error(`Failed to get county for huntarea ${huntAreaId}`, error)
      throw error
    }
  }
}

export default GameService
