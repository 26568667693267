class ParticipantModel {
  constructor (context) {
    const data = Object.assign({}, context)

    this.id = data.id || null
    this.userId = data.userId || null
    this.firstName = data.firstName || ''
    this.lastName = data.lastName || ''
    this.email = data.email || ''
    this.role = data.role || ''
    this.status = data.status || ''
    this.pass = null
    this.name = this.getFullName()
  }

  getFullName () {
    return this.firstName.length > 0
      ? this.firstName + ' ' + this.lastName
      : this.email
  }

  isLoading () {
    return this.status === 'loading'
  }

  isAssignable () {
    return this.role === 'leader' || this.role === 'pass'
  }
}

export default ParticipantModel
