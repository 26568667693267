import api from '@/api/'
import TrailModel from './trailModel'

class TrailService {
  static async read (huntAreaId) {
    try {
      const { data } = await api.get(`/areas/${huntAreaId}/map/trails`)
      const trails = data.map(trail => new TrailModel(trail))

      const promises = []

      trails.forEach(trail => {
        const promise = api.get(`/areas/${huntAreaId}/map/trails/${trail.id}/coordinates`)
        promises.push(promise)

        promise.then(response => {
          trail.coordinates = response.data.coordinates
        })
      })

      await Promise.all(promises)

      // Some trails can for some reason be corrupt and have no length. These
      // will later on cause problems when rendering labels and when centering om them so just ignore these
      return trails.filter(trail => {
        return trail.coordinates.length > 0
      })
    } catch (error) {
      console.error(`Failed to read trails for area ${huntAreaId}.`)
      throw error
    }
  }

  static async update (huntAreaId, trail) {
    try {
      const { data } = await api.put(`/areas/${huntAreaId}/map/trails/${trail.id}`, trail.toJSON())
      return new TrailModel(data)
    } catch (error) {
      console.error(`Failed to update trail ${trail.id}.`)
      throw error
    }
  }

  static async delete (huntAreaId, trailId) {
    try {
      await api.delete(`/areas/${huntAreaId}/map/trails/${trailId}`)
    } catch (error) {
      console.error(`Failed to delete trail ${trailId}.`)
      throw error
    }
  }
}

export default TrailService
