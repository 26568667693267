<template>
  <div :class="[activeClass]" class="modal">
    <div class="modal-background" @click="close"></div>

    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">{{ $t('subscription.gps.buy') }}</p>
        <button class="delete" @click="close"></button>
      </header>

      <section class="modal-card-body">
        <p class="push-bottom-xs">{{ $t('subscription.gps.activateHelpText') }}</p>

        <form @submit.prevent="purchaseGps">
          <FormField>
            <TextInputField
              v-model="imei"
              :disabled="isLoading"
              placeholder="IMEI"
              maxlength="15">
            </TextInputField>
          </FormField>
        </form>

        <p v-if="errorMessage !== null" class="help is-danger push-top-xxs is-marginless-bottom">
          {{ errorMessage }}
        </p>
      </section>

      <footer class="modal-card-foot">
        <div class="flex justify-end">
          <button class="button" @click="close">
            {{ $t('general.cancel') }}
          </button>

          <button
            :class="{ 'is-loading': isLoading }"
            :disabled="!isValid"
            class="button is-primary"
            @click="purchaseGps">
            {{ $t('subscription.gps.activate') }}
          </button>
        </div>
      </footer>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      imei: '',
      isLoading: false,
      errorMessage: null
    }
  },

  computed: {
    isActive: {
      get () {
        return this.$store.getters['ui/isNewGpsModalActive']
      },
      set (state) {
        return this.$store.dispatch('ui/newGpsModal', state)
      }
    },

    activeClass () {
      return this.isActive ? 'is-active' : ''
    },

    isValid () {
      return this.imei && this.imei.length === 15
    }
  },

  methods: {
    close () {
      this.imei = ''
      this.isActive = false
      this.errorMessage = null
    },

    async purchaseGps () {
      if (!this.isValid) return

      this.errorMessage = null
      this.isLoading = true

      try {
        await this.$store.dispatch('subscription/purchaseGps', {
          imei: this.imei,
          returnPath: '/profile/subscription'
        })

        // No need to do anything on success since we'll get redirected to the Stripe checkout page.
      } catch (error) {
        this.errorMessage = error.message // Server returns translated error message
        this.isLoading = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.modal-card-foot {
  .flex {
    width: 100%;
  }
}
</style>
