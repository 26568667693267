import AssignmentService from '@/api/hunt/assignments/assignmentService'
import HuntDriveService from '@/api/hunt/drives/huntDriveService'

export default {

  async createDrive ({ dispatch, commit, rootGetters }, huntDrive) {
    const hunt = rootGetters['hunt/hunt']

    const result = await HuntDriveService.createDrive(hunt.id, huntDrive)
    commit('add', result)

    return result
  },

  create ({ dispatch, commit }, data) {
    const { huntId, assignment } = data

    return new Promise(async (resolve, reject) => {
      try {
        const $assignment = await AssignmentService.create(huntId, assignment)
        $assignment.subArea = assignment.subArea

        // WHY?
        // $assignment.subArea = await dispatch('hunt/map/getSubAreaWithId', assignment.subArea.id, { root: true })

        commit('add', $assignment)
        commit('setCurrentAssignmentArea', $assignment)

        resolve($assignment)
      } catch (error) {
        reject(error)
      }
    })
  },

  read ({ commit, dispatch }, huntId) {
    commit('set', null)

    return new Promise(async (resolve, reject) => {
      try {
        const assignments = await AssignmentService.read(huntId)
        commit('set', assignments)
        resolve(assignments)

        dispatch('hunt/assignments/stands/read', huntId, { root: true })
      } catch (error) {
        reject(error)
      }
    })
  },

  delete ({ commit, state }, data) {
    const { huntId, assignment } = data
    assignment.status = 'removing'

    return new Promise(async (resolve, reject) => {
      try {
        await AssignmentService.delete(huntId, assignment.id)
        commit('delete', assignment)
        resolve()
      } catch (error) {
        assignment.status = ''
        reject(error)
      }
    })
  }

}
