import LocationModel from './locationModel'

/**
 * GPS location data for a specific hunter.
 */
class HunterLocationModel extends LocationModel {
  constructor (data) {
    super(data)

    this.accuracy = data.accuracy
    this.isPublishing = data.isPublishing
    this.checkIn = null

    if (data.checkInPoIId) {
      this.checkIn = {
        id: data.checkInPoIId.substring(0, data.checkInPoIId.indexOf('_')),
        name: data.checkInPoIName,
        location: {
          lat: data.checkInLatitude,
          lng: data.checkInLongitude
        }
      }
    }
  }
}

export default HunterLocationModel
