import api from '@/api/'
import ChatMessageModel from './chatMessageModel'

class ChatService {
  static async readAllForHunt (huntId) {
    let response

    try {
      const { data } = await api.get(`hunts/${huntId}/chat`)
      const messages = data.map(message => new ChatMessageModel(message))
      response = messages
    } catch (error) {
      console.error(`Failed to read chat messages for hunt ${huntId}.`)
      Promise.reject(error)
    }

    return Promise.resolve(response)
  }

  static async sendMessage (huntId, text) {
    let response

    try {
      const { data } = await api.post(`hunts/${huntId}/chat`, { text })
      response = new ChatMessageModel(data)
    } catch (error) {
      console.error(`Failed to send chat message to hunt ${huntId}.`)
      Promise.reject(error)
    }

    return Promise.resolve(response)
  }
}

export default ChatService
