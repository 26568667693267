import firebaseClient from '@/api/firebase/firebaseClient'
import HunterLocationModel from '@/api/hunt/playback/hunterLocationModel'
import GpsLocationModel from '@/api/hunt/playback/gpsLocationModel'
import GpsDeviceModel from '@/api/hunt/playback/gpsDeviceModel'
import HuntService from '@/api/hunt/huntService'

export default {
  async loadHunterLocations ({ commit }, huntId) {
    commit('setHunterLocations', null)

    const hunt = await HuntService.read(huntId)

    const hunterLocations = await firebaseClient.getSnapshot('groups/' + huntId + '_LLGroup/hunterTrails', {
      orderBy: 'timestamp',
      convertTo: (data) => new HunterLocationModel(data)
    }, hunt.getHunterLocationsSlave())

    commit('setHunterLocations', hunterLocations)
  },

  async loadGpsLocations ({ commit, rootGetters }, huntId) {
    commit('setGpsLocations', null)
    commit('setGpsDevices', null)

    const hunt = await HuntService.read(huntId)

    let gpsLocations = []
    try {
      gpsLocations = await firebaseClient.getSnapshot('groups/' + huntId + '_LLGroup/gpsLocations', {
        orderBy: 'timestamp',
        convertTo: (data) => new GpsLocationModel(data)
      }, hunt.getGpsLocationsSlave())
    } catch (ex) {
      console.log(ex)
    }

    const userId = rootGetters['auth/getUserId']

    let privateGpsLocations = []
    try {
      privateGpsLocations = await firebaseClient.getSnapshot('users/' + userId + '_LLUser/private/gpsLocations/' + huntId + '_LLGroup', {
        orderBy: 'timestamp',
        convertTo: (data) => new GpsLocationModel(data)
      }, hunt.getGpsLocationsSlave())
    } catch (ex) {
      console.log(ex)
    }

    privateGpsLocations.forEach(privateGpsLocation => {
      if (gpsLocations.filter(e => (e.deviceId === privateGpsLocation.deviceId && e.timestamp === privateGpsLocation.timestamp)).length === 0) {
        gpsLocations.push(privateGpsLocation)
      }
    })
    gpsLocations.sort((a, b) => a.timestamp < b.timestamp ? -1 : 1)

    const gpsDevices = []

    gpsLocations.forEach(gpsLocation => {
      if (!getGpsDeviceById(gpsLocation.deviceId, gpsDevices)) {
        const gpsDevice = new GpsDeviceModel(gpsLocation.deviceId, gpsLocation.name)

        if (gpsDevice.name === '') {
          gpsDevice.name = getGpsDeviceName(gpsDevices)
        }

        gpsDevices.push(gpsDevice)
      }
    })

    commit('setGpsDevices', gpsDevices)
    commit('setGpsLocations', gpsLocations)
  },

  play ({ commit }) {
    commit('play')
  },

  pause ({ commit }) {
    commit('pause')
  },

  stop ({ commit }) {
    commit('stop')
  },

  status ({ commit }, status) {
    commit('status', status)
  },

  duration ({ commit }, duration) {
    commit('duration', duration)
  },

  elapsedTime ({ commit }, elapsedTime) {
    commit('elapsedTime', elapsedTime)
  },

  speed ({ commit }, speed) {
    commit('speed', speed)
  },

  selectParticipant ({ commit }, participant) {
    commit('selectParticipant', participant)

    if (participant !== null) {
      commit('map/setCenter', participant.location, { root: true })
    }
  }
}

function getGpsDeviceById (deviceId, gpsDevices) {
  const devices = gpsDevices || []

  for (let i = 0; i < devices.length; i++) {
    if (devices[i].id === deviceId) {
      return devices[i]
    }
  }

  return null
}

function getGpsDeviceName (gpsDevices) {
  const devices = gpsDevices || []

  const unnamedDevices = devices.filter(device => device.name === '')
  return 'GPS #' + (unnamedDevices.length + 1)
}
