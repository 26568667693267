export default {
  setMap (state, map) {
    state.map = map
  },

  setCenter (state, latLng) {
    state.center = latLng
  },

  setBounds (state, bounds) {
    state.bounds = bounds
  },

  setDrawingMode (state, drawingMode) {
    state.drawingMode = drawingMode
  },

  setImported (state, data) {
    state.imported = data
  },

  isMarkersModalActive (state, bool) {
    state.isMarkersModalActive = bool
  },

  isFilterActive (state, bool) {
    state.isFilterActive = bool
  },

  isPrintActive (state, bool) {
    state.isPrintActive = bool
  },

  isImportActive (state, bool) {
    state.isImportActive = bool
  },

  isExportActive (state, bool) {
    state.isExportActive = bool
  },

  setPropertyLayerTileLoadingProgress (state, progress) {
    state.propertyLayerTileLoadingProgress.status = progress.status
    state.propertyLayerTileLoadingProgress.tileCount = progress.tileCount
    state.propertyLayerTileLoadingProgress.loadedCount = progress.loadedCount
  }
}
