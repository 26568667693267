import Vue from 'vue'
import Router from 'vue-router'

import * as storage from '@/assets/libs/storage/'
import store from '@/store/'

Vue.use(Router)

class RouterController {
  constructor (routes) {
    this.router = this.initializeRouter(routes)
    this.beforeRouteChange()
    return this.router
  }

  initializeRouter (routes) {
    const router = new Router({
      linkExactActiveClass: 'is-active',
      mode: 'history',
      base: process.env.BASE_URL,
      routes
    })

    return router
  }

  beforeRouteChange () {
    this.router.beforeEach((to, from, next) => {
      if (to.meta.isPublic) {
        next()
      } else {
        if (storage.has('token') || store.getters['auth/hasAuth'] === true) {
          next()
        } else {
          if (!to.path.includes('signin')) {
            next({
              path: '/signin',
              query: {
                redirect: to.fullPath
              }
            })
          } else {
            next()
          }
        }
      }
    })
  }
}

export default RouterController
