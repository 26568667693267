class BoundaryModel {
  constructor (context) {
    const data = Object.assign({}, context)

    this.coordinates = data.coordinates || []
    this.visible = data.visible !== undefined ? data.visible : true
    this.selected = data.selected !== undefined ? data.selected : false
    this.editable = data.editable !== undefined ? data.editable : false
  }

  computeArea () {
    const latLng = this.coordinates.map(coord => new window.google.maps.LatLng(coord.lat, coord.lng))
    return window.google.maps.geometry.spherical.computeArea(latLng)
  }

  coords () {
    return this.coordinates
  }

  empty () {
    return this.coordinates.length === 0
  }

  toJSON () {
    return {
      coordinates: this.coordinates,
      visible: this.visible,
      selected: this.selected,
      editable: this.editable
    }
  }
}

export default BoundaryModel
