import markers from './../markers/'

export default {
  markers: state => state.markers, // array
  selected: state => state.selected, // object

  getSubAreas: state => state.subAreas,
  getForbiddenAreas: state => state.forbiddenAreas,

  showSubAreaLabels: state => state.showSubAreaLabels, // boolean
  showForbiddenLabels: state => state.showForbiddenLabels, // boolean

  getMarkersInSubArea: state => subArea => {
    const polygon = new window.google.maps.Polygon({ paths: subArea.borderCoordinates })

    return markers.state.markers.filter(marker => {
      const location = new window.google.maps.LatLng(
        marker.location.lat,
        marker.location.lng
      )

      return window.google.maps.geometry.poly.containsLocation(location, polygon)
    })
  }
}
