export default {

  setImages (state, images) {
    state.images = images
  },

  setCurrentImage (state, index) {
    state.currentImage = index
  }

}
