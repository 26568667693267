import ChatService from '@/api/hunt/chat/chatService'

export default {
  readAllForHunt ({ commit }, huntId) {
    commit('set', null)

    return new Promise(async (resolve, reject) => {
      try {
        const messages = await ChatService.readAllForHunt(huntId)
        commit('set', messages)
        resolve(messages)
      } catch (error) {
        reject(error)
      }
    })
  },

  sendMessage ({ commit }, { huntId, text }) {
    return new Promise(async (resolve, reject) => {
      try {
        const message = await ChatService.sendMessage(huntId, text)
        commit('add', message)
        resolve(message)
      } catch (error) {
        reject(error)
      }
    })
  }
}
