/**
 * Base class containing generic information about GPS location data.
 */
class LocationModel {
  constructor (data) {
    this.deviceId = data.deviceId || null
    this.name = data.name || ''
    this.location = { lat: data.latitude, lng: data.longitude }
    this.timestamp = data.timestamp
    this.visible = true // Must be set by child classes
  }
}

export default LocationModel
