export default {
  set (state, lines) {
    state.lines = lines
  },

  add (state, line) {
    state.lines.push(line)
  },

  delete (state, line) {
    const i = state.lines.indexOf(line)
    if (i !== -1) state.lines.splice(i, 1)
  },

  update (state, line) {
    const i = state.lines.findIndex(l => l.id === line.id)
    if (i !== -1) {
      state.lines.splice(i, 1, line)
    }
  },

  clear (state) {
    state.lines = null
  },

  select (state, line) {
    state.selected = line
  },

  showLineLabels (state, bool) {
    state.showLineLabels = bool
  },

  showArrowLabels (state, bool) {
    state.showArrowLabels = bool
  }
}
