export default {
  set (state, participants) {
    state.participants = participants
  },

  add (state, participant) {
    if (!state.participants) state.participants = []
    state.participants.push(participant)
  },

  update (state, member) {
    const i = state.participants.indexOf(member)
    state.participants[i].assign(member)
  },

  remove (state, member) {
    const i = state.participants.indexOf(member)
    state.participants.splice(i, 1)
  }
}
