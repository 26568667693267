export default [{
  name: 'gps',
  path: '/gps/:imei',
  component: () => import('./GpsModule.vue'),
  children: [{
    path: 'dashboard',
    component: () => import('./components/dashboard/GpsDashboardPage.vue')
  }, {
    path: 'coverage',
    component: () => import('./components/coverage/GpsCoveragePage.vue'),
    meta: { isMap: true }
  }]
}, {
  path: '/devices',
  name: 'GpsDevices',
  component: () => import('./components/devices/GpsDevicesPage.vue')
}]
