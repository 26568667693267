<template>
  <div :class="[activeClass]" class="panel is-marginless">
    <slot />
  </div>
</template>

<script>
export default {
  props: {
    isActive: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    activeClass () {
      return this.isActive ? 'is-active' : ''
    }
  }
}
</script>

<style lang="scss" scoped>
.panel {
  position: absolute;
  top: 1.5rem;
  left: 1.5rem;
  z-index: 25;
  display: flex;
  flex-direction: column;
  border-radius: 6px;
  background: white;
  max-height: calc(100% - 3rem);
  width: 20rem;
  font-size: 0.875rem !important;
  box-shadow: 0 0.5em 1em -0.125em rgba(40, 40, 40, 0), 0 0 0 1px rgba(60, 60, 60, 0);
  transition: all 0.15s ease-in-out;
  visibility: hidden;

  .ie & {
    overflow: auto; // IE doesn't like to overflow in panel-content, so we have to do it here instead
  }

  &.is-active {
    visibility: visible;
    box-shadow: 0 1rem 2rem 0 rgba(72, 84, 96, 0.11), 0 0.375rem 1rem rgba(72, 84, 96, 0.08) !important;
  }
}
</style>
