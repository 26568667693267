import dayjs from 'dayjs'

export const DOCUMENT_STATUS_NONE = ''
export const DOCUMENT_STATUS_UPLOADING = 'uploading'

class DocumentModel {
  constructor (context) {
    const data = Object.assign({}, context)

    this.id = data.id || null
    this.name = data.name || ''
    this.folderId = data.folderId || null

    this.fileSize = data.fileSize || null
    this.mimeType = data.mimeType || ''

    this.createdAt = data.createdAt ? dayjs(data.createdAt).toDate() : null
    this.createdBy = data.createdBy || null

    this.editing = data.editing !== undefined ? data.editing : false
    this.status = data.status || DOCUMENT_STATUS_NONE
    this.parent = data.parent || null
  }

  get type () {
    return this.name.split('.').pop()
  }

  get size () {
    const kb = this.fileSize / 1000

    if (kb > 1000) {
      return (kb / 1000).toFixed(2) + ' Mb'
    }

    return Math.floor(kb) + ' Kb'
  }

  setParent (folder) {
    this.parent = folder !== null ? folder : null
    this.folderId = folder !== null ? folder.id : null
  }

  toJSON () {
    return {
      name: this.name,
      folderId: this.folderId
    }
  }
}

export default DocumentModel
