// Original snippet: https://stackoverflow.com/a/3955258/9391254

// Basic usage:

// const label = new LabelOverlay({
//   position: position, // bounds.getCenter()
//   text: this.marker.name,
//   map: this.$map
//   class: 'subarea-label-overlay'
// })

/* global google */

class LabelOverlay extends window.google.maps.OverlayView {
  constructor (data) {
    super()
    Object.assign(this, data)
    this.div = null
    this.$size = data.size || 1
    this.$offset = data.offset || null

    this.setMap(this.map)
  }

  get size () {
    return this.$size
  }

  set size (size) {
    this.$size = size
    this.setFontSize()
  }

  set label (text) {
    this.text = text

    const span = this.div && this.div.querySelector('span')

    if (span) {
      span.textContent = text
    }
  }

  set offset (offset) {
    this.$offset = offset

    if (this.div) {
      this.div.style.marginTop = `${this.$offset}px`
    }
  }

  setPosition (position) {
    this.position = new google.maps.LatLng(position.lat, position.lng)
  }

  setFontSize () {
    if (this.div) {
      const sizes = {
        0.5: '8px',
        0.75: '9px',
        1: '10px',
        1.25: '11px',
        1.5: '12px',
        1.75: '13px',
        2: '14px',
        2.25: '15px',
        2.5: '16px',
        2.75: '17px',
        3: '18px',
        3.25: '19px',
        3.5: '20px',
        3.75: '21px',
        4: '22px'
      }

      this.div.style.fontSize = sizes[this.$size]
    }
  }

  getProjectionPosition () {
    // Retrieve the southwest and northeast coordinates of this overlay
    // in latlngs and convert them to pixels coordinates.
    // We'll use these coordinates to resize the DIV.
    const projection = this.getProjection()
    const position = projection.fromLatLngToDivPixel(this.position)

    return position
  }

  onAdd () {
    // Note: an overlay's receipt of onAdd() indicates that
    // the map's panes are now available for attaching
    // the overlay to the map via the DOM.
    const div = document.createElement('div')
    const span = document.createElement('span')

    div.className = 'label-overlay'

    if (this.class) {
      if (this.class.constructor === Array) {
        this.class.forEach(className => div.classList.add(className))
      } else {
        div.classList.add(this.class)
      }
    }

    span.textContent = this.text

    div.appendChild(span)
    this.div = div

    const position = this.getProjectionPosition()

    div.style.position = 'absolute'
    div.style.left = position.x + 'px'
    div.style.top = position.y + 'px'

    if (this.$offset) {
      div.style.marginTop = `${this.$offset}px`
    }

    // We add an overlay to a map via one of the map's panes.
    const panes = this.getPanes()
    panes.floatPane.appendChild(div)

    this.setFontSize()
  }

  onRemove () {
    this.div.parentNode.removeChild(this.div)
    this.div = null
  }

  draw () {
    const position = this.getProjectionPosition()
    const div = this.div

    if (div) {
      div.style.position = 'absolute'
      div.style.left = position.x + 'px'
      div.style.top = position.y + 'px'
    }
  }

  hide () {
    if (this.div) {
      this.div.style.visibility = 'hidden'
    }
  }

  show () {
    if (this.div) {
      this.div.style.visibility = 'visible'
    }
  }

  toggle () {
    if (this.div && this.div.style.visibility === 'hidden') {
      this.show()
    } else {
      this.hide()
    }
  }

  toggleDOM () {
    this.setMap(this.getMap() ? null : this.map)
  }
}

export default LabelOverlay
