<template>
  <div :class="[sizeClass, roundClass]" class="profile-picture">
    <Icon v-show="!picture" name="icon-user" />
    <img
      v-show="picture"
      :src="img(picture)"
      :alt="name"
      :title="name" />
  </div>
</template>

<script>
export default {
  props: {
    userId: {
      type: Number,
      default: null
    },

    name: {
      type: String,
      default: ''
    },

    size: {
      type: String,
      default: 'normal' // x-small, small, normal, large
    },

    round: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
      picture: null
    }
  },

  computed: {
    sizeClass () {
      return {
        'x-small': 'is-x-small',
        'small': 'is-small',
        'normal': 'is-normal',
        'large': 'is-large'
      }[this.size]
    },

    roundClass () {
      return this.round ? 'is-round' : ''
    }
  },

  created () {
    if (this.userId) this.read()
  },

  methods: {
    async read () {
      try {
        this.picture = await this.$store.dispatch('profile/readProfilePicture', this.userId)
      } catch (error) {
        // do nothing
      }
    },

    img (picture) {
      return 'data:image/jpeg;base64, ' + picture
    }
  }
}
</script>

<style lang="scss">
.profile-picture {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background: #eaeaea;
  overflow: hidden;

  img {
    display: block;
  }

  .icon {
    margin: 0 !important;
  }

  &.is-round {
    border-radius: 50%;
  }

  &.is-x-small {
    width: 1.5rem;
    height: 1.5rem;

    .icon { font-size: 0.75rem; }
  }

  &.is-small {
    width: 2rem;
    height: 2rem;
  }

  &.is-normal {
    width: 4rem;
    height: 4rem;

    .icon { font-size: 2rem; }
  }

  &.is-large {
    width: 8rem;
    height: 8rem;

    .icon { font-size: 4rem; }
  }
}
</style>
