import dayjs from 'dayjs'

class DeviceModel {
  constructor (context) {
    const data = Object.assign({}, context)

    this.imei = data.imei || null
    this.vendor = data.vendor || null
    this.model = data.model || null
    this.expires = dayjs.unix(data.expires).toDate() || null
    this.autoRenew = Number(data.auto_renew) === 1
    this.canPurchase = Number(data.can_purchase) === 1
    this.paymentFailed = Number(data.payment_failed) === 1
  }

  toJSON () {
    return {
      imei: this.imei,
      vendor: this.vendor,
      model: this.model,
      expires: this.expires,
      autoRenew: this.autoRenew,
      canPurchase: this.canPurchase,
      paymentFailed: this.paymentFailed
    }
  }
}

export default DeviceModel
