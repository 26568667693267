export default {
  getPro: 'Få Pro',

  selectLanguage: 'Vælg dit sprog',

  feedback: 'Feedback',
  leaveFeedback: 'Giv feedback',

  games: 'Spil',
  elkGame: 'Älgjaktsbanan',
  boarGame: 'Viltsvinspasset',

  theme: 'Tema',
  themeContent: 'Surfer du de fleste aftener?',
  dark: 'Mørk'
}
