<template>
  <a
    :class="[activeClass]"
    role="button"
    class="navbar-burger burger"
    aria-label="menu"
    aria-expanded="false"
    @click="toggle">
    <span aria-hidden="true"></span>
    <span aria-hidden="true"></span>
    <span aria-hidden="true"></span>
  </a>
</template>

<script>
export default {
  computed: {
    isActive: {
      get () {
        return this.$store.getters['ui/isMobileNavActive']
      },
      set (state) {
        return this.$store.dispatch('ui/mobileNav', state)
      }
    },

    activeClass () {
      return this.isActive ? 'is-active' : ''
    }
  },

  methods: {
    toggle () {
      this.isActive = !this.isActive
    }
  }
}
</script>

<style lang="scss" scoped>
.burger {
  color: white;
}
</style>
