export default {

  /* Sub Areas */
  setSubAreas (state, subAreas) {
    state.subAreas = subAreas
  },

  addSubArea (state, subArea) {
    state.subAreas.push(subArea)
  },

  deleteSubArea (state, subArea) {
    const i = state.subAreas.indexOf(subArea)
    if (i !== -1) state.subAreas.splice(i, 1)
  },

  /* Forbidden Areas */
  setForbiddenAreas (state, forbiddenAreas) {
    state.forbiddenAreas = forbiddenAreas
  },

  addForbiddenArea (state, forbiddenArea) {
    state.forbiddenAreas.push(forbiddenArea)
  },

  deleteForbiddenArea (state, forbiddenArea) {
    const i = state.forbiddenAreas.indexOf(forbiddenArea)
    if (i !== -1) state.forbiddenAreas.splice(i, 1)
  },

  /* Misc */
  clear (state) {
    state.subAreas = null
  },

  select (state, area) {
    state.selected = area
  },

  showSubAreaLabels (state, bool) {
    state.showSubAreaLabels = bool
  },

  showForbiddenLabels (state, bool) {
    state.showForbiddenLabels = bool
  }

}
