/**
 * Information about a district in Viltrapport that a specific hunt area
 * is connected to.
 */
class HuntAreaStatusModel {
  constructor (json) {
    this.id = json.district.id || null
    this.name = json.district.name || ''
  }
}

export default HuntAreaStatusModel
