<template>
  <label :class="[checkboxClass]" class="checkbox">
    <input
      v-if="!loading"
      :value="value"
      :checked="checked"
      type="checkbox"
      @change="update" />

    <InlineLoader v-if="loading" size="small" />
    <Icon v-if="!loading" :name="iconName" :class="[iconClass]" />

    <span v-if="label.length" :class="[labelClass]">
      {{ label }}
    </span>
  </label>
</template>

<script>
export default {
  props: {
    value: {
      type: Boolean,
      default: false
    },

    label: {
      type: String,
      default: ''
    },

    disabled: {
      type: Boolean,
      default: false
    },

    type: {
      type: String,
      default: 'checkbox' // checkbox, switch
    },

    loading: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
      checked: this.value
    }
  },

  computed: {
    checkboxClass () {
      return {
        'is-dimmed is-disabled': this.disabled,
        'switch': this.type === 'switch'
      }
    },

    iconClass () {
      return {
        'is-medium': this.type === 'switch'
      }
    },

    iconName () {
      const icons = {
        checkbox: {
          on: 'icon-check-square',
          off: 'icon-square'
        },
        switch: {
          on: 'icon-toggle-right has-text-success',
          off: 'icon-toggle-left has-text-danger'
        }
      }

      return icons[this.type][this.checked ? 'on' : 'off']
    },

    labelClass () {
      return {
        'push-left-xxxs': this.type === 'checkbox'
      }
    }
  },

  watch: {
    value (value) {
      this.checked = value
    }
  },

  methods: {
    update (value) {
      const checked = value.target.checked
      this.checked = checked
      this.$emit('input', checked)
    }
  }
}
</script>

<style lang="scss" scoped>
.checkbox {
  display: flex;
  flex-direction: row;
  align-items: center;

  &.switch {
    justify-content: space-between;
    flex-direction: row-reverse;
  }

  input {
    display: none;
  }

  span {
    user-select: none;
  }
}
</style>
