import dayjs from 'dayjs'

class LineModel {
  constructor (context) {
    const data = Object.assign({}, context)

    this.id = data.id || null
    this.parentId = data.parentId || null
    this.etag = data.etag || null

    this.label = data.label || ''
    this.lineType = data.lineType || 'line'

    this.color = data.color || '#000000'
    this.width = Number(data.width) || 3

    this.path = data.path || []
    this.startCap = data.startCap || null
    this.endCap = data.endCap || null

    this.created = data.created ? dayjs(data.created).toDate() : null
    this.createdBy = data.createdBy || null
    this.updated = data.updated ? dayjs(data.updated).toDate() : null
    this.updatedBy = data.updatedBy || null

    this.visible = data.visible !== undefined ? data.visible : true
    this.editable = data.editable !== undefined ? data.editable : false
  }

  toJSON () {
    return {
      id: this.id,
      parentId: this.parentId,

      label: this.label,
      lineType: this.lineType,

      color: this.color,
      width: this.width,

      path: this.path,
      startCap: this.startCap,
      endCap: this.endCap,

      visible: this.visible,
      editable: this.editable
    }
  }
}

export default LineModel
