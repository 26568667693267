export default {
  title: "PRO GRATUIT",
  subtitle: "Nous offrons actuellement WeHunt Pro tout le mois d'août",

  getPro: "Obtenez Pro",
  readMore: "En savoir plus",

  infoText: "Inscrivez-vous pour un abonnement annuel de 23,90 € et obtenez le mois d'août gratuitement. Annulez à tout moment.",
  price: "Août gratuit, puis 23,90€ /an"
}
