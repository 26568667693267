<template>
  <div v-if="hasIcons" class="control" :class="controlClasses">
    <Icon
      v-if="iconLeft"
      :name="iconLeft"
      class="is-left">
    </Icon>

    <input
      type="text"
      class="input"
      :class="inputClasses"
      :placeholder="placeholder"
      :disabled="disabled"
      :value="value"
      @input="$emit('input', $event.target.value)" />

    <Icon
      v-if="iconRight"
      :name="iconRight"
      class="is-right">
    </Icon>
  </div>

  <input
    v-else
    type="text"
    class="input"
    :class="inputClasses"
    :placeholder="placeholder"
    :disabled="disabled"
    :value="value"
    @input="$emit('input', $event.target.value)" />
</template>

<script>
export default {
  props: {
    value: {
      type: String,
      default: ''
    },

    placeholder: {
      type: String,
      default: ''
    },

    disabled: {
      type: Boolean,
      default: false
    },

    size: {
      type: String,
      default: ''
    },

    valid: {
      type: String,
      default: null
    },

    loading: {
      type: Boolean,
      default: false
    },

    iconLeft: {
      type: String,
      default: null
    },

    iconRight: {
      type: String,
      default: null
    }
  },

  computed: {
    controlClasses () {
      return {
        'has-icons-left': this.iconLeft !== null,
        'has-icons-right': this.iconRight !== null,
        'is-loading': this.loading
      }
    },

    inputClasses () {
      const classes = {}

      if (this.size.length > 0) {
        classes['is-' + this.size] = true
      }

      classes['is-danger'] = this.valid === 'invalid'
      classes['is-success'] = this.valid === 'valid'

      return classes
    },

    hasIcons () {
      return this.iconLeft || this.iconRight
    }
  }
}
</script>
