class Assignment {
  constructor (data) {
    const params = data || {}

    this.id = params.id || null
    this.name = params.name || ''
    this.subArea = params.subArea || null
    this.participants = params.participants || []
    this.expanded = true
    this.selected = false
    this.status = ''
  }

  getParticipantIndex (userId) {
    for (let i = 0; i < this.participants.length; i++) {
      if (this.participants[i].id === userId) {
        return i
      }
    }

    return -1
  }

  getParticipantWithId (userId) {
    var index = this.getParticipantIndex(userId)
    return index !== -1 ? this.participants[index] : null
  }

  addParticipant (participant) {
    this.participants.push(participant)
  }

  removeParticipant (userId) {
    var index = this.getParticipantIndex(userId)
    if (index !== -1) {
      this.participants.splice(index, 1)
    }
  }

  toJSON () {
    return {
      id: this.id,
      name: this.name.length ? this.name : null,
      subArea: this.subArea ? this.subArea.id : null,
      participants: this.participants // this.participants.filter(participant => participant.role === 'leader' || participant.role === 'pass')
    }
  }
}

export default Assignment
