import dayjs from 'dayjs'
import api from '@/api/'
import CalendarEventModel from '../models/calendarEventModel'
import CalendarHuntModel from '../models/calendarHuntModel'
import HuntReportModel from '../../huntArea/report/models/huntReportModel'

import dayjsPluginUTC from 'dayjs-plugin-utc'

dayjs.extend(dayjsPluginUTC, { parseToLocal: true })

class CalendarService {
  static async getPeriod (startDate, endDate) {
    const startTime = dayjs(startDate).utc().toISOString()
    const endTime = dayjs(endDate).hour(23).minute(59).utc().toISOString()

    try {
      const { data } = await api.get(`/areas/iamfake/calendar/data?from=${startTime}&to=${endTime}`)

      return {
        events: data.events.map(event => new CalendarEventModel(event)),
        hunts: data.hunts.map(hunt => new CalendarHuntModel(hunt)),
        reports: data.reports.map(huntReport => new HuntReportModel(huntReport))
      }
    } catch (error) {
      console.error('Failed to read calendar events.')
      throw error
    }
  }
}

export default CalendarService
