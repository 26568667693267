export default {
  setMessages (state, messages) {
    state.messages = messages
  },

  addMessage (state, message) {
    if (!state.messages) {
      state.messages = []
    }

    state.messages.push(message)
  }
}
