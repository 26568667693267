import GPXService from '@/api/map/gpx/gpxService'

import BoundaryService from '@/api/map/boundary/boundaryService'
import SubAreaService from '@/api/map/subareas/subAreaService'
import MarkerService from '@/api/map/markers/markerService'
import SubAreaModel from '@/api/map/subareas/subAreaModel'
import MarkerModel from '@/api/map/markers/markerModel'

import colors from '@/components/map/utils/colors'
import { getMarkerNameFromType } from '@/api/map/markers/markers'

export const IMPORT_STATUS_NO_FILE = ''
export const IMPORT_STATUS_READING = 'reading'
export const IMPORT_STATUS_READ_FAILED = 'read-failed'
export const IMPORT_STATUS_READ_SUCCEEDED = 'read-succeeded'
export const IMPORT_STATUS_SAVING = 'saving'
export const IMPORT_STATUS_SAVE_COMPLETE = 'save-complete'

function filterSelected (object) {
  return object.selected
}

export default {
  namespaced: true,

  state: {
    metaData: null,
    markers: [],
    boundaries: [],
    subAreas: [],
    forbiddenAreas: [],
    importedCount: 0,
    failedObjects: [],
    status: IMPORT_STATUS_NO_FILE
  },

  getters: {
    hasData: state => state.metaData !== null,
    getStatus: state => state.status,
    getMetaData: state => state.metaData,

    getBoundaries: state => state.boundaries,
    getSelectedBoundaries: state => state.boundaries.filter(filterSelected),

    getSubAreas: state => state.subAreas,
    getSelectedSubAreas: state => state.subAreas.filter(filterSelected),

    getForbiddenAreas: state => state.forbiddenAreas,
    getSelectedForbiddenAreas: state => state.forbiddenAreas.filter(filterSelected),

    getMarkers: state => state.markers,
    getSelectedMarkers: state => state.markers.filter(filterSelected),

    getAllObjects: state => {
      return [...state.boundaries, ...state.subAreas, ...state.forbiddenAreas, ...state.markers]
    },

    getProgress (state, getters) {
      const selectionCount = getters.getAllObjects.filter(filterSelected).length
      return selectionCount > 0 ? Math.floor(state.importedCount / selectionCount * 100) : 0
    },

    getFailedObjects: state => state.failedObjects
  },

  mutations: {
    setMetaData (state, metaData) {
      state.metaData = metaData
    },

    setBoundaries (state, importAreas) {
      state.boundaries = importAreas
    },

    setSubAreas (state, importAreas) {
      state.subAreas = importAreas
    },

    setForbiddenAreas (state, importAreas) {
      state.forbiddenAreas = importAreas
    },

    setMarkers (state, markers) {
      state.markers = markers
    },

    setStatus (state, status) {
      state.status = status
    },

    updateProgress (state) {
      state.importedCount++
    },

    updateFailed (state, object) {
      state.failedObjects.push(object)
    },

    cancel (state) {
      state.metaData = null
      state.markers = []
      state.boundaries = []
      state.subAreas = []
      state.forbiddenAreas = []
      state.markers = []

      state.failedObjects = []
      state.importedCount = 0

      state.status = IMPORT_STATUS_NO_FILE
    }
  },

  actions: {
    async readFile ({ commit, rootGetters }, file) {
      const huntArea = rootGetters['huntarea/selected']

      const formData = new FormData() // eslint-disable-line
      formData.append('importFile', file)

      try {
        commit('setStatus', IMPORT_STATUS_READING)

        const importData = await GPXService.import(huntArea.id, formData)

        commit('setMetaData', {
          creator: importData.creator,
          author: importData.author,
          createdAt: importData.createdAt
        })

        const boundaries = importData.areas.filter(area => area.type === 'huntarea_border')
        const subAreas = importData.areas.filter(area => area.type === 'sub_area' || area.type === '')
        const forbiddenAreas = importData.areas.filter(area => area.type === 'forbidden_area')

        commit('setBoundaries', boundaries)
        commit('setSubAreas', subAreas)
        commit('setForbiddenAreas', forbiddenAreas)
        commit('setMarkers', sanitizeMarkers(importData.markers))

        commit('setStatus', IMPORT_STATUS_READ_SUCCEEDED)
      } catch (error) {
        commit('setStatus', IMPORT_STATUS_READ_FAILED)
        throw error
      }
    },

    async save ({ dispatch, commit, getters }) {
      commit('setStatus', IMPORT_STATUS_SAVING)

      await dispatch('saveBoundaries')
      await dispatch('saveSubAreas')
      await dispatch('saveForbiddenAreas')
      await dispatch('saveMarkers')

      commit('setStatus', IMPORT_STATUS_SAVE_COMPLETE)

      const failedObjects = getters.getFailedObjects
      if (failedObjects.length === 0) {
        console.info('All objects were imported successfully.')
      } else {
        console.error('One or more objects failed to import.')
        console.error(failedObjects)
      }

      window.location.reload()
    },

    async saveBoundaries ({ getters, rootGetters, commit }) {
      // For now we don't support multiple boundaries in practice in all places of the app, but the
      // import code is prepared in case the file would actually contain multiple boundaries
      const huntArea = rootGetters['huntarea/selected']
      const selectedBoundaries = getters.getSelectedBoundaries

      for (const boundary of selectedBoundaries) {
        try {
          await BoundaryService.update(huntArea.id, boundary.borderCoordinates)
        } catch (error) {
          commit('updateFailed', boundary)
        } finally {
          commit('updateProgress')
        }
      }
    },

    async saveMarkers ({ getters, rootGetters, commit }) {
      const huntArea = rootGetters['huntarea/selected']
      const selectedMarkers = getters.getSelectedMarkers

      for (const importMarker of selectedMarkers) {
        try {
          const marker = await MarkerService.create(huntArea.id, new MarkerModel(importMarker))
          commit('map/markers/add', marker, { root: true })
        } catch (error) {
          commit('updateFailed', importMarker)
        } finally {
          commit('updateProgress')
        }
      }
    },

    async saveSubAreas ({ getters, rootGetters, commit }) {
      const huntArea = rootGetters['huntarea/selected']
      const selectedSubAreas = getters.getSelectedSubAreas

      for (const importArea of selectedSubAreas) {
        const subArea = new SubAreaModel({
          name: importArea.name,
          type: 'saat',
          borderCoordinates: importArea.borderCoordinates,
          borderColor: colors.subArea
        })

        try {
          const createdSubArea = await SubAreaService.create(huntArea.id, subArea)
          commit('map/subAreas/addSubArea', createdSubArea, { root: true })
        } catch (error) {
          commit('updateFailed', importArea)
        } finally {
          commit('updateProgress')
        }
      }
    },

    async saveForbiddenAreas ({ getters, rootGetters, commit }) {
      const huntArea = rootGetters['huntarea/selected']
      const selectedForbiddenAreas = getters.getSelectedForbiddenAreas

      for (const importArea of selectedForbiddenAreas) {
        const forbiddenArea = new SubAreaModel({
          name: importArea.name,
          type: 'forbiddenArea',
          borderCoordinates: importArea.borderCoordinates,
          borderColor: colors.forbiddenArea,
          fillColor: colors.forbiddenArea
        })

        try {
          const createdForbiddenArea = await SubAreaService.create(huntArea.id, forbiddenArea)
          commit('map/subAreas/addForbiddenArea', createdForbiddenArea, { root: true })
        } catch (error) {
          commit('updateFailed', importArea)
        } finally {
          commit('updateProgress')
        }
      }
    }
  }
}

function sanitizeMarkers (markers) {
  for (const marker of markers) {
    if (marker.name.length === 0) {
      marker.name = getMarkerNameFromType(marker.type)
    }
  }

  return markers
}
