import api from '@/api'
import MemberModel from '../models/memberModel'

class MemberService {
  static async create (huntAreaId, userId) {
    try {
      const response = await api.post(`/areas/${huntAreaId}/members`, { userId })
      return new MemberModel(response.data)
    } catch (error) {
      console.error(`Failed to add member ${userId} to huntArea ${huntAreaId}.`, error)
      throw error
    }
  }

  static async read (huntAreaId) {
    try {
      const { data } = await api.get(`/areas/${huntAreaId}/members`)
      const members = data.map(member => new MemberModel(member))
      return members
    } catch (error) {
      console.error(`Failed to read members for huntArea ${huntAreaId}.`, error)
      throw error
    }
  }

  static async delete (huntAreaId, userId) {
    try {
      await api.delete(`/areas/${huntAreaId}/members/${userId}`)
    } catch (error) {
      console.error(`Failed to delete member ${userId} from huntArea ${huntAreaId}.`)
      throw error
    }
  }
}

export default MemberService
