export default {
  firstName: 'Prénom',
  lastName: 'Nom de famille',
  repeatPassword: 'Répéter le mot de passe',
  forgotPassword: 'Mot de passe oublié?',

  signin: {
    title: 'Se connecter',
    remember: 'Se souvenir',
    error: "Désolé, nous n'avons pas pu vous connecter. Veuillez réessayer.",
    answerInvite: "Veuillez vous connecter pour répondre à l'invitation.",
    oldSiteDividerTitle: 'Ou',
    oldSiteSignIn: "Connectez-vous à l'ancien site Web"
  },

  join: {
    title: "S'inscrire",
    invalidName: 'Vous devez saisir un nom',
    invalidLastName: 'Vous devez saisir un nom de famille',
    repeatPassword: 'Répéter le mot de passe',
    invalidEmail: 'Vous devez saisir un email valide',
    emailOccupied: 'Cet e-mail est déjà enregistré dans notre système.',
    passNoMatch: 'Votre mot de passe est trop court ou ne correspond pas.',
    welcomeToWeHunt:
      'Bienvenue sur WeHunt <strong>{firstName}</strong>. Nous sommes heureux que vous soyez membre de notre communauté !',
    downloadAppReminder:
      "N'oubliez pas de télécharger l'application sur votre téléphone depuis l'Appstore ou Google Play.",
    accountRequiredToAnswerInvite:
      "Vous devez créer un compte WeHunt <strong>gratuit</strong> afin de répondre à l'invitation.",
    signInAndAnswerInvitation: "Connectez-vous et répondez à l'invitation",
    unknownErrorMessage:
      "Une erreur s'est produite et le compte n'a pas pu être enregistré. Veuillez réessayer."
  },

  recover: {
    title: 'Récupérer',
    helpText:
      'Saisissez votre adresse e-mail et nous vous enverrons un courriel contenant des instructions sur la façon de créer un nouveau mot de passe.',
    success:
      "Un courriel vous a été envoyé avec les instructions nécéssaires afin de réinitialiser votre mot de passe.<br /><br />Si vous ne retrouvez pas le courriel, assurez-vous d'avoir correctement renseigné votre adresse e-mail ou qu'il ne se situe pas dans vos courriers indésirables.",
    recoverPasswordFailedErrorMessage:
      "Une erreur s'est produite et il n'a pas été possible de réinitialiser votre mot de passe. Veuillez réessayer.",
    invalidEmail: 'Vous devez saisir une adresse e-mail valide'
  },

  updatePassword: {
    title: 'Mettre à jour le mot de passe',
    helpText: 'Saisissez un nouveau mot de passe pour {email}',
    updateButton: 'Mettre à jour le mot de passe',
    invalidQueryError: 'URL invalide',
    permissionError:
      "Vous n'êtes pas autorisé à mettre à jour le mot de passe de ce compte.",
    success: 'Le mot de passe a été mis à jour !'
  },

  signout: {
    title: 'Se déconnecter'
  }
}
