/**
 * Utility class for simplifying access to localStorage and sessionStorage.
 */
export default class Storage {
  constructor (persistent) {
    this.storage = persistent ? window.localStorage : window.sessionStorage
  }

  setItem (key, value) {
    this.storage.setItem(key, value)
  }

  getItem (key) {
    return this.storage.getItem(key)
  }

  removeItem (key) {
    this.storage.removeItem(key)
  }

  static isPersistent (key) {
    return window.localStorage.getItem(key) !== null
  }
}
