<template>
  <div :class="className">
    <div v-if="img" :class="`${className}-img`" />

    <div :class="`${className}-content`">
      <div :class="`${className}-title`" />
      <div v-if="subtitle" :class="`${className}-subtitle`" />
    </div>
  </div>
</template>

<script>
export default {

  props: {
    img: {
      type: Boolean,
      default: false
    },

    subtitle: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
      className: 'content-placeholders-heading'
    }
  }

}
</script>
