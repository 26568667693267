import authRoutes from '@/modules/auth/authRoutes'
import profileRoutes from '@/modules/profile/profileRoutes'
import huntAreaRoutes from '@/modules/huntArea/huntAreaRoutes'
import huntRoutes from './routes/hunt'
import gpsRoutes from '@/modules/gps/gpsRoutes'
import otherRoutes from './routes/routes'
import calendarRoutes from '@/modules/calendar/calendarRoutes'
import inviteRoutes from '@/modules/invites/inviteRoutes'
import dashboardRoutes from '@/modules/dashboard/dashboardRoutes'
import newsRoutes from '@/modules/news/newsRoutes'

// NOTE: For reasons beyond me, these routes can't be placed along the other related routes
// in reportRoutes.js. This will trigger some kind of path conflict which makes the router route to the wrong paths
// with related errors to follow.
const reportRoutes = [{
  name: 'edit-report-no-huntarea',
  path: '/reports/:reportId/edit',
  component: () => import('@/modules/huntArea/report/components/edit/ReportEditPage.vue')
}, {
  name: 'view-report-no-huntarea',
  path: '/reports/:reportId',
  component: () => import('@/modules/huntArea/report/components/view/ReportPage.vue'),
  children: [{
    name: 'view-report-no-huntarea-general-tab',
    path: 'general',
    component: () => import('@/modules/huntArea/report/components/view/ReportPageTabGeneral.vue')
  }, {
    path: 'chat',
    component: () => import('@/modules/huntArea/report/components/view/ReportPageTabChat.vue')
  }, {
    path: 'planning',
    component: () => import('@/modules/huntArea/report/components/view/ReportPageTabInvitation.vue')
  }]
}]

export default [
  ...authRoutes,
  ...profileRoutes,
  ...huntAreaRoutes,
  ...huntRoutes,
  ...gpsRoutes,
  ...calendarRoutes,
  ...inviteRoutes,
  ...otherRoutes,
  ...dashboardRoutes,
  ...newsRoutes,
  ...reportRoutes
]
