import { i18n } from '../../../../i18n'
import ReportService from '../services/reportService'

export default {
  namespaced: true,

  state: {
    reports: null
  },

  getters: {
    getAll (state) {
      return state.reports
    },

    get: state => reportId => {
      const reports = state.reports || []
      const i = reports.findIndex(report => report.id === reportId)
      return i !== -1 ? reports[i] : null
    },

    getByHunt: state => huntId => {
      const reports = state.reports || []
      const i = reports.findIndex(report => report.huntId === huntId)
      return i !== -1 ? reports[i] : null
    },

    getTotalKillCount (state) {
      let count = 0
      const reports = state.reports || []

      reports.forEach(report => {
        report.events.forEach(event => {
          if (event.type === 'kill') {
            event.games.forEach(game => {
              count += game.count
            })
          }
        })
      })

      return count
    },

    getTotalObservationCount (state) {
      let count = 0
      const reports = state.reports || []

      reports.forEach(report => {
        report.events.forEach(event => {
          if (event.type === 'observation') {
            event.games.forEach(game => {
              count += game.count
            })
          }
        })
      })

      return count
    }
  },

  mutations: {
    set (state, reports) {
      state.reports = reports
    },

    add (state, report) {
      console.log('Adding new report.')

      if (state.reports === null) {
        state.reports = []
      }

      // If the report for some reason already exists, don't add it to prevent duplication errors
      const i = state.reports.findIndex(r => r.id === report.id)
      if (i === -1) {
        state.reports.push(report)
      }
    },

    remove (state, report) {
      const i = state.reports.findIndex(r => r.id === report.id)
      if (i !== -1) {
        console.log('Removing report ' + report.id + '.')
        state.reports.splice(i, 1)
      }
    },

    update (state, report) {
      const i = state.reports.findIndex(r => r.id === report.id)
      if (i !== -1) {
        console.log('Updating report ' + report.id + '.')
        state.reports.splice(i, 1, report)
      }
    }
  },

  actions: {
    async read ({ commit, rootGetters }) {
      commit('set', null)

      const huntArea = rootGetters['huntarea/selected']
      const credentials = rootGetters['auth/getBase64']

      const reportService = new ReportService(credentials)
      let reports = await reportService.getReports(huntArea)
      reports = reports.filter(report => report.state === 'finished')

      commit('set', reports)
    },

    async getById ({ getters, rootGetters }, reportId) {
      let report = getters.get(reportId)

      if (report === null) {
        const huntArea = rootGetters['huntarea/selected']
        const credentials = rootGetters['auth/getBase64']

        const reportService = new ReportService(credentials)
        report = await reportService.getReport(huntArea, reportId)
      }

      return report
    },

    async getByHunt ({ commit, getters, rootGetters }, hunt) {
      let report = getters.getByHunt(hunt.id)

      if (report === null) {
        console.log(`Loading report for hunt ${hunt.id}.`)

        const credentials = rootGetters['auth/getBase64']

        const reportService = new ReportService(credentials)
        report = await reportService.getReportFromHunt(hunt)

        console.log(report)
        commit('add', report)
      }

      return report
    },

    async downloadAsExcel ({ rootGetters }) {
      const huntArea = rootGetters['huntarea/selected']
      const language = rootGetters['ui/language']
      const credentials = rootGetters['auth/getBase64']

      const reportService = new ReportService(credentials)
      const blob = await reportService.downloadAsExcel(huntArea.id, language)

      const fileName = huntArea.name + ' - ' + i18n.t('hunts.reports') + '.xlsx'

      if (window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveBlob(blob, fileName)
      } else {
        const a = window.document.createElement('a')

        a.setAttribute('href', window.URL.createObjectURL(blob))
        a.setAttribute('download', fileName)

        a.click()
      }
    }
  }
}
