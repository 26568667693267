import NotFoundPage from '@/views/NotFoundPage.vue'

export default [{
  path: '/huntareas',
  name: 'HuntAreas',
  component: () => import('@/views/huntAreas/HuntAreas.vue')
}, {
  path: '/hunts',
  name: 'Invites',
  component: () => import('@/views/huntInvitations/HuntInvitationsPage.vue')
}, {
  path: '/icons',
  name: 'Icons',
  component: () => import('@/views/Icons.vue'),
  meta: { isPublic: true }
}, {
  path: '*',
  component: NotFoundPage
}]
