export default {

  isPrinting ({ commit }, state) {
    commit('isPrinting', state)
  },

  isAdjusting ({ commit, dispatch }, state) {
    commit('isAdjusting', state)

    if (state) {
      dispatch('map/closeAllPanels', null, { root: true })
    }
  }

}
