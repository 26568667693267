<template>
  <div
    id="print-preview"
    :class="[
      isAdjusting && !isPrinting ? 'is-active' : '',
      $orientation
    ]"
    class="print-overlay resizable dontprint">
    <div class="resizers">
      <div
        class="resizer bottom-right"
        @mousedown.prevent="drag">
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  data () {
    return {
      minimum_size: 20,
      original_width: 0,
      original_height: 0,
      original_x: 0,
      original_y: 0,
      original_mouse_x: 0,
      original_mouse_y: 0
    }
  },

  computed: {
    ...mapGetters({
      isAdjusting: 'map/print/isAdjusting',
      isPrinting: 'map/print/isPrinting',
      orientation: 'map/print/paperOrientation'
    }),

    $map () {
      return document.querySelector('.map-wrap')
    },

    $orientation () {
      return `is-${this.orientation}`
    }
  },

  watch: {
    isAdjusting (state) {
      if (state) {
        document.documentElement.classList.add('map-overlay-is-active')
        this.setInitialValues()
        setTimeout(() => this.updatePrintPreview(), 250)
      } else {
        document.documentElement.classList.remove('map-overlay-is-active')
        this.restore()
      }
    },

    isPrinting (state) {
    },

    orientation () {
      setTimeout(() => this.updatePrintPreview(), 250)
    }
  },

  created () {
    window.addEventListener('resize', this.updatePrintPreview)
  },

  methods: {
    setInitialValues () {
      this.original_width = this.$el.clientWidth
      this.original_height = this.$el.clientHeight

      this.original_x = this.$el.offsetLeft
      this.original_y = this.$el.offsetTop

      const resizer = this.$el.querySelector('.resizer')

      this.original_mouse_x = resizer.getBoundingClientRect().left - (resizer.clientWidth / 2)
      this.original_mouse_y = resizer.getBoundingClientRect().top - (resizer.clientHeight / 2)
    },

    updatePrintPreview () {
      let w = Math.round(this.$el.clientWidth)
      let h = Math.round(this.$el.clientHeight)

      let pw = 2480
      let ph = 3508

      if (this.orientation === 'landscape') {
        pw = 3508
        ph = 2480
      }

      h = Math.round(ph / pw * w)

      if (this.$map instanceof HTMLElement) {
        let r = w / h

        let mapWidth = parseFloat(window.getComputedStyle(this.$map, null).getPropertyValue('width').replace('px', ''))
        let mapHeight = parseFloat(window.getComputedStyle(this.$map, null).getPropertyValue('height').replace('px', ''))

        if (w / mapWidth > 0.8) {
          w = mapWidth * 0.8
          h = w / r
        }

        if (h / mapHeight > 0.8) {
          h = mapHeight * 0.8
          w = h * r
        }

        const centerX = this.$map.clientWidth / 2
        const centerY = this.$map.clientHeight / 2

        this.$el.style.width = w + 'px'
        this.$el.style.height = h + 'px'

        this.$el.style.left = (centerX - w / 2) + 'px'
        this.$el.style.top = (centerY - h / 2) + 'px'
      }
    },

    drag (e) {
      this.original_width = this.$el.clientWidth
      this.original_height = this.$el.clientHeight

      this.original_x = this.$el.offsetLeft
      this.original_y = this.$el.offsetTop

      this.original_mouse_x = e.pageX
      this.original_mouse_y = e.pageY

      window.addEventListener('mousemove', this.resize)
      window.addEventListener('mouseup', this.stopResize)
    },

    resize (e) {
      const dx = (e.pageX - this.original_mouse_x) * 2
      const dy = (e.pageY - this.original_mouse_y) * 2

      const width = this.original_width + dx
      const height = this.original_height + dy

      if (width > this.minimum_size) {
        this.$el.style.width = width + 'px'
      }

      if (height > this.minimum_size) {
        this.$el.style.height = height + 'px'
      }

      this.updatePrintPreview()
    },

    stopResize () {
      window.removeEventListener('mousemove', this.resize)
    },

    restore () {
      this.$el.style.width = this.original_width + 'px'
      this.$el.style.height = this.original_height + 'px'
    }
  }
}
</script>

<style src="./printOverlay.scss" lang="scss"></style>
