import HuntService from '@/api/hunt/huntService'
import HuntModel from '@/api/hunt/huntModel'
import ParticipantService from '@/api/hunt/participants/participantService'

export default {
  async create ({ commit, rootGetters }, payload) {
    const { name, huntAreaId } = payload

    const userId = rootGetters['auth/getUserId']

    const hunt = await HuntService.create(new HuntModel({
      name: name,
      huntAreaId: huntAreaId,
      huntLeaderUserId: userId
    }))

    await ParticipantService.inviteByUserId(hunt.id, userId)

    commit('add', hunt)

    return hunt
  },

  async read ({ commit, getters, state }, huntId) {
    commit('setHunt', null)

    let hunt = await getters.getHuntWithId(huntId)

    if (hunt === null) {
      hunt = await HuntService.read(huntId)
    }

    commit('setHunt', hunt)

    commit('hunt/participants/set', null, { root: true })
    commit('hunt/chat/set', null, { root: true })
    commit('hunt/events/set', null, { root: true })
    commit('hunt/drives/set', null, { root: true })
    commit('hunt/games/setGames', null, { root: true })
    commit('hunt/map/setMarkers', null, { root: true })
    commit('hunt/map/setSubAreas', null, { root: true })
    commit('hunt/map/setForbiddenAreas', null, { root: true })
    commit('hunt/map/setBoundary', null, { root: true })

    if (hunt.status !== 'finished') {
      commit('hunt/playback/setHunterLocations', [], { root: true })
      commit('hunt/playback/setGpsLocations', [], { root: true })
      commit('hunt/events/set', [], { root: true })
      commit('hunt/chat/set', [], { root: true })
    }

    return hunt
  },

  async readAllForUser ({ commit }) {
    commit('setHunts', null)
    const hunts = await HuntService.getAll()
    commit('setHunts', hunts)

    return hunts
  },

  async getHuntWithId ({ getters }, huntId) {
    let hunt = getters.getHuntWithId(huntId)

    if (hunt == null) {
      console.log('Hunt ' + huntId + ' was not found in the cache. Loading from server.')
      hunt = await HuntService.read(huntId)
    }

    return hunt
  },

  async update ({ commit }, hunt) {
    await HuntService.update(hunt)
    commit('update', hunt)
  },

  async delete ({ commit, state }, hunt) {
    await HuntService.delete(hunt.id)

    commit('delete', hunt)

    if (state.hunt === hunt) {
      commit('setHunt', null)
    }
  },

  async leave ({ rootGetters, commit }) {
    const userId = rootGetters['auth/getUserId']
    const hunt = rootGetters['hunt/hunt']

    await ParticipantService.removeMember(hunt.id, userId)

    commit('delete', hunt)
  },

  async plan ({ commit, rootGetters }, hunt) {
    const language = rootGetters['ui/language']
    const plannedHunt = await HuntService.plan(hunt, language)

    commit('delete', hunt)
    commit('add', plannedHunt)

    return plannedHunt
  },

  state ({ commit }, state) {
    commit('state', state)
  },

  setMode ({ commit }, mode) {
    commit('setMode', mode)
  },

  clear ({ commit }) {
    commit('setHunt', null)
    commit('setHunts', null)
  }
}
