<script>
/* global google */
import tileListener from "@/components/map/print/tileListener"
import { mapGetters } from "vuex"
import store from "@/store/"

const LAYER_NAME = "terrainLayer"

export default {
  inject: ["getMap"],

  data () {
    return {
      mapType: null
    }
  },

  computed: {
    ...mapGetters({
      isVisible: "map/settings/isTerrainLayerVisible"
    })
  },

  watch: {
    isVisible () {
      this.updateVisibility()
    }
  },

  async created () {
    // Below is the old code for getting terrain map from MapProxy at mp2.wehuntapp.com
    // const token = await this.getToken()
    // this.createMapType(token)
    // this.addTileLoadListener()
    // this.updateVisibility()

    // Free base map from Tanger (not licensed maps, since Tanger does not have a license for web use)
    this.createTangerFreeMapType()
  },

  render () {
    return ""
  },

  methods: {
    async getToken () {
      const credentials = store.getters["auth/getBase64"]

      const options = {
        headers: new Headers({
          Authorization: `Basic ${credentials}`
        }),
        credentials: "include"
      }

      const response = await fetch(
        "https://mp2.wehuntapp.com/auth.php",
        options
      )
      if (response.ok) {
        const json = await response.json()
        return json.token
      } else {
        throw new Error("Failed to fetch access token for mp2.wehuntapp.com")
      }
    },

    createMapType (token) {
      this.mapType = new google.maps.ImageMapType({
        name: LAYER_NAME,
        tileSize: new google.maps.Size(256, 256),
        maxZoom: 16,
        getTileUrl (tile, zoom) {
          const { x, y } = tile
          const ix = zoom + "-" + x + "-" + y

          tileListener.addPendingUrl(LAYER_NAME, ix)

          return `https://mp2.wehuntapp.com/webmercator512/${zoom}/${x}/${y}.png?token=${token}`
        }
      })
    },

    createTangerFreeMapType () {
      fetch("https://tanger.tracker.fi/api/v3/maps/list", {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          application: "I7f6rYyWz3VSCV97tyrFrzbMb3Rmigni",
          language: "se"
        })
      })
        .then((res) => res.json())
        .then((json) => {
          if (json.base_maps) {
            const map = json.base_maps.find(
              (map) => map.id === "tanger-world/fancy-d1DeZA"
            )
            if (map != null) {
              const url = map.url
              // https://tanger.tracker.fi/tiles/world/fancy-d1DeZA/{G}/{W}/{Z}/{Y}/{X}?token=...
              this.mapType = new google.maps.ImageMapType({
                name: LAYER_NAME,
                tileSize: new google.maps.Size(256, 256),
                maxZoom: 16,
                getTileUrl (tile, zoom) {
                  const { x, y } = tile
                  const ix = zoom + "-" + x + "-" + y

                  tileListener.addPendingUrl(LAYER_NAME, ix)

                  return url
                    .replace("{G}", map.generation)
                    .replace("{W}", 768)
                    .replace("{Z}", zoom)
                    .replace("{Y}", y)
                    .replace("{X}", x)
                }
              })

              this.addTileLoadListener()
              this.updateVisibility()
            }
          }
        })
    },

    async updateVisibility () {
      const map = await this.getMap()

      if (this.isVisible) {
        map.overlayMapTypes.insertAt(0, this.mapType)
      } else {
        const i = map.overlayMapTypes.indexOf(this.mapType)
        if (i !== -1) {
          map.overlayMapTypes.removeAt(i)
        }
      }
    },

    addTileLoadListener () {
      tileListener.addTileLoadListener(LAYER_NAME, this.mapType, 20000)
    }
  }
}
</script>
