<script>
/* global google */
export default {
  props: {
    data: {
      type: Array,
      default: () => []
    },

    visible: {
      type: Boolean,
      default: true
    },

    gradient: {
      type: Array,
      default: () => []
    },

    radius: {
      type: Number,
      default: 0.12
    },

    maxIntensity: {
      type: Number,
      default: 5
    },

    dissipating: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
      map: null,
      heatmap: null
    }
  },

  watch: {
    visible () {
      this.updateVisibility()
    }
  },

  async mounted () {
    this.map = await this.$parent.getMap()

    const data = this.data.map(weightedLocation => {
      return {
        location: new google.maps.LatLng(weightedLocation.location.lat, weightedLocation.location.lng),
        weight: weightedLocation.weight
      }
    })

    this.heatmap = new google.maps.visualization.HeatmapLayer({
      data: data,
      map: this.map,
      radius: this.radius,
      maxIntensity: this.maxIntensity,
      dissipating: this.dissipating,
      gradient: this.gradient
    })

    this.updateVisibility()
  },

  destroyed () {
    this.heatmap.setMap(null)
  },

  render () {
    return ''
  },

  methods: {
    updateVisibility () {
      this.heatmap.setMap(this.visible ? this.map : null)
    }
  }
}
</script>
