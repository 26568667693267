export default [{
  path: '/profile/',
  component: () => import('@/views/profile/Profile.vue'),
  children: [{
    path: '',
    component: () => import('@/views/profile/account/Account.vue'),
    children: [{
      name: 'AccountInformation',
      path: '',
      component: () => import('@/views/profile/account/AccountInformation.vue')
    }, {
      name: 'AccountSubscription',
      path: 'subscription',
      component: () => import('@/views/profile/account/subscription/Subscription.vue')
    }, {
      name: 'AccountAbout',
      path: 'about',
      component: () => import('@/views/profile/account/AccountAbout.vue')
    }, {
      name: 'AccountInterests',
      path: 'interests',
      component: () => import('@/views/profile/account/AccountInterests.vue')
    }, {
      name: 'AccountManage',
      path: 'manage',
      component: () => import('@/views/profile/account/manage/ManageSubscription.vue')
    }]
  }, {
    name: 'profileEmail',
    path: 'email',
    component: () => import('@/views/profile/settings/SettingsEmail.vue')
  }, {
    name: 'profilePassword',
    path: 'password',
    component: () => import('@/views/profile/settings/SettingsPassword.vue')
  }, {
    name: 'profilePayment',
    path: 'payment',
    component: () => import('@/views/profile/settings/SettingsPayment.vue')
  }, {
    path: 'WeHunt',
    component: () => import('@/views/profile/wehunt/WeHunt.vue'),
    children: [{
      name: 'WeHuntNotifications',
      path: 'notifications',
      component: () => import('@/views/profile/wehunt/WeHuntNotifications.vue')
    }, {
      name: 'WeHuntNewsletter',
      path: 'newsletter',
      component: () => import('@/views/profile/wehunt/WeHuntNewsletter.vue')
    }]
  }]
}]
