import api from '@/api/'
import axios from 'axios'
import dayjs from 'dayjs'

import GpsModel from '../models/gpsModel'
import GpsPositionModel from '../models/gpsPositionModel'
import GsmCoverageModel from '../models/gsmCoverageModel'

import store from '@/store'

class GpsService {
  static async readGpsDevices () {
    const base64 = store.getters['auth/getBase64']

    try {
      const { data } = await axios({
        method: 'GET',
        url: 'https://adm.wehuntapp.com/stripe/status.php',
        headers: {
          Authorization: `Basic ${base64}`
        }
      })

      if (data.subscription.gps) {
        return data.subscription.gps.map(device => new GpsModel(device))
      } else {
        return []
      }
    } catch (error) {
      console.error('Failed to read gps devices.')
      throw error
    }
  }

  static async readAvailableDates (imei) {
    try {
      const { data } = await api.get(`/gps/positions/${imei}/historyDates`)
      return data
    } catch (error) {
      console.error(`Failed to read available dates for imei: ${imei}`)
      throw error
    }
  }

  static async readGpsPositions (imei, date) {
    try {
      const dateString = dayjs(date).format('YYYY-MM-DD')

      const { data } = await api.get(`/gps/positions/${imei}/history/${dateString}`)
      const positions = data.map(position => new GpsPositionModel(position))

      return positions
    } catch (error) {
      console.error(`Failed to read gps positions at ${date} for imei: ${imei}`)
      throw error
    }
  }

  static async readGsmCoverage (location) {
    const { lat, lng } = location

    try {
      const { data } = await api.get(`/gps/gsmcoverage/?lat=${lat.toFixed(1)}&lng=${lng.toFixed(1)}`)

      return data.map(position => new GsmCoverageModel(position))
    } catch (error) {
      console.error(`Failed to read GSM coverage at latitude: ${lat} & longitude: ${lng}`)
      throw error
    }
  }
}

export default GpsService
