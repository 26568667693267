<template>
  <div :class="[sidebarClass]" class="split-view">
    <div v-if="sidebar === 'left'" :class="[isWideClass, isHalfClass]" class="split-view-sidebar overflow-auto fancy-scrollbar">
      <slot name="sidebar" />
    </div>

    <div class="split-view-wrapper overflow-auto fancy-scrollbar">
      <slot name="wrapper" />
    </div>

    <div v-if="sidebar === 'right'" class="split-view-sidebar overflow-auto fancy-scrollbar">
      <slot name="sidebar" />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    sidebar: {
      type: String,
      default: 'left'
    },

    wide: {
      type: Boolean,
      default: false
    },

    half: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    sidebarClass () {
      return this.sidebar === 'right' ? 'sidebar-is-right' : 'sidebar-is-left'
    },

    isWideClass () {
      return this.wide ? 'sidebar-is-wide' : ''
    },

    isHalfClass () {
      return this.half ? 'sidebar-is-half' : ''
    }
  }
}
</script>

<style lang="scss">
.split-view {
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-grow: 0;
  overflow: auto;

  @media screen and (min-width: 1025px) {
    min-height: 100vh;
    max-height: 100vh;
  }

  @media screen and (min-width: 769px) {
    flex-grow: 1;
    overflow: hidden;
  }

  &.sidebar-is-left {
    @media screen and (min-width: 769px) {
      flex-direction: row;
    }
  }

  &.sidebar-is-right {
    @media screen and (min-width: 769px) {
      flex-direction: row;
    }
  }

  .split-view-sidebar {
    width: 100%;
    overflow: unset;

    @media screen and (min-width: 769px) {
      width: 15rem;
      overflow: auto;
    }

    @media screen and (min-width: 1025px) {
      width: 17.5rem;
      overflow: auto;

      &.sidebar-is-wide {
        width: 24.25rem;
      }

      &.sidebar-is-half {
        width: 50%;
      }
    }
  }

  &.sidebar-is-left .split-view-sidebar {
    border-right: 0;

    @media screen and (min-width: 769px) {
      border-right: 1px solid #d1d8e0;
    }
  }

  &.sidebar-is-right .split-view-sidebar {
    border-left: 0;

    @media screen and (min-width: 769px) {
      border-left: 1px solid #d1d8e0;
    }
  }

  .split-view-wrapper {
    width: 100%;
    overflow: unset;
    position: relative;
    flex-grow: 1;

    @media screen and (min-width: 769px) {
      width: calc(100% - 15rem);
      overflow: auto;
    }

    @media screen and (min-width: 1025px) {
      width: calc(100% - 17.5rem);
      overflow: auto;
    }

    .titlebar {
      padding: 1.5rem;
      margin-bottom: 0;
    }
  }
}

/*
  * Columns
*/
.split-view-columns {
  padding: 0 0.75rem;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: -0.75rem;
}

.split-view-column {
  width: 100%;
  padding: 0.75rem;

  @media screen and (min-width: 641px) and (max-width: 768px) { width: 50%; }
  @media screen and (min-width: 769px) and (max-width: 899px) { width: 100%; }
  @media screen and (min-width: 900px) and (max-width: 1199px) { width: 50%; }
  @media screen and (min-width: 1200px) and (max-width: 1599px) { width: calc(100% / 3); }
  @media screen and (min-width: 1600px) { width: 25%; }
}
</style>
