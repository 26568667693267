import api from '@/api/'
import HuntEventModel from './huntEventModel'

class HuntEventService {
  static async create (huntId, event) {
    let response

    try {
      const { data } = await api.post(`hunts/${huntId}/events`, new HuntEventModel(event).toJSON())
      response = new HuntEventModel(data)
    } catch (error) {
      console.error(`Failed to create event for hunt ${huntId}`)
      Promise.reject(error)
    }

    return Promise.resolve(response)
  }

  static async read (huntId) {
    let response

    try {
      const { data } = await api.get(`hunts/${huntId}/events`)
      const events = data.map(event => new HuntEventModel(event))
      response = events
    } catch (error) {
      console.error(`Failed to fetch events for hunt ${huntId}`)
      Promise.reject(error)
    }

    return Promise.resolve(response)
  }

  static async update (huntId, event) {
    let response

    try {
      const { data } = await api.put(`hunts/${huntId}/events/${event.id}`, new HuntEventModel(event).toJSON())
      response = new HuntEventModel(data)
    } catch (error) {
      console.error(`Failed to update event ${event.id} for hunt ${huntId}`)
      Promise.reject(error)
    }

    return Promise.resolve(response)
  }

  static async delete (huntId, eventId) {
    let response

    try {
      await api.delete(`hunts/${huntId}/events/${eventId}`)
      response = true
    } catch (error) {
      console.error(`Failed to delete event ${eventId} for hunt ${huntId}`)
      Promise.reject(error)
    }

    return Promise.resolve(response)
  }
}

export default HuntEventService
