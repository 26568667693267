export default {
  zoom: 11,
  mapType: 'hybrid',

  labelSize: 1,
  fullscreen: false,

  // Fastighetskartan & Terrängkartan
  terrainLayer: false,
  propertyLayer: false,
  propertyBoundariesLayer: false,

  scrollZoom: false,

  isPanelActive: false
}
