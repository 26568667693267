<template>
  <div class="chat-messages">
    <slot />
  </div>
</template>

<script>
export default {
  data () {
    return {
      observer: null
    }
  },

  mounted () {
    this.$nextTick(this.scrollToBottom)

    // Observe the slot for new DOM elements (messages)
    // And scroll the entire div to bottom
    this.observer = new MutationObserver(mutations => {
      this.scrollToBottom()
    })

    this.observer.observe(this.$el, {
      attributes: true,
      childList: true,
      subtree: true
    })
  },

  destroyed () {
    this.observer.disconnect()
  },

  methods: {
    scrollToBottom () {
      const chat = this.$el

      if (chat !== null) {
        chat.scrollTop = chat.scrollHeight
      }
    }
  }
}
</script>

<style lang="scss">
.chat-messages {
  flex: 1 1 auto; // Force full-height
  height: 100%;
  width: 100%;
  padding: 1.5rem 1.5rem 0 1.5rem;
  overflow-y: auto;

  // Padding-bottom fix for IE11
  &::after {
    content: '';
    display: block;
    padding-bottom: 1.5rem;
  }
}
</style>
