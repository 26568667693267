import state from './state'
import mutations from './mutations'
import actions from './actions'
import getters from './getters'

import dashboard from '@/modules/huntArea/dashboard/store/dashboardStore'
import documents from '@/modules/huntArea/documents/store/documentStore'
import reports from '@/modules/huntArea/report/store/reportsStore'
import members from '@/modules/huntArea/members/store/memberStore'
import images from './images/'
import messages from './messages/'
import viltrapport from '@/modules/huntArea/viltrapport/store/viltrapportStore.js'

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
  modules: {
    dashboard,
    documents,
    members,
    images,
    messages,
    viltrapport,
    reports
  }
}
