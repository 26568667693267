import state from './state'
import mutations from './mutations'
import actions from './actions'
import getters from './getters'

import stands from './stands/'

const namespaced = true

export default {
  namespaced,
  state,
  actions,
  mutations,
  getters,
  modules: {
    stands
  }
}
