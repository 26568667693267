export default {
  zoomLevelNotSupportedNotification: 'One of the selected map layers does not support the current zoom level.<br/><strong>Zoom out</strong> to see all selected map layers.',
  colorPickerNotSupportedMessage: 'In order to change color, you need to upgrade to a more modern browser.',
  nameMissing: 'You must enter a name',
  invalidCoordinate: 'Invalid coordinate',
  filter: {
    title: 'Filter',
    searchPlaceholder: 'Search'
  },

  tools: {
    export: 'Download',
    import: 'Upload',
    filter: 'Filter',
    print: 'Print'
  },

  general: {
    huntingGround: 'Hunting ground',
    boundary: 'Boundary',
    boundaries: 'Boundaries',
    marker: 'Marker',
    markers: 'Markers',
    subArea: 'Sub area',
    subAreas: 'Sub areas',
    forbiddenArea: 'Forbidden area',
    forbiddenAreas: 'Forbidden areas',
    line: 'Line',
    lines: 'Lines',
    arrow: 'Arrow',
    arrows: 'Arrows',
    trails: 'Trails',
    print: 'Print',
    hectare: 'Hectare',
    size: 'Size',
    labels: 'Labels',
    zoom: 'Zoom',
    note: 'Note',
    type: 'Type',
    export: 'Export',

    noNote: 'No note',

    printLoadingMessage: 'We\'re preparing your print. This could take a couple of minutes. Please wait.',
    reallyRemoveOutsideBoundary: 'Are you sure that you want to delete <strong>{total}</strong> markers outside the boundary?',

    hasActiveHunt: 'Ongoing hunt',

    tools: {
      addMarker: 'Add Marker',
      addSubArea: 'Add Sub area',
      addForbiddenArea: 'Add Forbidden area',
      editBoundary: 'Edit boundary',
      drawLine: 'Draw Line',
      drawArrow: 'Draw Arrow'
    },

    createdAt: "Created on {date} by {name}",
    fullscreen: 'Fullscreen',
    normal: 'Normal',
    zoomIn: 'Zoom in',
    zoomOut: 'Zoom out',

    notification: {
      marker: '<b>Click on the map</b> to apply a position for the marker. Then enter the <b>name</b> and <b>type</b> in the panel before saving.',
      subArea: "<b>Draw the outer border</b> of the area on the map by <b>pointing and clicking with the mouse</b>. Start by drawing an approximate boundary, you can always fine-tune it later.",
      forbiddenArea: "<b>Draw the outer border</b> of the area on the map by <b>pointing and clicking with the mouse</b>. Start by drawing an approximate boundary, you can always fine-tune it later.",
      line: '<b>Click on the map</b> to start a line, then click again to select another point. <b>Double-click</b> on the last point.',
      arrow: "<b>Click on the map</b> to start an arrow, then click again to select another point. <b>Double-click</b> on the last point."
    }
  },

  markers: {
    pass: 'Stand',
    tower: 'Tower',
    food_place: "Feeding place",
    bait: 'Bait',
    gathering_place: 'Gathering place',
    bridge: 'Bridge',
    ford: 'Ford',
    salt_lick: 'Salt stone',
    bar: 'Road barrier',
    cam: 'Camera',
    burrow: 'Burrow',
    parking: 'Parking',
    other: 'Other',
    custom: 'Custom',

    after_search: 'Tracking',
    observation: 'Observation',
    kill: 'Downed game',
    poo: 'Droppings',
    tracks: 'Tracks',

    hunter: '',
    hunter_safe: '',
    hunter_off: '',

    dog: '',
    dog_off: ''
  },

  marker: {
    customType: 'Symbol',
    customTypeMissing: 'You must enter a symbol',
    customColor: 'Color'
  },

  print: {
    title: 'Print',
    size: 'Size',
    orientation: 'Orientation',
    portrait: 'Portrait',
    landscape: 'Landscape',
    createButtonTitle: 'Create PDF'
  },

  settings: {
    title: 'Settings',
    mapType: 'Map type',
    standard: 'Standard',
    satellite: 'Satellite',
    hybrid: 'Hybrid',
    terrain: 'Terrain map',
    propertyMap: 'Property map 2018',
    nordicPropertyBoundariesLayer: 'Nordic property boundaries',
    labels: 'Labels',
    size: 'Size',
    zoom: 'Zoom',
    scrollToZoom: 'Zoom using mouse wheel',
    removeMarkersOutsideBorder: 'Delete markers outside the boundary',
    removeAllMarkers: 'Delete all markers',
    removingMarkers: 'Deleting markers',
    removeMarkersFailed: 'One or more markers could not be removed.'
  },

  import: {
    title: 'Upload',
    content: 'Upload map data from an external <strong>GPS</strong>, or downloaded data from <strong>WeHunt</strong>.',
    readFailedErrorMesssage: 'An error occurred and the file could not be read. Please try again.',
    importFailedErrorMessage: 'One or more objects could not be uploaded to the hunting ground.',
    importSucceededMessage: 'All objects were uploaded to the hunting ground without any problems.',
    objectsWithEmptyNamesWarningMessage1: ' | A selected item has <em>no specified name</em> and can therefore <em>not be saved</em>. | {count} selected items have <em>no specified name</em> and can therefore <em>not be saved</em>.',
    objectsWithEmptyNamesWarningMessage2: ' | Return to the list and deselect the item or give it a name. | Return to the list and deselect the items or give them a name.',
    noSupportedDataMessage: 'The file does not contain any data that can be read by WeHunt.',
    selectFile: 'Choose file',

    application: '<strong>Application</strong> {name}',
    createdBy: '<strong>Created by</strong> {name}',
    createdAt: "<strong>Created on</strong> {date}",

    boundary: 'Boundary',
    boundaries: 'Boundaries',

    markers: 'Markers',
    subAreas: 'Sub areas',
    forbiddenAreas: 'Forbidden areas',

    confirmTitle: 'Upload?',
    confirmMessage: 'Are you sure you want to upload the following items to the hunting ground?',
    doImport: 'Upload',
    importingData: 'Uploading data',

    boundaryPlural: ' | boundary | boundaries',
    subAreaPlural: ' | sub area | sub areas',
    forbiddenAreaPlural: ' | forbidden area | forbidden areas',
    markerPlural: ' | marker | markers'
  },

  export: {
    title: 'Download',
    error: 'An error occurred and the file could not be saved. Please try again.',
    content1: 'Download data from the map to a <b>GPX (GPS Exchange Format)</b> file.',
    content2: 'It can be used with an <b>external GPS</b> or copied to a new <b>hunting area in WeHunt</b> later on.</p>',
    subtitle: 'What would you like to download?',
    noExport: 'There\'s no data to download',
    download: 'Download'
  }
}
