export default {
  map: null,
  center: null,
  bounds: null,

  drawingMode: null,

  isMarkersModalActive: false,
  isFilterActive: false,
  isPrintActive: false,
  isImportActive: false,
  isExportActive: false,

  propertyLayerTileLoadingProgress: {
    status: '',
    tileCount: 0,
    loadedCount: 0
  }
}
