export default {
  zoom: state => state.zoom, // Number
  mapType: state => state.mapType, // String 'standard' or 'satellite'

  labelSize: state => state.labelSize, // Number
  isFullscreen: state => state.fullscreen, // Boolean

  hasScrollZoom: state => state.scrollZoom, // Boolean

  isTerrainLayerVisible: state => state.terrainLayer, // Boolean
  isPropertyLayerVisible: state => state.propertyLayer, // Boolean
  isPropertyBoundariesLayerVisible: state => state.propertyBoundariesLayer, // Boolean

  isPanelActive: state => state.isPanelActive // Boolean
}
