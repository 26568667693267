<template>
  <TextInputField
    :value="value"
    :placeholder="placeholder || $t('general.search')"
    :disabled="disabled"
    :loading="loading"
    icon-left="icon-search"
    @input="onInput">
  </TextInputField>
</template>

<script>
export default {
  props: {
    value: {
      type: String,
      default: ''
    },

    disabled: {
      type: Boolean,
      default: false
    },

    placeholder: {
      type: String,
      default: null
    },

    loading: {
      type: Boolean,
      default: false
    }
  },

  methods: {
    onInput (value) {
      this.$emit('input', value)
    }
  }
}
</script>
