import dashboard from '@/modules/huntArea/dashboard/translations/sv'

export default {
  huntingGround: 'Jaktmark',
  date: 'Datum',
  time: 'Tidpunkt',
  newHuntingGround: 'Ny jaktmark',
  createHuntingGround: 'Skapa ny jaktmark',
  huntingGroundNamePlaceholder: 'Jaktmarken/lagets namn',
  noHuntingGroundNameErrorMessage: 'Du måste ge jaktmarken ett namn',
  createHuntingGroundFailedErrorMessage: 'Ett fel inträffade. Vänligen försök igen.',
  noHuntAreasEmptyStateMessage: 'Du är inte ansluten till någon jaktmark.<br /><br />Klicka på knappen ovan för att skapa din första jaktmark.',

  dashboard
}
