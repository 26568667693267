export default {
  huntingReport: 'Jaktrapport',
  huntingReportDefaultTitle: 'Jaktrapport {date}',

  title: 'Titel',
  titlePlaceholder: 'Namn på jakten, t.ex Älgjakt, Drevjakt etc',

  startedAt: 'Började',
  startedAtPlaceholder: 'När började jakten',
  startedAtRequired: 'Du måste ange en starttid.',

  endedAt: 'Slutade',
  endedAtPlaceholder: 'När avslutades jakten',
  endedAtRequired: 'Du måste ange en sluttid.',

  manHours: 'Mantimmar',
  manHoursAutomaticCalculation: 'Räkna ut automatiskt',

  comment: 'Kommentar',
  commentNone: 'Ingen kommentar.',

  location: 'Plats',
  locationHelpText: 'Välj ett pass genom att klicka på ett pass eller klicka i kartan för att ange en exakt position.',
  locationError: 'Du måste ange en koordinat på kartan eller välja ett pass/torn.',

  dateAndTime: 'Datum och tid',
  enterTime: 'Ange tid',
  dateError: 'Du måste ange ett datum.',
  sendToViltrapport: 'Skicka till Viltrapport',

  gameRemovedMessage: 'Du har tagit bort <strong>{gameName}</strong> som har rapporterats vidare till Viltrapport. För att ta bort informationen i Viltrapport behöver du kontakta er områdesadministratör.',
  exportFailedErrorMessage: 'Ett fel inträffade och det gick inte att exportera jaktrapporterna till Excel. Vänligen försök igen.',

  // Special String used to download animals using the correct country code regardless of language chosen
  loadAnimalsCountryCode: 'se',

  participants: {
    title: 'Deltagare',
    none: 'Det finns inga deltagare',
    add: 'Lägg till deltagare',
    select: 'Välj deltagare',
    unspecified: 'Ej angivet',
    showAll: 'Visa alla',
    emailNotFound: 'Det finns ingen användare med den angivna e-postadressen. Vill du ändå lägga till deltagaren?',
    helpText: 'Lägg till alla deltagare som ska ingå i rapporten. Du kan lägga till jaktlagets medlemmar eller bjuda in gästjägare genom att söka på deras e-post.',
    allMembersIncludedInReport: 'Alla medlemmar i jaktlaget ingår i rapporten.',
    addButtonTitle: 'Lägg till | Lägg till <strong>1</strong> deltagare | Lägg till <strong>{userCount}</strong> deltagare'
  },

  events: {
    title: 'Händelser',
    none: 'Inga händelser har rapporterats.',
    noneOccurredYet: 'Inga händelser har skett än.',
    addKill: 'Lägg till fällt vilt',
    addObservation: 'Lägg till observerat vilt',
    editKill: 'Redigera fällt vilt',
    editObservation: 'Redigera observerat vilt',
    editAfterSearch: 'Redigera eftersök',
    kill: 'Fällt',
    killShort: 'Fällt',
    observation: 'Observation',
    observationShort: 'Obs',
    afterSearch: 'Eftersök',
    afterSearchShort: 'Eftersök',
    shooter: 'Skytt',
    observer: 'Observatör'
  },

  game: {
    title: 'Vilt',
    add: 'Lägg till vilt',
    none: 'Inget vilt hittat för ditt land. Kontakta supporten.',
    error: 'Du måste lägga till minst ett vilt.',
    sent: 'Skickad',
    type: 'Typ',
    typeSelect: 'Välj typ'
  },

  invitation: {
    title: 'Jaktinbjudan',
    invited: 'Inbjudna',
    none: 'Ingen inbjudan skickades ut.'
  },

  chat: {
    title: 'Meddelande',
    none: 'Inga meddelanden har skickats än.'
  },

  markers: {
    affectedByHunt: 'Berörda av jakten',
    all: 'Alla'
  },

  removeReport: {
    title: 'Ta bort rapport',
    message: 'Vill du ta bort jaktrapporten?',
    viltrapportMessage: 'Information i denna jaktrapport har rapporterats vidare till Viltrapport. För att ta bort informationen från Viltrapport behöver du kontakta er områdesadministratör.<br/><br/>Vill du ta bort jaktrapporten?'
  },

  removeEvent: {
    title: 'Ta bort händelse',
    message: 'Vill du ta bort händelsen?',
    viltrapportMessage: 'Information i denna händelse har rapporterats vidare till Viltrapport. För att ta bort informationen från Viltrapport behöver du kontakta er områdesadministratör.<br/><br/>Vill du ta bort händelsen?'
  }
}
