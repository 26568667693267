export default {
  title: 'Viltrapport',
  quotas: 'Lovligt',
  report: 'Rapportera',
  showingQuotasForDistrict: 'Visar lovligt vilt för <strong>{name}</strong>.',
  district: 'Skötselområde',

  restrictions: 'Restriktioner',
  noRestrictionsSpecified: 'Inga generella restriktioner angivna.',

  type: 'Typ',
  allocated: 'Tilldelat',
  animalsLeft: 'Lovligt',
  quotaFull: 'Fullskjuten',
  noLimitQuota: 'Fri avskjutning',
  quotaExceeded: 'Kvot överskjuten med {count} djur',
  noQuota: 'Inget lovligt vilt.'
}
