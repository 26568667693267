<template>
  <Fragment>
    <RouterLink v-if="huntArea" :to="`/area/${huntArea.id}/dashboard`" class="has-text-white sidebar-nav-label">
      {{ name }}
    </RouterLink>

    <ul v-if="huntArea" class="sidebar-nav-list">
      <li>
        <RouterLink :to="`/area/${huntArea.id}/dashboard`">
          <Icon name="icon-layout" />
          <span>{{ $t('area.nav.dashboard') }}</span>
        </RouterLink>
      </li>

      <li>
        <RouterLink :to="`/area/${huntArea.id}/map`">
          <Icon name="icon-map" />
          <span>{{ $t('area.nav.map') }}</span>
        </RouterLink>
      </li>

      <li>
        <RouterLink :to="`/area/${huntArea.id}/members`">
          <Icon name="icon-users" />
          <span>{{ $t('area.nav.team') }}</span>
        </RouterLink>
      </li>

      <li>
        <RouterLink :to="`/area/${huntArea.id}/hunts`" :class="{ 'is-active': hasActiveHunt }">
          <Icon name="icon-crosshair" />
          <span>{{ $t('area.nav.hunts') }}</span>
        </RouterLink>
      </li>

      <li>
        <RouterLink :to="`/area/${huntArea.id}/documents`">
          <Icon name="icon-file" />
          <span>{{ $t('area.nav.documents') }}</span>
        </RouterLink>
      </li>

      <li>
        <RouterLink :to="`/area/${huntArea.id}/gallery`">
          <Icon name="icon-image" />
          <span>{{ $t('area.nav.gallery') }}</span>
        </RouterLink>
      </li>

      <!--
      <li>
        <RouterLink :to="`/area/${huntArea.id}/messages`">
          <Icon name="icon-message-square" />
          <span>{{ $t('area.nav.messages') }}</span>
        </RouterLink>
      </li>
      -->

      <li v-if="isConnectedToViltrapport">
        <RouterLink :to="`/area/${huntArea.id}/viltrapport`">
          <Icon name="icon-crosshair" />
          <span>{{ $t('nav.viltrapport') }}</span>
        </RouterLink>
      </li>

      <li>
        <RouterLink :to="`/area/${huntArea.id}/settings`">
          <Icon name="icon-settings" />
          <span>{{ $t('area.nav.settings') }}</span>
        </RouterLink>
      </li>
    </ul>
  </Fragment>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  data () {
    return {
      huntAreaId: this.$route.params.huntAreaId
    }
  },

  computed: {
    ...mapGetters({
      huntArea: 'huntarea/selected',
      isConnectedToViltrapport: 'huntarea/viltrapport/isConnected'
    }),

    hasActiveHunt () {
      const hunt = this.$store.getters['hunt/hunt']
      return hunt !== null
    },

    name () {
      return this.huntArea && this.huntArea.name
    }
  }
}
</script>
