export default {
  install (Vue, options) {
    let staticFn = 'static'

    if (options && options.name && typeof (options.name) === 'string') {
      staticFn = options.name
    }

    if (typeof (Vue.config.optionMergeStrategies[staticFn]) !== 'function') {
      Vue.config.optionMergeStrategies[staticFn] = Vue.config.optionMergeStrategies.data
    }

    if (options && options.namespaced) {
      Vue.prototype.$static = {}
    }

    Vue.mixin({
      beforeCreate () {
        const $static = this.$options[staticFn]
        const $destination = this.$static || this

        if ($static && typeof ($static) === 'function') {
          Object.assign($destination, $static.apply(this))
        } else if ($static && typeof ($static) === 'object') {
          Object.assign($destination, $static)
        }
      }
    })
  }
}
