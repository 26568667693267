export default {
  title: 'Inbjudan till {name}',
  huntAreaInviteMessage: 'Du har blivit inbjuden av <strong>{name}</strong> att bli medlem i <strong>{area}</strong>.<br/><br/>Ett kostnadsfritt <strong>WeHunt</strong>-konto behövs för att besvara inbjudan.',
  alreadyAccepted: 'Du har redan accepterat inbjudan.',
  alreadyAnswered: 'Du har redan besvarat inbjudan.',
  userAcceptedHunt: 'Du har accepterat inbjudan till jakten.',
  userDeclinedHunt: 'Du har avböjt inbjudan till jakten.',
  userAcceptedCalendarEvent: 'Du har accepterat inbjudan till aktiviteten.',
  userDeclinedCalendarEvent: 'Du har avböjt inbjudan till aktiviteten.',
  accountCreated: 'Ditt konto är skapat och inbjudan är accepterad.',
  noInviteFound: 'Inbjudan gäller inte längre.',
  joinArea: 'Gå med i jaktlaget',
  passwordsNotMatching: 'Lösenorden stämmer inte överrens',
  notAnswered: 'Ej svarat',
  accept: 'Acceptera',
  decline: 'Avböj',
  accepted: 'Accepterat',
  declined: 'Avböjt',
  unknownErrorMessage: 'Ett fel inträffade och det gick inte att besvara inbjudan. Vänligen försök igen.'
}
