import dayjs from 'dayjs'

export const HUNT_STATUS_PLANNING = 'planning'
export const HUNT_STATUS_PLANNED = 'planned'
export const HUNT_STATUS_STARTED = 'started'
export const HUNT_STATUS_PAUSED = 'paused'
export const HUNT_STATUS_FINISHED = 'finished'

export default class HuntModel {
  constructor (context) {
    const data = Object.assign({}, context)

    this.id = data.id || null
    this.name = data.name || ''
    this.status = data.status || ''

    this.note = data.note || ''

    this.huntAreaId = data.huntAreaId || null
    this.huntLeaderUserId = data.huntLeaderUserId || null
    this.afterSearchUserId = data.afterSearchUserId || null

    this.createdAt = data.createdAt !== null ? dayjs(data.createdAt).toDate() : null
    this.createdBy = data.createdBy || ''

    this.startsAt = data.startsAt ? dayjs(data.startsAt).toDate() : null
    this.endsAt = data.endsAt ? dayjs(data.endsAt).toDate() : null

    this.updatedAt = data.updatedAt !== null ? dayjs(data.updatedAt).toDate() : null
    this.updatedBy = data.updatedBy || ''

    this.firebaseDatabaseConfiguration = data.firebaseDatabaseConfiguration || null
  }

  isMultipleDays () {
    return this.startsAt.getDate() !== this.endsAt.getDate()
  }

  getLocationsSlaveForType (theType) {
    if (this.firebaseDatabaseConfiguration) {
      for (var str of this.firebaseDatabaseConfiguration) {
        const arr = str.split(' ')
        if (arr && arr.length === 2 && arr[0] === theType) return arr[1]
      }
    }
    return null
  }

  getHunterLocationsSlave () {
    return this.getLocationsSlaveForType('hunterLocations')
  }

  getGpsLocationsSlave () {
    return this.getLocationsSlaveForType('gpsLocations')
  }

  toJSON () {
    return {
      id: this.id,
      name: this.name,
      status: this.status,
      note: this.note,
      huntAreaId: this.huntAreaId,
      huntLeaderUserId: this.huntLeaderUserId,
      afterSearchUserId: this.afterSearchUserId,
      createdAt: this.createdAt,
      createdBy: this.createdBy,
      startsAt: this.startsAt,
      endsAt: this.endsAt,
      updatedAt: this.updatedAt,
      updatedBy: this.updatedBy,
      firebaseDatabaseConfiguration: this.firebaseDatabaseConfiguration
    }
  }
}
