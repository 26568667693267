import Dropdown from './Dropdown.vue'
import DropdownItem from './DropdownItem.vue'
import DropdownDivider from './DropdownDivider.vue'

export default {
  install (Vue) {
    Vue.component('Dropdown', Dropdown)
    Vue.component('DropdownItem', DropdownItem)
    Vue.component('DropdownDivider', DropdownDivider)
  }
}
