<template>
  <div :class="className" />
</template>

<script>
export default {

  data () {
    return {
      className: 'content-placeholders-img'
    }
  }

}
</script>
