export default {
  start: 'Start',
  calendar: 'Kalender',
  news: 'Nyheder',

  areas: 'Jagtområder',
  hunts: 'Jagt',

  dashboard: 'Oversigt',
  profile: 'Profil',
  viltrapport: 'Viltrapport'
}
