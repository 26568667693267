<template>
  <Fragment>
    <div class="bg-space-wrapper">
      <div
        v-if="advertisement !== null"
        :style="{ 'background-image': `url(${advertisement.imageUrl})` }"
        class="bg-space"
        @click.stop="open">
      </div>
    </div>
  </Fragment>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters({
      advertisement: 'advertisement/getSignInAd'
    })
  },

  methods: {
    open () {
      if (this.advertisement.targetUrl !== null) {
        window.location.href = this.advertisement.targetUrl
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.bg-space-wrapper {
  z-index: 0;
}

.bg-space {
  cursor: pointer;
  pointer-events: all;

  width: 100vw;
  height: 100vh;
  position: fixed;

  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  background-position: center center;
}
</style>
