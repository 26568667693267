<template>
  <div class="copyright has-text-grey-light is-size-7">
    <strong>&copy;</strong> {{ year }} - {{ brand }}
  </div>
</template>

<script>
export default {
  computed: {
    brand () {
      return process.env.VUE_APP_BRAND
    },

    year () {
      return new Date().getFullYear()
    }
  }
}
</script>

<style lang="scss">
.copyright {
  color: #808e9b;
  display: none;
  text-align: left;
  margin: 0.75rem;

  strong {
    color: inherit;
  }

  @media screen and (min-width: 1024px) {
    display: block;
  }
}
</style>
