import dayjs from 'dayjs'

class GpsPositionModel {
  constructor (context) {
    const data = Object.assign({}, context)

    this.imei = data.imei || null
    this.timestamp = dayjs(data.timestamp).toDate() || null
    this.received = dayjs(data.receivedAt).toDate() || null

    this.location = data.location ? {
      lat: data.location.lat,
      lng: data.location.lng
    } : {
      lat: 0,
      lng: 0
    }

    this.gpsSignal = data.gpsSignal || null
    this.csq = data.csq || null
    this.speed = data.speed || null
    this.battery = data.battery || null

    this.huntMode = data.huntMode || null
    this.newConnection = data.newConnection || null
  }

  toJSON () {
    return {
      imei: this.imei,
      timestamp: this.timestamp,
      received: this.received,
      location: this.location,
      gpsSignal: this.signal,
      csq: this.csq,
      speed: this.speed,
      battery: this.battery,
      huntMode: this.huntMode,
      newConnection: this.newConnection
    }
  }
}

export default GpsPositionModel
