class StripeService {
  static async checkout (sessionId) {
    let response
    const stripe = Stripe(process.env.VUE_APP_STRIPE) // eslint-disable-line

    try {
      const result = await stripe.redirectToCheckout({ sessionId })
      response = result
    } catch (error) {
      Promise.reject(error)
    }

    return Promise.resolve(response)
  }
}

export default StripeService
