import Vue from 'vue'

import GoogleMaps from '@/components/map/GoogleMaps.vue'

import BoundaryOverlay from '@/components/map/overlays/BoundaryOverlay.vue'
import LineOverlay from '@/components/map/overlays/LineOverlay.vue'
import SubAreaOverlay from '@/components/map/overlays/SubAreaOverlay.vue'
import TrailOverlay from '@/components/map/overlays/TrailOverlay.vue'

import HeatmapLayer from './layers/HeatmapLayer.vue'

import ZoomButtons from './components/ZoomButtons.vue'

import SettingsButton from './components/SettingsButton.vue'
import SettingsPanel from './components/settingsPanel/SettingsPanel.vue'

import Panel from './components/panel/Panel.vue'
import PanelTitle from './components/panel/PanelTitle.vue'
import PanelContent from './components/panel/PanelContent.vue'

Vue.component('GoogleMaps', GoogleMaps)

Vue.component('BoundaryOverlay', BoundaryOverlay)
Vue.component('LineOverlay', LineOverlay)
Vue.component('SubAreaOverlay', SubAreaOverlay)
Vue.component('TrailOverlay', TrailOverlay)

Vue.component('HeatmapLayer', HeatmapLayer)

Vue.component('ZoomButtons', ZoomButtons)

Vue.component('SettingsPanel', SettingsPanel)
Vue.component('SettingsButton', SettingsButton)

Vue.component('Panel', Panel)
Vue.component('PanelTitle', PanelTitle)
Vue.component('PanelContent', PanelContent)
