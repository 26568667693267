<template>
  <div :class="classes" class="level">
    <slot />
  </div>
</template>

<script>
export default {
  props: {
    mobile: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    classes () {
      return this.mobile ? 'is-mobile' : ''
    }
  }
}
</script>
