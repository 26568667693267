import api from '@/api'
import MemberInviteModel from '../models/memberInviteModel'

class MemberInviteService {
  static async create (huntAreaId, email, language) {
    try {
      const request = { email, language }
      const { data } = await api.post(`/areas/${huntAreaId}/invitations`, request)
      return new MemberInviteModel(data)
    } catch (error) {
      console.error(`Failed to invite ${email} to area ${huntAreaId}.`, error)
      throw error
    }
  }

  static async read (huntAreaId, inviteId) {
    const url = inviteId ? `/areas/${huntAreaId}/invitations/${inviteId}` : `/areas/${huntAreaId}/invitations`

    try {
      const { data } = await api.get(url)
      const invites = data.map(user => new MemberInviteModel(user))
      return invites
    } catch (error) {
      console.error(`Failed to read invites for area ${huntAreaId}.`, error)
      throw error
    }
  }

  static async delete (huntAreaId, inviteId) {
    try {
      await api.delete(`/areas/${huntAreaId}/invitations/${inviteId}`)
    } catch (error) {
      console.error(`Failed to delete invite ${inviteId} from area ${huntAreaId}.`, error)
      throw error
    }
  }
}

export default MemberInviteService
