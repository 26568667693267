<template>
  <article class="media alert" :class="classes">
    <figure class="media-left">
      <Icon :name="icon" />
    </figure>

    <div class="media-content">
      <slot>
        <div v-html="message" />
      </slot>
    </div>
  </article>
</template>

<script>
export default {
  props: {
    type: {
      type: String,
      default: 'default'
    },

    message: {
      type: String,
      default: ''
    }
  },

  computed: {
    classes () {
      return {
        'alert-danger': this.type === 'danger',
        'alert-info': this.type === 'info'
      }
    },

    icon () {
      const icons = {
        danger: 'icon-alert-triangle',
        info: 'icon-alert-circle'
      }

      return icons[this.type] !== undefined ? icons[this.type] : 'icon-alert-circle'
    }
  }
}
</script>

<style lang="scss">
.alert {
  background-color: white;
  border-radius: 3px;
  padding: 0.75rem;
  border: 1px solid lightgrey;

  .media-left {
    align-self: center;
  }

  &:not(:last-child) {
    margin-bottom: 1rem;
  }

  &.alert-danger {
    border-color: #eb3b5a;
    color: #eb3b5a !important;
  }

  &.alert-info {
    border-color: #3298dc;
    color: #3298dc !important;
  }

  strong {
    color: inherit !important;
  }

  :last-child {
    margin-bottom: 0 !important;
  }
}
</style>
