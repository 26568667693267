/**
 * getCurrentPosition
 * - Recieve position either from navigator.geolocation or Google Geolocation
*/
export default async function getCurrentPosition () {
  try {
    return await getCurrentClientPosition()
  } catch (error) {
    console.error('Failed to read client position, trying to lookup IP instead', error)
    return getCurrentServerPosition()
  }
}

function getCurrentClientPosition () {
  return new Promise((resolve, reject) => {
    const options = {
      timeout: 3000,
      enableHighAccuracy: true,
      maximumAge: 0
    }

    navigator.geolocation.getCurrentPosition(
      position => {
        resolve({
          lat: position.coords.latitude,
          lng: position.coords.longitude
        })
      }, error => {
        reject(error)
      }, options
    )
  })
}

async function getCurrentServerPosition () {
  const url = `https://www.googleapis.com/geolocation/v1/geolocate?key=${process.env.VUE_APP_GOOGLE}`

  try {
    const response = await fetch(url, { method: 'POST' })
    if (response.ok) {
      const json = await response.json()

      console.log(`Got geolocation from Google, ${json.location.lat}, ${json.location.lng}.`)

      return {
        lat: json.location.lat,
        lng: json.location.lng
      }
    } else {
      console.log('Failed to get geolocation from Google.')
      throw new Error()
    }
  } catch (error) {
    // Fail silently. Don't throw error.
    console.error('Failed to lookup IP', error)
  }
}
