export default {
  title: 'Jaktlaget',

  noMembersMatchedSearched: 'Inga medlemmar matchade din sökning.',

  inviteTitle: 'Lägg till medlemmar',
  inviteDescription: 'Lägg till medlemmar som ingår i jaktlaget. Alla medlemmar får tillgång till jaktmarkens karta och kan delta i jaktmarkens jakter.',
  inviteButtonTitle: 'Lägg till | Lägg till <strong>1</strong> medlemmar | Lägg till <strong>{userCount}</strong> medlemmar',
  inviteAccountNotFoundMessage: 'Vi hittar inget konto i WeHunt med denna e-postadress. Vill du ändå skicka en inbjudan?',
  inviteFailedErrorMessage: 'Ett fel inträffade och det gick inte att bjuda in medlemmen. Vänligen försök igen.',
  inviteAwaitingAnswer: 'Väntar på svar',

  removeMemberTitle: 'Ta bort medlem',
  removeMemberConfirmMessage: 'Vill du verkligen ta bort <strong>{name}</strong> från jaktmarken?',
  removeMemberFailedErrorMessage: 'Ett fel inträffade och det gick inte att ta bort medlemmen från jaktlaget. Vänligen försök igen.',

  removeInviteTitle: 'Ta bort inbjudan',
  removeInviteConfirmMessage: 'Vill du verkligen ta bort inbjudningen till <strong>{email}</strong>?',
  removeInviteFailedErrorMessage: 'Ett fel inträffade och det gick inte att ta bort inbjudningen. Vänligen försök igen.'
}
