class HuntGameModel {
  constructor (context) {
    const data = Object.assign({}, context)

    this.id = data.id || null
    this.animalId = data.animalId || null
    this.name = data.name || ''
    this.category = data.category || ''
    this.note = data.note || ''

    this.editable = false
  }

  toJSON () {
    return {
      animalId: this.animalId,
      name: this.name,
      category: this.category,
      note: this.note
    }
  }
}

export default HuntGameModel
