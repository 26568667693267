import dayjs from 'dayjs'

class MessageModel {
  constructor (context) {
    const data = Object.assign({}, context)

    this.id = data.id || null

    this.text = data.messageText || ''
    this.createdBy = data.senderUserId ? data.senderUserId.substring(0, data.senderUserId.indexOf('_')) : null
    this.createdAt = data.timestamp ? dayjs(data.timestamp).toDate() : null

    this.imagePath = data.imagePath || ''
    this.src = data.src || ''
  }

  toJSON () {
    return {
      id: this.id,

      text: this.text,
      createdAt: this.createdAt,
      createdBy: this.createdBy,

      imagePath: this.imagePath,
      src: this.src
    }
  }
}

export default MessageModel
