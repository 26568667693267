export default {

  set (state, events) {
    state.events = events
  },

  add (state, events) {
    if (!state.events) state.events = []
    state.events.push(events)
  },

  update (state, event) {
    const i = state.events.findIndex(e => e.id === event.id)
    state.events.splice(i, 1, event)
  },

  delete (state, event) {
    const i = state.events.indexOf(event)
    if (i !== -1) state.events.splice(i, 1)
  },

  select (state, event) {
    state.selected = event
  },

  isAddingEvent (state, bool) {
    state.isAddingEvent = bool
  },

  setType (state, type) {
    state.type = type
  }

}
