export default [{
  path: '/signin',
  name: 'SignIn',
  component: () => import('./components/SignInPage.vue'),
  meta: {
    isAuthView: true,
    isPublic: true
  }
}, {
  path: '/join',
  name: 'Join',
  component: () => import('./components/JoinPage.vue'),
  meta: {
    isAuthView: true,
    isPublic: true
  }
}, {
  path: '/recover',
  name: 'Recover',
  component: () => import('./components/RecoverPasswordPage.vue'),
  meta: {
    isAuthView: true,
    isPublic: true
  }
}, {
  path: '/updatePassword',
  name: 'UpdatePassword',
  component: () => import('./components/UpdatePasswordPage.vue'),
  meta: {
    isAuthView: true,
    isPublic: true
  }
}, {
  path: '/signout',
  name: 'SignOut',
  component: () => import('./components/SignOut.vue'),
  meta: {
    isPublic: true
  }
}]
