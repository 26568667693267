<template>
  <Wrapper class="subpage page-not-found">
    <Titlebar />

    <EmptyState icon="icon-thumbs-down" :tagline="$t('general.pageNotFound')">
      <Button :title="$t('general.goBack')" @click="goBack" />
    </EmptyState>
  </Wrapper>
</template>

<script>
export default {
  methods: {
    goBack () {
      this.$router.go(-1)
    }
  }
}
</script>

<style lang="scss" scoped>
.page-not-found {
  display: flex;
  flex-direction: column;
}
</style>
