export default {
  title: 'Nyheter',
  readMore: 'Läs mer',
  readFullArticle: 'Läs mer hos {source}',
  showMore: 'Visa fler...',
  ad: 'Annons',
  advertisedContent: 'Annonserat innehåll',
  loadFailedErrorMessage: 'Ett fel inträffade och det gick inte att ladda ner nyheterna. Vänligen försök igen.',
  newsItemNotFoundTitle: 'Det gick inte att ladda nyheten',
  newsItemNotFoundMessage: 'Det verkar som att du klickat på en felaktig länk. Vänligen försök igen.'
}
