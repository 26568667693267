export default {
  title: 'Invited to {name}',
  huntAreaInviteMessage: 'You\'ve been invited by <strong>{name}</strong> to join <strong>{area}</strong>.<br/><br/>A free <strong>WeHunt</strong>-account is needed to answer the invitation.',
  alreadyAccepted: 'You\'ve already accepted the invitation.',
  alreadyAnswered: 'You\'ve already answered the invitation.',
  userAcceptedHunt: 'You\'ve accepted the hunt invitation',
  userDeclinedHunt: 'You\'ve declined the hunt invitation',
  userAcceptedCalendarEvent: 'You\'ve accepted the event invitation',
  userDeclinedCalendarEvent: 'You\'ve declined the event invitation',
  accountCreated: "Your account has been created and the invitation has been accepted.",
  noInviteFound: 'The invitation is no longer available.',
  joinArea: 'Join hunting ground',
  passwordsNotMatching: "Password doesn't match",
  notAnswered: 'Not Answered',
  accept: 'Accept',
  decline: 'Decline',
  accepted: 'Accepted',
  declined: 'Declined',
  unknownErrorMessage: 'An error occurred and it was not possible to answer the invitation. Please try again.'
}
