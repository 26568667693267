export default {
  title: 'FREE PRO',
  subtitle: 'We\'re currently giving away WeHunt Pro all of August',

  getPro: 'Get Pro',
  readMore: 'Read more',

  infoText: "Sign up for a yearly subscription of €23,90 and get August for free. Cancel anytime.",
  price: 'August free, then €23,90/year'
}
