import BoundaryModel from '@/api/map/boundary/boundaryModel'

export default {

  setMode (state, mode) {
    state.mode = mode
  },

  setZoom (state, zoom) {
    state.zoom = parseInt(zoom)
  },

  zoomIn (state) {
    state.zoom += 1
  },

  zoomOut (state) {
    state.zoom -= 1
  },

  setType (state, type) {
    state.type = type
  },

  setMarkers (state, markers) {
    state.markers = markers
  },

  setSubAreas (state, subAreas) {
    state.subAreas = subAreas
  },

  setForbiddenAreas (state, forbiddenAreas) {
    state.forbiddenAreas = forbiddenAreas
  },

  setBoundary (state, coordinates) {
    if (coordinates) {
      state.boundary = new BoundaryModel({
        coordinates: coordinates,
        visible: true,
        selected: false,
        editable: false
      })
    } else {
      state.boundary = null
    }
  },

  setSelectedSubArea (state, subArea) {
    state.selectedSubArea = subArea
  },

  setSelectedMarker (state, marker) {
    state.selectedMarker = marker
  },

  setCenter (state, latLng) {
    state.center = latLng
  },

  toggleSettingsPanel (state) {
    state.settingsPanelActive = !state.settingsPanelActive
  },

  setMarkerSize (state, size) {
    state.markerSize = size
  },

  setLabelSize (state, size) {
    state.labelSize = size
  },

  showLabels (state, bool) {
    state.showLabels = bool
  },

  toggleTerrainLayer (state, bool) {
    state.terrainLayer = bool
  },

  togglePropertyLayer (state, bool) {
    state.propertyLayer = bool
  }

}
