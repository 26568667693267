import api from '@/api/'

class BoundaryService {
  static async read (huntAreaId) {
    try {
      const { data } = await api.get(`/areas/${huntAreaId}/map`)
      return data // Array of coordinates
    } catch (error) {
      console.error(`Failed to read boundary for huntarea ${huntAreaId}.`)
      throw error
    }
  }

  static async update (huntAreaId, coordinates) {
    try {
      await api.put(`/areas/${huntAreaId}/map`, coordinates)
    } catch (error) {
      console.error(`Failed to update boundary for huntarea ${huntAreaId}.`)
      throw error
    }
  }
}

export default BoundaryService
