import api from "@/api"
import AdvertisementModel from "../models/advertisementModel"

class AdvertisementService {
  static async read () {
    try {
      const { data } = await api.get("/ads")
      return data.map((data) => new AdvertisementModel(data))
    } catch (error) {
      console.error("Failed to read advertisements.\n", error)
      return []
    }
  }
}

export default AdvertisementService
