<template>
  <a
    :class="[`is-${size}`, isDark]"
    class="button is-text is-loading">
    &nbsp;
  </a>
</template>

<script>
export default {
  props: {
    size: {
      type: String,
      default: 'normal'
    },

    dark: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    isDark () {
      return this.dark ? 'is-dark' : ''
    }
  }
}
</script>

<style lang="scss" scoped>
.button {
  margin-bottom: 0;

  &.is-loading.is-dark {
    background: transparent;

    &::after {
      border-color: transparent transparent #333 #333 !important;
    }
  }
}
</style>
