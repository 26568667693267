<template>
  <img
    v-if="hasLogo"
    :src="advertisement.logoUrl"
    class="logo"
    :class="{ 'is-clickable': advertisement.targetUrl }"
    @click.stop="open" />
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters({
      advertisement: 'advertisement/getSignInAd'
    }),

    hasLogo () {
      return this.advertisement && this.advertisement.logoUrl
    }
  },

  methods: {
    open () {
      if (this.advertisement.targetUrl !== null) {
        window.location.href = this.advertisement.targetUrl
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.logo {
  width: 200px;
  height: 200px;
  position: absolute;
  right: calc(2.5rem + 640px);
  bottom: 1.5rem;

  @media screen and (max-width: 1600px) {
    right: calc(2.5rem + 480px);
  }

  @media screen and (max-width: 1400px) {
    right: calc(2.5rem + 480px);
  }

  @media screen and (max-width: 768px) {
    width: 100px;
    height: 100px;
    right: 1.5rem;
    bottom: 0.75rem;
  }
}
</style>
