import HuntAreaStatusModel from '../models/huntAreaStatusModel'
import HuntAreaQuotaStatusModel from '../models/huntAreaQuotaStatusModel'
import ViltrapportReportModel from '../models/viltrapportReportModel'

/**
 * Handles all communication with the Viltrapport server.
 */
class ViltrapportService {
  constructor (credentials) {
    this.credentials = credentials
  }

  async getConnectedDistricts (huntAreaId) {
    try {
      const url = `${process.env.VUE_APP_VILTRAPPORT_URL}/api/v2/huntareas/${huntAreaId}_LLGroup/status`

      const response = await fetch(url)
      if (response.ok) {
        const json = await response.json()
        return json.map(data => new HuntAreaStatusModel(data))
      } else {
        if (response.status === 404) {
          return []
        } else {
          throw new Error('Unknown error when fetching Viltrapport status for huntarea ' + huntAreaId + '.')
        }
      }
    } catch (error) {
      console.error('Failed to get Viltrapport status on ' + huntAreaId + '.')
      throw error
    }
  }

  async getQuotas (huntAreaId, districtId) {
    try {
      let url = `${process.env.VUE_APP_VILTRAPPORT_URL}/api/v2/huntareas/${huntAreaId}_LLGroup/quotas`

      if (districtId) {
        url += '?districtId=' + districtId
      }

      const headers = new Headers({
        Authorization: `Basic ${this.credentials}`
      })

      const response = await fetch(url, { headers: headers })
      if (response.ok) {
        const json = await response.json()
        return new HuntAreaQuotaStatusModel(json)
      } else {
        throw new Error('Unknown error when getting quotas for hunt area ' + huntAreaId)
      }
    } catch (error) {
      console.error('Failed to get quotas from Viltrapport for ' + huntAreaId + '.')
      throw error
    }
  }

  async getGames (huntAreaId, districtId) {
    try {
      const url = `${process.env.VUE_APP_VILTRAPPORT_URL}/api/v2/huntareas/${huntAreaId}_LLGroup/games?districtId=${districtId}`

      const headers = new Headers({
        Authorization: `Basic ${this.credentials}`
      })

      const response = await fetch(url, { headers: headers })
      if (response.ok) {
        const json = await response.json()
        return json
      } else {
        throw new Error('Unknown error when getting games for hunt area ' + huntAreaId)
      }
    } catch (error) {
      console.error('Failed to get games from Viltrapport for ' + huntAreaId + '.')
      throw error
    }
  }

  async createReport (huntAreaId, viltrapportReport) {
    try {
      const url = `${process.env.VUE_APP_VILTRAPPORT_URL}/api/v2/huntareas/${huntAreaId}_LLGroup/reports`

      const options = {
        method: 'POST',
        body: JSON.stringify(viltrapportReport.toJSON()),
        headers: new Headers({
          Authorization: `Basic ${this.credentials}`,
          'Content-Type': 'application/json'
        })
      }

      const response = await fetch(url, options)
      if (!response.ok) {
        throw new Error('Unknown error when creating Viltrapport report.')
      }
    } catch (error) {
      console.error('Failed to create Viltrapport report.')
      console.error(error)
      throw error
    }
  }

  async getReports (huntAreaId, externalId) {
    try {
      const url = `${process.env.VUE_APP_VILTRAPPORT_URL}/api/v2/huntareas/${huntAreaId}_LLGroup/reports/${externalId}`

      const headers = new Headers({
        Authorization: `Basic ${this.credentials}`
      })

      const response = await fetch(url, { headers: headers })
      if (response.ok) {
        const json = await response.json()
        return json.map(data => new ViltrapportReportModel(data))
      } else {
        throw new Error(`Unknown error when getting Viltrapport reports for ${externalId}.`)
      }
    } catch (error) {
      console.error('Failed to get Viltrapport reports.')
      console.error(error)
      throw error
    }
  }
}

export default ViltrapportService
