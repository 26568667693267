export default {
  upcomingHunts: 'Chasses à venir',
  huntAreas: 'Territoires de chasse',
  members: 'Membres',
  ongoingHuntOnHuntingGround: 'Une chasse est en cours sur <strong>{huntArea}</strong>.',
  mapPack: 'Pack de cartes',
  buyNow: 'Acheter maintenant',

  upcomingEvents: {
    title: 'Activités à venir',
    noEvents: "Il n'y a aucune activité à venir"
  },

  lastVisited: {
    title: 'Dernier visité',
    randomHuntArea: 'Territoire de chasse aléatoire',
    noHuntAreas: "Vous n'avez créé aucun territoire de chasse. Cliquez ici pour commencer.",
    newHuntArea: 'Nouveau territoire de chasse'
  },

  subscription: {
    upgradeTitle: 'Mise à niveau',
    upgradeSubtitle: "Tirez le meilleur parti de WeHunt en passant à l’une de nos solutions d’abonnement. Accédez à l'indicateur météo, à la table de chasse, au démarrage des chasses et bien plus encore.",
    trialMessage: "Vous disposez d'une <strong>période d'essai</strong> de WeHunt Standard qui expire le <strong>{expiresAt}</strong>.",
    subscriptionExpires: 'Votre abonnement expire',
    nextPaymentAt: 'Se renouvelle automatiquement le',
    upgrade: 'Mise à niveau'
  },

  invites: {
    accept: 'Accepter',
    decline: 'Refuser',
    accepted: 'Vous avez accepté',
    declined: 'Vous avez refusé'
  }
}
