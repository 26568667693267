import api from '@/api'
import HuntAreaModel, { HuntAreaFirebaseConfig } from './huntAreaModel'

class HuntAreaService {
  static async create (huntArea) {
    try {
      const { data } = await api.post('/areas', huntArea.toJSON())
      return new HuntAreaModel(data)
    } catch (error) {
      console.error('Failed to create new huntarea.', error)
      throw error
    }
  }

  static async readAll () {
    try {
      const { data } = await api.get('/areas')
      const huntAreas = data.map(huntArea => new HuntAreaModel(huntArea))
      return huntAreas
    } catch (error) {
      console.error('Failed to read huntareas')
      throw error
    }
  }

  static async read (huntAreaId) {
    try {
      const { data } = await api.get(`/areas/${huntAreaId}`)
      return new HuntAreaModel(data)
    } catch (error) {
      console.error(`Failed to read huntarea ${huntAreaId}.`, error)
      throw error
    }
  }

  static async update (huntArea) {
    try {
      const { data } = await api.put(`/areas/${huntArea.id}`, huntArea.toJSON())
      return new HuntAreaModel(data)
    } catch (error) {
      console.error(`Failed to update huntarea ${huntArea.id}.`)
      throw error
    }
  }

  static async delete (huntAreaId) {
    try {
      await api.delete(`/areas/${huntAreaId}`)
    } catch (error) {
      console.error(`Failed to delete huntarea ${huntAreaId}.`, error)
      throw error
    }
  }

  static async updateFirebaseConfig (huntArea) {
    try {
      const { data } = await api.post(`/areas/${huntArea.id}/updatefirebaseconfig`)
      huntArea.firebaseConfig = new HuntAreaFirebaseConfig(data)
    } catch (error) {
      console.error(`Failed to update firebase config for huntarea ${huntArea.id}.`)
      throw error
    }
  }
}

export default HuntAreaService
