export default {
  getPro: 'Få Pro',

  selectLanguage: 'Velg ditt språk',

  feedback: 'Feedback',
  leaveFeedback: 'Gi feedback',

  games: 'Spill',
  elkGame: 'Älgjaktsbanan',
  boarGame: 'Viltsvinspasset',

  theme: 'Tema',
  themeContent: 'Surfer du de fleste kvelder?',
  dark: 'Mørk'
}
