<template>
  <SegmentedControl
    v-model="mapType"
    size="small"
    :segments="segments">
  </SegmentedControl>
</template>

<script>
export default {
  computed: {
    segments () {
      return [{
        title: this.$i18n.t('map.settings.standard'),
        value: 'roadmap'
      }, {
        title: this.$i18n.t('map.settings.satellite'),
        value: 'satellite'
      }, {
        title: this.$i18n.t('map.settings.hybrid'),
        value: 'hybrid'
      }]
    },

    mapType: {
      get () {
        return this.$store.getters['map/settings/mapType']
      },

      set (mapType) {
        return this.$store.dispatch('map/settings/setMapType', mapType)
      }
    }
  }
}
</script>
