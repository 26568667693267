import ProfileService from '../services/profileService'
import UserSettingsModel from '../models/userSettingsModel'
import * as storage from '@/assets/libs/storage/'

export default {
  namespaced: true,

  state: {
    profile: null
  },

  getters: {
    data: state => state.profile,
    getProfile: state => state.profile
  },

  mutations: {
    setProfile (state, profile) {
      state.profile = profile
    }
  },

  actions: {
    read ({ commit }, userId) {
      return ProfileService.read(userId)
    },

    async update ({ commit, getters }, data) {
      const profile = getters.getProfile
      const mergedProfile = Object.assign({}, profile, data)

      const updatedProfile = await ProfileService.update(new UserSettingsModel(mergedProfile))

      if ((storage.local.read('remember') !== 'false')) {
        storage.local.add('profile', JSON.stringify(updatedProfile))
      } else {
        storage.session.add('profile', JSON.stringify(updatedProfile))
      }

      commit('setProfile', updatedProfile)
    },

    readProfilePicture ({ dispatch }, userId) {
      return ProfileService.readProfilePicture(userId)
    },

    clear ({ commit }) {
      commit('setProfile', null)
    }
  }
}
