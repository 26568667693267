export default {
  zoomLevelNotSupportedNotification: "Une des couches sélectionnée de la carte ne prend pas en charge le niveau de zoom actuel.<br/><strong>Réduisez</strong> pour voir toutes les couches sélectionnées de la carte.",
  colorPickerNotSupportedMessage: "Pour changer de couleur, vous devez passer à un navigateur plus moderne.",
  nameMissing: "Vous devez saisir un nom.",
  invalidCoordinate: "Coordonnée invalide",
  filter: {
    title: "Filtre",
    searchPlaceholder: "Recherche"
  },

  tools: {
    export: "Télécharger",
    import: "Télécharger",
    filter: "Filtre",
    print: "Imprimer"
  },

  general: {
    huntingGround: "Territoire de chasse",
    boundary: "Limite de la propriété",
    boundaries: "Limites de la propriété",
    marker: "Marqueur",
    markers: "Marqueurs",
    subArea: "Sous-zone",
    subAreas: "Sous-zones",
    forbiddenArea: "Zone interdite",
    forbiddenAreas: "Zones interdites",
    line: "Ligne",
    lines: "Lignes",
    arrow: "Flèche",
    arrows: "Flèches",
    trails: "Sentiers",
    print: "Imprimer",
    hectare: 'Hectare',
    size: "Taille",
    labels: "Étiquettes",
    zoom: 'Zoom',
    note: 'Note',
    type: "Type",
    export: "Exporter",

    noNote: "Aucune note",

    printLoadingMessage: "Nous préparons votre impression. Cela peut prendre quelques minutes. Veuillez patienter.",
    reallyRemoveOutsideBoundary: "Êtes-vous sûr de vouloir supprimer <strong>{total></strong> marqueurs en dehors de la limite ?",

    hasActiveHunt: "Chasse en cours",

    tools: {
      addMarker: "Ajouter un marqueur",
      addSubArea: "Ajouter une sous-zone",
      addForbiddenArea: "Ajouter une zone interdite",
      editBoundary: "Modifier la limite",
      drawLine: "Dessiner une ligne",
      drawArrow: "Dessiner une flèche"
    },

    createdAt: "Créé le {date} par {name}",
    fullscreen: "Plein écran",
    normal: "Normal",
    zoomIn: "Agrandir",
    zoomOut: "Réduire",

    notification: {
      marker: "<b>Cliquez sur la carte</b> pour appliquer une position au marqueur. Saisissez ensuite le <b>nom</b> et le <b>type</b> dans le panneau avant d'enregistrer.",
      subArea: "<b>Dessinez la bordure extérieure</b> de la zone sur la carte en <b>pointant et cliquant avec la souris</b>. Commencez par tracer une limite approximative, vous pourrez toujours l’affiner plus tard.",
      forbiddenArea: "<b>Dessinez la bordure extérieure</b> de la zone sur la carte en <b>pointant et cliquant avec la souris</b>. Commencez par tracer une limite approximative, vous pourrez toujours l’affiner plus tard.",
      line: "<b>Cliquez sur la carte</b> pour démarrer une ligne, puis cliquez à nouveau pour sélectionner un autre point. <b>Double-cliquez</b> sur le dernier point.",
      arrow: "<b>Cliquez sur la carte</b> pour démarrer une flèche, puis cliquez à nouveau pour sélectionner un autre point. <b>Double-cliquez</b> sur le dernier point."
    }
  },

  markers: {
    pass: "Poste",
    tower: "Mirador",
    food_place: "place d'agrainage",
    bait: "Appât",
    gathering_place: "Lieu de rassemblement",
    bridge: "Pont",
    ford: "Gué",
    salt_lick: "Pierre de sel",
    bar: "Barrière routière",
    cam: "Caméra",
    burrow: "Terrier",
    parking: 'Parking',
    other: "Autre",
    custom: "personnalisé",

    after_search: "Suivi",
    observation: 'Observation',
    kill: "Gibier prélevé",
    poo: "Excréments",
    tracks: "Pistes",

    hunter: '',
    hunter_safe: '',
    hunter_off: '',

    dog: '',
    dog_off: ''
  },

  marker: {
    customType: "Symbole",
    customTypeMissing: "Vous devez saisir un symbole",
    customColor: "Couleur"
  },

  print: {
    title: "Imprimer",
    size: "Taille",
    orientation: 'Orientation',
    portrait: 'Portrait',
    landscape: "Paysage",
    createButtonTitle: "Créer un PDF"
  },

  settings: {
    title: "Paramètres",
    mapType: "Type de carte",
    standard: 'Standard',
    satellite: 'Satellite',
    hybrid: "Hybride",
    terrain: "Carte du relief",
    propertyMap: "Carte de la propriété de 2018",
    nordicPropertyBoundariesLayer: "Limites de propriété nordique",
    labels: "Étiquettes",
    size: "Taille",
    zoom: 'Zoom',
    scrollToZoom: "Agrandir avec la molette de la souris",
    removeMarkersOutsideBorder: "Supprimer les marqueurs en dehors de la limite",
    removeAllMarkers: "Supprimer tous les marqueurs",
    removingMarkers: "Supprimer des marqueurs",
    removeMarkersFailed: "Un ou plusieurs marqueurs n'ont pas pu être supprimés."
  },

  import: {
    title: "Télécharger",
    content: "Téléchargez des données cartographiques à partir d'un <strong>GPS</strong> externe ou des données téléchargées depuis <strong>WeHunt</strong>.",
    readFailedErrorMesssage: "Une erreur s'est produite et le fichier n'a pas pu être lu. Veuillez réessayer.",
    importFailedErrorMessage: "Un ou plusieurs objets n'ont pas pu être téléchargés sur le territoire de chasse.",
    importSucceededMessage: "Tous les objets ont été téléchargés sur le territoire de chasse sans aucun problème.",
    objectsWithEmptyNamesWarningMessage1: " | Un élément sélectionné n'a <em>pas de nom spécifié</em> et ne peut donc <em>pas être enregistré</em>. | {count} éléments sélectionnés n'ont <em>pas de nom spécifié</em> et ne peuvent donc <em>pas être enregistrés</em>.",
    objectsWithEmptyNamesWarningMessage2: " | Revenez à la liste et désélectionnez l'élément ou donnez-lui un nom. | Revenez à la liste et désélectionnez les éléments ou donnez-leur un nom.",
    noSupportedDataMessage: "Le fichier ne contient aucune donnée lisible par WeHunt.",
    selectFile: "Choisir un fichier",

    application: "<strong>Application</strong> {nom}",
    createdBy: "<strong>Créé par</strong> {name}",
    createdAt: "<strong>Créé le</strong> {date}",

    boundary: "Limite de la propriété",
    boundaries: "Limites de la propriété",

    markers: "Marqueurs",
    subAreas: "Sous-zones",
    forbiddenAreas: "Zones interdites",

    confirmTitle: "Télécharger?",
    confirmMessage: "Êtes-vous sûr de vouloir télécharger les éléments suivants sur le territoire de chasse ?",
    doImport: "Télécharger",
    importingData: "Téléchargement de données",

    boundaryPlural: " | limite de la propriété | limites de la propriété",
    subAreaPlural: " | sous-zone | sous-zones",
    forbiddenAreaPlural: " | zone interdite | zones interdites",
    markerPlural: " | marqueur | marqueurs"
  },

  export: {
    title: "Télécharger",
    error: "Une erreur s'est produite et le fichier n'a pas pu être enregistré. Veuillez réessayer.",
    content1: "Téléchargez des données sur la carte jusqu'à un fichier <b>GPX (GPS Exchange Format)</b>.",
    content2: "Il peut être utilisé avec un <b>GPS externe</b> ou copié ultérieurement dans une nouvelle <b>zone de chasse dans WeHunt</b>.</p>",
    subtitle: "Que souhaiteriez-vous télécharger ?",
    noExport: "Il n'y a aucune donnée à télécharger.",
    download: "Télécharger"
  }
}
