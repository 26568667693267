<template>
  <Fragment v-if="hasLoaded">
    <UnsupportedBrowserBanner v-if="isIE" />

    <Auth v-if="$route.meta.isAuthView" />

    <div v-if="pathIncludesInvite">
      <RouterView />
    </div>

    <div v-if="!$route.meta.isAuthView && !pathIncludesInvite" class="app">
      <div class="layout">
        <Navbar />
        <Sidebar />

        <div class="wrapper scrollbar-auto">
          <RouterView />
        </div>
      </div>

      <CursorLoader />
    </div>

    <CookiesNotification />
    <GpsActivationDialog />

    <PortalTarget name="loader" />
    <PortalTarget name="modal" />
    <PortalTarget name="huntareas-panel" />
    <PortalTarget name="gps-panel" />
  </Fragment>
</template>

<script>
import { mapGetters } from 'vuex'
import { eventManager } from '@/main'
import { isLanguageSupported } from '@/i18n.js'
import * as storage from '@/assets/libs/storage/'

import Auth from '@/modules/auth/components/Auth.vue'
import firebaseClient from '@/api/firebase/firebaseClient'
import CookiesNotification from '@/modules/core/components/CookiesNotification.vue'
import GpsActivationDialog from '@/modules/subscription/components/GpsActivationDialog.vue'

import UnsupportedBrowserBanner from '@/modules/core/components/browserSupport/UnsupportedBrowserBanner.vue'

export default {
  components: {
    Auth,
    CookiesNotification,
    GpsActivationDialog,
    UnsupportedBrowserBanner
  },

  data () {
    return {
      hasLoaded: false
    }
  },

  computed: {
    ...mapGetters({
      hasAuth: 'auth/hasAuth'
    }),

    pathIncludesInvite () {
      return this.$route.path.includes('invitation') || this.$route.path.includes('invite')
    },

    isIE () {
      return this.$utils.isInternetExplorer()
    }
  },

  watch: {
    hasAuth (isSignedIn) {
      if (isSignedIn) {
        this.$store.dispatch('subscription/read')
        this.$store.dispatch('user/getCurrentPosition')

        this.$store.dispatch('huntarea/readAll')

        this.$store.dispatch('hunt/readAllForUser')
        this.$store.dispatch('hunt/invites/readAll')

        this.signInToFirebase()
      }
    }
  },

  beforeMount () {
    if (storage.has('user')) this.setUser()

    if (storage.has('profile')) {
      this.setProfile()
      this.setUserLanguage()
    }

    this.readAdvertisement()

    // Browser detection
    if (this.isIE) {
      document.documentElement.className += ' ie'
    }
  },

  created () {
    this.setDefaultLanguage()

    this.$store.dispatch('auth/autoSignIn')

    eventManager.$on('setUser', user => this.set('user', 'setUser', user))
    eventManager.$on('setProfile', profile => {
      this.set('profile', 'setProfile', profile)
      this.setUserLanguage()
    })
  },

  mounted () {
    // In order to avoid unnecessary painting of DOM
    setTimeout(() => {
      this.hasLoaded = true
    }, 250)

    // if touch device === grid view
    this.setGridView()
  },

  methods: {
    closeModal () {
      this.$router.push('/')
    },

    set (name, method, payload) {
      this.$store.commit(`${name}/${method}`, payload)

      if ((storage.local.read('remember') !== 'false')) {
        storage.local.del(name)
        storage.local.add(name, JSON.stringify(payload))
      } else {
        storage.session.del(name)
        storage.session.add(name, JSON.stringify(payload))
      }
    },

    setUser () {
      this.$store.commit('user/setUser', JSON.parse(storage.has('user')))
    },

    setProfile () {
      this.$store.commit('profile/setProfile', JSON.parse(storage.has('profile')))
    },

    setGridView () {
      const isMobile = window.matchMedia('(max-width: 769px)')

      if (isMobile.matches) {
        this.$store.dispatch('huntarea/members/changeView', 'grid')
        this.$store.dispatch('huntarea/documents/setView', 'grid')
      }
    },

    signInToFirebase () {
      firebaseClient.signIn()
    },

    readAdvertisement () {
      this.$store.dispatch('advertisement/read')
    },

    setDefaultLanguage () {
      const currentLanguage = this.$i18n.locale
      this.$store.dispatch('ui/setLanguage', currentLanguage)

      console.log('Changed default UI language to \'' + currentLanguage + '\'.')
    },

    setUserLanguage () {
      const profile = this.$store.getters['profile/data']

      if (profile.languageCode !== null && isLanguageSupported(profile.languageCode)) {
        console.log('Changed UI language from profile to \'' + profile.languageCode + '\'.')
        this.$store.dispatch('ui/setLanguage', profile.languageCode)
      }
    }
  }
}
</script>

<style lang="scss">
@import "@/assets/scss/style.scss";

.app {
  height: 100%;
  display: flex;
  flex-direction: column;

  .layout {
    display: flex;
    flex-direction: column;
    z-index: 1;
    flex: 1 1 auto;
    min-height: 1px;
    overflow-y: auto;

    @media screen and (min-width: 1024px) {
      flex-direction: row;
    }
  }

  .wrapper {
    position: relative;
    flex: 1 1 auto;
    min-height: 1px;
    overflow-y: auto;
    display: flex;
    flex-direction: column;

    .section {
      width: 100%;
      padding: 0;
    }
  }

  .page {
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
  }

  .subpage {
    flex: 1 1 auto;
    overflow-y: auto;
  }
}
</style>
