export default {
  upcomingHunts: 'Upcoming hunts',
  huntAreas: 'Hunting grounds',
  members: 'Members',
  ongoingHuntOnHuntingGround: 'There is an ongoing hunt on <strong>{huntArea}</strong>.',
  mapPack: 'Mappack',
  buyNow: 'Buy now',

  upcomingEvents: {
    title: 'Upcoming activities',
    noEvents: 'There\'s no upcoming activities'
  },

  lastVisited: {
    title: 'Last visited',
    randomHuntArea: 'Random hunting ground',
    noHuntAreas: 'You have not created any hunting grounds. Click here to get started.',
    newHuntArea: 'New hunting ground'
  },

  subscription: {
    upgradeTitle: 'Upgrade',
    upgradeSubtitle: 'Get more out of WeHunt by upgrading to one of our subscription solutions. Get access to weather indicator, hunting table, start hunts and much more.',
    trialMessage: 'You have a <strong>trial period</strong> of WeHunt Standard that expires on <strong>{expiresAt}</strong>.',
    subscriptionExpires: 'Your subscription expires',
    nextPaymentAt: 'Automatically renews on',
    upgrade: 'Upgrade'
  },

  invites: {
    accept: 'Accept',
    decline: 'Decline',
    accepted: 'You have accepted',
    declined: 'You have declined'
  }
}
