<template>
  <div class="field">
    <label class="label text-is-normal is-marginless" for="scale">{{ $t('map.general.markers') }}</label>

    <div class="control">
      <div class="select is-small">
        <select v-model="markerSize">
          <option
            v-for="(size, i) in sizes"
            :key="i"
            :value="size"
            :selected="markerSize === size">
            {{ size * 100 }}%
          </option>
        </select>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      sizes: [0.5, 0.75, 1, 1.25, 1.5, 1.75, 2, 2.25, 2.5]
    }
  },

  computed: {
    markerSize: {
      get () { return this.$store.getters['map/markers/size'] },
      set (size) { return this.$store.dispatch('map/markers/setSize', size) }
    }
  }
}
</script>

<style scoped>
.field {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.field .label {
  margin: 0;
}
</style>
