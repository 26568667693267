<template>
  <Fragment>
    <div class="sidebar-nav-label">
      Meny
    </div>

    <ul v-if="!hasAuth" class="sidebar-nav-list push-bottom">
      <li>
        <RouterLink to="/signin">
          {{ $t('general.signin') }}
        </RouterLink>
      </li>

      <li>
        <RouterLink to="/join">
          {{ $t('general.signup') }}
        </RouterLink>
      </li>
    </ul>

    <ul v-if="hasAuth" class="sidebar-nav-list push-bottom">
      <li>
        <RouterLink to="/">
          <Icon name="icon-home" />
          <span>Start</span>
        </RouterLink>
      </li>

      <li>
        <RouterLink to="/calendar">
          <Icon name="icon-calendar" />
          <span>{{ $t('nav.calendar') }}</span>
          <span v-if="totalInvites" class="tag is-small is-rounded push-left-xxs">{{ totalInvites }}</span>
        </RouterLink>
      </li>

      <li>
        <RouterLink to="/news">
          <Icon name="icon-layers" />
          <span>{{ $t('nav.news') }}</span>
        </RouterLink>
      </li>

      <li :class="{ 'is-active': huntAreasPanelActive }">
        <a @click.prevent="toggleHuntAreasPanel">
          <Icon name="icon-crosshair" />
          <span>{{ $t('nav.areas') }}</span>
          <Icon name="icon-chevron-right" class="force-right" />
        </a>
      </li>

      <li :class="{ 'is-active': gpsPanelActive }">
        <a @click.prevent="toggleGpsPanel">
          <Icon name="icon-compass" />
          <span>GPS</span>
          <Icon name="icon-chevron-right" class="force-right" />
        </a>
      </li>
    </ul>
  </Fragment>
</template>

<script>
import { mapGetters } from 'vuex'

export default {

  computed: {
    ...mapGetters({
      hasAuth: 'auth/hasAuth',
      invites: 'hunt/invites/invites'
    }),

    huntAreasPanelActive: {
      get () { return this.$store.getters['ui/huntAreasPanelActive'] },
      set (bool) { return this.$store.commit('ui/setHuntAreasPanelActive', bool) }
    },

    gpsPanelActive: {
      get () { return this.$store.getters['ui/gpsPanelActive'] },
      set (bool) { return this.$store.commit('ui/setGpsPanelActive', bool) }
    },

    totalInvites () {
      const invites = this.invites || []
      return invites.length
    }
  },

  methods: {
    toggleHuntAreasPanel () {
      this.huntAreasPanelActive = !this.huntAreasPanelActive
    },

    toggleGpsPanel () {
      this.gpsPanelActive = !this.gpsPanelActive
    }
  }

}
</script>
