<template>
  <div v-if="title !== null" class="divider">
    {{ title }}
  </div>

  <hr v-else />
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: null
    }
  }
}
</script>

<style scoped>
hr {
  height: 1px;
}

.divider {
  display: flex;
  align-items: center;
  text-align: center;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.divider::before,
.divider::after {
  content: '';
  flex: 1;
  border-bottom: 1px solid rgb(240, 240, 240);
}

.divider:not(:empty)::after {
  margin-left: 0.25rem;
}

.divider:not(:empty)::before {
  margin-right: 0.25rem;
}
</style>
