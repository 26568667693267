<template>
  <div :class="classObject">
    <slot />
  </div>
</template>

<script>
export default {

  props: {
    rounded: {
      type: Boolean,
      default: false
    },

    centered: {
      type: Boolean,
      default: false
    },

    animated: {
      type: Boolean,
      default: true
    },

    dark: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    classObject () {
      return {
        'content-placeholders': true,
        'is-rounded': this.rounded,
        'is-centered': this.centered,
        'is-animated': this.animated,
        'is-dark': this.dark
      }
    }
  }

}
</script>

<style src="./ContentPlaceholders.scss" lang="scss"></style>
