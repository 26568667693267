<template>
  <label class="checkbox">
    <input
      :checked="value"
      type="checkbox"
      @input="onInput($event)">
    <span v-if="label.length > 0" class="'push-left-xxxs">
      {{ label }}
    </span>
  </label>
</template>

<script>
export default {
  props: {
    value: {
      type: Boolean,
      default: false
    },

    label: {
      type: String,
      default: ''
    }
  },

  methods: {
    onInput (event) {
      const checked = event.target.checked
      this.$emit('input', checked)
    }
  }
}
</script>
