<template>
  <RouterLink :to="target" replace class="tab" tag="li">
    <a>{{ name }}</a>
  </RouterLink>
</template>

<script>
export default {
  props: {
    name: {
      type: String,
      default: ''
    },

    to: {
      type: String,
      default: ''
    },

    query: {
      type: Object,
      default: () => {}
    }
  },

  computed: {
    target () {
      return {
        path: this.to,
        query: this.query
      }
    }
  }
}
</script>
