<template>
  <Portal to="huntareas-panel">
    <div :class="[activeClass]" class="sidebar-panel">
      <div>
        <div class="default-spacing">
          <div class="level">
            <div class="level-left">
              <h5 class="title is-5">{{ $t('nav.areas') }}</h5>
            </div>

            <div class="level-right">
              <button class="delete" @click="close" />
            </div>
          </div>

          <Button
            v-if="!isLoading"
            type="primary"
            :fullwidth="true"
            :title="$t('huntArea.createHuntingGround')"
            @click="showCreateHuntAreaModal">
          </Button>
        </div>

        <div v-if="isLoading">
          <HuntAreasPanelPlaceholder />
          <HuntAreasPanelPlaceholder />
          <HuntAreasPanelPlaceholder />
        </div>

        <div v-else>
          <HuntAreasPanelBox
            v-for="huntArea in huntAreas"
            :key="huntArea.id"
            :huntArea="huntArea">
          </HuntAreasPanelBox>

          <p
            v-if="!hasHuntAreas"
            class="default-spacing is-paddingless-top"
            v-html="$t('huntArea.noHuntAreasEmptyStateMessage')">
          </p>
        </div>
      </div>
    </div>

    <div v-show="isActive" class="sidebar-panel-background" @click="close"></div>

    <NewHuntAreaDialog v-if="huntAreaModalVisible" @close="closeCreateHuntAreaModal" />
  </Portal>
</template>

<script>
import HuntAreasPanelBox from './HuntAreasPanelBox.vue'
import HuntAreasPanelPlaceholder from './HuntAreasPanelPlaceholder.vue'
import NewHuntAreaDialog from '@/components/dialogs/NewHuntAreaDialog.vue'

export default {
  components: {
    HuntAreasPanelPlaceholder,
    HuntAreasPanelBox,
    NewHuntAreaDialog
  },

  data () {
    return {
      huntAreaModalVisible: false
    }
  },

  computed: {
    isActive: {
      get () { return this.$store.getters['ui/huntAreasPanelActive'] },
      set (bool) { return this.$store.commit('ui/setHuntAreasPanelActive', bool) }
    },

    activeClass () {
      return this.isActive ? 'is-active' : ''
    },

    huntAreas () {
      const huntAreas = this.$store.getters['huntarea/areas']

      if (huntAreas !== null) {
        const language = this.$store.getters['ui/language']
        return huntAreas.slice(0).sort((a, b) => a.name.localeCompare(b.name, language, { sensitivity: 'base' }))
      }

      return null
    },

    isLoading () {
      return this.huntAreas === null
    },

    hasHuntAreas () {
      return this.huntAreas.length > 0
    }
  },

  watch: {
    '$route' (to, from) {
      this.close()
    }
  },

  methods: {
    close () {
      this.isActive = false
    },

    showCreateHuntAreaModal () {
      this.close()
      this.huntAreaModalVisible = true
    },

    closeCreateHuntAreaModal () {
      this.huntAreaModalVisible = false
    }
  }

}
</script>
