<template>
  <div :class="[data.type, activeClass]" class="notification">
    <button class="delete" @click="close"></button>
    <p class="has-text-white is-marginless" v-html="data.text" />
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
      required: true
    },

    isActive: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    activeClass () {
      return this.isActive ? 'is-active' : ''
    }
  },

  methods: {
    close () {
      this.$emit('close', this.data)
    }
  }
}
</script>
