import * as firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/database'
import 'firebase/storage'

import UserService from '@/modules/user/services/userService'
import FirebaseDatabase from './firebaseDatabase'
import PubSub from '@/assets/libs/pubsub/'

const TOKEN_REFRESH_TIMEOUT = 30 // Number of minutes until the token is refreshed

class FirebaseClient {
  constructor () {
    this.token = null
    this.events = new PubSub() // TODO: Should be removed
    this.tokenWaiters = []
  }

  initializeDefaultApp () {
    const defaultConfig = this.buildConfiguration(process.env.VUE_APP_FIREBASE_DATABASE_URL)
    firebase.initializeApp(defaultConfig)
  }

  buildConfiguration (databaseUrl) {
    return {
      apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
      authDomain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN,
      databaseURL: databaseUrl,
      projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID,
      storageBucket: process.env.VUE_APP_FIREBASE_STORAGE_BUCKET,
      messagingSenderId: process.env.VUE_APP_FIREBASE_MESSAGE_SENDER_ID,
      appId: process.env.VUE_APP_FIREBASE_APP_ID
    }
  }

  async signIn () {
    this.initializeDefaultApp()
    this.refreshToken()
  }

  async signInApp (app) {
    const promise = app.auth().signInWithCustomToken(this.token)

    promise.then(() => {
      console.log(`Firebase: Signed into ${app.name}.`)
    })

    return promise
  }

  async refreshToken () {
    try {
      this.token = await UserService.getFirebaseToken()

      const promises = []

      for (const app of firebase.apps) {
        promises.push(this.signInApp(app))
      }

      await Promise.all(promises)

      this.resolveTokenWaiters()
      this.refreshTokenAfter30Minutes()
    } catch (error) {
      console.error('Firebase: Failed to sign in to firebase with token. Some parts of the application will not function correctly.')
      console.error(error)
    }
  }

  waitForToken () {
    return new Promise(resolve => {
      if (this.token !== null) {
        resolve(this.token)
      } else {
        this.tokenWaiters.push(resolve)
      }
    })
  }

  resolveTokenWaiters () {
    this.tokenWaiters.forEach(resolve => resolve(this.token))
    this.tokenWaiters = []
  }

  /**
   * The token from Firebase is valid for 1 hour. To make sure we don't time out and our calls
   * will start failing, we refresh the token every 30 minutes.
   */
  refreshTokenAfter30Minutes () {
    this.firebaseTokenTimer = setTimeout(async () => {
      this.refreshToken()
    }, TOKEN_REFRESH_TIMEOUT * 60 * 1000)
  }

  async signOut () {
    clearTimeout(this.firebaseTokenTimer)
    this.token = null

    for (let i = firebase.apps.length - 1; i >= 0; i--) {
      const app = firebase.apps[i]

      console.log(`Firebase: Signing out from ${app.name}.`)

      await app.auth().signOut()
      await app.delete()
    }
  }

  async getApp (databaseUrl) {
    try {
      return firebase.app(databaseUrl)
    } catch (error) {
      const config = this.buildConfiguration(databaseUrl)
      const app = firebase.initializeApp(config, databaseUrl)
      await this.signInApp(app)

      return app
    }
  }

  async getSnapshot (path, params, databaseUrl) {
    await this.waitForToken()

    const { orderBy, convertTo } = params

    const app = databaseUrl ? await this.getApp(databaseUrl) : firebase.app()
    let ref = app.database().ref(path)

    if (orderBy !== undefined) {
      ref = ref.orderByChild(orderBy)
    }

    const snapshot = await ref.once('value')
    if (snapshot == null) {
      return null
    }

    const data = []

    if (orderBy !== undefined) {
      snapshot.forEach(child => {
        const item = child.val()
        item.id = child.key
        data.push(convertTo !== undefined ? convertTo(item) : item)
      })
    } else {
      const value = snapshot.val()

      if (value) {
        Object.keys(value).forEach(key => {
          value[key].id = key
          data.push(convertTo !== undefined ? convertTo(value[key]) : value[key])
        })
      }
    }

    return data
  }

  async getMedia (path) {
    let response
    const storage = firebase.storage()
    const storageRef = storage.ref(path)

    try {
      const url = await storageRef.getDownloadURL()
      response = 'https://imgcache.wehuntapp.com/proxy.php?url=' + Buffer.from(url).toString('base64')
    } catch (error) {
      Promise.reject(error)
    }

    return Promise.resolve(response)
  }

  initializeListener (path) {
    const ref = firebase.database().ref(path).orderByChild('timestamp')
    const self = this

    try {
      ref.on('value', messages => {
        const value = messages.val()
        const data = []

        if (value) {
          Object.keys(value).forEach(key => {
            value[key].id = key
            data.push(value[key])
          })
        }

        self.events.emit('message', data.slice(-1)[0])
      })
    } catch (error) {
      Promise.reject(error)
    }
  }

  async sendMessage (path, data) {
    try {
      const newPostRef = firebase.database().ref(path).push()

      await newPostRef.set({
        messageText: data.text,
        senderUserId: data.createdBy + '_LLUser',
        timestamp: new Date().valueOf()
      })
    } catch (error) {
      console.error('Failed to append data to ' + path + '.')
      console.error(error)
      throw error
    }
  }

  async getDatabase (databaseUrl) {
    await this.waitForToken()

    const app = databaseUrl ? await this.getApp(databaseUrl) : firebase.app()
    return new FirebaseDatabase(app.database())
  }
}

export default new FirebaseClient()
