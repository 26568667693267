export default {
  huntingReport: 'Hunting report',
  huntingReportDefaultTitle: 'Hunting report {date}',

  title: 'Title',
  titlePlaceholder: 'Name of the hunt',

  startedAt: 'Started at',
  startedAtPlaceholder: "When did the hunt start?",
  startedAtRequired: 'You must enter a start time.',

  endedAt: 'Ended at',
  endedAtPlaceholder: "When did the hunt end?",
  endedAtRequired: 'You must enter an end time.',

  manHours: 'Man-hours',
  manHoursAutomaticCalculation: 'Calculate automatically',

  comment: 'Comment',
  commentNone: 'No comment specified',

  location: 'Location',
  locationHelpText: 'Select a pass or tower by clicking on their marker, or click on the map to select a specific coordinate.',
  locationError: 'You must pick a coordinate on the map or select a pass/tower.',

  dateAndTime: 'Date and time',
  enterTime: 'Enter time',
  dateError: 'You must enter a date.',
  sendToViltrapport: 'Send to Viltrapport',

  gameRemovedMessage: 'You have removed <strong>{gameName}</strong> which has been reported to Viltrapport. To delete the information from Viltrapport, you need to contact your area administrator.',
  exportFailedErrorMessage: 'An error occurred and the hunt reports could not be exported to Excel. Please try again.',

  // Special String used to download animals using the correct country code regardless of language chosen
  loadAnimalsCountryCode: 'gb',

  participants: {
    title: 'Participants',
    none: 'There are no participants',
    add: 'Add participants',
    select: 'Select participant',
    unspecified: 'Not specified',
    showAll: 'Show all',
    emailNotFound: 'There is no user with the specified email address. Do you still want to add the participant?',
    helpText: 'Add all participants to be included in the report. You can add hunting team members or invite guest hunters by searching for their email.',
    allMembersIncludedInReport: 'All members of the hunting team are included in the report.',
    addButtonTitle: 'Add | Add <strong>1</strong> participants | Add <strong>{userCount}</strong> participants'
  },

  events: {
    title: 'Events',
    none: 'No events have been reported.',
    noneOccurredYet: 'No events have occurret yet.',
    addKill: "Add downed game",
    addObservation: 'Add observed game',
    editKill: "Edit downed game",
    editObservation: 'Edit observered game',
    editAfterSearch: 'Edit after search',
    kill: 'Downed',
    killShort: 'Downed',
    observation: 'Observation',
    observationShort: 'Obs',
    afterSearch: 'Wounded',
    afterSearchShort: 'Wounded',
    shooter: 'Shooter',
    observer: 'Observer'
  },

  game: {
    title: 'Game',
    add: 'Add game',
    none: 'No game found for your country. Contact support.',
    error: 'You must add at least one game.',
    sent: 'Sent',
    type: 'Type',
    typeSelect: 'Select type'
  },

  invitation: {
    title: 'Invitation',
    invited: 'Invited',
    none: 'No invitation was sent.'
  },

  chat: {
    title: 'Messages',
    none: 'No messages have been sent yet.'
  },

  markers: {
    affectedByHunt: 'Affected by hunt',
    all: 'All'
  },

  removeReport: {
    title: 'Delete report',
    message: 'Are you sure you want to delete the report?',
    viltrapportMessage: 'Information in this hunting report has been reported to Viltrapport. To remove the information from Viltrapport, you need to contact your area administrator.<br/><br/> Do you want to delete the hunting report?'
  },

  removeEvent: {
    title: 'Delete event',
    message: 'Are you sure you want to delete the event?',
    viltrapportMessage: 'Information in this event has been reported to Viltrapport. To delete the information from Viltrapport, you need to contact your area administrator.<br/><br/>Do you want to delete the event?'
  }
}
