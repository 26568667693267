<template>
  <a href="#" class="dropdown-item" @click="clicked">
    <slot></slot>
  </a>
</template>

<script>
export default {
  methods: {
    clicked (event) {
      this.$emit('click', event)
    }
  }
}
</script>

<style lang="scss" scoped>
.dropdown-item {
  display: flex;
  align-items: center;
}
</style>
