<template>
  <article class="message" :class="classes">
    <div class="message-body" v-html="message" />
  </article>
</template>

<script>
export default {
  props: {
    type: {
      type: String,
      default: ''
    },

    message: {
      type: String,
      default: ''
    }
  },

  computed: {
    classes () {
      return 'is-' + this.type
    }
  }
}
</script>
