export default {
  title: 'Inställningar',
  name: 'Namn',
  nameHelpText: 'Namnet är viktigt för att era medlemmar lätt ska hitta jaktlaget.',

  county: {
    title: 'Län',
    helpText: 'Länet används för att kunna visa korrekt jakttabell i appen.',
    notSpecified: 'Ej angivet'
  },

  delete: {
    title: 'Ta bort jaktmark',
    helpText: 'Genom att ta bort jaktmarken så tas jaktlaget, marken, alla jakter och all information bort permanent.<br /><strong>Detta kan inte ångras.</strong>',
    errorMessage: 'Ett fel inträffade och det gick inte att ta bort jaktmarken. Vänligen försök igen.'
  },

  leave: {
    title: 'Lämna jaktmarken',
    helpText: 'När du lämnar jaktmarken så försvinner den från listan i appen och du kan inte längre delta i jakter. Du kan dock när som helst bli inbjuden igen.',
    errorMessage: 'Ett fel inträffade och det gick inte att lämna jaktmarken. Vänligen försök igen.'
  },

  viltrapport: {
    title: 'Viltrapport',
    helpText: 'Jaktmarken är ansluten till skötselområden i Viltrapport.<br>Ni kan nu se lovligt vilt och rapportera fält vilt inom dessa skötselområde.',
    notConnectedTitle: 'Jaktmarken är inte ansluten till Viltrapport',
    notConnectedMessage: 'Logga in i Viltrapport för ert skötselområde och anslut jaktlaget till er jaktmark för att kunna se lovligt vilt och rapportera fällt vilt via denna hemsidan och WeHunt-appen.'
  }
}
