export default {
  unsupportedBrowser: {
    updateRequired: 'You need to upgrade your browser.',
    readMore: 'Read more here',
    browserNotSupported: 'Your browser is not supported.',
    browserListHelpText: '<p>Unfortunately, WeHunt is no longer supported by your browser, which means that parts of WeHunt will not work as we intended.</p><p>Here are some browsers that will work better:</p>'
  },

  cookies: {
    information: 'We use cookies to ensure you the best possible experience of our website.'
  }
}
