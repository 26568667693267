<template>
  <Dialog :title="$t('core.unsupportedBrowser.browserNotSupported')" @close="onClose">
    <div v-html="$t('core.unsupportedBrowser.browserListHelpText')" />

    <ul class="browser-list">
      <li v-for="(browser, i) in browsers" :key="i">
        <img :src="browser.icon" />
        <a :href="browser.url">{{ browser.name }}</a>
      </li>
    </ul>
  </Dialog>
</template>

<script>
import Dialog from '@/components/ui/dialog2/Dialog2.vue'

export default {
  components: {
    Dialog
  },

  data () {
    return {
      browsers: [{
        icon: '/img/logos/chrome.png',
        name: 'Google Chrome',
        url: 'https://www.google.com/chrome/'
      }, {
        icon: '/img/logos/edge.png',
        name: 'Microsoft Edge',
        url: 'https://www.microsoft.com/en-us/edge'
      }, {
        icon: '/img/logos/firefox.png',
        name: 'Firefox',
        url: 'https://www.mozilla.org/en-US/firefox/new/'
      }]
    }
  },

  methods: {
    onClose () {
      this.$emit('close')
    }
  }
}
</script>

<style lang="scss" scoped>
.browser-list {
  li {
    margin-bottom: 1rem;
    display: flex;
    align-items: center;

    &:last-child {
      margin-bottom: 0;
    }

    img {
      width: 48px;
      height: 48px;
      margin-right: 1rem;
    }
  }
}
</style>
