<script>
/* global google */
import tileListener from "@/components/map/print/tileListener"
import { mapGetters } from "vuex"
import store from "@/store/"

const LAYER_NAME = "propertyBoundariesLayer"

export default {
  inject: ["getMap"],

  data () {
    return {
      mapType: null
    }
  },

  computed: {
    ...mapGetters({
      // isVisible: 'map/settings/isPropertyBoundariesLayerVisible'
      isVisible: "map/settings/isTerrainLayerVisible"
    })
  },

  watch: {
    isVisible () {
      this.updateVisibility()
    }
  },

  async created () {
    try {
      const token = await this.getToken()

      this.createMapType(token)
      this.addTileLoadListener()
      this.updateVisibility()
    } catch (error) {
      console.error("Failed to create MapPropertyBoundariesLayer")
    }
  },

  render () {
    return ""
  },

  methods: {
    async getToken () {
      const credentials = store.getters["auth/getBase64"]

      const options = {
        headers: new Headers({
          Authorization: `Basic ${credentials}`
        }),
        credentials: "include"
      }

      const response = await fetch(
        "https://mp2.wehuntapp.com/auth.php",
        options
      )
      if (response.ok) {
        const json = await response.json()
        return json.token
      } else {
        throw new Error("Failed to fetch access token for mp2.wehuntapp.com")
      }
    },

    createMapType (token) {
      this.mapType = new google.maps.ImageMapType({
        name: LAYER_NAME,
        tileSize: new google.maps.Size(256, 256),
        minZoom: 15,
        maxZoom: 19,
        getTileUrl (tile, zoom) {
          const { x, y } = tile
          const ix = zoom + "-" + x + "-" + y

          tileListener.addPendingUrl(LAYER_NAME, ix)

          return `https://mp2.wehuntapp.com/fastighetsindelning/webmercator512/${zoom}/${x}/${y}.png?source=web&token=${token}`
        }
      })
    },

    async updateVisibility () {
      const map = await this.getMap()

      if (this.mapType !== null) {
        if (this.isVisible) {
          const i = map.overlayMapTypes.getLength() // Always display as the topmost layer
          if (i > 0) {
            map.overlayMapTypes.insertAt(i, this.mapType)
          } else {
            setTimeout(() => {
              this.updateVisibility()
            }, 1000)
          }
        } else {
          const i = map.overlayMapTypes.indexOf(this.mapType)
          if (i !== -1) {
            map.overlayMapTypes.removeAt(i)
          }
        }
      }
    },

    addTileLoadListener () {
      tileListener.addTileLoadListener(LAYER_NAME, this.mapType, 20000)
    }
  }
}
</script>
