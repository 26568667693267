export default {
  play (state) {
    state.status = 'started'
  },

  pause (state) {
    state.status = 'paused'
  },

  stop (state) {
    state.status = 'stopped'
    state.elapsedTime = 0
  },

  status (state, status) {
    state.status = status
  },

  duration (state, duration) {
    state.duration = duration
  },

  elapsedTime (state, elapsed) {
    state.elapsedTime = elapsed
  },

  speed (state, speed) {
    state.speed = speed
  },

  addParticipant (state, participant) {
    state.participants.push(participant)
  },

  setParticipants (state, participants) {
    state.participants = participants
  },

  selectParticipant (state, participant) {
    state.selectedParticipant = participant
  },

  setHunters (state, hunters) {
    state.hunters = hunters
  },

  setGpsDevices (state, gpsDevices) {
    state.gpsDevices = gpsDevices
  },

  addGpsDevice (state, gpsDevice) {
    if (state.gpsDevices === null) {
      state.gpsDevices = []
    }

    state.gpsDevices.push(gpsDevice)
  },

  setHunterLocations (state, hunterLocations) {
    state.hunterLocations = hunterLocations
  },

  setGpsLocations (state, gpsLocations) {
    state.gpsLocations = gpsLocations
  },

  setHunterTrailLength (state, trailLength) {
    state.hunterTrailLength = trailLength
  },

  setGpsTrailLength (state, trailLength) {
    state.gpsTrailLength = trailLength
  }
}
