<template>
  <div
    :class="{
      'is-active': isActive,
      'centered': centered
    }"
    class="full-modal">
    <a class="delete is-large" @click="close"></a>

    <div class="container overflow-auto fancy-scrollbar">
      <div class="inner-container">
        <slot />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    isActive: {
      type: Boolean,
      default: false
    },

    centered: {
      type: Boolean,
      default: false
    }
  },

  methods: {
    close () {
      this.$emit('close')
    },

    abandon () {
      this.$emit('cancel')
    }
  }
}
</script>

<style lang="scss">
.full-modal {
  background: white;
  display: flex;

  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1001;

  opacity: 0;
  pointer-events: none;
  transform: scale(0.75);
  transition: all 0.25s ease-out;

  &.is-active {
    // padding: 2.5rem;
    pointer-events: all;
    opacity: 1;
    transform: scale(1);
  }

  &.centered {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .delete {
    position: absolute;
    top: 2.5rem;
    right: 2.5rem;
    z-index: 2;
  }

  .container {
    position: relative;
    z-index: 0;
    height: auto !important;
    width: 100%;
    max-width: 9999px;
    display: flex;
    justify-content: center;
    margin: 0;

    .inner-container {
      padding: 1.5rem;
      width: 80%;
      max-width: 30rem;

      @media screen and (min-width: 768px) {
        max-width: 50rem;
      }
    }
  }
}
</style>
