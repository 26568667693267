export default {
  title: 'News',
  readMore: 'Read more',
  readFullArticle: 'Read article at {source}',
  showMore: 'Show more...',
  ad: 'Ad',
  advertisedContent: 'Advertised content',
  loadFailedErrorMessage: 'An error occurred and the news could not be downloaded. Please try again.',
  newsItemNotFoundTitle: 'Could not load news article',
  newsItemNotFoundMessage: 'Looks like you clicked on an incorrect link. Please try again.'
}
