<template>
  <nav class="navbar is-transparent is-hidden-desktop" role="navigation" aria-label="main navigation">
    <div class="navbar-brand">
      <RouterLink to="/" class="logotype">
        <Logo color="white" />
      </RouterLink>

      <Burger />
      <UserDropdown />
    </div>

    <Menu />
  </nav>
</template>

<script>
import Menu from './NavbarMenu.vue'

export default {
  components: {
    Menu
  }
}
</script>

<style lang="scss">
.navbar {
  min-height: auto;
  box-shadow: 0 0.5rem 1rem -0.125rem rgba(40, 40, 40, 0.05), 0 0 0 1px rgba(60, 60, 60, 0.15);
  background: var(--noise), linear-gradient(25deg, #28393d, #42555b);
  background: -ms-linear-gradient(25deg, #28393d, #42555b); /* IE fallback */
  background-color: #42555b; /* IE fallback */

  .navbar-brand {
    align-items: center;
    padding: 0.75rem 1.5rem;
  }

  .logotype img {
    vertical-align: middle;
  }

  .navbar-start,
  .navbar-end {
    align-items: center;
  }
}
</style>
