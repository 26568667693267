export default {
  set (state, invites) {
    state.invites = invites
  },

  remove (state, inviteId) {
    const i = state.invites.findIndex(invite => invite.id === inviteId)
    if (i !== -1) {
      state.invites.splice(i, 1)
    }
  }
}
