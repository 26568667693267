import firebaseClient from '@/api/firebase/firebaseClient'
import MessageModel from '@/api/huntarea/messages/messageModel'

export default {

  async read ({ commit, state }, huntAreaId) {
    commit('setMessages', null)

    const messages = await firebaseClient.getSnapshot(`groups/${huntAreaId}_LLGroup/chatMessages`, {
      orderBy: 'timestamp',
      convertTo: message => new MessageModel(message)
    })

    commit('setMessages', messages)

    /*
      * Subscribe to new messages
    */
    await firebaseClient.initializeListener(`groups/${huntAreaId}_LLGroup/chatMessages`)

    firebaseClient.events.on('message', message => {
      const alreadyExists = state.messages.some(item => item.id === message.id)

      if (!alreadyExists) {
        commit('addMessage', new MessageModel(message))
      }
    })

    return messages
  },

  readMedia (store, path) {
    return firebaseClient.getMedia(path)
  },

  sendMessage ({ commit }, data) {
    return firebaseClient.sendMessage(`groups/${data.huntAreaId}_LLGroup/chatMessages`, data)
  }

}
