import jsonp from '@/assets/libs/jsonp/'
import store from '@/store'

import { PropertyLayerTile } from '@/components/map/layers/propertyLayerTileStore'

/**
 * Loads the tiles that the current user has access to and caches these in session storage
 */
class PropertyLayerTileLoader {
  constructor () {
    this.promise = null
  }

  load (tileStore) {
    // If we are already loading tiles, let the client wait for the same promise
    if (this.promise !== null) {
      return this.promise
    }

    this.promise = new Promise((resolve, reject) => {
      if (tileStore.load()) {
        resolve()
      } else {
        this.loadTiles(tileStore)
          .then(() => resolve())
          .catch((error) => reject(error))
      }
    })

    this.promise.finally(() => {
      this.promise = null
    })

    return this.promise
  }

  async loadTiles (tileStore) {
    try {
      store.dispatch('map/startLoadingPropertyLayerTiles')

      // We have to encode the credentials differently when they are sent as a query parameter compared
      // to the Authentication header which is used in other places of the app.
      const credentials = store.getters['auth/getCredentials']
      const base64 = btoa(`${credentials.email}:${credentials.password}`)

      const protectedResources = await this.loadProtectedResources(base64)

      const promises = []

      protectedResources.forEach(protectedResource => {
        promises.push(this.loadTile(protectedResource, base64, tileStore))
      })

      await Promise.all(promises)

      tileStore.save(false)

      store.dispatch('map/propertyLayerTilesLoaded')
    } catch (error) {
      console.error('Failed to load tiles for Fastighetskartan 2018.')
      console.error(error)
      return null
    }
  }

  async loadProtectedResources (auth) {
    const protectedResources = await jsonp({
      url: '//lm-tms.wehunt.se/protectedresources.php',
      data: { auth }
    })

    store.commit('map/setPropertyLayerTileLoadingProgress', {
      status: 'loading-tiles',
      tileCount: protectedResources.length,
      loadedCount: 0
    })

    return protectedResources
  }

  async loadTile (protectedResource, auth, tileStore) {
    const data = Object.assign({}, protectedResource, { auth: auth })

    const mapTile = await jsonp({
      url: '//lm-tms.wehunt.se/maptile.php',
      data: data
    })

    mapTile.forEach(tileString => {
      tileStore.add(PropertyLayerTile.parse(tileString))
    })

    const tileLoadingProgress = store.getters['map/propertyLayerTileLoadingProgress']
    tileLoadingProgress.loadedCount++

    store.commit('map/setPropertyLayerTileLoadingProgress', tileLoadingProgress)
  }
}

export default PropertyLayerTileLoader
