export default {

  set (state, trails) {
    state.trails = trails
  },

  delete (state, trail) {
    const i = state.trails.indexOf(trail)

    if (i !== -1) {
      state.trails.splice(i, 1)
    }
  },

  clear (state) {
    state.trails = null
  },

  select (state, trail) {
    state.selected = trail
  },

  showTrailLabels (state, bool) {
    state.showTrailLabels = bool
  }

}
