import api from '@/api/'
import AssignmentModel from './assignmentModel'

class AssignmentService {
  static async create (huntId, assignment) {
    let response

    try {
      const { data } = await api.post(`hunts/${huntId}/assignments`, assignment.toJSON())
      assignment.id = data.id

      response = assignment
    } catch (error) {
      console.error(`Failed to create assignment for hunt ${huntId}.`)
      Promise.reject(error)
    }

    return Promise.resolve(response)
  }

  static async read (huntId) {
    let response

    try {
      const { data } = await api.get(`hunts/${huntId}/assignments`)
      const assignments = data.map(data => new AssignmentModel(data))

      response = assignments
    } catch (error) {
      console.error(`Failed to read assignments for hunt ${huntId}.`)
      Promise.reject(error)
    }

    return Promise.resolve(response)
  }

  static async delete (huntId, assignmentId) {
    let response

    try {
      await api.delete(`hunts/${huntId}/assignments/${assignmentId}`)
      response = true
    } catch (error) {
      console.error(`Failed to delete assignment ${assignmentId} for hunt ${huntId}.`)
      Promise.reject(error)
    }

    return Promise.resolve(response)
  }
}

export default AssignmentService
