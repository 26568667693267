<template>
  <FormField>
    <Checkbox
      v-model="scrollZoom"
      :label="$t('map.settings.scrollToZoom')"
      type="switch">
    </Checkbox>
  </FormField>
</template>

<script>
export default {
  computed: {
    scrollZoom: {
      get () {
        return this.$store.getters['map/settings/hasScrollZoom']
      },

      set (state) {
        return this.$store.commit('map/settings/toggleScrollZoom', state)
      }
    }
  }
}
</script>
