export default {
  getPro: 'Get Pro',

  selectLanguage: 'Select Language',

  feedback: 'Feedback',
  leaveFeedback: 'Leave feedback',

  games: 'Games',
  elkGame: 'Moose track',
  boarGame: "Wild boar hunt",

  theme: "",
  themeContent: "",
  dark: ""
}
