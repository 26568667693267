/**
 * Information about an animal/game that can be hunted or referenced by a hunt report event.
 */
class GameModel {
  constructor (context) {
    const data = Object.assign({}, context)

    this.id = data.id || null
    this.name = data.name || ''
    this.category = data.category || ''
    this.description = data.description || ''
    this.iconURL = data.iconURL || ''
    this.imageURL = data.imageURL || ''

    this.selected = data.selected || false
  }
}

export default GameModel
