/* global google */
import tileListener from '@/components/map/print/tileListener'
import { PropertyLayerTileStore } from './propertyLayerTileStore'
import PropertyLayerTileLoader from './propertyLayerTileLoader'

const propertyLayerTileStore = new PropertyLayerTileStore()
const propertyLayerTileLoader = new PropertyLayerTileLoader()

export const PROPERTY_LAYER_MAX_ZOOM = 16

/**
 * Handles loading and displaying of image tiles from the "The Swedish National Land Survey" (Lantmäteriet).
 * This layer is always placed on top of all other layers/overlays in the map.
 */
class PropertyLayer {
  constructor (map) {
    this.map = map
    this.mapType = null
    this.visible = false

    propertyLayerTileLoader.load(propertyLayerTileStore).then(() => {
      this.createMapType(propertyLayerTileStore)
      tileListener.addTileLoadListener('propertyLayer', this.mapType, 120000)
    })
  }

  createMapType (tileStore) {
    this.mapType = new google.maps.ImageMapType({
      name: 'propertyLayer',
      tileSize: new google.maps.Size(256, 256),
      maxZoom: PROPERTY_LAYER_MAX_ZOOM,
      getTileUrl (tile, zoom) {
        const x = tile.x
        const y = (1 << zoom) - tile.y - 1
        const key = (zoom + ',' + tile.x + ',' + y)

        const tileId = tileStore.get(key)

        if (tileId !== null) {
          tileListener.addPendingUrl('propertyLayer', `${zoom}-${tile.x}-${tile.y}`)
          return `https://lm-tms.wehunt.se/tms/${zoom}/${x}/${y}/${tileId}`
        }

        return null
      }
    })

    // Update visibility on the map
    this.setVisible(this.visible)
  }

  setVisible (visible) {
    this.visible = visible

    if (this.mapType !== null) {
      if (visible) {
        this.map.overlayMapTypes.insertAt(1, this.mapType)
      } else {
        const i = this.map.overlayMapTypes.indexOf(this.mapType)
        if (i !== -1) {
          this.map.overlayMapTypes.removeAt(i)
        }
      }
    }
  }
}

export { PropertyLayer, propertyLayerTileStore }
