import dayjs from 'dayjs'

export default class BillingPortalSessionModel {
  constructor (json) {
    this.id = json.id || null
    this.object = json.object || null
    this.createdAt = json.created ? dayjs.unix(json.created).toDate() : null
    this.customer = json.customer || null
    this.liveMode = json.livemode || false
    this.returnUrl = json.return_url || null
    this.url = json.url || null
  }
}
