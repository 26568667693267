export default {
  readMore: "En savoir plus",
  welcome: "Accueillir",
  yes: "Oui",
  no: "Non",
  ok: "D'accord",
  save: "Sauvegarder",
  edit: "Modifier",
  draw: "Dessiner",
  update: "Mise à jour",
  create: "Créer",
  cancel: "Annuler",
  close: "Fermer",
  remove: "Retirer",
  delete: "Supprimer",
  send: "Envoyer",
  pleaseConfirm: "Veuillez saisir : <strong>{type}</strong> pour confirmer.",
  understandConsequences: "Je comprends les conséquences, {type}",
  leave: "Partir",
  move: "Déplacer",
  recover: "Récupérer",
  saveAs: "Sauvegarder",
  add: "Ajouter",
  search: "Recherche",
  searchForEmailOrName: "Rechercher une adresse e-mail ou un nom",
  admin: "Administrateur",
  email: 'E-mail',
  password: "Mot de passe",
  signup: "S'inscrire",
  signin: "Se connecter",
  signout: "Se déconnecter",
  remember: "Se souvenir",
  notListed: "Aucun",
  invite: "Inviter",
  invited: "Invité",
  member: "Membre",
  loadingContent: "Chargement du contenu...",
  errorOccured: "Une erreur s'est produite et il n'a pas été possible d'enregistrer les modifications. Veuillez réessayer.",
  sendInvites: "Envoyer des invitations",
  myAccount: "Mon abonnement",
  help: "Aide",
  upload: "Télécharger",
  sort: "Trier",
  name: "Nom",
  phoneNumber: "Numéro de téléphone",
  nameAZ: "Nom (A-Z)",
  nameZA: "Nom (Z-A)",
  grid: "Grille",
  list: "Liste",
  decline: "Refuser",
  accept: "Accepter",
  settings: "Paramètres",
  language: "Langue",
  developer: "Développeur",
  games: "Espèces",
  sex: "Sexe",
  male: "Mâle",
  female: "Femelle",
  firstName: "Prénom",
  lastName: "Nom de famille",
  created: "Créé",
  size: "Taille",
  from: "Depuis",
  to: "À",
  message: 'Message',
  when: "Quand",
  where: "Où",
  type: "Type",
  who: "OMS",
  comment: "Commentaire",
  begins: "Commence",
  ends: "Prend fin",
  print: "Imprimer",
  adjust: "Ajuster",
  orientation: 'Orientation',
  status: "Statut",
  thickness: "Épaisseur",
  color: "Couleur",
  title: "Titre",
  role: "Rôle",
  needsPro: "Nécessite WeHunt Pro",
  comingSoon: "À venir",
  reallyRemove: "Voulez-vous vraiment supprimer <strong>{name}</strong> ?",
  added: "Ajouté",
  undo: "Annuler",
  select: "Sélectionner",
  minutes: 'min',
  hours: 'h',

  newHuntingArea: "Nouveau territoire de chasse",
  selectHuntingArea: "Sélectionnez un territoire de chasse",
  selectHuntingAreaError: "Vous devez choisir un territoire de chasse.",

  previous: "Précédent",
  next: "Suivant",

  unsupportedBrowserMessage: "Cette fonction ne fonctionne pas avec votre navigateur. Veuillez passer à un navigateur plus moderne.",
  saveFailedErrorMessage: "Une erreur s'est produite et les modifications n'ont pas pu être enregistrées. Veuillez réessayer.",
  unknownErrorMessage: "Une erreur inconnue est survenue. Veuillez réessayer.",

  pageNotFound: "La page que vous recherchez est introuvable.",
  goBack: "Retourner"
}
