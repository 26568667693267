export const STATUS_DEFAULT = ''
export const STATUS_LOADING = 'loading'
export const STATUS_NOT_FOUND = 'not-found'
export const STATUS_UNKNOWN_ERROR = 'unknown-error'
export const STATUS_ACCEPTED = 'accept'
export const STATUS_DECLINED = 'decline'
export const STATUS_ACCEPTING = 'accepting'
export const STATUS_DECLINING = 'declining'
export const STATUS_ALREADY_ANSWERED = 'already-answered'
export const STATUS_EMAIL_EXISTS = 'email-already-exists'
export const STATUS_SUBMITTING = 'submitting'
