<template>
  <div :class="[scrollClass]" class="panel-content">
    <slot />
  </div>
</template>

<script>
export default {
  props: {
    scrollable: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    scrollClass () {
      if (this.scrollable) {
        return 'overflow-auto fancy-scrollbar'
      }

      return ''
    }
  }
}
</script>

<style lang="scss">
.panel-content {
  height: 100%;
  flex-grow: 1;
  padding: 0.375rem 1.5rem 1.5rem 1.5rem;
  touch-action: none; // disable pinch-to-zoom iOS
}
</style>
