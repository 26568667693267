export default {
  upgrade: 'Uppgradera',
  upcoming: 'Du har ett kommande abonnemang',
  show: 'Visa abonnemang',
  purchaseErrorMessage:
  'Ett fel inträffade och det gick inte att genomföra köpet. Vänligen försök igen.',

  purchase: {
    success: {
      title: 'Tack för ditt köp',
      text: 'Din betalning är under behandling och ditt abonnemang aktiveras automatiskt om ett ögonblick.'
    }
  },

  price: {
    yearly: '{price} {currency}/år',
    ongoing: 'Fortsatt {price} {currency}/år'
  },

  willBeRefunded:
  'Återstående period av ditt pågående abonnemang kommer återbetalas separat.',

  requiresVersion63:
  '<strong>OBS! Fungerar EJ ännu i Android (uppdatering släpps innan jaktsäsongen). Fungerar enbart i senaste versionen i iPhone.</strong>',

  firstPayment: 'Första dragningen sker {date}',
  nextPayment: 'Förlängs automatiskt den {date}',

  managedInAppStore: 'Hanteras i Apple App Store',
  managedInGooglePlay: 'Hanteras i Google Play',
  buyInAppStore: 'Köp i Apple App Store',
  buyInGooglePlay: 'Köp i Google Play',

  autoRenew: 'Du har ett <strong>{subscription}</strong> abonnemang',
  expires: '<strong>{subscription}</strong> löper ut<br />{date}',

  mySubscription: 'Ditt WeHunt',

  pro: {
    name: 'WeHunt Pro',
    shortName: 'Pro',
    autoRenew: 'Lösningen som ökar spänningen och säkerheten',
    upgrade: 'Gå över till <strong>WeHunt Standard</strong> redan nu',
    extend: 'Köp Standard'
  },

  standard: {
    name: 'WeHunt Standard',
    shortName: 'Standard',
    description: 'Se varandra på kartan för en bättre jaktupplevelse',
    autoRenew: 'Lösningen som ökar spänningen och säkerheten',
    upgrade:
    'Slipp krångel, gå över till fortlöpande abonnemang och säkra ditt WeHunt Standard till varje säsong',
    buy: 'Köp Standard',
    extend: 'Återaktivera Standard',
    features: [
      'Se realtidspositioner',
      'Jakttabell',
      'Starta jakter',
      'Vittringsindikator']

  },

  premium: {
    name: 'WeHunt Premium',
    shortName: 'Premium',
    description: 'Se Garmin, Tracker, UltraCom eller b-Bark',
    autoRenew: 'Abonnemanget som ger allt vi har i app & på webb',
    upgrade:
    'Slipp krångel, gå över till fortlöpande abonnemang och säkra ditt WeHunt Premium till varje säsong',
    buy: 'Köp Premium',
    extend: 'Förläng Premium',

    benefits: {
      title: 'Premiumförmåner',
      description:
      'Som Premium användare får du 20% rabatt när du handlar hos:<br />- widforss.se<br />- widforss.no<br />- alaska1795.com (ej till Norge)<br />- chevalier.se'
    },

    features: {
      addThirdPartyGPS: 'Se & dela Garmin GPS',
      seeThirdPartyGPS: 'Se Tracker, Ultracom, b-Bark GPS',
      terrainMap: 'Terrängkarta med fastighetsgräns',
      benefits: 'Premiumförmåner'
    }
  },

  gps: {
    name: 'WeHunt GPS',
    shortName: 'GPS',
    description: 'Se och dela din hund direkt i mobilen. Allt i WeHunt ingår.',
    autoRenew: 'Allt WeHunt har att erbjuda ingår i ditt abonnemang',
    upgrade: '',
    buy: 'Aktivera ny GPS',
    extend: 'Förläng abonnemang',
    renew: 'Förnya abonnemang',
    nextPayment: 'Förlängs automatiskt den {date}.',
    purchaseErrorMessage:
    'Felaktigt IMEI-nummer angivet. Vänligen försök igen.',
    activateHelpText: 'Aktivera din WeHunt GPS Plus eller WeHunt GPS',
    activate: 'Aktivera',
    paymentFailed: 'Misslyckad betalning! Förläng abonnemang.',
    cancelled: 'Abonnemang avslutat. Giltigt fram till {date}.',
    upgradeGpsSubscription:
    'Uppgradera ditt abonnemang för att använda fler GPS-enheter',

    features: [
      'Jaktlaget kan se din GPS',
      'Obegränsad räckvidd i EU/EES',
      'Uppringningsfunktion',
      'Skapa ett GEO-fence'],

    price: {
      regularPrice: 995,
      percentOff: 0,
      finalPrice: 995,
      currency: 'sek'
    }
  },

  gps_max: {
    name: 'GPS Max',
    shortName: 'GPS Max',
    description:
    'Se & dela din hunds positioner med din telefon. Ett GPS-abonnemang och allt i WeHunt Premium ingår.',
    buy: 'Köp GPS Max',
    autoRenew: '',
    upgrade: '',
    extend: 'Förläng abonnemang',

    features: {
      maxGPS: 'Högst 1 GPS-enhet (säljs separat) | Högst {count} GPS-enheter (säljs separat)',
      worksWith:
      'Fungerar med <a target="_blank" href="https://www.widforss.se/tracker-artemis-easy-1">Tracker Artemis</a>, <a target="_blank" href="https://www.widforss.se/tracker-bark">Tracker Bark</a>, <a target="_blank" href="https://www.widforss.se/tracker-g10i-1">Tracker G10i</a>, Tracker Supra, Tracker G1000, Tracker G500FI'
    },

    amountOfDevices: 'Antal enheter'
  },

  gps_1: {
    name: 'GPS Max 1',
    shortName: 'GPS Max 1',
    description:
    'Se & dela din hunds positioner med din telefon. Ett GPS-abonnemang och allt i WeHunt Premium ingår.',
    buy: 'Köp GPS Max 1',
    autoRenew: '',
    upgrade: '',
    extend: 'Förläng abonnemang',

    features: {
      maxGPS: 'Högst 1 GPS-enhet (säljs separat)',
      worksWith:
      'Fungerar med <a target="_blank" href="https://www.widforss.se/tracker-artemis-easy-1">Tracker Artemis</a>, <a target="_blank" href="https://www.widforss.se/tracker-bark">Tracker Bark</a>, <a target="_blank" href="https://www.widforss.se/tracker-g10i-1">Tracker G10i</a>, Tracker Supra, Tracker G1000, Tracker G500FI'
    }
  },

  gps_2: {
    name: 'GPS Max 2',
    shortName: 'GPS Max 2',
    description:
    'Se & dela dina hundars positioner med din telefon. Två GPS-abonnemang och allt i WeHunt Premium ingår.',
    buy: 'Köp GPS Max 2',
    autoRenew: '',
    upgrade: '',
    extend: 'Förläng abonnemang',

    features: {
      maxGPS: 'Högst 2 GPS-enheter (säljs separat)',
      worksWith:
      'Fungerar med <a target="_blank" href="https://www.widforss.se/tracker-artemis-easy-1">Tracker Artemis</a>, <a target="_blank" href="https://www.widforss.se/tracker-bark">Tracker Bark</a>, <a target="_blank" href="https://www.widforss.se/tracker-g10i-1">Tracker G10i</a>, Tracker Supra, Tracker G1000, Tracker G500FI'
    }
  },

  gps_3: {
    name: 'GPS Max 3',
    shortName: 'GPS Max 3',
    description:
    'Se & dela dina hundars positioner med din telefon. Tre GPS-abonnemang och allt i WeHunt Premium ingår.',
    buy: 'Köp GPS Max 3',
    autoRenew: '',
    upgrade: '',
    extend: 'Förläng abonnemang',

    features: {
      maxGPS: 'Högst 3 GPS-enheter (säljs separat)',
      worksWith:
      'Fungerar med <a target="_blank" href="https://www.widforss.se/tracker-artemis-easy-1">Tracker Artemis</a>, <a target="_blank" href="https://www.widforss.se/tracker-bark">Tracker Bark</a>, <a target="_blank" href="https://www.widforss.se/tracker-g10i-1">Tracker G10i</a>, Tracker Supra, Tracker G1000, Tracker G500FI'
    }
  },

  gps_6: {
    name: 'GPS Max 6',
    shortName: 'GPS Max 6',
    description:
    'Se & dela dina hundars positioner med din telefon. Sex GPS-abonnemang och allt i WeHunt Premium ingår.',
    buy: 'Köp GPS Max 6',
    autoRenew: '',
    upgrade: '',
    extend: 'Förläng abonnemang',

    features: {
      maxGPS: 'Högst 6 GPS-enheter (säljs separat)',
      worksWith:
      'Fungerar med <a target="_blank" href="https://www.widforss.se/tracker-artemis-easy-1">Tracker Artemis</a>, <a target="_blank" href="https://www.widforss.se/tracker-bark">Tracker Bark</a>, <a target="_blank" href="https://www.widforss.se/tracker-g10i-1">Tracker G10i</a>, Tracker Supra, Tracker G1000, Tracker G500FI'
    }
  }
}
