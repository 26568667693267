<template>
  <div class="advertisement" :class="{ 'is-clickable': targetUrl !== null }" @click="open">
    <img class="image" :src="imageUrl">
    <img v-if="logoUrl" class="logo" :src="logoUrl" />

    <Badge
      :text="$t('advertisement.ad')"
      type="info"
      light>
    </Badge>
  </div>
</template>

<script>
export default {
  props: {
    advertisement: {
      type: Object,
      default: () => null
    }
  },

  computed: {
    imageUrl () {
      return this.advertisement.imageUrl
    },

    logoUrl () {
      return this.advertisement.logoUrl
    },

    targetUrl () {
      return this.advertisement.targetUrl
    }
  },

  methods: {
    open () {
      if (this.targetUrl !== null) {
        window.location.href = this.targetUrl
      }
    }
  }
}
</script>

<style scoped>
.advertisement {
  position: relative;
}

.advertisement .image {
  border-radius: 4px;
}

.advertisement .logo {
  position: absolute;
  width: 15%;
  max-width: 100px;
  bottom: 0.5rem;
  right: 0.5rem;
}

.advertisement .tag {
  position: absolute;
  top: 0.75rem;
  right: 0.75rem;
}
</style>
