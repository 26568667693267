<template>
  <div
    :class="{ 'is-hoverable': hoverable }"
    class="list">
    <slot />
  </div>
</template>

<script>
export default {

  props: {
    hoverable: {
      type: Boolean,
      default: false
    }
  }

}
</script>
