export default {
  gps: 'GPS',
  gsm: 'GSM',
  battery: 'Batteri',
  latency: 'Fördröjning',
  info: 'Info',
  coverage: 'Täckning',
  coverageMap: 'Täckningskarta',
  pickDate: 'Välj datum',
  noPositions: 'Denna GPS har inga registrerade positioner de tre senaste månaderna.',

  activateGps: 'Aktivera ny GPS',
  activateGpsHelpText: 'Klicka på knappen ovan för att aktivera din första GPS.',

  loadGpsDeviceFailedErrorMessage: 'Ett fel inträffade och det gick inte att läsa informationen om din GPS. Vänligen försök igen.',
  loadGpsDataFailedErrorMessage: 'Ett fel inträffade och det gick inte att läsa in positionsdatan från din GPS. Vänligen försök igen.',
  loadGpsCoverageFailedErrorMessage: 'Ett fel inträffade och det gick inte att läsa in täckningskartan. Vänligen försök igen.',

  gpsContentGood: 'När GPS-täckningen är 100% räknas den som god. GPS:ens täckning baseras på antal satelliter den har kontakt med.',
  gpsContentDecent: 'När GPS-täckningen är mellan 80% och 100% räknas den som medel. GPS:ens täckning baseras på antal satelliter den har kontakt med. När GPS:en rör sig kan den få bättre täckning av att få kontakt med fler satelliter.',
  gpsContentBad1: 'När GPS-täckningen är lägre än 80% kan GPS:en positioneras felaktigt.<br />Positionen kan fastna på ett ställe för att sedan, när täckningen blivit god, förflyttas till korrekt position. Detta kan upplevas som hoppigt.',
  gpsContentBad2: 'GPS:ens täckning är beroende av antal satelliter den fått kontakt med, när GPS:en rör sig kan den få bättre täckning av att få kontakt med fler satelliter.',

  gsmContentGood: 'När GSM-täckningen är 6 eller mer räknas den som god. GSM-täckningen baseras på möjligheten att koppla upp på 2G-nät.',
  gsmContentDecent: 'När GSM-täckningen är mellan 3 och 6 räknas den som medel. GSM-täckningen baseras på möjligheten att koppla upp på 2G-nät.',
  gsmContentBad: 'När GSM-täckningen är lägre än 3 kan GPS:en positioneras felaktigt.<br />Det kan uppstå långa fördröjningar, GPS:en kan fastna på fel position för att sedan snabbt förflytta sig till korrekt position.',

  latencyContentGood: 'En fördröjning på mindre än 5s påverkar inte GPS:ens precision nämnvärt.<br />Positioneringen kan fördröjas när GPS- och GSM-täckningen inte fungerar som den ska.',
  latencyContentDecent: 'När fördröjningen är mellan 2 och 20 sekunder börjar den påverka positioneringen utav GPS:en på ett negativt vis.<br />Positioneringen kan fördröjas när GPS- och GSM-täckningen inte fungerar som den ska.',
  latencyContentBad: 'När fördröjningen är mer än 20 sekunder påverkar den positioneringen av GPS:en kraftigt.<br />Positioneringen kan fördröjas när GPS- och GSM-täckningen inte fungerar som den ska.'
}
