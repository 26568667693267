<template>
  <tr @click="rowClicked">
    <slot />
  </tr>
</template>

<script>
export default {
  methods: {
    rowClicked (event) {
      this.$emit('click', event)
    }
  }
}
</script>
