<template>
  <img
    :aria-label="alt"
    :alt="alt"
    :title="alt"
    :width="width"
    :height="height"
    :src="logotype"
    role="logotype" />
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      default: 'white'
    },

    width: {
      type: Number,
      default: 140
    },

    height: {
      type: Number,
      default: 35
    },

    alt: {
      type: String,
      default: process.env.VUE_APP_BRAND
    }
  },

  computed: {
    logotype () {
      const logos = {
        white: '/img/logos/logo-text.png',
        black: '/img/logos/logo-text-black.png'
      }

      return logos[this.color]
    }
  }
}
</script>

<style>
.navbar-brand img {
  vertical-align: middle;
}
</style>
