import state from './state'
import mutations from './mutations'
import actions from './actions'
import getters from './getters'

import assignments from './assignments/'
import chat from './chat/'
import events from './events/'
import games from './games/'
import invites from './invites/'
import map from './map/'
import participants from './participants/'
import roles from './roles/'
import playback from './playback/'
import drives from './drives/'

const namespaced = true

export default {
  namespaced,
  state,
  actions,
  mutations,
  getters,
  modules: {
    assignments,
    chat,
    events,
    games,
    invites,
    map,
    participants,
    roles,
    playback,
    drives
  }
}
