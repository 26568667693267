export default {
  gps: 'GPS',
  gsm: "Couverture",
  battery: "Batterie",
  latency: "Retard",
  info: 'Info',
  coverage: "Couverture",
  coverageMap: "Carte de couverture",
  pickDate: "Sélectionner une date",
  noPositions: "Aucune position n'a été reçue du GPS au cours des trois derniers mois.",

  activateGps: "Activer un nouveau GPS",
  activateGpsHelpText: "Cliquez sur le bouton ci-dessus pour activer votre premier GPS.",

  loadGpsDeviceFailedErrorMessage: "Une erreur s'est produite et les informations GPS n'ont pas pu être lues. Veuillez réessayer.",
  loadGpsDataFailedErrorMessage: "Une erreur s'est produite et les données de localisation GPS n'ont pas pu être chargées. Veuillez réessayer.",
  loadGpsCoverageFailedErrorMessage: "Une erreur s'est produite et la carte de couverture GPS n'a pas pu être chargée. Veuillez réessayer.",

  gpsContentGood: "Lorsque la couverture GPS est de 100 %, elle est considérée comme bonne. La couverture GPS dépend du nombre de satellites avec lesquels il est entré en contact.",
  gpsContentDecent: "Lorsque la couverture GPS est comprise entre 80 % et 100 %, elle est considérée comme moyenne. La couverture GPS dépend du nombre de satellites avec lesquels il est entré en contact. Si le GPS se déplace, il peut obtenir une meilleure couverture en entrant en contact avec davantage de satellites.",
  gpsContentBad1: "Lorsque la couverture GPS est inférieure à 80 %, le GPS peut être mal positionné. La position peut rester bloquée à un endroit. Puis, lorsque la couverture s'est améliorée, la position se remet à jour. Ceci peut apparaître comme une petite anomalie d'affichage.",
  gpsContentBad2: "La couverture GPS dépend du nombre de satellites avec lesquels il est entré en contact. Si le GPS se déplace, il peut obtenir une meilleure couverture en entrant en contact avec davantage de satellites.",

  gsmContentGood: "Lorsque la couverture GSM est de 6 ou plus, elle est considérée comme bonne. La couverture GSM est basée sur la possibilité de se connecter aux réseaux 2G.",
  gsmContentDecent: "Lorsque la couverture GSM est comprise entre 3 et 6, elle est considérée comme moyenne. La couverture GSM repose sur la possibilité de se connecter aux réseaux 2G.",
  gsmContentBad: "Lorsque la couverture GSM est inférieure à 3, le GPS peut être mal positionné. Il peut y avoir de longs délais, le GPS peut rester bloqué dans la mauvaise position puis se déplacer rapidement vers la bonne position.",

  latencyContentGood: "Un retard inférieur à 5 secondes n'affecte pas de manière significative la précision du GPS. Le positionnement peut être retardé lorsque le GPS et le GSM ne fonctionnent pas correctement.",
  latencyContentDecent: "Lorsque le retard est compris entre 2 et 20 secondes, cela commence à affecter négativement le positionnement du GPS. Le positionnement peut être retardé lorsque la couverture GPS et GSM ne fonctionne pas correctement.",
  latencyContentBad: "Lorsque le retard est supérieur à 20 secondes, cela affecte grandement le positionnement du GPS. Le positionnement peut être retardé lorsque la couverture GPS et GSM ne fonctionne pas correctement."
}
