export default {
  years: {
    all: "Toutes les années",
    today: "Aujourd'hui"
  },

  events: {
    kills: "Prélevé",
    observations: "Observé",
    all: "Tous"
  },

  game: {
    killed: "Gibier prélevé",
    observed: "Gibier observé",
    killedAndObserved: "Gibier prélevé et observé",
    all: "Tout le gibier",
    select: "Sélectionnez un animal",
    none: "Il n'y a pas de gibier"
  },

  time: {
    time: "Temps (heures)",
    total: "Temps total",
    perGame: "Temps par animal"
  },

  units: {
    pcs: "ordinateur",
    hours: 'h',
    minutes: 'min'
  },

  pro: {
    heatMap: {
      title: "Carte thermique",
      subtitle: "Suivez les déplacements du gibier sur le territoire de chasse au fil du temps."
    },

    killed: "Statistiques sur le gibier prélevé.",
    observed: "Statistiques sur le gibier observé.",
    killedAndObserved: "Aperçu de tous les gibiers signalés, chassés et observés.",
    manHours: "Suivez la population de gibier du territoire de chasse pendant les heures de travail. "
  },

  manHoursPerGame: "Heures de travail par gibier"
}
