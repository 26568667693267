import Vue from 'vue'

import App from '@/App.vue'
import router from '@/router/'
import store from '@/store/'
import { i18n } from '@/i18n.js'

import '@/plugins.js'
import '@/components/index.js'

require('es6-promise').polyfill()

// Make vue's event manager available across the app
const eventManager = new Vue()

// Keep our console clean in production,
// And filled with info during development.
Vue.config.productionTip = false
Vue.config.devtools = process.env.NODE_ENV !== 'production'
Vue.config.performance = process.env.NODE_ENV !== 'production'

// Mount app
const app = new Vue({
  router,
  store,
  i18n,
  render: h => h(App),
  mq: {
    phone: '(max-width: 768px)',
    tablet: '(max-width: 1024px)',
    desktop: '(min-width: 1024px)'
  }
}).$mount('.app')

export {
  eventManager,
  app
}
