import inviteActivityStore from './inviteActivityStore'
import inviteHuntStore from './inviteHuntStore'
import inviteHuntAreaStore from './inviteHuntAreaStore'

export default {
  namespaced: true,

  modules: {
    activity: inviteActivityStore,
    hunt: inviteHuntStore,
    huntarea: inviteHuntAreaStore
  }
}
