export default {
  dashboard: 'Dashboard',
  map: 'Map',
  team: 'Team',
  documents: 'Documents',
  hunts: 'Hunts',
  calendar: 'Calendar',
  gallery: 'Gallery',
  messages: 'Messages',
  settings: 'Settings'
}
