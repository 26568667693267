import { i18n } from '@/i18n'

const markerTypes = [
  'pass',
  'tower',
  'custom',
  'food_place',
  'bait',
  'gathering_place',
  'bridge',
  'ford',
  'salt_lick',
  'bar',
  'cam',
  'burrow',
  'parking',
  'other',

  'after_search',
  'observation',
  'kill',
  'poo',
  'tracks', // This ain't "trails" - this is something else. I know.. trust me :-D

  'hunter',
  'hunter_safe',
  'hunter_off',

  'dog',
  'dog_off'
]

const markers = () => {
  const markers = []

  markerTypes.forEach($type => {
    const type = ($type || 'other')

    markers.push({
      type: type,
      name: i18n.t('map.markers.' + type),
      icon: getMarkerTypeIconUrl(type)
    })
  })

  return markers
}

const getMarkerTypeIconUrl = type => {
  const icon = (type || 'other').replace('_', '-').toLowerCase()
  return `/img/markers/marker-${icon}.svg`
}

const getMarkerNameFromType = type => {
  return i18n.t(`map.markers.${(type || 'other')}`)
}

export {
  markerTypes,
  markers,
  getMarkerTypeIconUrl,
  getMarkerNameFromType
}
