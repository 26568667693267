import StripeService from "./stripeService"

import BillingPortalSessionModel from "../models/billingPortalSessionModel"
import StripeSessionModel from "../models/stripeSessionModel"
import SubscriptionModel from "../models/subscriptionModel"

// Documentation: https://bitbucket.org/wehunt/wehunt-adm/src/master/
class SubscriptionService {
  static async read (credentials) {
    const url =
      process.env.VUE_APP_SUBSCRIPTION_API_URL_V3 +
      "/stripe/v3/status.php?" +
      getTestModeParameter()

    const headers = new Headers({
      Authorization: `Basic ${credentials}`
    })

    const response = await fetch(url, { headers })
    if (!response.ok) {
      console.error(response)
      throw new Error("Failed to get subscription status.")
    }

    const { subscription } = await response.json()
    return new SubscriptionModel(subscription)
  }

  static async purchase (type, credentials, returnPath, customMessage) {
    const parameters = [
      "type=" + type,
      "callback=" + process.env.VUE_APP_BASEURL + returnPath,
      "custom_message=" + customMessage,
      ...getUtmParameters(),
      getTestModeParameter()
    ]

    const url =
      `${process.env.VUE_APP_SUBSCRIPTION_API_URL_V3}/stripe/v3/session.php?` +
      parameters.join("&")

    const headers = new Headers({
      Authorization: `Basic ${credentials}`
    })

    const response = await fetch(url, { headers })
    if (!response.ok) {
      console.error(response)
      throw new Error(`Failed to get ${type} subscription session.`)
    }

    const json = await response.json()
    return new StripeSessionModel(json)
  }

  /*
  static async purchaseGps (imei, returnPath, credentials) {
    const parameters = [
      'imei=' + imei,
      'callback=' + process.env.VUE_APP_BASEURL + returnPath,
      ...getUtmParameters(),
      getTestModeParameter()
    ]

    const url =
      process.env.VUE_APP_SUBSCRIPTION_API_URL +
      '/stripe/gps/session.php?' +
      parameters.join('&')

    const headers = new Headers({
      Authorization: `Basic ${credentials}`
    })

    const response = await fetch(url, { headers })
    const json = await response.json()

    if (response.ok) {
      const session = new StripeSessionModel(json)
      SubscriptionService.checkout(session)
    } else {
      console.error(response)
      throw new Error(json.error)
    }
  }
  */

  static checkout (session) {
    return StripeService.checkout(session.id)
  }

  static async cancel (credentials) {
    const url =
      process.env.VUE_APP_SUBSCRIPTION_API_URL_V3 +
      "/stripe/v3/cancel.php?" +
      getTestModeParameter()

    const headers = new Headers({
      Authorization: `Basic ${credentials}`
    })

    const response = await fetch(url, { headers })
    if (!response.ok) {
      console.error(response)
      throw new Error("Failed to cancel pro subscription.")
    }
  }

  static async cancelGps (imei, credentials) {
    const parameters = ["imei=" + imei, getTestModeParameter()]

    const url =
      process.env.VUE_APP_SUBSCRIPTION_API_URL +
      "/stripe/gps/cancel.php?" +
      parameters.join("&")

    const headers = new Headers({
      Authorization: `Basic ${credentials}`
    })

    const response = await fetch(url, { headers })
    if (!response.ok) {
      console.error(response)
      throw new Error("Failed to cancel gps subscription.")
    }
  }

  static async getBillingPortalSession (credentials, returnPath) {
    try {
      const baseUrl =
        process.env.VUE_APP_SUBSCRIPTION_API_URL_V3 + "/stripe/v3/portal.php"

      const parameters = [
        "callback=" + (process.env.VUE_APP_BASEURL + returnPath),
        getTestModeParameter()
      ]

      const url = baseUrl + "?" + parameters.join("&")

      const headers = new Headers({
        Authorization: `Basic ${credentials}`
      })

      const response = await fetch(url, { headers })
      if (response.ok) {
        const json = await response.json()

        const session = new BillingPortalSessionModel(json)
        if (session.url === null) {
          throw new Error("Session did not contain a url.")
        }

        return session
      } else {
        throw response
      }
    } catch (error) {
      console.error("Failed to get billing session to stripe.")
      console.error(error)
      throw error
    }
  }
}

function getUtmParameters () {
  const url = new URL(window.location)

  const source = url.searchParams.get("utm_source") || ""
  const medium = url.searchParams.get("utm_medium") || ""
  const campaign = url.searchParams.get("utm_campaign") || ""

  // Make sure we don't get invalid values
  const regex = new RegExp("[^0-9a-z_]", "gi")

  return [
    "utm_source=" + source.replace(regex, ""),
    "utm_medium=" + medium.replace(regex, ""),
    "utm_campaign=" + campaign.replace(regex, "")
  ]
}

function getTestModeParameter () {
  return process.env.VUE_APP_SUBSCRIPTION_API_TEST_MODE === "true"
    ? "test=true"
    : ""
}

export default SubscriptionService
