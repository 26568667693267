class GpsModel {
  constructor (context) {
    const data = Object.assign({}, context)
    this.imei = data.imei || null
  }

  toJSON () {
    return {
      imei: this.imei
    }
  }
}

export default GpsModel
