export default {
  hunts: 'Hunts',

  reports: 'Hunting reports',
  noReports: 'No hunting reports have been created yet.',

  plannedHunts: 'Planned hunts',
  noHunts: 'No hunts are planned.',

  newReport: 'New hunting report',
  exportToExcel: 'Export to Excel',

  hunt: 'Hunt',
  date: 'Date',
  killsAndObservations: "Downed/Observations",
  planning: 'Planning ongoing'
}
