import api from '@/api/'
import SubAreaModel from './subAreaModel'

class SubAreaService {
  static async create (huntAreaId, subArea) {
    try {
      const { data } = await api.post(`/areas/${huntAreaId}/map/subareas`, subArea.toJSON())
      return new SubAreaModel(data)
    } catch (error) {
      console.error(`Failed to create subArea for huntArea ${huntAreaId}.`)
      throw error
    }
  }

  static async read (huntAreaId, type) {
    try {
      const { data } = await api.get(`/areas/${huntAreaId}/map/subareas?type=${type}`)
      return data.map(area => new SubAreaModel(area))
    } catch (error) {
      console.error(`Failed to read subAreas for huntArea ${huntAreaId}.`)
      throw error
    }
  }

  static async get (huntAreaId, subAreaId) {
    try {
      const { data } = await api.get(`/areas/${huntAreaId}/map/subareas/${subAreaId}`)
      return new SubAreaModel(data)
    } catch (error) {
      console.error(`Failed to get subArea with id ${subAreaId}.`)
      throw error
    }
  }

  static async update (huntAreaId, subArea) {
    try {
      await api.put(`/areas/${huntAreaId}/map/subareas/${subArea.id}`, subArea.toJSON())
    } catch (error) {
      console.error(`Failed to update sub area ${subArea.id}.`)
      throw error
    }
  }

  static async delete (huntAreaId, subAreaId) {
    try {
      await api.delete(`/areas/${huntAreaId}/map/subareas/${subAreaId}`)
    } catch (error) {
      console.error(`Failed to delete sub area ${subAreaId}.`)
      throw error
    }
  }
}

export default SubAreaService
