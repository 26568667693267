export default {
  title: 'Kalender',
  huntAreas: 'Jaktmarker',
  invites: 'Inbjudningar',
  showAll: 'Visa alla',
  where: 'Var',
  notInvitedMessage: 'Du deltar inte i jakten och kan därför inte se all information om jakten.',
  noInvitationText: 'Ingen notering gjord.',
  noComment: 'Ingen kommentar.',
  noParticipants: 'Inga jaktdeltagare.',
  seeCompleteReport: 'Se fullständig rapport',
  seeCompletePlanning: 'Se fullständig planering',
  hunt: 'Jakt',
  huntingReport: 'Jaktrapport',
  invited: 'Inbjudna',
  noInvites: 'Du har inga obesvarade inbjudningar',

  loadActivitiesFailedErrorMessage: 'Ett fel inträffade och det gick inte att ladda in alla händelser och jakter i kalendern. Vänligen försök igen.',
  noHuntAreasText: '<p>Du är inte medlem i någon jaktmark.</p><p>Du måste ingå i minst en jaktmark för att kunna skapa aktiviteter eller planera en jakt.</p>',

  planHunt: 'Planera jakt',
  planHuntHelpText: 'Välj vilken jaktmark som jakten ska planeras för.',
  planHuntFailedErrorMessage: 'Ett fel inträffade och det gick inte att påbörja planering av en ny jakt. Vänligen försök igen.',

  startsAt: 'Börjar',
  startedAt: 'Började',
  endsAt: 'Slutar',
  endedAt: 'Slutade',

  activity: {
    new: 'Ny aktivitet',
    huntArea: 'Jaktmark',
    title: 'Titel',
    titlePlaceholder: 'T.ex Passröjning, Spillningsinventering',
    titleRequired: 'Du måste ange en titel',
    from: 'Från',
    to: 'Till',
    fromRequired: 'Du måste ange när aktiviteten startar',
    toRequired: 'Du måste ange när aktiviteten slutar',
    description: 'Beskrivning',
    descriptionPlaceholder: 'Beskrivning',
    participants: 'Deltagare',
    noParticipants: 'Inga medlemmar har bjudits in till aktiviteten.',
    invitedNoAnswer: 'Ej svarat',
    invitedAccepted: 'Accepterat',
    invitedDeclined: 'Avböjt',
    removeTitle: 'Radera aktivitet?',
    removeMessage: 'Vill du verkligen radera aktiviteten?',
    removeFailedErrorMessage: 'Ett fel inträffade och det gick inte att ta bort aktiviteten. Vänligen försök igen.',
    saveFailedErrorMessage: 'Ett fel inträffade och det gick inte att spara ändringarna. Vänligen försök igen.',
    createdBy: 'Skapades av {name}, {date}',
    unknown: 'Okänd',
    removeParticipation: 'Ta bort deltagande'
  },

  moonPhases: {
    newMoon: 'Nymåne',
    waxingCrescentMoon: 'Tilltagande skära',
    firstQuarterMoon: 'Växande halvmåne',
    waxingGibbousMoon: 'Tilltagande halvmåne',
    fullMoon: 'Fullmåne',
    waningGibbousMoon: 'Avtagande halvmåne',
    lastQuarterMoon: 'Avtagande Halvmåne',
    waningCrescentMoon: 'Avtagande skära'
  }
}
