export default {

  /* Zoom */
  setZoom (state, level) {
    state.zoom = parseInt(level)
  },

  zoomIn (state) {
    state.zoom += 1
  },

  zoomOut (state) {
    state.zoom -= 1
  },

  toggleScrollZoom (state, bool) {
    state.scrollZoom = bool
  },

  maximize (state) {
    state.fullscreen = true
  },

  minimize (state) {
    state.fullscreen = false
  },

  /* Layers */
  setMapType (state, type) {
    state.mapType = type
  },

  setTerrainLayerVisibility (state, boolean) {
    state.terrainLayer = boolean
  },

  setPropertyLayerVisibility (state, boolean) {
    state.propertyLayer = boolean
  },

  setPropertyBoundariesLayerVisibility (state, visible) {
    state.propertyBoundariesLayer = visible
  },

  /* Misc */
  setLabelSize (state, size) {
    state.labelSize = size
  },

  toggleFullscreen (state, boolean) {
    state.fullscreen = boolean
  },

  /* Panel */
  togglePanel (state) {
    state.isPanelActive = !state.isPanelActive
  },

  closePanel (state) {
    state.isPanelActive = false
  }

}
