import dayjs from 'dayjs'

export default class CalendarActivityInviteModel {
  constructor (json) {
    this.title = json.title || ''
    this.description = json.description || ''
    this.huntArea = json.parentGroupName || ''
    this.startsAt = json.from ? dayjs(json.from).toDate() : null
    this.endsAt = json.to ? dayjs(json.to).toDate() : null
    this.invitations = json.invitations ? json.invitations.map(invitation => new CalendarActivityInviteMember(invitation)) : []
  }
}

class CalendarActivityInviteMember {
  constructor (json) {
    this.id = json.id || null
    this.userId = json.invitedUserId || null
    this.name = json.invitedUserName || ''
    this.hasAnswered = json.hasAnswered || false
    this.hasAccepted = json.hasAccepted || false
    this.invitedAt = json.invitedAt ? dayjs(json.invitedAt).toDate() : null
  }
}
