export default {
  invites: "Invitations",
  noInvites: "Il n'y a pas d'invitations.",
  showMoreInvites: " | Afficher 1 invitation supplémentaire | Afficher {count} invitations supplémentaires",
  ongoingHunts: "En cours",
  ongoingHunt: "Chasse en cours",
  planningHunts: "Planification",
  noPlanningHunts: "Aucune chasse n'est prévue.",
  plannedHunts: "Prévu",
  plannedHunt: "Chasse planifiée",
  noPlannedHunts: "Aucune chasse n'est prévue.",
  finishedHunts: "Terminées",
  noFinishedHunts: "Il n'y a pas de chasses terminées.",
  saving: "Enregistrement...",

  title: "Chasse",
  hunt: "Chasse",
  hunts: "Chasses",
  noDateAvailable: "Aucune date précisée",
  overview: "Aperçu",
  overviewHelpText: "Ici, vous pouvez partager des informations et des détails pratiques sur la chasse avec tous les participants.",
  date: "Date",
  startDate: 'Date',
  startTime: "Temps",
  startsAt: "Commence",
  endsAt: "Prend fin",
  toHunt: "Chasser",

  invitation: "Inviter",
  invitationToHunt: "Invité à chasser",
  invitationHelpText: "Rédigez une invitation aux participants. Les informations qui doivent apparaître sont le lieu et la date de rassemblement ainsi que d'autres détails pratiques sur la chasse.",

  huntableGames: {
    title: "espèce chassable",
    helpText: "Précisez quel gibier est autorisé pendant la chasse.",
    noHuntableGamesSelected: "Aucune espèce chassable spécifiée.",
    add: "Ajouter du gibier",
    species: "Espèces",
    comment: "Commentaire",
    mammals: "Mammifères",
    noMammalsAvailable: "Aucun mammifère disponible",
    birds: "Oiseaux",
    noBirdsAvailable: "Aucun oiseau disponible",
    addFailedErrorMessage: "Une erreur s'est produite et le gibier n'a pas pu être ajouté. Veuillez réessayer.",
    removeFailedErrorMessage: "Une erreur s'est produite et le gibier n'a pas pu être supprimé. Veuillez réessayer."
  },

  noDateSelected: "Vous devez choisir une date avant de pouvoir sélectionner une espèce chassable.",
  participant: 'Participant',
  participants: 'Participants',
  accepted: "Accepté",
  declined: "Décliné",
  waitingForReply: "En attente d'une réponse",
  unknownParticipant: "Inconnu",
  addHuntLeader: "Ajouter un responsable de chasse",
  addTrackingHunter: "Sélectionnez le participant à la chasse qui gère la recherche pendant la chasse.",
  removeParticipantFailedErrorMessage: "Une erreur s'est produite et le membre n'a pas pu être supprimé. Veuillez réessayer.",
  assign: "Attribuer",

  assignments: {
    title: "Attribution des postes et des rôles",
    helpText: "Planifiez votre chasse en la séparant en différentes sous-zones et attribuez des rôles à tous les participants.",
    addArea: "Ajouter une sous-zone",
    entireHuntArea: "Totalité du territoire de chasse ",
    createDriveFailedErrorMessage: "Une erreur s'est produite et la sous-zone n'a pas pu être créé. Veuillez réessayer.",
    removeDrive: "Êtes-vous sûr de vouloir supprimer la sous-zone <strong>{name}</strong> et les attributions associées ?",
    removeDriveErrorMessage: "Une erreur s'est produite et il n'a pas été possible de retirer la sous-zone. Veuillez réessayer.",
    removeMarkerFailedErrorMessage: "Une erreur s'est produite et il n'a pas été possible de supprimer l'affectation. Veuillez réessayer.",
    stand: "Poste",
    noAssignments: "Aucune attribution de poste ou de rôle n'est effectuée.",
    showAllParticipants: "Afficher tous les participants",
    hideDeclinedParticipants: "Masquer les participants qui ont refusé"
  },

  // Participants
  huntLeader: {
    title: "Responsable de chasse",
    helpText: "Sélectionnez un participant qui sera le responsable tout au long de la chasse.",
    errorMessage: "Une erreur s'est produite et aucun responsable de chasse n'a pu être sélectionné. Veuillez réessayer."
  },

  trackingHunter: {
    title: "Chasseur à l'approche ",
    helpText: "Sélectionnez un participant qui gère la recherche pendant la chasse.",
    noneSelected: "Aucune sélection",
    errorMessage: "Une erreur s'est produite et un chasseur à l'approche n'a pas pu être sélectionné. Veuillez réessayer."
  },

  // Add participants modal dialog
  addParticipants: {
    title: "Ajouter des participants",
    helpText: "Ajoutez tous les participants à inclure dans la chasse. Vous pouvez ajouter des membres de l'équipe de chasse ou inviter des invités en recherchant leur nom ou leur adresse e-mail.",
    searchPlaceholder: "Nom ou adresse email",
    allMembersIncludedInHunt: "Tous les membres de l'équipe de chasse sont inclus dans la chasse.",
    searchMatchCounterMessage: "{userCount} utilisateurs correspondent à votre recherche.",
    noUserWithEmail: "Nous ne trouvons pas de compte dans WeHunt avec cette adresse e-mail. Souhaitez-vous envoyer une invitation ?",
    addWithCount: "Ajouter | Ajouter <strong>1</strong> participant | Ajouter <strong>{userCount}</strong> participants"
  },

  // Different roles during hunt on a drive
  roles: {
    dogHandler: "Conducteur de chien",
    standShooter: "Tireur posté",
    driveHunter: "Rabatteur",
    unassigned: "Non attribué"
  },

  // Summary/confirm modal
  summary: {
    title: "Envoie des invitations?",
    summaryHelpText: "Tous les participants recevront une invitation par courriel et tous les participants qui disposent déjà de l'application recevront également une note.",
    inviteTitle: "Les invitations ont été envoyées à tous les participants"
  },

  selectStand: "Sélectionnez un poste",
  assignNotification: "Cliquez sur un <strong>poste</strong> ou un <strong>mirador</strong> pour l'attribuer à <strong>{name}</strong>",
  reports: "Rapports",
  wannaInviteEmail: "<a href=\"mailto: {email}\">{email}</a>&nbsp;n'a pas de compte WeHunt. Souhaitez-vous envoyer une invitation ?",
  hasNoAccount: "N'a pas de compte <strong>WeHunt</strong>",
  noMembersFound: "Il n'y a plus de membres sur ce territoire de chasse.",
  noUsersFound: "Aucun utilisateur trouvé.",
  isAlreadyAMember: "Cet utilisateur est déjà un participant.",
  huntCreatedMessage: "La chasse a été créée et nous avons envoyé des invitations à tous les participants.",

  mandatoryFieldsDialogTitle: "Incomplet",
  mandatoryFieldsDialogText: "Veuillez renseigner tous les champs obligatoires marqués d'un *.",

  planHunt: "Planifier une chasse",
  planHuntFailedErrorMessage: "Une erreur s'est produite et la planification d'une nouvelle chasse a échoué. Veuillez réessayer.",
  details: "Détails",
  huntReport: "Rapport de chasse",

  settings: "Paramètres",
  terrainMap: "Territoire ",
  propertyMap: "Propriété",

  tower: "Mirador",

  events: {
    title: "Événements",
    helpText: "Ici vous pouvez signaler des événements intéressants survenus pendant la chasse",
    noEvents: "Aucun événement ne s'est produit.",
    locked: "Vous ne pouvez pas modifier l'événement car il a été créé par un autre chasseur.",
    kill: "Gibier prélevé",
    killShort: "Prélevé",
    observation: 'Observation',
    observationShort: "Obs.",
    selectSpecies: "Sélectionner des espèces",
    eventPanelHelpText: "Cliquez sur la carte pour marquer le lieu de l'événement.",
    createFailedErrorMessage: "Une erreur s'est produite et l'événement n'a pas pu être créé. Veuillez réessayer.",
    updateFailedErrorMessage: "Une erreur s'est produite et l'événement n'a pas pu être mis à jour. Veuillez réessayer.",
    removeFailedErrorMessage: "Une erreur s'est produite et l'événement n'a pas pu être supprimé. Veuillez réessayer.",
    removeConfirmMessage: "Êtes-vous sûr de vouloir supprimer le <strong>{type></strong> qui s'est produit le <strong>{date></strong>."
  },

  // Chat
  sendMessageToParticipantsPlaceholder: "Envoyer un message à tous les participants",

  // Playback
  chat: "Discussion",
  play: "Rejouer la chasse",
  hunters: "Chasseurs",
  dogs: "Chiens",
  noDogsInHunt: "Aucun chien doté d'un GPS compatible n'a participé à la chasse.",
  trailLength: "Longueur du sentier",
  noEventsYet: "Aucun événement ne s'est encore produit.",
  noMessagesYet: "Aucun message n'a été envoyé.",

  showPanelText: "Afficher les participants, les messages et les événements",
  hidePanelText: "Tout cacher",

  gameTitle: "Gibier",

  invite: {
    acceptInvite: "Accepter l'invitation?",
    accept: "Accepter",
    declineInvite: "Refuser l'invitation ?",
    decline: "Refuser",
    acceptConfirmMessage: "Êtes-vous sûr de vouloir accepter l'invitation à chasser à {huntArea} le {date} ?",
    declineConfirmMessage: "Êtes-vous sûr de vouloir refuser l'invitation à chasser à {huntArea}, le {date} ?",
    respondToInviteFailedErrorMessage: "Une erreur s'est produite et il n'a pas été possible de répondre à l'invitation. Veuillez réessayer."
  },

  removeHuntFailedErrorMessage: "Une erreur s'est produite et il n'a pas été possible de supprimer la chasse. Veuillez réessayer.",
  leave: "Quitter la chasse",
  confirmLeaveTitle: "Quitter la chasse ?",
  confirmLeaveMessage: "Êtes-vous sûr de vouloir quitter la chasse ?"
}
