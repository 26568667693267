<template>
  <p class="help" :class="classes">
    <slot>{{ text }}</slot>
  </p>
</template>

<script>
export default {
  props: {
    text: {
      type: String,
      default: ''
    },

    type: {
      type: String,
      default: ''
    }
  },

  computed: {
    classes () {
      return this.type.length > 0 ? ['is-' + this.type] : []
    }
  }
}
</script>
