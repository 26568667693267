import * as localization from '@/i18n.js'
import { getCountryCode } from '@/i18n'

export default {
  namespaced: true,

  state: {
    gdprModal: false,
    feedbackModal: false,
    newGpsModal: false,

    mobileNav: false,
    cursorLoader: false,

    darkMode: false,
    easyMode: false,

    huntAreasPanelActive: false,
    gpsPanelActive: false,

    language: ''
  },

  getters: {
    isGdprModalActive: state => state.gdprModal,
    isFeedbackModalActive: state => state.feedbackModal,
    isNewGpsModalActive: state => state.newGpsModal,

    isMobileNavActive: state => state.mobileNav,
    cursorLoader: state => state.cursorLoader,

    isDarkMode: state => state.darkMode,
    isEasyMode: state => state.easyMode,

    huntAreasPanelActive: state => state.huntAreasPanelActive,
    gpsPanelActive: state => state.gpsPanelActive,

    language: state => state.language,
    getCountryCode: state => getCountryCode(state.language),

    getLocale (state) {
      const countryCode = getCountryCode(state.language)
      return `${state.language}-${countryCode.toUpperCase()}`
    }
  },

  actions: {
    gdprModal ({ commit }, bool) {
      commit('gdprModal', bool)
    },

    feedbackModal ({ commit }, bool) {
      commit('feedbackModal', bool)
    },

    newGpsModal ({ commit }, bool) {
      commit('newGpsModal', bool)
    },

    mobileNav ({ commit }, bool) {
      commit('mobileNav', bool)
    },

    cursorLoader ({ commit }, bool) {
      commit('cursorLoader', bool)
    },

    isDeveloper ({ commit, rootGetters }) {
      const hasAccess = {
        theodor: '5703349030617088',
        daniel: '5652843293835264',
        linus: '5754918736494592',
        johanna: '5769442705276928',
        jenny: '5503984578068480',
        betamicke: '5757979884257280'
      }

      const userId = rootGetters['auth/getUserId']
      return Object.values(hasAccess).includes(userId)
    },

    clearAllStates ({ dispatch, commit }) {
      // Area
      dispatch('huntarea/select', null, { root: true })
      dispatch('huntarea/clear', null, { root: true })
      dispatch('huntarea/members/clear', null, { root: true })
      dispatch('map/markers/clear', null, { root: true })
      dispatch('map/subAreas/clear', null, { root: true })
      dispatch('map/lines/clear', null, { root: true })
      dispatch('map/trails/clear', null, { root: true })

      commit('calendar/clear', null, { root: true })
      commit('user/clear', null, { root: true })
      commit('subscription/clear', null, { root: true })
      commit('gps/clear', null, { root: true })
      commit('news/clear', null, { root: true })
    },

    setLanguage ({ commit }, language) {
      commit('setLanguage', language)
    }
  },

  mutations: {
    gdprModal (state, bool) {
      state.gdprModal = bool
    },

    feedbackModal (state, bool) {
      state.feedbackModal = bool
    },

    newGpsModal (state, bool) {
      state.newGpsModal = bool
    },

    mobileNav (state, bool) {
      state.mobileNav = bool
    },

    cursorLoader (state, bool) {
      state.cursorLoader = bool
    },

    isDarkMode (state, bool) {
      state.darkMode = bool
    },

    isEasyMode (state, bool) {
      state.easyMode = bool
    },

    setHuntAreasPanelActive (state, bool) {
      state.huntAreasPanelActive = bool
      state.gpsPanelActive = false
    },

    setGpsPanelActive (state, bool) {
      state.gpsPanelActive = bool
      state.huntAreasPanelActive = false
    },

    setLanguage (state, languageCode) {
      localization.setCurrentLanguage(languageCode)
      state.language = languageCode // Reactive and let other components perform logic when the language changes
    }
  }
}
