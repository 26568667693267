<template>
  <div :class="[verticallyAlignedClass]" class="empty-state">
    <Icon
      v-if="icon"
      :name="icon"
      class="is-x-large has-text-grey-light push-bottom">
    </Icon>

    <h4 v-if="title" class="title is-4" v-html="title"></h4>
    <p :class="[subTitleClass]" class="subtitle" v-html="tagline"></p>

    <slot />
  </div>
</template>

<script>
export default {
  props: {
    icon: {
      type: String,
      default: ''
    },

    title: {
      type: String,
      default: ''
    },

    tagline: {
      type: String,
      default: ''
    },

    verticallyAligned: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    hasDefaultSlot () {
      return this.$slots.default !== undefined
    },

    verticallyAlignedClass () {
      return this.verticallyAligned ? 'is-vertically-aligned' : ''
    },

    subTitleClass () {
      return {
        'is-marginless': !this.hasDefaultSlot,
        'is-6': this.title && this.tagline
      }
    }
  }
}
</script>

<style lang="scss">
.empty-state {
  padding: 1.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin: auto;
  width: 100%;
  max-width: 30rem;

  &.is-vertically-aligned {
    transform: translateY(-50%);
  }
}
</style>
