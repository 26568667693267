<template>
  <button :class="[activeClass]" class="button is-white" @click="togglePanel">
    <Icon name="icon-settings" />
  </button>
</template>

<script>
import { mapGetters } from 'vuex'

export default {

  computed: {
    ...mapGetters({
      isActive: 'map/settings/isPanelActive'
    }),

    activeClass () {
      return this.isActive ? 'is-primary' : ''
    }
  },

  methods: {
    togglePanel () {
      this.$store.dispatch('map/settings/togglePanel')
    }
  }

}
</script>
