import dayjs from 'dayjs'

import eventStore from './reportEventStore'
import participantStore from './reportParticipantStore'
import inviteStore from './reportInviteStore'
import chatStore from './reportChatStore'
import playbackStore from './reportPlaybackStore'
import gameStore from './reportGameStore'

import HuntReportModel, { HUNT_REPORT_STATE_FINISHED } from '../models/huntReportModel'
import HuntReportParticipantModel from '../models/huntReportParticipantModel'

import ReportService from '../services/reportService'

export default {
  namespaced: true,

  modules: {
    events: eventStore,
    participants: participantStore,
    invite: inviteStore,
    chat: chatStore,
    playback: playbackStore,
    games: gameStore
  },

  state: {
    report: null,
    hunt: null,
    huntArea: null,
    calculateHours: true,
    validation: {
      startsAt: null,
      endsAt: null
    }
  },

  getters: {
    isLoading (state) {
      return state.report === null
    },

    getReport (state) {
      return state.report
    },

    getHunt (state) {
      return state.hunt
    },

    getHuntArea (state) {
      return state.huntArea
    },

    getId (state) {
      return state.report.id
    },

    getTitle (state) {
      return state.report.title
    },

    getComment (state) {
      return state.report.comment
    },

    getStartsAt (state) {
      return state.report !== null ? state.report.startsAt : null
    },

    getEndsAt (state) {
      return state.report !== null ? state.report.endsAt : null
    },

    getHours (state, getters) {
      const startsAt = getters.getStartsAt
      const endsAt = getters.getEndsAt
      const calculateHours = getters.getCalculateHours
      const participants = getters['participants/getAll'] || []

      if (calculateHours) {
        if (startsAt && endsAt) {
          const hours = dayjs(endsAt).diff(startsAt, 'hours')
          return hours * participants.length
        }
      }

      return state.report.hours
    },

    getCalculateHours (state) {
      return state.calculateHours
    },

    getValidation (state) {
      return state.validation
    },

    canEdit (state, getters) {
      const hasHunt = state.report !== null && state.report.huntId !== null
      return hasHunt && (getters.isOwner || getters.isParticipant)
    },

    isOwner (state, getters, rootState, rootGetters) {
      const userId = rootGetters['auth/getUserId']
      return state.report.createdBy === userId
    },

    isParticipant (state, getters, rootState, rootGetters) {
      const userId = rootGetters['auth/getUserId']
      const participants = getters['participants/getAll']

      for (const participant of participants) {
        if (participant.userId === userId) {
          return true
        }
      }
      return false
    }
  },

  mutations: {
    set (state, report) {
      state.report = report
      state.hunt = null
    },

    new (state, { createdBy, huntAreaId }) {
      state.report = new HuntReportModel(({
        title: '',
        state: HUNT_REPORT_STATE_FINISHED,
        huntAreaId: huntAreaId,
        createdBy: createdBy
      }))

      state.calculateHours = true
    },

    setTitle (state, title) {
      state.report.title = title
    },

    setComment (state, comment) {
      state.report.comment = comment
    },

    setHours (state, hours) {
      state.report.hours = hours
    },

    setCalculateHours (state, calculateHours) {
      state.calculateHours = calculateHours
    },

    setStartsAt (state, startsAt) {
      state.report.startsAt = startsAt
      state.report.endsAt = startsAt ? dayjs(startsAt).add(1, 'hour').toDate() : null

      state.validation.startsAt = null
    },

    setEndsAt (state, endsAt) {
      state.report.endsAt = endsAt

      if (state.report.startsAt && state.report.endsAt) {
        const startsAt = dayjs(state.report.startsAt)
        const endsAt = dayjs(state.report.endsAt)

        if (endsAt.isBefore(startsAt)) {
          state.report.startsAt = null
        }
      }

      state.validation.endsAt = null
    },

    setHunt (state, hunt) {
      state.hunt = hunt
    },

    setHuntArea (state, huntArea) {
      state.huntArea = huntArea
    },

    setValidation (state, validation) {
      state.validation = validation
    }
  },

  actions: {
    new ({ dispatch, commit, getters, rootGetters }, huntAreaId) {
      const userId = rootGetters['auth/getUserId']

      commit('new', {
        createdBy: userId,
        huntAreaId: huntAreaId
      })

      const report = getters.getReport

      const profile = rootGetters['profile/getProfile']
      if (profile !== null) { // Can be null on page-refresh
        report.members.push(new HuntReportParticipantModel({
          userId: profile.userId,
          name: profile.name,
          email: profile.email
        }))
      }

      dispatch('readHuntArea')

      commit('setValidation', { startsAt: null, endsAat: null })
      commit('participants/set', report.members) // Modifying the store will actually modify the copy.members array
      commit('events/set', report.events) // Modifying the store will actually modify the copy.events array
    },

    async open ({ commit, getters, dispatch }, { reportId, huntId }) {
      commit('set', null)
      commit('setHunt', null)
      commit('setValidation', { startsAt: null, endsAat: null })
      commit('participants/set', null)
      commit('events/set', null)
      commit('chat/setMessages', null)
      commit('games/setGames', null)

      commit('invite/clear')
      commit('playback/clear')

      let report = null
      let hunt = null

      if (huntId) {
        // The user is not a member, so we must read from HC instead of Firebase
        hunt = await dispatch('hunt/getHuntWithId', huntId, { root: true })
        report = await dispatch('huntarea/reports/getByHunt', hunt, { root: true })
      } else {
        report = await dispatch('huntarea/reports/getById', reportId, { root: true })
      }

      const copy = new HuntReportModel(report) // Make copy in case user cancels without saving

      commit('set', copy)
      commit('setCalculateHours', false)

      // Modifying the store will actually modify the 'copy' object's attributes directly
      commit('participants/set', copy.members)
      commit('participants/setRemovedParticipants', copy.removedMembers)
      commit('events/set', copy.events)
      commit('events/setRemovedEvents', copy.removedEvents)

      dispatch('readHuntArea')
      dispatch('readMap')

      if (getters.canEdit) {
        console.log('User can edit report. Loading hunt data...')

        dispatch('chat/read', report.huntId)

        if (hunt !== null) {
          commit('setHunt', hunt)
        } else {
          hunt = await dispatch('readHunt', report.huntId)
        }

        dispatch('invite/read', hunt)
        dispatch('playback/read', { hunt, report })
      }
    },

    async save ({ getters, rootGetters, commit }) {
      commit('setHours', getters.getHours)

      let report = getters.getReport

      const action = report.id === null ? 'huntarea/reports/add' : 'huntarea/reports/update'

      const huntArea = getters.getHuntArea
      const credentials = rootGetters['auth/getBase64']

      const reportService = new ReportService(credentials)
      report = await reportService.saveReport(huntArea, report)

      commit(action, report, { root: true })
    },

    async readHunt ({ commit, dispatch }, huntId) {
      commit('setHunt', null)
      const hunt = await dispatch('hunt/getHuntWithId', huntId, { root: true })
      commit('setHunt', hunt)

      return hunt
    },

    async readHuntArea ({ getters, dispatch, commit }) {
      const report = getters.getReport

      commit('setHuntArea', null)
      const huntArea = await dispatch('huntarea/getById', report.huntAreaId, { root: true })
      commit('setHuntArea', huntArea)
    },

    async readMap ({ getters, rootGetters, dispatch }) {
      const huntArea = rootGetters['huntarea/selected']
      const report = getters.getReport

      // User is a guest hunter or viewing the report without a hunt area selected.
      // This means no map data is loaded, so we must load it ourselves.
      if (huntArea === null) {
        console.log('No hunt area selected. Loading map data...')

        dispatch('map/boundary/read', report.huntAreaId, { root: true })
        dispatch('map/markers/read', report.huntAreaId, { root: true })
      }
    },

    async remove ({ getters, rootGetters, commit }) {
      const report = getters.getReport

      const huntArea = rootGetters['huntarea/selected']
      const credentials = rootGetters['auth/getBase64']

      const reportService = new ReportService(credentials)
      await reportService.deleteReport(huntArea, report)

      commit('huntarea/reports/remove', report, { root: true })
    },

    close ({ commit }) {
      commit('events/set', null)
      commit('participants/set', null)
    },

    validate ({ commit, state }) {
      const validation = {
        startsAt: state.report.startsAt !== null,
        endsAt: state.report.endsAt !== null
      }

      commit('setValidation', validation)

      return validation.startsAt === true && validation.endsAt === true
    }
  }
}
