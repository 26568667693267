import dayjs from 'dayjs'

class MarkerModel {
  constructor (context) {
    const data = Object.assign({}, context)

    this.id = data.id || null
    this.name = data.name || ''
    this.type = data.type || ''
    this.note = data.note || ''
    this.customType = data.customType || null
    this.customColor = data.customColor || null
    this.location = data.location ? {
      lat: data.location.lat,
      lng: data.location.lng
    } : {
      lat: 0,
      lng: 0
    }

    this.createdAt = data.createdAt ? dayjs(data.createdAt).toDate() : null
    this.createdBy = data.createdBy || null

    this.visible = data.visible !== undefined ? data.visible : true
    this.editable = data.editable !== undefined ? data.editable : false

    this.user = null
  }

  assign (copy) {
    this.id = copy.id
    this.name = copy.name
    this.type = copy.type
    this.note = copy.note
    this.customType = copy.customType
    this.customColor = copy.customColor
    this.createdAt = copy.createdAt
    this.createdBy = copy.createdBy
    this.location = copy.location
    this.visible = copy.visible
    this.editable = copy.editable
    this.user = copy.user
  }

  toJSON () {
    return {
      id: this.id,
      name: this.name,
      type: this.type,
      note: this.note.length > 0 ? this.note : null,
      customType: this.customType || null,
      customColor: this.customColor || null,
      location: this.location
    }
  }
}

export default MarkerModel
