export default {
  /* Zoom */
  setZoom ({ commit }, level) {
    commit('setZoom', level)
  },

  zoomIn ({ commit }) {
    commit('zoomIn')
  },

  zoomOut ({ commit }) {
    commit('zoomOut')
  },

  /* Fullscreen */
  minimize ({ commit }) {
    commit('minimize')
    document.documentElement.classList.remove('map-is-fullscreen')
  },

  maximize ({ commit }) {
    commit('maximize')
    document.documentElement.classList.add('map-is-fullscreen')
  },

  /* Layers */
  setMapType ({ commit }, type) {
    commit('setMapType', type)
  },

  toggleTerrainLayerVisibility ({ commit }, visibility) {
    commit('setTerrainLayerVisibility', visibility)
  },

  togglePropertyLayerVisibility ({ commit }, visibility) {
    commit('setPropertyLayerVisibility', visibility)
  },

  /* Panel */
  togglePanel ({ commit }) {
    commit('togglePanel')
  },

  closePanel ({ commit }) {
    commit('closePanel')
  }
}
