import InviteService from '../services/inviteService'

import {
  STATUS_DEFAULT,
  STATUS_LOADING,
  STATUS_ACCEPTED,
  STATUS_DECLINED,
  STATUS_ACCEPTING,
  STATUS_DECLINING,
  STATUS_NOT_FOUND,
  STATUS_ALREADY_ANSWERED
} from './inviteStatus'

export default {
  namespaced: true,

  state: {
    token: null,
    invite: null,
    status: STATUS_DEFAULT
  },

  mutations: {
    setToken (state, token) {
      state.token = token
    },

    setStatus (state, status) {
      state.status = status
    },

    setInvite (state, invite) {
      state.invite = invite
    }
  },

  getters: {
    getToken (state) {
      return state.token
    },

    getStatus (state) {
      return state.status
    },

    getInvite (state) {
      return state.invite
    }
  },

  actions: {
    async readByToken ({ commit, rootGetters }, token) {
      const userId = rootGetters['auth/getUserId']

      commit('setToken', token)
      commit('setStatus', STATUS_LOADING)

      try {
        const invite = await InviteService.getActivityInviteByToken(token)

        commit('setInvite', invite)
        commit('setStatus', getInviteStatus(invite, userId))

        return invite
      } catch (error) {
        commit('setStatus', STATUS_NOT_FOUND)
        console.error(error)
      }
    },

    async answerInvite ({ getters, commit }, accept) {
      const token = getters.getToken

      commit('setStatus', accept ? STATUS_ACCEPTING : STATUS_DECLINING)

      try {
        await InviteService.answerActivityInvite(accept, token)

        commit('setStatus', accept ? STATUS_ACCEPTED : STATUS_DECLINED)
      } catch (error) {
        commit('setStatus', STATUS_DEFAULT)
        throw error
      }
    }
  }
}

function getInviteStatus (invite, userId) {
  const i = invite.invitations.findIndex(invitation => invitation.userId === userId)

  const invitation = invite.invitations[i]
  return invitation.hasAnswered ? STATUS_ALREADY_ANSWERED : STATUS_DEFAULT
}
