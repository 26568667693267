export default {
  upcomingHunts: 'Kommande jakter',
  huntAreas: 'Jaktmarker',
  members: 'Medlemmar',
  ongoingHuntOnHuntingGround: 'Det pågår just nu en jakt på <strong>{huntArea}</strong>.',
  mapPack: 'Kartpaket',
  buyNow: 'Köp nu',

  upcomingEvents: {
    title: 'Kommande aktiviteter',
    noEvents: 'Du har inga kommande aktiviteter.'
  },

  lastVisited: {
    title: 'Senast besökta jaktmark',
    randomHuntArea: 'Slumpad jaktmark',
    noHuntAreas: 'Du har inte skapat någon jaktmark. Klicka här för att komma igång.',
    newHuntArea: 'Ny Jaktmark'
  },

  subscription: {
    upgradeTitle: 'Uppgradera',
    upgradeSubtitle: 'Få ut mer av WeHunt genom att uppgradera till någon av våra abonnemangslösningar. Få tillgång till vittringsindikator, jakttabell, starta jakter och mycket mer.',
    trialMessage: 'Du har en <strong>testperiod</strong> av WeHunt Standard som löper ut <strong>{expiresAt}</strong>.',
    subscriptionExpires: 'Ditt abonnemang löper ut',
    nextPaymentAt: 'Förlängs automatiskt den',
    upgrade: 'Uppgradera'
  },

  invites: {
    accept: 'Tacka Ja',
    decline: 'Avböj',
    accepted: 'Du har accepterat',
    declined: 'Du har avböjt'
  }
}
