<template>
  <div class="box">
    <ContentPlaceholders :rounded="false">
      <ContentPlaceholdersHeading />
      <ContentPlaceholdersText :lines="1" />
    </ContentPlaceholders>
  </div>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
.box {
  padding: 0.75rem 1.5rem !important;
  box-shadow: none !important;
  margin-bottom: 0 !important;
  border-radius: 0;
  border: none;
  border-bottom: 1px solid #f2f2f2;

  &:first-of-type {
    border-top: 1px solid #f2f2f2;
  }
}
</style>
