<template>
  <div>
    <div :class="classes" class="chat-message">
      <div class="chat-message-bubble">
        <div>
          <ContentPlaceholders :dark="me">
            <ContentPlaceholdersText :lines="2" />
          </ContentPlaceholders>
        </div>
      </div>
    </div>

    <div class="clearfix"></div>
    <!-- IE11 doesn't respect overflowing texts within flexbox weirdly enough -->
    <!-- So I'm using float to position them left or right instead -->
  </div>
</template>

<script>
export default {
  props: {
    me: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    classes () {
      return this.me ? 'me' : ''
    }
  }
}
</script>

<style lang="scss" scoped>
.chat-message-bubble {
  background: white;
  border: 1px solid #d1e0e5;
}
</style>
