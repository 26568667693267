import Vue from 'vue'

import Accordion from '@/components/ui/accordion/'
import Checkbox from '@/components/ui/checkbox/Checkbox.vue'
import Checkbox2 from '@/components/ui/checkbox/Checkbox2.vue'
import Dialog from '@/components/ui/dialog/'
import RemoveDialog from '@/components/ui/removeDialog/'
import Confirm from '@/components/ui/confirm/Confirm.vue'
import Modal from '@/components/ui/modal/Modal.vue'
import Notification from '@/components/ui/notification/'
import FullModal from '@/components/ui/fullModal/FullModal.vue'
import Badge from '@/components/ui/Badge.vue'
import BadgeList from '@/components/ui/BadgeList.vue'
import Paragraph from '@/components/ui/Paragraph.vue'
import Message from '@/components/ui/Message.vue'

import Tabs from '@/components/ui/tabs/Tabs.vue'
import Tab from '@/components/ui/tabs/Tab.vue'
import RouteTabs from '@/components/ui/tabs/RouteTabs.vue'
import RouteTab from '@/components/ui/tabs/RouteTab.vue'

import Level from '@/components/ui/level/Level.vue'
import LevelItem from '@/components/ui/level/LevelItem.vue'
import LevelRight from '@/components/ui/level/LevelRight.vue'
import LevelLeft from '@/components/ui/level/LevelLeft.vue'

import List from '@/components/ui/list/List.vue'
import ListItem from '@/components/ui/list/ListItem.vue'

import EmptyState from '@/components/ui/EmptyState.vue'

import Button from '@/components/ui/buttons/Button.vue'
import ButtonGroup from '@/components/ui/buttons/ButtonGroup.vue'

import SegmentedControl from '@/components/ui/SegmentedControl.vue'

import Heading from '@/components/ui/Heading.vue'
import Divider from '@/components/ui/Divider.vue'
import Alert from '@/components/ui/Alert.vue'
import Editor from '@/components/ui/Editor.vue'

import Table from '@/components/ui/table/Table.vue'
import TableHeader from '@/components/ui/table/TableHeader.vue'
import TableHeaderCell from '@/components/ui/table/TableHeaderCell.vue'
import TableContent from '@/components/ui/table/TableContent.vue'
import TableRow from '@/components/ui/table/TableRow.vue'
import TableCell from '@/components/ui/table/TableCell.vue'

import Toolbar from '@/components/ui/toolbar/Toolbar.vue'
import ToolbarItem from '@/components/ui/toolbar/ToolbarItem.vue'
import ToolbarItemGroup from '@/components/ui/toolbar/ToolbarItemGroup.vue'

Vue.use(Accordion)
Vue.component('Checkbox', Checkbox)
Vue.component('Checkbox2', Checkbox2)
Vue.component('Confirm', Confirm)
Vue.use(Dialog)
Vue.use(RemoveDialog)
Vue.component('Modal', Modal)
Vue.component('FullModal', FullModal)
Vue.use(Notification)
Vue.component('Badge', Badge)
Vue.component('BadgeList', BadgeList)
Vue.component('Paragraph', Paragraph)
Vue.component('Message', Message)

Vue.component('Level', Level)
Vue.component('LevelItem', LevelItem)
Vue.component('LevelRight', LevelRight)
Vue.component('LevelLeft', LevelLeft)

Vue.component('List', List)
Vue.component('ListItem', ListItem)

Vue.component('Tabs', Tabs)
Vue.component('Tab', Tab)
Vue.component('RouteTabs', RouteTabs)
Vue.component('RouteTab', RouteTab)

Vue.component('EmptyState', EmptyState)

Vue.component('Button', Button)
Vue.component('ButtonGroup', ButtonGroup)

Vue.component('SegmentedControl', SegmentedControl)

Vue.component('Heading', Heading)
Vue.component('Divider', Divider)
Vue.component('Alert', Alert)
Vue.component('Editor', Editor)

Vue.component('Table', Table)
Vue.component('TableHeader', TableHeader)
Vue.component('TableHeaderCell', TableHeaderCell)
Vue.component('TableContent', TableContent)
Vue.component('TableRow', TableRow)
Vue.component('TableCell', TableCell)

Vue.component('Toolbar', Toolbar)
Vue.component('ToolbarItem', ToolbarItem)
Vue.component('ToolbarItemGroup', ToolbarItemGroup)
