<template>
  <Dropdown
    alignment="right"
    class="top-right"
    :image="selectedLanguage.icon"
    :text="selectedLanguage.name">
    <DropdownItem v-for="(language, i) in languages" :key="i" @click="selectLanguage(language)">
      <img :src="language.icon" class="flag-icon" />
      {{ language.name }}
    </DropdownItem>
  </Dropdown>
</template>

<script>
import { supportedLanguages } from '@/i18n.js'

export default {
  data () {
    return {
      languages: supportedLanguages
    }
  },

  computed: {
    selectedLanguage () {
      const currentLanguage = this.$store.getters['ui/language']
      const i = this.languages.findIndex(language => language.locale === currentLanguage)
      return this.languages[i]
    }
  },

  methods: {
    selectLanguage (language) {
      this.$store.dispatch('ui/setLanguage', language.locale)
    }
  }
}
</script>

<style scoped>
.top-right {
  position: absolute;
  top: 1.5rem;
  right: 1.5rem;
}

.flag-icon {
  width: 1.5rem;
  height: 1.5rem;
  margin-right: 0.5rem;
}
</style>
