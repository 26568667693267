export default {
  title: 'Profile',

  account: {
    title: 'Account',

    information: {
      title: 'Information',
      helpText: '<p>In order for you to be easily found when you are invited to a hunt, it is important that your name is spelled correctly so that your friends can find you.</p><p>Your cellphone phone number is never shown as searchable information in WeHunt. Your cell phone number is needed for the "Call" feature during ongoing hunts with WeHunt.</p>',
      cellphoneNumber: 'Cell phone number'
    },

    interests: {
      title: 'Interests',
      helpText: 'What is your passion? Fill in the information so that we do not talk about things that do not interest you!',

      dog: 'Dogs',
      fishing: 'Fishing',
      huntingTrips: 'Hunting trips',
      outdoors: 'Outdoor life',
      food: 'Cooking',
      shooting: 'Shooting',
      secondHand: 'Second hand',
      gadgets: 'Hunting accessories',
      politics: 'Politics',
      conservation: 'Wildlife conservation',
      education: 'Education'
    },

    whoAreYou: {
      title: 'Who are you?',
      helpText: '<p>In order to give you the right information about our offers, we want to know who you are. This is an important tool so we do not disturb you unnecessarily. It also gives us important information about our users, so we can continue to develop WeHunt in the right direction.</p><p>We never share this information with anyone else.</p>',
      born: 'Year of birth',
      roles: 'Roles',

      leader: 'Hunting leader',
      stand: 'Stand hunter',
      stalker: 'Stalker',
      carrion: 'Carrion hunter',
      handler: 'Dog handler',
      agent: 'Hunting Intermediaries',
      trackingHunter: 'Tracking hunter',
      butcher: 'Butcher',
      gunTech: 'Gunsmith'
    },

    manage: {
      title: 'Manage subscription',
      helpText: '',
      endSubscription: 'End subscription',
      endSubscriptionSurveyText: 'I would like to answer some questions about why I chose to end my subscription',
      endSubscriptionHelpText: 'Are you sure that you want to end your subscription?'
    }
  },

  settings: {
    title: 'Settings',

    email: {
      title: 'E-mail',
      helpText: 'Here you can change your email address if you wish. If you encounter problems, please contact <a href="mailto:support@wehunt.se">support@wehunt.se</a> and we will be happy to help!',
      currentEmail: 'Current e-mail',
      newEmail: 'New e-mail',
      emailAddress: 'E-mail',
      changeEmail: 'Change E-post',
      busyEmail: 'This email is already registered in our system.',
      emailChangedMessage: 'Your email address has been changed to <strong>{email}</strong>'
    },

    password: {
      title: 'Password',
      noAccessToPassword: 'WeHunt Nordic AB does not have access to your password. The information is encrypted and locked to your personal account.',
      passwordIsUnique: 'The password in WeHunt is unique to WeHunt and cannot be used together with, for example, “My pages” at the Swedish Jägareförbundet.',
      passwordRequirements: 'Your password must be at least six letters or numbers and should only be characters a-z and 0-9.',
      newPassword: 'New password',
      repeatPassword: 'Repeat password',
      changePassword: 'Change password',
      passwordChangedMessage: 'Your password has been changed.'
    },

    payment: {
      title: 'Payment',
      helpText: 'All payment processing takes place via Stripe. For more information about Stripe, see billing history or change payment method, please go to Stripe.',
      openStripe: "Go to Stripe",
      getPortalSessionFailedErrorMessage: 'An error occurred and it was not possible to open the payment portal. Please try again.'
    },

    notifications: {
      title: 'Notifications',
      helpText: 'Receive notifications to your smartphone when WeHunt or one of our partners publishes news.',

      weHunt: 'WeHunt',
      jaktJournalen: 'Jaktjournalen',
      sjf: 'Svenska Jägareförbundet',
      svenskJakt: 'Svensk Jakt',
      njff: 'NJFF',
      dj: 'Danmarks Jaegarforbund',
      theGuardian: 'The Guardian',
      theField: 'The Field',
      shootingUK: 'Shooting UK'
    },

    newsletter: {
      title: 'Newsletter',
      helpText: 'We regularly send out our popular newsletter with news, reports and tips on how to use WeHunt, before, during and after the hunt and offers from us and our partners.',
      helpText2: 'We never share your e-mail address or any other personal information and you can unsubscribe from the newsletter at any time.',
      subtitle: 'Would you like to subscribe to the WeHunt newsletter?'
    }
  }
}
