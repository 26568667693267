<template>
  <div :class="[activeClass]" class="navbar-menu">
    <div class="navbar-start">
      <RouterLink to="/" class="navbar-item">
        <Icon name="icon-home" class="push-right-xxs" />
        <span>Start</span>
      </RouterLink>

      <RouterLink to="/calendar" class="navbar-item">
        <Icon name="icon-calendar" class="push-right-xxs" />
        <span>{{ $t('nav.calendar') }}</span>
      </RouterLink>

      <RouterLink to="/news" class="navbar-item">
        <Icon name="icon-layers" class="push-right-xxs" />
        <span>{{ $t('nav.news') }}</span>
      </RouterLink>

      <RouterLink to="/huntareas" class="navbar-item">
        <Icon name="icon-crosshair" class="push-right-xxs" />
        <span>{{ $t('nav.areas') }}</span>
      </RouterLink>

      <RouterLink to="/devices" class="navbar-item">
        <Icon name="icon-compass" class="push-right-xxs" />
        <span>GPS</span>
      </RouterLink>

      <div v-if="huntArea" class="navbar-item push-top-xxs">
        <RouterLink :to="`/area/${huntArea.id}/dashboard`" class="title is-6">
          {{ name }}
        </RouterLink>
      </div>

      <RouterLink v-if="huntArea" :to="`/area/${huntArea.id}/dashboard`" class="navbar-item">
        <Icon name="icon-layout" class="push-right-xxs" />
        <span>{{ $t('area.nav.dashboard') }}</span>
      </RouterLink>

      <RouterLink v-if="huntArea" :to="`/area/${huntArea.id}/map`" class="navbar-item">
        <Icon name="icon-map" class="push-right-xxs" />
        <span>{{ $t('area.nav.map') }}</span>
      </RouterLink>

      <RouterLink v-if="huntArea" :to="`/area/${huntArea.id}/members`" class="navbar-item">
        <Icon name="icon-users" class="push-right-xxs" />
        <span>{{ $t('area.nav.team') }}</span>
      </RouterLink>

      <RouterLink
        v-if="huntArea"
        :to="`/area/${huntArea.id}/hunts`"
        :class="{ 'is-active': hasActiveHunt }"
        class="navbar-item">
        <Icon name="icon-crosshair" class="push-right-xxs" />
        <span>{{ $t('area.nav.hunts') }}</span>
        <span v-if="totalInvites" class="tag is-small is-rounded push-left-xxs">{{ totalInvites }}</span>
      </RouterLink>

      <RouterLink v-if="huntArea" :to="`/area/${huntArea.id}/documents`" class="navbar-item">
        <Icon name="icon-file" class="push-right-xxs" />
        <span>{{ $t('area.nav.documents') }}</span>
      </RouterLink>

      <RouterLink v-if="huntArea" :to="`/area/${huntArea.id}/gallery`" class="navbar-item">
        <Icon name="icon-image" class="push-right-xxs" />
        <span>{{ $t('area.nav.gallery') }}</span>
      </RouterLink>

      <RouterLink v-if="isConnectedToViltrapport && huntArea" :to="`/area/${huntArea.id}/viltrapport`" class="navbar-item">
        <Icon name="icon-crosshair" class="push-right-xxs" />
        <span>{{ $t('nav.viltrapport') }}</span>
      </RouterLink>

      <RouterLink v-if="huntArea" :to="`/area/${huntArea.id}/settings`" class="navbar-item">
        <Icon name="icon-settings" class="push-right-xxs" />
        <span>{{ $t('area.nav.settings') }}</span>
      </RouterLink>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  data () {
    return {
      huntAreaId: this.$route.params.huntAreaId
    }
  },

  computed: {
    ...mapGetters({
      huntArea: 'huntarea/selected',
      invites: 'hunt/invites/invites',
      isConnectedToViltrapport: 'huntarea/viltrapport/isConnected'
    }),

    name () {
      return this.huntArea && this.huntArea.name
    },

    hasActiveHunt () {
      const hunt = this.$store.getters['hunt/hunt']
      return hunt !== null
    },

    totalInvites () {
      return this.invites && this.invites.filter(invite => Number(invite.huntAreaId) === Number(this.huntArea.id)).length
    },

    isActive: {
      get () { return this.$store.getters['ui/isMobileNavActive'] },
      set (state) { return this.$store.dispatch('ui/mobileNav', state) }
    },

    activeClass () {
      return this.isActive ? 'is-active' : ''
    }
  },

  watch: {
    $route (to, from) {
      this.isActive = false
    }
  },

  methods: {
    close () {
      this.isActive = false
    }
  }
}
</script>
