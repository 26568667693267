<template>
  <Fragment>
    <label v-if="hunt" class="label is-marginless">Jakt</label>

    <div v-if="hunt" class="buttons are-small has-addons push-top-xs">
      <button class="button" @click.prevent="setHuntMode('planning')">Planering</button>
      <button class="button" @click.prevent="setHuntMode('planned')">Planerad</button>
      <button class="button" @click.prevent="setHuntMode('finished')">Färdig</button>
    </div>
  </Fragment>
</template>

<script>
import { mapGetters } from 'vuex'

export default {

  computed: {
    ...mapGetters({
      hunt: 'hunt/hunt'
    })
  },

  methods: {
    setHuntMode (mode) {
      this.$store.dispatch('hunt/setMode', mode)
    }
  }

}
</script>
