export default {

  add (state, hunt) {
    if (state.hunts === null) {
      state.hunts = []
    }

    state.hunts.push(hunt)
  },

  delete (state, hunt) {
    const i = state.hunts.indexOf(hunt)
    if (i !== -1) {
      state.hunts.splice(i, 1)
    }
  },

  update (state, hunt) {
    const i = state.hunts.findIndex(h => h.id === hunt.id)
    if (i !== -1) {
      state.hunts.splice(i, 1, hunt)
    }
  },

  setHunt (state, hunt) {
    state.hunt = hunt
  },

  setHuntById (state, huntId) {
    const i = state.hunts.findIndex(hunt => hunt.id === huntId)
    if (i === -1) {
      throw new Error('No hunt with the specified id ' + huntId + ' found.')
    }

    state.hunt = state.hunts[i]
  },

  setHunts (state, hunts) {
    state.hunts = hunts
  },

  // Mostly for dev uses.
  setMode (state, status) {
    state.hunt.status = status
  },

  // Assignment
  setSelectedUser (state, user) {
    state.selectedUser = user
  },

  state (state, value) {
    state.state = value
  },

  removeAllForHuntArea (state, huntAreaId) {
    for (let i = state.hunts.length - 1; i >= 0; i--) {
      if (state.hunts[i].huntAreaId === huntAreaId) {
        state.hunts.splice(i, 1)
      }
    }
  }

}
