<template>
  <div v-show="isActive">
    <slot />
  </div>
</template>

<script>
export default {
  props: {
    name: {
      type: String,
      required: true
    },

    selected: {
      type: Boolean,
      default: false
    },

    badge: {
      type: Number,
      default: null
    },

    badgeType: {
      type: String,
      default: 'is-light'
    }
  },

  data () {
    return {
      isActive: false
    }
  },

  computed: {
    href () {
      return '#' + this.name.toLowerCase().replace(/ /g, '-')
    }
  },

  mounted () {
    this.isActive = this.selected
  }
}
</script>
