export const HUNTER_STATUS_ACTIVE = 'active'
export const HUNTER_STATUS_INACTIVE = 'inactive'
export const HUNTER_STATUS_CHECKED_IN = 'checked-in'

class HunterModel {
  constructor (id, name) {
    this.id = id
    this.name = name
    this.location = { lat: 0, lng: 0 }

    this.active = false
    this.visible = true
    this.accuracy = 0

    this.status = HUNTER_STATUS_INACTIVE

    this.trail = []
    this.checkInMarker = null
  }

  checkIn (marker) {
    this.checkInMarker = marker
    this.checkInMarker.visible = false
    this.status = HUNTER_STATUS_CHECKED_IN
  }

  checkOut () {
    this.checkInMarker.visible = true
    this.checkInMarker = null
  }
}

export default HunterModel
