export default [{
  path: '/hunt/:huntId/',
  component: () => import('@/views/hunt/HuntPage.vue'),
  meta: { isMap: true },
  children: [{
    name: 'GeneralTab',
    path: '',
    component: () => import('@/views/hunt/tabs/general/General.vue'),
    meta: { isMap: true }
  }, {
    name: 'members',
    path: 'members',
    component: () => import('@/views/hunt/tabs/members/Members.vue'),
    meta: { isMap: true }
  }, {
    name: 'assign',
    path: 'assign',
    component: () => import('@/views/hunt/tabs/assign/Assign.vue'),
    meta: { isMap: true }
  }, {
    name: 'chat',
    path: 'chat',
    component: () => import('@/views/hunt/tabs/chat/Chat.vue'),
    meta: { isMap: true }
  }, {
    name: 'events',
    path: 'events',
    component: () => import('@/views/hunt/tabs/events/Events.vue'),
    meta: { isMap: true }
  }]
}]
