import * as storage from '@/assets/libs/storage/'

class PropertyLayerTile {
  constructor (xyz, tileId) {
    this.xyz = xyz
    this.tileId = tileId
  }

  static parse (tileString) {
    const tileData = tileString.split(':')
    return new PropertyLayerTile(tileData[0], tileData[1])
  }
}

/**
 * Stores LM map tiles in session storage.
 * After this object has been created load() must be called before any other method.
 */
class PropertyLayerTileStore {
  constructor () {
    this.tiles = null
  }

  load () {
    if (this.tiles === null) {
      const tiles = storage.has('tileData')
      this.tiles = tiles !== undefined ? JSON.parse(tiles) : {}
    }

    return this.getCount() > 0
  }

  save (persist) {
    const store = persist ? storage.local : storage.session
    store.add('tileData', this.tiles)
  }

  add (tile) {
    if (!(tile.xyz in this.tiles)) {
      this.tiles[tile.xyz] = tile.tileId
    }
  }

  get (xyz) {
    const tileId = this.tiles[xyz]
    return tileId !== undefined ? tileId : null
  }

  getAll () {
    return this.tiles
  }

  clear () {
    this.tiles = {}

    storage.local.del('tileData')
    storage.session.del('tileData')
  }

  getCount () {
    return Object.keys(this.tiles).length
  }
}

export {
  PropertyLayerTileStore,
  PropertyLayerTile
}
