import ViltrapportService from '../services/viltrapportService.js'

const viltrapport = {
  namespaced: true,

  state () {
    return {
      districts: null,
      selectedDistrict: null,
      quotas: null,
      games: null
    }
  },

  getters: {
    getDistricts (state) {
      return state.districts
    },

    getSelectedDistrict (state) {
      return state.selectedDistrict
    },

    isConnected (state) {
      return state.districts !== null ? state.districts.length > 0 : false
    },

    getQuotas (state) {
      return state.quotas
    },

    getGames (state) {
      return state.games
    }
  },

  mutations: {
    setDistricts (state, districts) {
      state.districts = districts
    },

    setQuotas (state, quotas) {
      state.quotas = quotas
    },

    setGames (state, games) {
      state.games = games
    },

    setSelectedDistrict (state, district) {
      state.selectedDistrict = district
    }
  },

  actions: {
    async getStatus ({ commit, rootGetters, dispatch }) {
      commit('setDistricts', null)
      commit('setSelectedDistrict', null)

      const huntArea = rootGetters['huntarea/selected']
      const credentials = rootGetters['auth/getBase64']

      const viltrapport = new ViltrapportService(credentials)
      const districts = await viltrapport.getConnectedDistricts(huntArea.id)

      commit('setDistricts', districts)
      commit('setSelectedDistrict', districts[0])

      return districts
    },

    setSelectedDistrict ({ commit, dispatch }, district) {
      commit('setSelectedDistrict', district)
      dispatch('getQuota')
    },

    async getQuota ({ commit, state, rootGetters }) {
      commit('setQuotas', null)

      const huntArea = rootGetters['huntarea/selected']
      const credentials = rootGetters['auth/getBase64']

      const viltrapport = new ViltrapportService(credentials)
      const quota = await viltrapport.getQuotas(huntArea.id, state.selectedDistrict.id)

      quota.quotas.forEach(species => {
        species.quotas.sort((a, b) => a.name.localeCompare(b.name))
      })

      commit('setQuotas', quota)

      return quota
    },

    async getGames ({ commit, state, rootGetters }) {
      commit('setGames', null)

      const huntArea = rootGetters['huntarea/selected']
      const credentials = rootGetters['auth/getBase64']

      const viltrapport = new ViltrapportService(credentials)
      const games = await viltrapport.getGames(huntArea.id, state.selectedDistrict.id)

      commit('setGames', games)
    }
  }
}

export default viltrapport
