<template>
  <div class="dropdown" :class="classObject">
    <div class="dropdown-trigger">
      <span v-if="hasIconAndText">
        <button
          type="button"
          class="button"
          aria-haspopup="true"
          aria-controls="dropdown-menu"
          :class="buttonSizeClass"
          :disabled="disabled"
          @click="toggleDropDown">
          <Icon v-if="icon !== null" :name="icon" />
          <img v-if="image !== null" :src="image" :class="imageClasses" />
          <span>{{ text }}</span>

          <span class="icon is-small">
            <Icon name="icon-chevron-down" />
          </span>
        </button>
      </span>

      <i
        v-if="hasIconOnly"
        :class="icon"
        class="icon is-clickable"
        @click="toggleDropDown">
      </i>
    </div>

    <div class="dropdown-menu" role="menu">
      <div class="dropdown-content">
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
// The currently visible drop down. Needed in order to close it
// if the user clicks on the same dropdown again or opens a new one.
// This is because we stop event propagation in order to support placing the dropdown
// within a clickable area on a parent component. For example the common "more" button in the right most column of a table.
// let currentDropDown = null

// IMPORTANT: The support for having a dropdown in a clickable parent element was in the end dropped because it added other problems in the documents page
// which as to be the first use. I'm leaving the code commented out in case we need this in the future.

export default {
  props: {
    text: {
      type: String,
      default: ''
    },

    icon: {
      type: String,
      default: null
    },

    image: {
      type: String,
      default: null
    },

    size: {
      type: String,
      default: 'normal'
    },

    alignment: {
      type: String,
      default: 'left'
    },

    type: {
      type: String,
      default: 'icon-and-text'
    },

    disabled: {
      type: Boolean,
      default: false
    },

    autoClose: {
      type: Boolean,
      default: true
    }
  },

  data () {
    return {
      isActive: false
    }
  },

  computed: {
    classObject () {
      return {
        'is-active': this.isActive,
        'is-right': this.alignment === 'right'
      }
    },

    imageClasses () {
      let classes = ['image-' + this.size]
      if (this.text.length > 0) classes.push('image-margin-right')
      return classes
    },

    buttonSizeClass () {
      return 'is-' + this.size
    },

    hasIconAndText () {
      return this.type === 'icon-and-text'
    },

    hasIconOnly () {
      return this.type === 'icon-only'
    }
  },

  methods: {
    toggleDropDown () {
      if (!this.isActive) {
        this.showDropdown()
      } else {
        this.closeDropdown()
      }
    },

    showDropdown () {
      // Don't show dropdown if it's already visible
      this.isActive = true

      /*
      if (currentDropDown !== null) {
        currentDropDown.closeDropdown()
      }

      currentDropDown = this
      */

      // Add event listener after this callback chain is completed or we'll get the callback directly
      if (this.autoClose) {
        setTimeout(() => {
          document.addEventListener('click', this.closeDropdown)
        }, 0)
      }
    },

    closeDropdown () {
      this.isActive = false
      // currentDropDown = null

      document.removeEventListener('click', this.closeDropdown)
    }
  }
}
</script>

<style scoped>
.image-margin-right {
  margin-right: 5px;
}

.image-normal {
  width: 24px;
  height: 24px;
}
</style>
