import jstz from 'jstimezonedetect'

class TimeZone {
  static getCurrent () {
    const timeZone = jstz.determine()
    return timeZone ? timeZone.name() : undefined
  }
}

export default TimeZone
