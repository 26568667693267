import dayjs from 'dayjs'

import HuntReportEventGameModel from './huntReportEventGameModel'

export const HUNT_REPORT_EVENT_TYPE_KILL = 'kill'
export const HUNT_REPORT_EVENT_TYPE_AFTER_SEARCH = 'after_search'
export const HUNT_REPORT_EVENT_TYPE_OBSERVATION = 'observation'

export default class HuntReportEventModel {
  constructor (params) {
    const data = Object.assign({}, params)

    this.id = data.id || null
    this.type = data.type || ''
    this.comment = data.comment || ''
    this.timestamp = data.timestamp !== undefined ? dayjs(data.timestamp).toDate() : null
    this.useTimeInTimestamp = data.useTimeInTimestamp || false
    this.reportedBy = data.reportedBy || null

    this.poiId = data.poiId || null
    this.poiName = data.poiName || null
    this.poiType = data.poiType || null

    this.coordinate = data.coordinate || null

    this.dirty = false // Used to track whether or not we should POST or PUT
    this.removing = false // Used to show spinner before removal

    this.games = data.games ? data.games.map(game => new HuntReportEventGameModel(game)) : []
    this.removedGames = []

    this.createdBy = data.createdBy || null
  }

  addGame (game) {
    this.games.push(game)

    const i = this.removedGames.indexOf(game)
    if (i !== -1) {
      this.removedGames.splice(i)
    }
  }

  removeGame (game) {
    const i = this.games.indexOf(game)
    this.games.splice(i, 1)

    if (game.id !== null) {
      this.removedGames.push(game)
    }
  }

  isSentToViltrapport () {
    for (const game of this.games) {
      for (const report of game.viltrapportReports) {
        if (report.id !== null) {
          return true
        }
      }
    }
    return false
  }
}
