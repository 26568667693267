<template>
  <div v-if="scrollable" class="table-container">
    <table class="table" :class="classes">
      <slot />
    </table>
  </div>

  <table v-else class="table" :class="classes">
    <slot />
  </table>
</template>

<script>
export default {
  props: {
    fullwidth: {
      type: Boolean,
      default: true
    },

    hoverable: {
      type: Boolean,
      default: false
    },

    bordered: {
      type: Boolean,
      default: false
    },

    scrollable: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    classes () {
      const classes = {}

      classes['is-fullwidth'] = this.fullwidth
      classes['is-hoverable'] = this.hoverable
      classes['is-bordered'] = this.bordered

      return classes
    }
  }
}
</script>

<style scoped>
.table {
  border-radius: 6px;
}
</style>
