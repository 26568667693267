<script>
/* global google */
import tileListener from "@/components/map/print/tileListener"
import { mapGetters } from "vuex"

const LAYER_NAME = "fiPropertyBoundariesLayer"

export default {
  inject: ["getMap"],

  data () {
    return {
      mapType: null
    }
  },

  computed: {
    ...mapGetters({
      isVisible: "map/settings/isTerrainLayerVisible"
    })
  },

  watch: {
    isVisible () {
      this.updateVisibility()
    }
  },

  async created () {
    this.createTangerFreeMapType()
  },

  render () {
    return ""
  },

  methods: {
    createTangerFreeMapType () {
      fetch("https://tanger.tracker.fi/api/v3/maps/list", {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          application: "I7f6rYyWz3VSCV97tyrFrzbMb3Rmigni",
          language: "se"
        })
      })
        .then((res) => res.json())
        .then((json) => {
          if (json.base_maps) {
            const map = json.overlay_maps.find(
              (map) => map.id === "fin-property"
            )
            if (map != null) {
              const flavor = map.flavors.find(
                (flavor) => flavor.id === "fin-property/full"
              )
              if (flavor != null) {
                const url = flavor.url
                // https://tanger.tracker.fi/tiles/nlsprop/full/{G}/{W}/{Z}/{Y}/{X}?token=...
                this.mapType = new google.maps.ImageMapType({
                  name: LAYER_NAME,
                  tileSize: new google.maps.Size(256, 256),
                  maxZoom: 16,
                  getTileUrl (tile, zoom) {
                    const { x, y } = tile
                    const ix = zoom + "-" + x + "-" + y

                    tileListener.addPendingUrl(LAYER_NAME, ix)

                    return url
                      .replace("{G}", map.generation)
                      .replace("{W}", 768)
                      .replace("{Z}", zoom)
                      .replace("{Y}", y)
                      .replace("{X}", x)
                  }
                })
              }

              this.addTileLoadListener()
              this.updateVisibility()
            }
          }
        })
    },

    async updateVisibility () {
      const map = await this.getMap()

      if (this.isVisible) {
        const i = map.overlayMapTypes.getLength() // Always display as the topmost layer
        if (i > 0) {
          map.overlayMapTypes.insertAt(i, this.mapType)
        } else {
          setTimeout(() => {
            this.updateVisibility()
          }, 1000)
        }
      } else {
        const i = map.overlayMapTypes.indexOf(this.mapType)
        if (i !== -1) {
          map.overlayMapTypes.removeAt(i)
        }
      }
    },

    addTileLoadListener () {
      tileListener.addTileLoadListener(LAYER_NAME, this.mapType, 20000)
    }
  }
}
</script>
