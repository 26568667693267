import dayjs from 'dayjs'

export const CALENDAR_EVENT_STATUS_NOT_INVITED = 'not-invited'
export const CALENDAR_EVENT_STATUS_MEMBER = 'member'

class CalendarEventModel {
  constructor (context) {
    const data = Object.assign({}, context)

    this.id = data.id || null
    this.huntAreaId = data.huntAreaId || null
    this.title = data.title || ''
    this.description = data.description || ''

    this.from = data.from ? dayjs(data.from).toDate() : null
    this.to = data.to ? dayjs(data.to).toDate() : null

    this.createdAt = data.createdAt !== null ? dayjs(data.createdAt).toDate() : null
    this.createdBy = data.createdBy || null

    this.status = data.status || ''
    this.editable = data.editable !== undefined ? data.editable : false
  }

  toJson () {
    return {
      name: this.name,
      title: this.title,
      description: this.description,
      to: this.to,
      from: this.from
    }
  }
}

export default CalendarEventModel
