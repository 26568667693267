export default {
  boundary: null,
  markers: null,
  subAreas: null,

  center: null,
  zoom: 15,
  type: 'roadmap',
  mode: 'pass-with-participant',
  forbiddenAreas: null,
  selectedSubArea: null,
  selectedMarker: null,

  settingsPanelActive: false,

  markerSize: 1,
  labelSize: 1,
  showLabels: true,

  terrainLayer: false,
  propertyLayer: false
}
