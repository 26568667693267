<template>
  <form role="form" novalidate @submit.prevent="submit">
    <slot />
  </form>
</template>

<script>
export default {
  methods: {
    submit () {
      this.$emit('submit')
    }
  }
}
</script>
