export default {
  title: 'Calendar',
  huntAreas: 'Hunting grounds',
  invites: 'Invites',
  showAll: 'Show all',
  where: 'Where',
  notInvitedMessage: 'You are not participating in the hunt and therefore can not see all the information about the hunt.',
  noInvitationText: 'No description made.',
  noComment: 'No comment made.',
  noParticipants: 'No participants.',
  seeCompleteReport: 'See complete report',
  seeCompletePlanning: 'See complete planning',
  hunt: 'Hunt',
  huntingReport: 'Hunting report',
  invited: 'Invited',
  noInvites: 'You have no unanswered invites.',

  loadActivitiesFailedErrorMessage: 'An error occurred and the calendar failed to load. Please try again.',
  noHuntAreasText: '<p> You are not a member of any hunting ground.</p><p>You must be part of at least one hunting ground in order to create activities or to plan a hunt.</p>',

  planHunt: 'Plan hunt',
  planHuntHelpText: 'Choose the hunting ground for which the hunt is planned.',
  planHuntFailedErrorMessage: 'An error occurred and the planning of a new hunt failed. Please try again.',

  startsAt: 'Starts at',
  startedAt: 'Started at',
  endsAt: "Ends on",
  endedAt: 'Ended at',

  activity: {
    new: 'New event',
    huntArea: 'Hunt area',
    title: 'Title',
    titlePlaceholder: "E.g meeting, barbecue etc",
    titleRequired: 'You must enter a title',
    from: 'Starts at',
    to: "Ends on",
    fromRequired: 'You must specify when the event starts',
    toRequired: 'You must specify when the event ends',
    description: 'Description',
    descriptionPlaceholder: 'Describe the event in more detail',
    participants: 'Participants',
    noParticipants: 'No members have been invited to the event.',
    invitedNoAnswer: 'Not replied',
    invitedAccepted: 'Accepted',
    invitedDeclined: 'Declined',
    removeTitle: 'Remove event?',
    removeMessage: 'Do you really want to remove the event?',
    removeFailedErrorMessage: 'An error occurred and the event could not be removed. Please try again.',
    saveFailedErrorMessage: 'An error occurred and the changes could not be saved. Please try again.',
    createdBy: "Created by {name}, on {date}",
    unknown: 'Unknown',
    removeParticipation: 'Remove participation'
  },

  moonPhases: {
    newMoon: 'New moon',
    waxingCrescentMoon: 'Waxing crescent moon',
    firstQuarterMoon: 'First quarter moon',
    waxingGibbousMoon: 'Waxing gibbous moon',
    fullMoon: 'Full moon',
    waningGibbousMoon: 'Waning gibbous moon',
    lastQuarterMoon: 'Last quarter moon',
    waningCrescentMoon: 'Waning crescent moon'
  }
}
