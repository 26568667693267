import local from './local'
import session from './session'

const has = key => {
  const localStore = local.read(key)
  const sessionStore = session.read(key)

  if (localStore !== null) return localStore
  else if (sessionStore !== null) return sessionStore
}

const whichType = key => {
  const localStore = local.read(key)
  const sessionStore = session.read(key)

  if (localStore !== null) return 'local'
  else if (sessionStore !== null) return 'session'
  else return false
}

export {
  local,
  session,
  has,
  whichType
}
