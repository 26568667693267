import Vue from 'vue'

import Form from './Form.vue'
import FormField from './FormField.vue'
import TextInputField from './TextInputField.vue'
import NumberInputField from './NumberInputField.vue'
import SearchInputField from './SearchInputField.vue'
import EmailInputField from './EmailInputField.vue'
import PasswordInputField from './PasswordInputField.vue'
import ColorInputField from './ColorInputField.vue'
import TextArea from './TextArea.vue'
import HelpText from './HelpText.vue'
import Label from './Label.vue'
import DatePicker from './DatePicker.vue'

Vue.component('Form', Form)
Vue.component('FormField', FormField)
Vue.component('TextInputField', TextInputField)
Vue.component('NumberInputField', NumberInputField)
Vue.component('SearchInputField', SearchInputField)
Vue.component('EmailInputField', EmailInputField)
Vue.component('PasswordInputField', PasswordInputField)
Vue.component('ColorInputField', ColorInputField)
Vue.component('TextArea', TextArea)
Vue.component('HelpText', HelpText)
Vue.component('Label', Label)
Vue.component('DatePicker', DatePicker)
