<template>
  <div class="flex field align-center justify-between push-top-xs">
    <label class="label is-marginless">Clear state</label>
    <button class="button is-small" @click="clearState">Clear</button>
  </div>
</template>

<script>
export default {

  methods: {
    clearState () {
      this.$store.dispatch('ui/clearAllStates')
    }
  }

}
</script>
