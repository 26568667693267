<template>
  <span class="is-clickable" @click="toggleExpanded">
    <Icon v-if="!isExpanded" name="icon-chevron-down" />
    <Icon v-if="isExpanded" name="icon-chevron-up" />
  </span>
</template>

<script>
export default {
  props: {
    isExpanded: {
      type: Boolean,
      default: false
    }
  },

  methods: {
    toggleExpanded () {
      this.$emit('toggle-expanded')
    }
  }
}
</script>
