<template>
  <i
    :class="name"
    class="icon"
    @click="$emit('click')">
  </i>
</template>

<script>
export default {
  props: {
    name: {
      type: String,
      default: ''
    }
  }
}
</script>

<style lang="scss">
.icon.is-medium {
  width: 1.75rem;
  height: 1.75rem;
  font-size: 1.75rem;
}

.icon.is-large {
  width: 2rem;
  height: 2rem;
  font-size: 2rem;
}

.icon.is-x-large {
  width: 3rem;
  height: 3rem;
  font-size: 3rem;
}

.icon.is-xx-large {
  width: 4rem;
  height: 4rem;
  font-size: 4rem;
}
</style>
