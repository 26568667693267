// NOTE: This module also contains routes in routes.js. Read why, in that file.

export default [{
  name: 'submit-report',
  path: 'reports/submit',
  component: () => import('./components/edit/ReportEditPage.vue')
}, {
  name: 'edit-report',
  path: 'reports/:reportId/edit',
  component: () => import('./components/edit/ReportEditPage.vue')
}, {
  name: 'view-report',
  path: 'reports/:reportId',
  component: () => import('./components/view/ReportPage.vue'),
  children: [{
    name: 'view-report-general-tab',
    path: 'general',
    component: () => import('./components/view/ReportPageTabGeneral.vue')
  }, {
    name: 'view-report-chat-tab',
    path: 'chat',
    component: () => import('./components/view/ReportPageTabChat.vue')
  }, {
    name: 'view-report-invitation-tab',
    path: 'planning',
    component: () => import('./components/view/ReportPageTabInvitation.vue')
  }]
}]
