import store from '@/store/map/print/'

export default {

  addPendingUrl (id, url) {
    // console.log('Adding pending url to ' + id + ': ' + url)
    // Add this url to our list of pending urls
    store.state.pendingTiles[id].push(url)

    // if this is our first pending tile, signal that we just became busy
    if (store.state.pendingTiles[id].length === 1) {
      console.log(`overlay ${id} is busy`)
      if (store.state.busyLayers.indexOf(id) === -1) {
        store.state.busyLayers.push(id)
      }
      // console.log(busyLayers)
    }
  }, // addPendingUrl()

  addTileLoadListener (id, mapType, timeout) {
    // Initialise the sub-array for this particular id
    store.state.pendingTiles[id] = []

    // Copy the original getTile function so we can override it,
    // but still make use of the original function
    mapType.baseGetTile = mapType.getTile

    // Override getTile so we may add event listeners to know when the images load
    mapType.getTile = (tileCoord, zoom, ownerDocument) => {
      // Get the DOM node generated by the out-of-the-box ImageMapType
      const node = mapType.baseGetTile(tileCoord, zoom, ownerDocument)
      const ix = zoom + '-' + tileCoord.x + '-' + tileCoord.y
      node.dataset.tile = ix

      const checkIfLoaded = n => {
        if (n.firstChild) removePendingImg(n, n.dataset.tile, 'loaded')
      }

      // node contains a div that is populated with an img by Google Maps once tile is loaded.
      // Sometimes the node is populated immediately (probably when tile is read from cache).
      checkIfLoaded(node)

      // Add an event listener that triggers if the node is populated later.
      node.addEventListener('DOMNodeInserted', () => checkIfLoaded(node), false)

      node.addEventListener('DOMNodeRemoved', () => removePendingImg(node, node.dataset.tile, 'removed'))

      setTimeout(function () {
        removePendingImg(node, node.dataset.tile, 'timeout')
      }, timeout)

      const removePendingImg = (node, src, result) => {
        var i = -1

        do {
          i = store.state.pendingTiles[id].indexOf(src)

          if (i === -1) {
            // console.log('URL ' + src + ' ' + 'not found' + ' (' + pendingTiles[id].length + ')')
          } else {
            // console.log('Removing URL ' + src + ': ' + result)
            store.state.pendingTiles[id].splice(i, 1)

            // If the pending url list is empty,
            // emit an event to indicate that the tiles are finished loading
            if (store.state.pendingTiles[id].length === 0) {
              console.log(`overlay ${id} is idle`)
              store.state.busyLayers.splice(store.state.busyLayers.indexOf(id), 1)
            }
          }
        } while (i > -1)
      } // removePendingImg()

      return node
    }
  } // addTileLoadListener()

}
