<template>
  <div :class="{ 'is-active': active }" class="modal">
    <div class="modal-background"></div>

    <div class="modal-card">
      <div class="modal-card-head">
        <h4 v-if="title.length" class="modal-card-title">{{ title }}</h4>
        <button class="delete" aria-label="close" @click="close"></button>
      </div>

      <section class="modal-card-body">
        <slot />
      </section>

      <footer class="modal-card-foot buttons is-right is-marginless">
        <button class="button" @click="abandon">
          {{ cancel }}
        </button>

        <slot name="save" />
      </footer>
    </div>
  </div>
</template>

<script>
import { i18n } from '@/i18n'

export default {
  props: {
    active: {
      type: Boolean,
      default: false
    },

    title: {
      type: String,
      default: ''
    },

    cancel: {
      type: String,
      default: i18n.t('general.cancel')
    }
  },

  methods: {
    close () {
      this.$emit('modal-close')
    },

    abandon () {
      this.$emit('modal-cancel')
    }
  }
}
</script>

<style lang="scss" scoped>
.buttons .button {
  margin-bottom: 0;
}
</style>
