import Vue from 'vue'

// General
import Burger from './burger/'
import Loader from './loader/'
import Icon from './icon/'
import IconBars from './iconBars/'
import InlineLoader from './inlineLoader/'
import CursorLoader from './cursorLoader/'
import ProfilePicture from './profilePicture/'
import Logo from './logo/'
import DropdownSelect from './dropdownSelect/'
import UserDropdown from './userDropdown/'
import Dropdown from './dropdown/'
import More from './more/'
import Chevron from './chevron/'

import Chat from './chat/Chat.vue'
import ChatForm from './chat/ChatForm.vue'
import ChatMessages from './chat/ChatMessages.vue'
import ChatMessage from './chat/ChatMessage.vue'
import ChatMessagesPlaceholder from './chat/ChatMessagesPlaceholder.vue'
import ChatMessagePlaceholder from './chat/ChatMessagePlaceholder.vue'

// General
Vue.use(Burger)
Vue.use(Chat)
Vue.use(Loader)
Vue.use(Icon)
Vue.use(IconBars)
Vue.use(InlineLoader)
Vue.use(CursorLoader)
Vue.use(ProfilePicture)
Vue.use(Logo)
Vue.use(DropdownSelect)
Vue.use(UserDropdown)
Vue.use(Dropdown)
Vue.use(More)
Vue.use(Chevron)

Vue.component('Chat', Chat)
Vue.component('ChatForm', ChatForm)
Vue.component('ChatMessages', ChatMessages)
Vue.component('ChatMessage', ChatMessage)
Vue.component('ChatMessagesPlaceholder', ChatMessagesPlaceholder)
Vue.component('ChatMessagePlaceholder', ChatMessagePlaceholder)
