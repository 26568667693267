<template>
  <RouterLink :to="`/area/${huntArea.id}/dashboard`" class="hunt-areas-panel-box">
    <div class="level">
      <div class="level-left">
        <div>
          <h4 v-if="huntArea.name" class="title is-5">
            {{ capitalize(huntArea.name) }}
          </h4>

          <div class="subtitle is-6">
            <div class="flex align-center">
              <span v-if="huntArea.county">{{ huntArea.county }}</span>
            </div>
          </div>
        </div>
      </div>

      <div v-if="isSvg(huntArea)" class="level-right">
        <div :data-id="huntArea.id" class="area-svg" />
      </div>
    </div>
  </RouterLink>
</template>

<script>
import * as _ from '@/assets/libs/utils/'

export default {
  props: {
    huntArea: {
      type: Object,
      default: () => {}
    }
  },

  created () {
    if (this.huntArea.svg instanceof SVGElement) {
      setTimeout(this.appendSvg) // nextTick
    }
  },

  methods: {
    isSvg (huntArea) {
      return huntArea.svg instanceof SVGElement
    },

    capitalize (str) {
      return _.capitalize(str)
    },

    appendSvg () {
      const el = this.$el.querySelector('.area-svg')
      const svg = this.huntArea.svg

      el.appendChild(svg)
    }
  }
}
</script>

<style lang="scss" scoped>
.hunt-areas-panel-box {
  padding: 0.75rem 1.5rem !important;
  display: block;
  border-bottom: 1px solid #f2f2f2;

  &:first-of-type {
    border-top: 1px solid #f2f2f2;
  }

  &:hover {
    background: #f7f7f7;
  }

  .level-left {
    width: calc(100% - 4rem);

    > div {
      width: 100%;
    }

    .title {
      line-height: 1.5;
      width: 100%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .area-svg {
    width: 3rem;
    max-width: 3rem;
    height: 3rem;
    max-height: 3rem;

    pointer-events: none;
    z-index: 0;
    overflow: hidden;

    svg {
      max-width: 3rem !important;
      max-height: 3rem !important;
    }
  }
}
</style>
