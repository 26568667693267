import * as firebase from 'firebase/app'
import 'firebase/database'

/**
 * High-level wrapper around the Firebase Realtime Database APIs used to
 * simplify writing client code.
 */
class FirebaseDatabase {
  constructor (database) {
    this.database = database
  }

  ref (path) {
    return this.database.ref(path)
  }

  async getAll (path, params) {
    const { convertTo } = params

    const ref = this.database.ref(path)

    const snapshot = await ref.once('value')
    if (snapshot === null) return null

    const objects = []

    snapshot.forEach(child => {
      objects.push(convertTo(child.key, child.val()))
    })

    return objects
  }

  async get (path, params) {
    const { convertTo } = params

    const ref = this.database.ref(path)

    const snapshot = await ref.once('value')
    if (snapshot === null) return null

    return convertTo(snapshot.key, snapshot.val())
  }

  async insert (path, data) {
    const ref = this.database.ref(path).push()
    await ref.update(data)
    return ref.key
  }

  async update (path, data) {
    const ref = this.database.ref(path)
    await ref.update(data)
  }

  async delete (path) {
    const firebaseUpdate = {}
    firebaseUpdate[path] = null

    const ref = this.database.ref()
    await ref.update(firebaseUpdate)
  }

  getTimestamp () {
    return firebase.database.ServerValue.TIMESTAMP
  }
}

export default FirebaseDatabase
