<template>
  <input
    type="number"
    class="input"
    :class="classes"
    :placeholder="placeholder"
    :disabled="disabled"
    :value="value"
    :min="min"
    :max="max"
    @input="$emit('input', new Number($event.target.value))" />
</template>

<script>
export default {
  props: {
    value: {
      type: Number,
      default: null
    },

    min: {
      type: Number,
      default: null
    },

    max: {
      type: Number,
      default: null
    },

    placeholder: {
      type: String,
      default: ''
    },

    disabled: {
      type: Boolean,
      default: false
    },

    size: {
      type: String,
      default: ''
    },

    valid: {
      type: String,
      default: null
    }
  },

  computed: {
    classes () {
      const classes = {}

      if (this.size.length > 0) {
        classes['is-' + this.size] = true
      }

      classes['is-danger'] = this.valid === 'invalid'
      classes['is-success'] = this.valid === 'valid'

      return classes
    }
  }
}
</script>
