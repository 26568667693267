import dayjs from 'dayjs'

class UserModel {
  constructor (context) {
    const data = Object.assign({}, context)

    this.id = data.id || null
    this.firstName = data.firstName || ''
    this.lastName = data.lastName || ''
    this.phoneNumber = data.phoneNumber || ''
    this.email = data.email || ''

    this.languageCode = data.langaugeCode
    this.countryCode = data.countryCode

    this.activated = data.activated || false
    this.createdAt = dayjs(data.createdAt).toDate()
    this.updatedAt = dayjs(data.updatedAt).toDate()
    this.subscriptionExpirationDate = data.subscriptionExpirationDate != null ? dayjs(data.subscriptionExpirationDate).toDate() : null
    this.hasPro = new Date(this.subscriptionExpirationDate) > new Date()
    this.selected = data.selected || false
  }

  getFullName () {
    return this.firstName.length > 0
      ? this.firstName + ' ' + this.lastName
      : this.email
  }

  toJSON () {
    return {
      id: this.id,
      firstName: this.firstName,
      lastName: this.lastName,
      fullName: this.firstName + ' ' + this.lastName,
      phoneNumber: this.phoneNumber.length > 0 ? this.phoneNumber : null,
      email: this.email,
      hasPro: this.hasPro,
      proExpires: this.subscriptionExpirationDate,
      subscriptionExpirationDate: this.subscriptionExpirationDate
    }
  }
}

export default UserModel
