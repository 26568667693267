import InviteService from '../services/inviteService'

import {
  STATUS_DEFAULT,
  STATUS_LOADING,
  STATUS_ALREADY_ANSWERED,
  STATUS_ACCEPTED,
  STATUS_DECLINED,
  STATUS_ACCEPTING,
  STATUS_DECLINING,
  STATUS_NOT_FOUND
} from './inviteStatus'

export default {
  namespaced: true,

  state: {
    token: null,
    invite: null,
    status: STATUS_DEFAULT
  },

  mutations: {
    setToken (state, token) {
      state.token = token
    },

    setStatus (state, status) {
      state.status = status
    },

    setInvite (state, invite) {
      state.invite = invite
    }
  },

  getters: {
    getToken (state) {
      return state.token
    },

    getStatus (state) {
      return state.status
    },

    getInvite (state) {
      return state.invite
    }
  },

  actions: {
    async readByToken ({ commit }, token) {
      commit('setToken', token)
      commit('setStatus', STATUS_LOADING)

      try {
        const invite = await InviteService.getHuntInviteByToken(token)

        commit('setInvite', invite)
        commit('setStatus', invite.respondedAt !== null ? STATUS_ALREADY_ANSWERED : STATUS_DEFAULT)

        return invite
      } catch (error) {
        commit('setStatus', STATUS_NOT_FOUND)
        console.error(error)
      }
    },

    async answerInvite ({ getters, commit }, accept) {
      const token = getters.getToken
      const invite = getters.getInvite
      const huntId = invite.id

      commit('setStatus', accept ? STATUS_ACCEPTING : STATUS_DECLINING)

      try {
        await InviteService.answerHuntInvite(accept, token)

        // Remove invite from global invites or it will show up in the dashboard and calendar
        commit('hunt/invites/remove', huntId, { root: true })

        commit('setStatus', accept ? STATUS_ACCEPTED : STATUS_DECLINED)
      } catch (error) {
        commit('setStatus', STATUS_DEFAULT)
        throw error
      }
    }
  }
}
