<template>
  <Fragment>
    <div v-if="!hasAuth && !user" class="buttons">
      <RouterLink to="/signin" class="button is-primary">{{ $t('general.signin') }}</RouterLink>
      <RouterLink to="/join" class="button">{{ $t('general.signup') }}</RouterLink>
    </div>

    <div
      :class="{ 'is-active': isActive }"
      class="user-dropdown-background"
      @click="closeDropdown">
    </div>

    <div
      v-if="hasAuth && user"
      :class="{ 'is-active': isActive }"
      class="user-dropdown push-left-xxs">
      <a class="button is-white has-shadow" @click="toggleDropdown">
        <span class="user-dropdown-name is-hidden-mobile">
          {{ fullName }}
        </span>

        <ProfilePicture
          :userId="Number(user.id)"
          :name="fullName"
          size="x-small"
          round>
        </ProfilePicture>
      </a>

      <div class="user-dropdown-content box">
        <DeveloperTools
          v-if="current === 'devtools'"
          @back="setCurrent('')"
          @close="closeDropdown">
        </DeveloperTools>

        <Language
          v-if="current === 'language'"
          @back="setCurrent('')"
          @close="closeDropdown">
        </Language>

        <Games
          v-if="current === 'games'"
          @back="setCurrent('')"
          @close="closeDropdown">
        </Games>

        <Feedback
          v-if="current === 'feedback'"
          @back="setCurrent('')"
          @close="closeDropdown">
        </Feedback>

        <div v-if="current === ''" class="card-content is-paddingless">
          <Media />

          <ul class="user-dropdown-menu">
            <li v-if="isDeveloper" class="has-children" @click="setCurrent('devtools')">
              <a>
                <Icon name="icon-terminal" />
                <span>{{ $t('general.developer') }}</span>
              </a>
            </li>

            <RouterLink to="/profile" tag="li">
              <a>
                <Icon name="icon-user" />
                <span>{{ $t('nav.profile') }}</span>
              </a>
            </RouterLink>

            <RouterLink to="/profile/subscription" tag="li">
              <a>
                <Icon name="icon-shopping-cart" />
                <span>{{ $t('general.myAccount') }}</span>
              </a>
            </RouterLink>
          </ul>

          <ul class="user-dropdown-menu">
            <li class="has-children" @click="setCurrent('language')">
              <a>
                <Icon name="icon-flag" />
                <span>{{ $t('general.language') }}</span>
              </a>
            </li>

            <li class="has-children" @click="setCurrent('games')">
              <a>
                <Icon name="icon-target" />
                <span>{{ $t('general.games') }}</span>
              </a>
            </li>

            <li class="has-children" @click="setCurrent('feedback')">
              <a>
                <Icon name="icon-message-square" />
                <span>{{ $t('userDropdown.feedback') }}</span>
              </a>
            </li>

            <li>
              <a :href="supportUrl" target="_blank">
                <Icon name="icon icon-heart" />
                <span>{{ $t('general.help') }}</span>
              </a>
            </li>

            <RouterLink to="/signout" tag="li">
              <a>
                <Icon name="icon icon-power" />
                <span>{{ $t('general.signout') }}</span>
              </a>
            </RouterLink>
          </ul>
        </div>
      </div>
    </div>
  </Fragment>
</template>

<script>
import { mapGetters } from 'vuex'
import Media from './components/Media.vue'
import DeveloperTools from '@/components/general/developerTools/DeveloperTools.vue'
import Language from './components/Language.vue'
import Games from './components/Games.vue'
import Feedback from './components/Feedback.vue'

export default {
  components: {
    Media,
    DeveloperTools,
    Language,
    Games,
    Feedback
  },

  data () {
    return {
      isDeveloper: false,
      isActive: false,
      isMap: false,

      current: '',

      isLanguageActive: false,
      isDevToolsActive: false,
      isFeedbackActive: false
    }
  },

  computed: {
    ...mapGetters({
      user: 'user/data',
      hasAuth: 'auth/hasAuth',
      hasPro: 'subscription/hasPro'
    }),

    firstName () {
      return this.user && this.user.firstName
    },

    lastName () {
      return this.user && this.user.lastName
    },

    fullName () {
      return `${this.firstName} ${this.lastName}`
    },

    supportUrl () {
      return process.env.VUE_APP_SUPPORTURL
    }
  },

  watch: {
    '$route' (to, from) {
      this.closeDropdown()
    }
  },

  beforeMount () {
    this.checkPermissions()
  },

  methods: {
    async checkPermissions () {
      this.isDeveloper = await this.$store.dispatch('ui/isDeveloper')
    },

    toggleDropdown () {
      if (!this.isActive) {
        this.isActive = true
      } else {
        this.isActive = false
        setTimeout(this.restoreCurrent, 150) // wait for animation
      }
    },

    closeDropdown () {
      this.isActive = false
      setTimeout(this.restoreCurrent, 150) // wait for animation
    },

    restoreCurrent () {
      this.current = ''
    },

    setCurrent (tab) {
      this.current = tab
    }
  }
}
</script>

<style lang="scss">
.user-dropdown {
  position: relative;
  z-index: 40;

  &:focus {
    outline: 0;
  }

  .user-dropdown-name {
    color: #333;
    margin-right: 0.75rem;
    max-width: 8rem;

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .user-dropdown-content {
    visibility: hidden;
    opacity: 0;
    overflow: hidden;
    padding: 0;
    position: fixed;
    z-index: 40;
    align-self: flex-start;
    margin-top: 0.75rem;
    right: 1.5rem;
    width: 17.5rem;
    transition: all 0.15s ease-out;
    transform: translateY(0.5rem);
  }

  &.is-active .user-dropdown-content {
    visibility: visible;
    opacity: 1;
    transform: translateY(0);
  }

  .user-dropdown-menu {
    border-top: 1px solid #f0f0f0;

    li {
      cursor: pointer;
      display: block;
      border-bottom: 1px solid #f0f0f0;

      &:hover {
        background: #eee;
      }

      &:last-of-type {
        border-bottom: 0;
      }

      a,
      a:visited {
        padding: 0.5rem 1rem;
        display: flex;
        align-items: center;
        color: #666;

        .icon {
          margin-right: 0.5rem;
        }
      }

      &.has-children {
        a,
        a:visited {
          &::after {
            font-family: "icons", sans-serif;
            content: "\e933";
            position: absolute;
            right: 1rem;
          }
        }
      }
    }
  }

  &-background.is-active {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 15;

    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0);
  }
}
</style>
