import BoundaryService from '@/api/map/boundary/boundaryService'
import SubAreaService from '@/api/map/subareas/subAreaService'

import MarkerService from '@/api/map/markers/markerService'
import MarkerModel from '@/api/map/markers/markerModel'

export default {

  readBoundary ({ commit }, huntAreaId) {
    commit('setBoundary', null)

    return new Promise(async (resolve, reject) => {
      try {
        const bounds = await BoundaryService.read(huntAreaId)
        commit('setBoundary', bounds)
        resolve(bounds)
      } catch (error) {
        reject(error)
      }
    })
  },

  readMarkers ({ commit }, huntAreaId) {
    commit('setMarkers', null)

    return new Promise(async (resolve, reject) => {
      try {
        const response = await MarkerService.read(huntAreaId, 'markers')
        const $markers = response.map(marker => new MarkerModel(marker))
        commit('setMarkers', $markers)
        resolve($markers)
      } catch (error) {
        reject(error)
      }
    })
  },

  readSubAreas ({ commit }, huntAreaId) {
    commit('setSubAreas', null)

    return new Promise(async (resolve, reject) => {
      try {
        const $subAreas = await SubAreaService.read(huntAreaId, 'saat')
        commit('setSubAreas', $subAreas)
        resolve($subAreas)
      } catch (error) {
        reject(error)
      }
    })
  },

  readForbiddenAreas ({ commit }, id) {
    commit('setForbiddenAreas', null)

    return new Promise(async (resolve, reject) => {
      try {
        const forbiddenAreas = await SubAreaService.read(id, 'forbiddenArea')
        commit('setForbiddenAreas', forbiddenAreas)
        resolve(forbiddenAreas)
      } catch (error) {
        reject(error)
      }
    })
  },

  setZoom ({ commit }, zoom) {
    commit('setZoom', zoom)
  },

  setType ({ commit }, type) {
    commit('setType', type)
  },

  setMarkerSize ({ commit }, size) {
    commit('setMarkerSize', size)
  },

  setLabelSize ({ commit }, size) {
    commit('setLabelSize', size)
  },

  getSubAreaWithId ({ state }, subAreaId) {
    for (let i = 0; i < state.subAreas.length; i++) {
      if (state.subAreas[i].id === subAreaId) {
        return state.subAreas[i]
      }
    }

    return null
  },

  toggleSettingsPanel ({ commit }) {
    commit('toggleSettingsPanel')
  }
}
