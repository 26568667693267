export default {
  title: "Calendrier",
  huntAreas: "Territoires de chasse",
  invites: "Invitations",
  showAll: "Afficher tout",
  where: "Où",
  notInvitedMessage: "Vous ne participez pas à la chasse et ne pouvez donc pas voir toutes les informations à son sujet.",
  noInvitationText: "Aucune description.",
  noComment: "Aucun commentaire.",
  noParticipants: "Aucun participant.",
  seeCompleteReport: "Voir le rapport complet",
  seeCompletePlanning: "Voir la planfication complète",
  hunt: "Chasse",
  huntingReport: "Rapport de chasse",
  invited: "Invité",
  noInvites: "Vous n'avez aucune invitation sans réponse.",

  loadActivitiesFailedErrorMessage: "Une erreur s'est produite et le calendrier n'a pas pu se charger. Veuillez réessayer.",
  noHuntAreasText: "<p> Vous n'êtes membre d'aucun territoire de chasse.</p><p>Vous devez faire partie d'au moins un territoire de chasse afin de créer des activités ou de planifier une chasse.</p>",

  planHunt: "Planifier la chasse",
  planHuntHelpText: "Choisissez le territoire de chasse pour lequel la chasse est prévue.",
  planHuntFailedErrorMessage: "Une erreur s'est produite et la planification d'une nouvelle chasse a échoué. Veuillez réessayer.",

  startsAt: "Commence à",
  startedAt: "Commencé à",
  endsAt: "Fini le",
  endedAt: "Terminé à",

  activity: {
    new: "Nouvel évènement",
    huntArea: "Zone de chasse",
    title: "Titre",
    titlePlaceholder: "Par exemple, réunion, barbecue, etc.",
    titleRequired: "Vous devez saisir un titre",
    from: "Commence à",
    to: "Fini le",
    fromRequired: "Vous devez préciser quand l'événement commence",
    toRequired: "Vous devez préciser quand l'événement se termine",
    description: 'Description',
    descriptionPlaceholder: "Décrivez l'événement plus en détail",
    participants: 'Participants',
    noParticipants: "Aucun membre n'a été invité à l'événement.",
    invitedNoAnswer: "Pas de réponse",
    invitedAccepted: "Accepté",
    invitedDeclined: "Refusé\n",
    removeTitle: "Supprimer l'événement ?",
    removeMessage: "Voulez-vous vraiment supprimer l'événement ?",
    removeFailedErrorMessage: "Une erreur s'est produite et l'événement n'a pas pu être supprimé. Veuillez réessayer.",
    saveFailedErrorMessage: "Une erreur s'est produite et les modifications n'ont pas pu être enregistrées. Veuillez réessayer.",
    createdBy: "Créé par {name}, le {date}",
    unknown: "Inconnu",
    removeParticipation: "Supprimer la participation"
  },

  moonPhases: {
    newMoon: "Nouvelle lune",
    waxingCrescentMoon: "Croissant de lune croissant",
    firstQuarterMoon: "Premier quartier de lune",
    waxingGibbousMoon: "Lune gibbeuse croissante",
    fullMoon: "Pleine lune",
    waningGibbousMoon: "Lune gibbeuse décroissante",
    lastQuarterMoon: "Dernier quartier de lune",
    waningCrescentMoon: "Croissant de lune décroissant"
  }
}
