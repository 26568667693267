import general from './general.js'
import nav from './nav.js'
import userDropdown from './user-dropdown.js'

import auth from '@/modules/auth/translations/en'
import profile from '@/modules/profile/translations/en'
import invite from '@/modules/invites/translations/en'
import calendar from '@/modules/calendar/translations/en'
import advertisement from '@/modules/advertisement/translations/en'
import dashboard from '@/modules/dashboard/translations/en'
import news from '@/modules/news/translations/en'
import document from '@/modules/huntArea/documents/translations/en'
import hunts from '@/modules/huntArea/hunts/translations/en'
import report from '@/modules/huntArea/report/translations/en'
import viltrapport from '@/modules/huntArea/viltrapport/translations/en'
import gps from '@/modules/gps/translations/en'
import user from '@/modules/user/translations/en'
import members from '@/modules/huntArea/members/translations/en'
import core from '@/modules/core/translations/en'
import settings from '@/modules/huntArea/settings/translations/en.js'
import gallery from '@/modules/huntArea/gallery/translations/en.js'

import areas from './views/areas.js'
import area from './views/area/'
import map from './map.js'
import hunt from './hunt.js'
import subscription from '@/modules/subscription/translations/en.js'
import campaign from './campaign.js'
import huntArea from './huntArea.js'

export default {
  general,
  nav,
  userDropdown,

  auth,
  areas,
  area,
  dashboard,
  map,
  profile,
  hunts,
  hunt,
  report,
  news,
  invite,
  gps,
  calendar,
  gallery,
  settings,
  viltrapport,
  subscription,
  document,
  campaign,
  huntArea,
  advertisement,
  user,
  members,
  core
}
