<template>
  <textarea
    class="textarea"
    :rows="rows"
    :value="value"
    :disabled="disabled"
    :class="{ 'has-fixed-size': !isResizable }"
    @input="$emit('input', $event.target.value)" />
</template>

<script>
export default {
  props: {
    value: {
      type: String,
      default: ''
    },

    isResizable: {
      type: Boolean,
      default: false
    },

    disabled: {
      type: Boolean,
      default: false
    },

    rows: {
      type: Number,
      default: 3
    }
  }
}
</script>
