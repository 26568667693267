import HuntService from '@/api/hunt/huntService'
import ParticipantService from '@/api/hunt/participants/participantService'
import HuntEventService from '@/api/hunt/events/huntEventService'
import HuntDriveService from '@/api/hunt/drives/huntDriveService'
import SubAreaService from '@/api/map/subareas/subAreaService'
import MarkerService from '@/api/map/markers/markerService'

export default {
  namespaced: true,

  state: {
    hunt: null,
    note: null,
    participants: null,
    events: null,
    drives: null,
    subAreas: null,
    markers: null
  },

  getters: {
    getHunt (state) {
      return state.hunt
    },

    getNote (state) {
      return state.note
    },

    getParticipants (state) {
      if (state.participants !== null) {
        if (state.hunt.status === 'finished') {
          return state.participants.filter(participant => participant.status === 'member')
        } else {
          return state.participants
        }
      }
      return null
    },

    getEvents (state) {
      return state.events
    },

    getDrives (state) {
      return state.drives
    },

    getSubAreas (state) {
      return state.subAreas
    },

    getMarkers (state) {
      return state.markers
    }
  },

  mutations: {
    setHunt (state, hunt) {
      state.hunt = hunt
    },

    setNote (state, note) {
      state.note = note
    },

    setParticipants (state, participants) {
      state.participants = participants
    },

    setEvents (state, events) {
      state.events = events
    },

    setDrives (state, drives) {
      state.drives = drives
    },

    setSubAreas (state, subAreas) {
      state.subAreas = subAreas
    },

    setMarkers (state, markers) {
      state.markers = markers
    }
  },

  actions: {
    select ({ commit }, hunt) {
      commit('setHunt', hunt)
      commit('setNote', null)
      commit('setParticipants', null)
      commit('setEvents', null)
      commit('setDrives', null)
      commit('setSubAreas', null)
      commit('setMarkers', null)

      HuntService.read(hunt.id).then(huntData => {
        commit('setNote', huntData.note)
      })

      ParticipantService.getMembers(hunt.id).then(participants => {
        commit('setParticipants', participants)
      })

      if (hunt.status === 'finished') {
        HuntEventService.read(hunt.id).then(events => {
          commit('setEvents', events)
        })
      } else {
        HuntDriveService.getDrives(hunt.id).then(drives => {
          commit('setDrives', drives)

          if (drives.length > 0) {
            SubAreaService.read(hunt.huntAreaId, 'saat').then(subAreas => {
              commit('setSubAreas', subAreas)
            })

            MarkerService.read(hunt.huntAreaId).then(markers => {
              commit('setMarkers', markers)
            })
          } else {
            commit('setSubAreas', [])
            commit('setMarkers', [])
          }
        })
      }
    }
  }
}
