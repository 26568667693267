<template>
  <div>
    <Checkbox
      v-if="hasPro"
      v-model="isPropertyLayerVisible"
      :label="$t('map.settings.propertyMap')"
      :loading="isPropertyMapLoading"
      type="switch">
    </Checkbox>

    <div v-else class="level">
      <div class="level-left">
        {{ $t('map.settings.propertyMap') }}
      </div>

      <div class="level-right">
        <Icon name="icon-lock" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters({
      hasPro: 'subscription/hasPro'
    }),

    isPropertyMapLoading () {
      const loading = this.$store.getters['map/isPropertyMapLoading']
      return this.isPropertyLayerVisible && loading
    },

    isPropertyLayerVisible: {
      get () {
        return this.$store.getters['map/settings/isPropertyLayerVisible']
      },

      set (visibility) {
        return this.$store.dispatch('map/settings/togglePropertyLayerVisibility', visibility)
      }
    }
  }
}
</script>
