export default {
  getPro: "Obtenez Pro",

  selectLanguage: "Choisir la langue",

  feedback: "Retour",
  leaveFeedback: "Faire un retour",

  games: "Espèces",
  elkGame: "Traces/pieds d'élan",
  boarGame: "Chasse au sanglier",

  theme: "",
  themeContent: "",
  dark: ""
}
