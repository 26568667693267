<template>
  <Dropdown
    :icon="icon"
    :alignment="alignment"
    :size="size"
    type="icon-only">
    <slot></slot>
  </Dropdown>
</template>

<script>
export default {
  props: {
    alignment: {
      type: String,
      default: 'left'
    },

    size: {
      type: String,
      default: 'normal'
    },

    icon: {
      type: String,
      default: 'icon-more-vertical'
    }
  }
}
</script>
