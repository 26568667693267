<template>
  <div v-if="!isPrinting" class="zoom-buttons">
    <Button
      v-if="!noMaximize"
      :type="fullscreenButtonType"
      :tooltip="(!isFullscreen ? $t('map.general.fullscreen') : $t('map.general.normal'))"
      @click="toggleFullscreen">
      <Icon :name="fullscreenIcon" />
    </Button>

    <Button
      type="white"
      :class="{ 'is-dimmed is-disabled': !canZoomIn }"
      :tooltip="$t('map.general.zoomIn')"
      @click="zoomIn">
      <Icon name="icon-zoom-in" />
    </Button>

    <Button
      type="white"
      :class="{ 'is-dimmed is-disabled': !canZoomOut }"
      :tooltip="$t('map.general.zoomOut')"
      @click="zoomOut">
      <Icon name="icon-zoom-out" />
    </Button>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { MAP_MAX_ZOOM, MAP_MIN_ZOOM } from '../map'

export default {
  props: {
    noMaximize: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    ...mapGetters({
      isPrinting: 'map/print/isPrinting',
      isFullscreen: 'map/settings/isFullscreen',
      currentZoom: 'map/settings/zoom'
    }),

    fullscreenButtonType () {
      return this.isFullscreen ? 'primary' : 'white'
    },

    fullscreenIcon () {
      return this.isFullscreen ? 'icon-minimize-2' : 'icon-maximize-2'
    },

    canZoomIn () {
      return this.currentZoom < MAP_MAX_ZOOM
    },

    canZoomOut () {
      return this.currentZoom > MAP_MIN_ZOOM
    }
  },

  methods: {
    toggleFullscreen () {
      if (this.isFullscreen) {
        this.$store.dispatch('map/settings/minimize')
      } else {
        this.$store.dispatch('map/settings/maximize')
      }
    },

    zoomIn () {
      this.$store.commit('map/settings/zoomIn')
    },

    zoomOut () {
      this.$store.commit('map/settings/zoomOut')
    }
  }
}
</script>

<style lang="scss" scoped>
.zoom-buttons {
  position: absolute;
  top: 50%;
  right: 1.5rem;
  transform: translateY(-50%);
  z-index: 1;
  display: flex;
  flex-direction: column;
  touch-action: none; // disable pinch-to-zoom iOS

  .button {
    margin: 0 0 0.75rem 0 !important;

    &:last-of-type {
      margin: 0 !important;
    }
  }
}
</style>
