import api from '@/api/'
import FolderModel from '../models/folderModel'

class FolderService {
  static async read (huntAreaId, folderId) {
    try {
      const { data } = await api.get(`/areas/${huntAreaId}/folders?folderId=${folderId}`)
      const folders = data.map(data => new FolderModel(data))
      return folders
    } catch (error) {
      console.error(`Failed to read folders for area ${huntAreaId}.`)
      throw error
    }
  }

  static async save (huntAreaId, folder) {
    try {
      let response = null

      if (folder.id === null) {
        response = await api.post(`/areas/${huntAreaId}/folders`, folder.toJSON())
      } else {
        response = await api.put(`/areas/${huntAreaId}/folders/${folder.id}`, new FolderModel(folder).toJSON())
      }

      return new FolderModel(response.data)
    } catch (error) {
      console.error(`Failed to create folder for parent folder ${folder.parentId}.`)
      throw error
    }
  }

  static async create (huntAreaId, folder) {
    try {
      const { data } = await api.post(`/areas/${huntAreaId}/folders`, folder.toJSON())
      return new FolderModel(data)
    } catch (error) {
      console.error(`Failed to create folder for parent folder ${folder.parentId}.`)
      throw error
    }
  }

  static async update (huntAreaId, folder) {
    try {
      const { data } = await api.put(`/areas/${huntAreaId}/folders/${folder.id}`, new FolderModel(folder).toJSON())
      return new FolderModel(data)
    } catch (error) {
      console.error(`Failed to update folder ${folder.id}.`)
      throw error
    }
  }

  static async delete (huntAreaId, folder) {
    try {
      await api.delete(`/areas/${huntAreaId}/folders/${folder.id}`)
    } catch (error) {
      console.error(`Failed to delete folder ${folder.id}.`)
      throw error
    }
  }
}

export default FolderService
