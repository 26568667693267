export default {
  invites: 'Invites',
  noInvites: 'There\'s no invites.',
  showMoreInvites: ' | Show 1 more invite | Show {count} more invites',
  ongoingHunts: 'Ongoing',
  ongoingHunt: 'Ongoing hunt',
  planningHunts: 'Planning',
  noPlanningHunts: 'There\'s no hunts under planning.',
  plannedHunts: 'Planned',
  plannedHunt: 'Planned hunt',
  noPlannedHunts: 'There\'s no planned hunts.',
  finishedHunts: 'Finished',
  noFinishedHunts: 'There\'s no finished hunts.',
  saving: 'Saving...',

  title: 'Hunt',
  hunt: "Hunt",
  hunts: 'Hunts',
  noDateAvailable: 'No date specified',
  overview: 'Overview',
  overviewHelpText: 'Here you can share information and practical details about the hunt for all participants.',
  date: "Date",
  startDate: 'Date',
  startTime: 'Time',
  startsAt: 'Begins',
  endsAt: 'Ends',
  toHunt: 'To hunt',

  invitation: 'Invite',
  invitationToHunt: 'Invited to hunt',
  invitationHelpText: 'Write an invite to the participants. Information that should appear is where and when to gather and other practical details about the hunt.',

  huntableGames: {
    title: 'Huntable game',
    helpText: 'Specify which game is allowed during the hunt.',
    noHuntableGamesSelected: 'No huntable game specified.',
    add: 'Add game',
    species: 'Species',
    comment: 'Comment',
    mammals: 'Mammals',
    noMammalsAvailable: 'No mammals available',
    birds: 'Birds',
    noBirdsAvailable: 'No birds available',
    addFailedErrorMessage: 'An error has occurred and the game could not be added. Please try again.',
    removeFailedErrorMessage: 'An error has occurred and the game could not be removed. Please try again.'
  },

  noDateSelected: 'You need to pick a date before you can select a huntable game',
  participant: 'Participant',
  participants: 'Participants',
  accepted: 'Accepted',
  declined: 'Declined',
  waitingForReply: 'Waiting for reply',
  unknownParticipant: 'Unknown',
  addHuntLeader: 'Add leader',
  addTrackingHunter: 'Select the hunting participant who manages the search during the hunt.',
  removeParticipantFailedErrorMessage: 'An error has occurred and the member could not be removed. Please try again.',
  assign: 'Assign',

  assignments: {
    title: 'Stand and role assignments',
    helpText: 'Plan your hunt by separating the hunt into different drives and assign roles to all participants.',
    addArea: 'Add drive',
    entireHuntArea: 'Entire hunting ground',
    createDriveFailedErrorMessage: 'An error has occurred and the drive could not be created. Please try again.',
    removeDrive: 'Are you sure you want to remove the drive <strong>{name}</strong> and associated assignments?',
    removeDriveErrorMessage: 'An error has occurred and it was not possible to remove the drive. Please try again.',
    removeMarkerFailedErrorMessage: 'An error has occurred and it was not possible to remove the assignment. Please try again.',
    stand: 'Stand',
    noAssignments: 'No stand or role assignments made.',
    showAllParticipants: 'Show all participants',
    hideDeclinedParticipants: 'Hide participants who have declined'
  },

  // Participants
  huntLeader: {
    title: 'Hunt leader',
    helpText: 'Select a participant who is the hunting leader throughout the hunt.',
    errorMessage: 'An error has occurred and a leader could not be selected. Please try again.'
  },

  trackingHunter: {
    title: 'Tracking hunter',
    helpText: 'Select a participant who manages the search during the hunt.',
    noneSelected: 'None selected',
    errorMessage: 'An error has occurred and a tracking hunter could not be selected. Please try again.'
  },

  // Add participants modal dialog
  addParticipants: {
    title: 'Add participants',
    helpText: 'Add all participants to be included in the hunt. You can add members of the hunting team or invite guests by searching for their name or email.',
    searchPlaceholder: 'Name or email',
    allMembersIncludedInHunt: 'All members of the hunting team are included in the hunt.',
    searchMatchCounterMessage: '{userCount} users matched your search.',
    noUserWithEmail: 'We can\'t find an account in WeHunt with this e-mail address. Would you like to send an invite?',
    addWithCount: 'Add | Add <strong>1</strong> participant | Add <strong>{userCount}</strong> participants'
  },

  // Different roles during hunt on a drive
  roles: {
    dogHandler: 'Dog handler',
    standShooter: 'Stand shooter',
    driveHunter: 'Beater',
    unassigned: 'Unassigned'
  },

  // Summary/confirm modal
  summary: {
    title: 'Send invites?',
    summaryHelpText: 'All participants will receive an invite via email and all participants who already have the app will also receive a note.',
    inviteTitle: 'Invites have been sent to all participants'
  },

  selectStand: 'Select stand',
  assignNotification: 'Click on a <strong>Stand</strong> or <strong>Tower</strong> to assign this to <strong>{name}</strong>',
  reports: "Reports",
  wannaInviteEmail: '<a href="mailto: {email}">{email}</a>&nbsp;doesn\'t have a WeHunt account. Would you like to send an invite?',
  hasNoAccount: "Does not have a <strong>WeHunt</strong> account",
  noMembersFound: 'There\'s no more members in this hunting ground.',
  noUsersFound: 'No users found.',
  isAlreadyAMember: 'This user is already a participant.',
  huntCreatedMessage: 'The hunt has been created and we\'ve sent invites to all participants.',

  mandatoryFieldsDialogTitle: 'Not complete',
  mandatoryFieldsDialogText: 'Please complete all mandatory fields marked with *.',

  planHunt: 'Plan hunt',
  planHuntFailedErrorMessage: 'An error has occurred and the planning of a new hunt failed. Please try again.',
  details: 'Details',
  huntReport: 'Hunt report',

  settings: 'Settings',
  terrainMap: 'Terrain',
  propertyMap: 'Property',

  tower: 'Tower',

  events: {
    title: 'Events',
    helpText: 'Here you can report interesting events that occurred during the hunt',
    noEvents: 'No events occurred.',
    locked: 'You can\'t edit the event as it was created by another hunter.',
    kill: "Downed game",
    killShort: "Downed",
    observation: 'Observation',
    observationShort: 'Obs',
    selectSpecies: 'Select species',
    eventPanelHelpText: 'Click on the map to mark the location of the event.',
    createFailedErrorMessage: 'An error has occurred and the event could not be created. Please try again.',
    updateFailedErrorMessage: 'An error has occurred and the event could not be updated. Please try again.',
    removeFailedErrorMessage: 'An error has occurred and the event could not be removed. Please try again.',
    removeConfirmMessage: "Are you sure you want to remove the <strong>{type}</strong> that occurred on <strong>{date}</strong>."
  },

  // Chat
  sendMessageToParticipantsPlaceholder: 'Send a message to all participants',

  // Playback
  chat: 'Chat',
  play: 'Replay hunt',
  hunters: 'Hunters',
  dogs: 'Dogs',
  noDogsInHunt: 'No dogs with a compatible GPS participated in the hunt.',
  trailLength: 'Trail length',
  noEventsYet: 'No events have occurred yet.',
  noMessagesYet: 'No messages have been sent yet.',

  showPanelText: 'Show participants, messages and events',
  hidePanelText: 'Hide all',

  gameTitle: 'Game',

  invite: {
    acceptInvite: 'Accept invite?',
    accept: 'Accept',
    declineInvite: 'Decline invite?',
    decline: 'Decline',
    acceptConfirmMessage: "Are you sure that you want to accept the invite to hunt at {huntArea} on {date}?",
    declineConfirmMessage: "Are you sure that you want to decline the invite to hunt at {huntArea} on {date}?",
    respondToInviteFailedErrorMessage: 'An error has occurred and it was not possible to answer the invite. Please try agian.'
  },

  removeHuntFailedErrorMessage: 'An error has occurred and it was not possible to remove the hunt. Please try again.',
  leave: 'Leave hunt',
  confirmLeaveTitle: 'Leave hunt?',
  confirmLeaveMessage: 'Are you sure you want to leave the hunt?'
}
