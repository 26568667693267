export default {
  createNew: 'Skapa ny jaktmark',
  newArea: 'Ny jaktmark',
  members: "Medlemmar",
  area: 'Jaktmark',
  areas: 'Jaktmarker',
  hunts: 'Jakter',
  noAreasFound: 'Du har inte skapat någon jaktmark ännu.',
  areaName: 'Jaktmarken/lagets namn',
  name: 'Namn',
  invalidName: 'Du måste ange ett namn.'
}
