import Vue from 'vue'
import Vuex from 'vuex'

import auth from '@/modules/auth/store/authStore'
import huntarea from './huntarea/'
import map from './map/'
import profile from '@/modules/profile/store/profileStore'
import user from '@/modules/user/store/userStore'
import hunt from './hunt/'
import calendar from '@/modules/calendar/store/calendarStore'
import ui from './ui/'
import invites from '@/modules/invites/store/inviteStore'
import news from '@/modules/news/store/newsStore'
import gps from '@/modules/gps/store/gpsStore'
import subscription from '@/modules/subscription/store/subscriptionStore'
import advertisement from '@/modules/advertisement/store/advertisementStore'
import campaign from './campaignStore'
import report from '@/modules/huntArea/report/store/reportStore'

Vue.use(Vuex)

const store = new Vuex.Store({
  modules: {
    auth,
    huntarea,
    map,
    profile,
    user,
    hunt,
    calendar,
    ui,
    invites,
    news,
    gps,
    subscription,
    advertisement,
    campaign,
    report
  }
})

export default store
