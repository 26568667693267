import ChatService from '@/api/hunt/chat/chatService'

export default {
  namespaced: true,

  state: {
    messages: null
  },

  getters: {
    getMessages (state) {
      return state.messages
    }
  },

  mutations: {
    setMessages (state, messages) {
      state.messages = messages
    },

    addMessage (state, message) {
      if (state.messages === null) {
        state.messages = []
      }

      state.messages.push(message)
    }
  },

  actions: {
    async read ({ commit }, huntId) {
      commit('setMessages', null)
      const messages = await ChatService.readAllForHunt(huntId)
      commit('setMessages', messages)
    },

    async sendMessage ({ commit }, { huntId, text }) {
      const message = await ChatService.sendMessage(huntId, text)
      commit('addMessage', message)
    }
  }
}
