import AdvertisementService from "../services/advertisementService"
import AdvertisementModel, {
  ADVERTISEMENT_SPACE_FULL_PAGE,
  ADVERTISEMENT_SPACE_SIGN_IN,
  ADVERTISEMENT_SPACE_SPONSORED_POST
} from "../models/advertisementModel"

export default {
  namespaced: true,

  state: {
    signInAds: null,
    bannerAds: null,
    sponsoredPosts: null
  },

  getters: {
    sponsoredPosts: (state) => state.sponsoredPosts,

    getSignInAd (state, getters, rootState, rootGetters) {
      if (state.signInAds === null) {
        return null
      }

      const language = rootGetters["ui/language"]

      let advertisement = state.signInAds.find(
        (advertisement) => advertisement.language === language
      )
      if (!advertisement) {
        advertisement = state.signInAds.find(
          (advertisement) => advertisement.language === null
        )
      }

      return advertisement
    },

    getBannerAds (state, getters, rootState, rootGetters) {
      const language = rootGetters["ui/language"]
      const bannerAds = state.bannerAds || []

      const languageAds = bannerAds.filter((ad) => ad.language === language)
      const languageIndependentAds = bannerAds.filter(
        (ad) => ad.language === null
      )

      return [...languageAds, ...languageIndependentAds]
    }
  },

  mutations: {
    setSignInAds (state, advertisements) {
      state.signInAds = advertisements
    },

    setSponsoredPosts (state, advertisements) {
      state.sponsoredPosts = advertisements
    },

    setBannerAds (state, bannerAds) {
      state.bannerAds = bannerAds
    }
  },

  actions: {
    async read ({ commit }) {
      try {
        const advertisements = await AdvertisementService.read()

        const signInAds = advertisements.filter(
          (advertisement) => advertisement.space === ADVERTISEMENT_SPACE_SIGN_IN
        )

        // Add default fallback image in case no ads are active
        signInAds.push(
          new AdvertisementModel({
            space: ADVERTISEMENT_SPACE_SIGN_IN,
            imageUrl:
              "https://hitude-service.appspot.com/service/resources/appIntro/data", // App background image
            language: null
          })
        )

        commit("setSignInAds", signInAds)

        const bannerAds = advertisements.filter(
          (advertisement) =>
            advertisement.space === ADVERTISEMENT_SPACE_FULL_PAGE
        )
        commit("setBannerAds", bannerAds)

        const sponsoredPosts = advertisements.filter(
          (advertisement) =>
            advertisement.space === ADVERTISEMENT_SPACE_SPONSORED_POST
        )
        commit("setSponsoredPosts", sponsoredPosts)
      } catch (error) {
        console.error("Failed to read advertisements.\n", error)
        throw error
      }
    }
  }
}
