import GpsLocationModel from './gpsLocationModel'

/**
 * GPS location data for private GPS trails that are not visible to other hunters.
 * Private locations must always be visible otherwise the GPS and trail will not show up during playback.
 */
class GpsPrivateLocationModel extends GpsLocationModel {
  constructor (data) {
    super(data)

    this.visible = true
  }
}

export default GpsPrivateLocationModel
