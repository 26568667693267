import LocationModel from './locationModel'

/**
 * GPS location data for a specific GPS device.
 */
class GpsLocationModel extends LocationModel {
  constructor (data) {
    super(data)

    this.owner = data.owner ? data.owner.substring(0, data.owner.indexOf('_')) : null
    this.speed = data.speed
    this.stand = data.stand
    this.azimut = data.azimut
    this.color = data.color || '#FA6400'
    this.visible = data.visible
  }
}

export default GpsLocationModel
