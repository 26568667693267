/* global google */
import markers from './markers/'

export default {
  getMap: state => state.map,
  getCenter: state => state.center, // object
  getBounds: state => state.bounds,
  getDrawingMode: state => state.drawingMode,

  isMarkersModalActive: state => state.isMarkersModalActive,
  isFilterActive: state => state.isFilterActive,
  isPrintActive: state => state.isPrintActive,
  isImportActive: state => state.isImportActive,
  isExportActive: state => state.isExportActive,

  isPropertyMapLoading: state => {
    return state.propertyLayerTileLoadingProgress.status === 'loading-protected-resources' || state.propertyLayerTileLoadingProgress === 'loading-tiles'
  },

  propertyLayerTileLoadingProgress: state => state.propertyLayerTileLoadingProgress,

  getMarkersInSubArea: state => subArea => {
    const polygon = new window.google.maps.Polygon({ paths: subArea.borderCoordinates })

    return markers.state.markers.filter(marker => {
      const location = new window.google.maps.LatLng(
        marker.location.lat,
        marker.location.lng
      )

      return window.google.maps.geometry.poly.containsLocation(location, polygon)
    })
  },

  getMarkersOutsideBoundary: state => area => {
    const coordinates = area !== null ? area.coordinates : []
    if (coordinates.length === 0) {
      return [] // No boundary, means no markers can be outside it
    }

    const polygon = new window.google.maps.Polygon({ paths: coordinates })

    return markers.state.markers.filter(marker => {
      const location = new window.google.maps.LatLng(
        marker.location.lat,
        marker.location.lng
      )

      return !google.maps.geometry.poly.containsLocation(location, polygon)
    })
  }
}
