export default {
  dashboard: 'Översikt',
  map: 'Karta',
  team: 'Jaktlaget',
  documents: 'Dokument',
  hunts: 'Jakter',
  calendar: 'Kalender',
  gallery: 'Galleri',
  messages: 'Meddelanden',
  settings: 'Inställningar'
}
